<template>
  <div class="h-100">
    <v-container fluid class="white pt-2 h-100 pb-0">
      <ProductPartsSelectionComponent />
    </v-container>
  </div>
</template>
<script>
import ProductPartsSelectionComponent from "@/components/personalize/product-detail/ProductPartsSelectionComponent.vue";
export default {
  name: "PartSelection",
  components: {
    ProductPartsSelectionComponent,
  },
};
</script>
<style src="./product.scss" lang="scss" scopped />
