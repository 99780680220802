<template>
  <div class="h-100 pt-4">
    <v-overlay
      v-model="inProgress"
      scrim="#9d9a99"
      persistent
      contained
      class="align-center justify-center"
    >
      <v-container>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              size="80"
              color="primary"
              align="center"
              justify="center"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
    <ProductPageHeader
      v-if="isMobileDevice"
      :undoRedoImages="{ redoInactive, redoActive, undoActive, undoInactive }"
    />
    <v-container fluid class="white pt-2 h-100 pb-1" v-resize="resize">
      <v-row>
        <v-col cols="12" sm="6" md="8" class="pb-1 pt-1" v-if="!isMobileDevice">
          <v-row>
            <v-col
              cols="12"
              class="py-0 d-flex justify-space-between align-center"
            >
              <v-img
                :src="craneLogoBlue"
                class="cursor-pointer"
                @click="goToHomePage()"
                max-width="100"
                max-height="42"
                aspect-ratio="1"
                contain
              ></v-img>
            </v-col>
            <v-container class="pb-0 max-height-style">
              <v-row class="mx-sm-4 align-start">
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-for="(part, index) in productParts"
                  :key="index"
                  class="pa-1"
                >
                  <div class="summary-border pa-3">
                    <v-img
                      :src="!part.thumbnailUrl ? defaultUrl : part.thumbnailUrl"
                      :lazy-src="defaultUrl"
                      width="auto"
                      height="auto"
                      @click="goToBuilder(part.id, part.userSelected)"
                      class="cursor-pointer"
                    >
                      <div class="text-right" v-if="part.partModified">
                        <v-icon icon class="text-primary">mdi-check</v-icon>
                      </div>
                      <div
                        class="text-right mt-n1 mr-n1"
                        v-if="!part.userSelected"
                      >
                        <v-icon icon class="img-square-color"
                          >mdi-square</v-icon
                        >
                      </div>
                    </v-img>
                    <p
                      class="text-primary pt-2 mb-0 body-font fontsize-12 text-uppercase text-center"
                    >
                      {{ part.name }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          ref="sideBar"
          class="pt-0 pb-1 d-block block-column"
        >
          <v-row class="d-block align-center px-3">
            <v-row class="pb-1 d-flex align-center" v-if="!isMobileDevice">
              <v-col cols="6" :class="designerFlag ? 'py-3' : 'pt-1'">
                <p
                  class="text-uppercase fontsize-16 body ls-n01 text-primary mb-0 text-truncate"
                >
                  Review Your Order
                </p>
              </v-col>
              <v-col class="text-right pt-2" cols="6">
                <ProductPageHeader
                  v-if="!isMobileDevice"
                  :undoRedoImages="{
                    redoInactive,
                    redoActive,
                    undoActive,
                    undoInactive,
                  }"
                />
              </v-col>
            </v-row>
            <v-card tile flat outlined class="productpage-stylebar-border mt-3">
              <v-card-text>
                <v-row no-gutters>
                  <v-col class>
                    <p class="text-uppercase text-primary mb-0 float-left">
                      Design
                    </p>
                  </v-col>
                  <v-col class>
                    <p
                      class="text-uppercase fontsize-18 regular-font text-primary mb-0 float-right"
                    >
                      {{ productOne.name }}
                    </p>
                  </v-col>
                </v-row>
                <div
                  color="text-primary"
                  class="mx-n4 productpage-hr-line my-3"
                ></div>
                <div
                  class
                  v-for="(part, index) in productSummaryDetails"
                  :key="index"
                >
                  <v-row v-if="part.partSelected" class="mt-3">
                    <v-col cols="12" sm="2" class="d-flex pr-0">
                      <span
                        class="text-uppercase text-primary d-block text-truncate mb-0 float-left"
                        >{{ part.name }}</span
                      >
                    </v-col>
                    <v-col cols="12" sm="10">
                      <v-row no-gutters>
                        <v-col cols="12" class="d-flex justify-space-between">
                          <p class="text-uppercase text-primary mb-0">Size</p>
                          <p class="text-uppercase text-primary mb-0">
                            {{ part.size }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12" class="d-flex justify-space-between">
                          <p class="text-uppercase text-primary mb-0">
                            Stock &nbsp;
                          </p>
                          <p
                            class="text-uppercase text-primary mb-0"
                            v-if="part.stock"
                          >
                            {{ part.stock.name }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12" class="d-flex justify-space-between">
                          <p class="text-uppercase text-primary mb-0">
                            Process
                          </p>
                          <p
                            class="text-uppercase text-primary mb-0 text-truncate"
                          >
                            {{ part.printing.toString() }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters v-if="part.linerObject">
                        <v-col cols="12" class="d-flex justify-space-between">
                          <p class="text-uppercase text-primary mb-0">Liner</p>
                          <p
                            class="text-uppercase text-primary mb-0 text-truncate"
                          >
                            {{ part.linerObject.selectedLiner.name }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12" class="d-flex justify-space-between">
                          <p class="text-uppercase text-primary mb-0">Ink</p>
                          <p
                            class="text-uppercase text-primary mb-0 text-truncate"
                          >
                            {{ part.ink.toString() }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-divider color="text-primary"></v-divider>
                    <v-row
                      no-gutters
                      class="all-side-primary-border left-right-border-none mx-n1"
                    >
                      <v-col
                        cols="6"
                        class="d-flex flex-row align-center py-0 right-primary-border"
                      >
                        <v-row no-gutters>
                          <v-col
                            cols="12"
                            class="py-2 d-flex align-center text-center"
                          >
                            <p class="text-uppercase text-primary mb-0 pl-2">
                              {{ productQuantity }} {{ part.name }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col class="my-auto">
                        <h2
                          v-if="!isPromotionApplied"
                          class="text-uppercase text-primary float-right pr-2"
                        >
                          ${{ priceFormatter(part.partPrice) }}
                        </h2>
                      </v-col>
                    </v-row>
                  </v-row>
                </div>
                <v-row no-gutters class="mr-n2">
                  <v-col
                    class="right-primary-border mb-n4 d-flex align-center ml-n1 pt-4 pb-1"
                    cols="6"
                  >
                    <p class="text-uppercase text-primary mb-0">Quantity :</p>
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex flex-row align-center mb-n4 pt-4 pb-1"
                  >
                    <v-row no-gutters>
                      <v-col cols="2">
                        <v-btn
                          variant="plain"
                          :ripple="false"
                          size="x-small"
                          icon
                          :disabled="selectedIndex > 0 ? false : true"
                          class="btn-plus-minus-font"
                          :class="
                            selectedIndex > 0 ? 'btn-plus-minus-opacity' : ''
                          "
                        >
                          <v-icon
                            color="primary"
                            class="header-buttons"
                            @click="changeQuantity(selectedIndex - 1)"
                            >mdi mdi-minus</v-icon
                          >
                        </v-btn>
                      </v-col>
                      <v-col cols="8" class="d-flex align-center text-center">
                        <p class="text-uppercase text-primary ma-auto">
                          {{ productQuantity }}
                        </p>
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          variant="plain"
                          :ripple="false"
                          size="x-small"
                          class="pr-2 btn-plus-minus-font"
                          :class="
                            qtyPriceArray.length == 0
                              ? ''
                              : selectedIndex == qtyPriceArray.length - 1
                              ? ''
                              : 'btn-plus-minus-opacity'
                          "
                          icon
                          :disabled="
                            qtyPriceArray.length == 0
                              ? true
                              : selectedIndex == qtyPriceArray.length - 1
                              ? true
                              : false
                          "
                        >
                          <v-icon
                            color="primary"
                            class="header-buttons"
                            @click="changeQuantity(selectedIndex + 1)"
                            >mdi mdi-plus</v-icon
                          >
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <div class="pl-1 mb-2 pt-2 pt-sm-0">
              <v-checkbox
                v-model="acceptAndApproved"
                @change="checkAcceptAndApproved"
                color="primary"
                class="mt-1 ml-0 color-checkbox"
                hide-details
                label="I’ve reviewed all spelling, dates and other personalized information for accuracy. I acknowledge that Crane is not responsible for additional review, and that my order will be printed as shown. I approve that all my order information is correct."
                :value="true"
              ></v-checkbox>
            </div>
            <div class="pl-1 mb-2 pt-2 pt-sm-0" v-if="printProcessMismatched">
              <v-checkbox
                v-model="printProcessGranted"
                @change="checkMismatchPrintProcess"
                color="primary"
                class="mt-1 ml-0 color-checkbox"
                hide-details
                label="your order includes items that use different printing processes. this may affect your order price or processing time. if you intended this, then you're all  set. please check the box to proceed. if this selection was unitentional, just use the edit design button below to change your selection."
                :value="true"
              ></v-checkbox>
            </div>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          offset-sm="6"
          offset-md="8"
          sm="6"
          md="4"
          ref="sideBar"
          class="pt-5 d-block block-column"
        >
          <v-row class="d-block justify-end align-end max-height-100 px-3">
            <v-row>
              <v-col class="d-flex justify-space-between py-1" cols="12">
                <h3 class="text-uppercase text-primary mb-0">subtotal</h3>
                <h3 class="text-uppercase text-primary mb-0">
                  <v-icon
                    color="primary"
                    v-if="isPromotionApplied"
                    class="header-buttons"
                    >mdi-sale</v-icon
                  >
                  ${{ priceFormatter(subTotal) }}
                </h3>
              </v-col>
            </v-row>
            <v-row class="d-flex align-end justify-end mt-5 pb-3">
              <v-col cols="6" class="py-0 pr-0">
                <v-btn
                  block
                  class="text-uppercase ls-0 body pb-1 px-1 rounded-0"
                  :size="this.$vuetify.display.mdAndUp ? 'x-large' : 'default'"
                  variant="outlined"
                  color="primary"
                  @click="backToEditPart()"
                  >EDIT DESIGN</v-btn
                >
              </v-col>
              <v-col cols="6" class="py-0 pl-0" v-if="!designerFlag">
                <v-btn
                  v-if="!cartItemEdit"
                  :disabled="
                    !subTotal ||
                    acceptAndApproved == false ||
                    isErrorInPrice ||
                    disableSubmit == true
                      ? true
                      : false
                  "
                  block
                  :size="this.$vuetify.display.mdAndUp ? 'x-large' : 'default'"
                  class="text-uppercase ls-0 body pb-1 px-1 rounded-0"
                  tile
                  color="primary"
                  @click="addToCart()"
                  >ADD TO CART</v-btn
                >
                <v-btn
                  v-if="cartItemEdit"
                  :disabled="
                    !subTotal ||
                    acceptAndApproved == false ||
                    isErrorInPrice ||
                    disableSubmit == true
                      ? true
                      : false
                  "
                  block
                  :size="this.$vuetify.display.mdAndUp ? 'x-large' : 'default'"
                  class="text-uppercase ls-0 body pb-1 px-1 rounded-0"
                  tile
                  color="primary"
                  @click="updateToCart()"
                  >UPDATE CART</v-btn
                >
              </v-col>
            </v-row>
            <v-row class="d-flex align-end justify-end pb-3"> </v-row>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="" v-if="projectDetails">
        <v-col cols="6" class="">
          <p class="mt-5">
            {{ projectDetails.version }}
          </p>
        </v-col>
        <v-row class="d-flex align-end justify-end pb-3">
          <v-col cols="12" sm="6" class="pa-2 pb-1">
            <v-btn
              block
              :size="this.$vuetify.display.mdAndUp ? 'x-large' : 'default'"
              class="text-uppercase ls-0 body px-1 rounded-0"
              variant="outlined"
              color="primary"
              @click="addSaveForLater"
              >Save For Later</v-btn
            ></v-col>
            <!-- :disabled="
                subTotal == 0 ||
                acceptAndApproved == false ||
                isErrorInPrice ||
                disableSubmit == true
                  ? true
                  : false
              " -->
        </v-row>
      </v-row>
    </v-container>
    <!-- add session name form  -->
    <v-dialog v-model="dialog" persistent width="450" attach>
      <v-form ref="sesssionForm">
        <v-card>
          <v-card-title>
            <span class="text-h5">Session Details</span>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="sessionName"
                  :rules="rules"
                  label="Session name"
                  clearable
                  variant="outlined"
                  density="comfortable"
                  required
                  class="mt-2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" variant="outlined" @click="cancel()">
              Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="button"
              @click="addToSaveForLater"
              variant="outlined"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- sign in popup -->
    <v-dialog v-model="signInForm" persistent flat max-width="500">
      <v-card flat>
        <v-card-title class="d-flex justify-space-between">
          <p class="h3 mb-0 whyte-regular-font">Sign In</p>
          <v-btn
            @click="closeSignInForm()"
            density="comfortable"
            variant="text"
            :ripple="false"
            icon
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-0">
          <v-alert class="word-break" type="error" v-if="!!errorMessage">{{
            errorMessage
          }}</v-alert>
          <v-alert class="word-break" type="success" v-if="!!signInMessage">{{
            signInMessage
          }}</v-alert>
          <v-form ref="loginForm" v-model="validLogin">
            <v-col class="pt-3 pb-1">
              <v-text-field
                v-model="emailLogin"
                type="email"
                label="Email Address"
                ref="email"
                name="email"
                variant="outlined"
                density="comfortable"
                :rules="commonValidationRules.requiredRules"
              ></v-text-field>
            </v-col>
            <v-col class="pt-1 pb-0">
              <v-text-field
                v-model="passwordLogin"
                label="Password"
                ref="password"
                type="password"
                name="password"
                variant="outlined"
                density="comfortable"
                :rules="commonValidationRules.requiredRules"
              ></v-text-field>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" class="px-7">
              <v-btn
                variant="flat"
                block
                color="primary"
                class="text-white"
                @click="signIn()"
              >
                <v-progress-circular
                  :size="20"
                  color="white"
                  indeterminate
                  v-if="inProgress"
                ></v-progress-circular>
                &nbsp;SIGN IN
              </v-btn>
            </v-col>
            <v-col cols="6" class="py-5 pl-7" align="left">
              <span
                class="text-decoration-none cursor-pointer text-primary"
                @click="openSignUpDialog()"
                >Sign Up</span
              >
            </v-col>
            <v-col cols="6" class="py-5 pr-7" align="right"> </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Sign Up -->
    <v-dialog v-model="signUpForm" persistent flat max-width="500">
      <v-card flat>
        <v-card-title class="d-flex justify-space-between">
          <p class="h3 mb-0 whyte-regular-font">Sign Up</p>
          <v-btn
            @click="closeSignUpForm()"
            density="comfortable"
            variant="text"
            :ripple="false"
            icon
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-0">
          <v-alert class="word-break" type="error" v-if="!!errorMessage">{{
            errorMessage
          }}</v-alert>
          <v-alert class="word-break" type="success" v-if="!!signUpMessage">{{
            signUpMessage
          }}</v-alert>
          <v-form ref="signUpForm" id="signup-form" @submit.prevent="signUp">
            <v-text-field
              class="pt-2"
              label="First Name"
              type="text"
              ref="firstName"
              name="firstName"
              variant="outlined"
              density="comfortable"
              v-model="firstname"
              :rules="firstNameRules"
            ></v-text-field>
            <v-text-field
              class="pt-2"
              label="Last Name"
              type="text"
              ref="lastName"
              name="lastName"
              variant="outlined"
              density="comfortable"
              v-model="lastname"
              :rules="lastNameRules"
            ></v-text-field>

            <v-text-field
              class="pt-2"
              label="Email Id"
              type="email"
              ref="email"
              name="email"
              id="userEmail"
              variant="outlined"
              density="comfortable"
              v-model="email"
              :rules="signUpEmailRules"
            ></v-text-field>

            <v-text-field
              class="pt-2 mt-0"
              type="password"
              label="Password"
              ref="password"
              name="password"
              variant="outlined"
              density="comfortable"
              v-model="password"
              :rules="commonValidationRules.passwordRule"
            ></v-text-field>
            <v-checkbox
              v-model="newsletter"
              label="Subscribe to our newsletter"
              hide-details="true"
              color="indigo"
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" class="px-7">
              <v-btn
                variant="flat"
                block
                color="primary"
                class="text-white"
                @click="signUp(product)"
              >
                <v-progress-circular
                  :size="20"
                  color="white"
                  indeterminate
                  v-if="inProgress"
                ></v-progress-circular>
                &nbsp;SIGN UP
              </v-btn>
            </v-col>
            <v-col cols="6" class="py-5 pl-7" align="left">
              <span
                class="text-decoration-none cursor-pointer text-primary"
                @click="openSignInDialog()"
                >Sign In</span
              >
            </v-col>
            <v-col cols="6" class="py-5 pr-7" align="right"> </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/*eslint-disable*/
import { loginInterrupt } from "@/services/nav";
import { createHelpers } from "vuex-map-fields";
import redoInactive from "@/assets/redo-inactive.png";
import redoActive from "@/assets/redo-active.png";
import undoActive from "@/assets/undo-active.png";
import undoInactive from "@/assets/undo-inactive.png";
import ProductPageHeader from "@/components/layout/header/ProductPageHeader.vue";
import {
  getLable,
  googleAanalytics,
  priceFormatter,
  checkPromotion,
  handleBeforeUnload,
  customAttributeValue,
  commonValidationRules,
  googleAanalyticsEventSaveForLatter,
  isoStringToNormalDate,
} from "@/services/util.service";
import { getPartPricByStructure } from "@/services/getSessionItemsPricerObject.service.js";
const { mapFields: mapProductPartsFields } = createHelpers({
  getterType: "productPartList/getField",
  mutationType: "productPartList/updateField",
});
const craneShopUrl = import.meta.env.VITE_APP_CRANE_DAILY_URL;

import craneLogoBlue from "@/assets/Crane_Logo_blue.png";
import craneLogoWhite from "@/assets/Crane_Logo_white.png";

const { mapFields: mapFieldsAuth } = createHelpers({
  getterType: "auth/getField",
  mutationType: "auth/updateField",
});
const { mapFields: registerInfo } = createHelpers({
  getterType: "registration/getField",
  mutationType: "registration/updateField",
});

import { pricerFieldsIdx, pricerFiles } from "crane-pricer";

export default {
  name: "ProductSummaryComponent",
  props: ["productSummary"],
  components: {
    ProductPageHeader,
  },
  data: () => ({
    commonValidationRules,
    redoInactive,
    redoActive,
    undoActive,
    undoInactive,
    priceFormatter,
    index: 0,
    right: true,
    dialog: false,
    showMenu: false,
    productSummaryDetails: [],
    userSelectedParts: [],
    subTotal: 0,
    productQuantity: 0,
    imagePanelHeight: 0,
    isPageLoader: false,
    defaultUrl:
      "https://s3.amazonaws.com/cdn.crane.com/stg-images/cc12011-1_360.webp",
    disableSubmit: true,
    isErrorInPrice: false,
    qtyPriceArray: [],
    selectedIndex: null,
    processingTime: null,
    builderItems: [],
    printProcessMismatched: false,
    printProcessGranted: false,
    isPromotionApplied: false,
    productPrice: 0,
    acceptAndApproved: false,
    productParts: [],
    priceAdjustmentType: null,
    priceAdjustmentValue: null,
    craneLogoBlue: craneLogoBlue,
    craneLogoWhite: craneLogoWhite,
    sessionName: null,

    productSku: null,
    itemId: null,
    cartId: null,
    userQuantity: null,
    summaryDetails: null,
    customAttributesOfCurrentProduct: null,

    signUpForm: false,
    signInForm: false,
    valid: true,
    validLogin: false,
    lazy: false,
    firstNameRules: [
      (v) => !!v || "First Name is required",
      (v) => (v && v.length >= 3) || "First Name must be at least 3 characters",
    ],
    lastNameRules: [
      (v) => !!v || "Last Name is required",
      (v) => (v && v.length >= 3) || "Last Name must be at least 3 characters",
    ],
    signUpEmailRules: [
      (v) => !!v || "Email Id is required",
      (v) => /.+@.+\..+/.test(v) || "Email Id must be valid",
    ],
  }),
  computed: {
    ...mapFieldsAuth({
      oneLogin: "one",
      emailLogin: "one.email",
      passwordLogin: "one.password",
      errorMessage: "errorMessage",
      inProgress: "inProgress",
      signInMessage: "signInMessage",
    }),
    ...registerInfo([
      "one",
      "one.firstname",
      "one.lastname",
      "one.newsletter",
      "one.email",
      "one.password",
      "signUpMessage",
    ]),
    projectDetails: function () {
      return this.$store.state.layout.product;
    },
    builderSessionId: function () {
      return this.$route.params.sessionId;
    },
    builderSessionItems: function () {
      return this.$store.state.sessionItemList.list;
    },
    // magento v1 product details
    sessionProductDetails: function () {
      return this.$store.state.productOne.sessionProductDetails;
    },
    // builder session details
    sessionOneDetails: function () {
      return this.$store.state.sessionOne.one;
    },
    categoryId: function () {
      return this.$route.query.categoryId;
    },
    designerFlag: function () {
      return this.$route.params.designerMode;
    },
    cartItemEdit: function () {
      if (this.cartId) return true;
      return false;
    },
    isMobileDevice() {
      return this.$vuetify.display.xs ? true : false;
    },
    productOne: function () {
      return this.$store.state.designerOne.one;
    },
    productDetails: function () {
      return this.$store.state.productOne.productDetails;
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    userId: function () {
      if (this.userAuth && this.$store.state.accountOne.one)
        return this.$store.state.accountOne.one.id;
      return "";
    },
    categoryListIdWise: function () {
      return this.$store.state.headerCategoryList.categoryList;
    },
    basicInfo() {
      return this.$store.state.accountOne.one;
    },
    productPricerDataset: function () {
      return this.$store.state.productOne.productPricerDataset;
    },
    cartItems() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductList;
      } else {
        return this.$store.state.cartProduct.cartProductList;
      }
    },
    ...mapProductPartsFields(["list", "inProgress"]),
  },
  watch: {
    productDetails() {
      this.fetchQuantiyArray();
    },
  },
  methods: {
    builderSession(product) {
      let builderSessionOne = null;
      if (
        product &&
        product.options &&
        product.options.options &&
        product.options.options.length > 0
      ) {
        let optionsList = product.options.options;
        builderSessionOne = optionsList.find(
          (x) => x && x.label && x.label.toLowerCase() == "buildersessionid"
        );

        if (builderSessionOne) {
          return true;
        }
      }
      return false;
    },
    // sign in and sign up
    async signIn() {
      try {
        this.cartProductsProgress = true;
        this.inProgress = true;
        const { valid } = await this.$refs.loginForm.validate();
        if (valid) {
          this.$store.dispatch("auth/changeRedirectFlag", false);
          let status = await this.$store.dispatch("auth/login");
          if (status) {
            this.$store.dispatch("auth/changeRedirectFlag", true);
            await this.$store.dispatch("accountOne/basicInfo");
            if (this.builderSessionId) {
              await this.$store.dispatch(
                "userCartProduct/cartProductList",
                this.builderSessionId
              );
              if (this.basicInfo) {
                await this.$store.dispatch("sessionOne/updateOne", {
                  sessionId: this.builderSessionId,
                  property: {
                    state: "saved",
                    orderState: null,
                    quoteData: null,
                    userData: {
                      customer_id: this.basicInfo.id,
                      customer_name: this.basicInfo.firstname,
                    },
                    isNameDefault: false,
                    name: this.sessionName,
                    // summaryTs: new Date().toISOString()
                  },
                });
                googleAanalyticsEventSaveForLatter(
                  this.productDetails,
                  "saved_for_later"
                );
              }

              if (this.customAttributesOfCurrentProduct) {
                let urlKey = customAttributeValue(
                  this.customAttributesOfCurrentProduct,
                  "url_key"
                );
                window.open(`${craneShopUrl}/${urlKey}`, "_self");
              }

              this.signInForm = false;
              this.dialog = false;
            }
          }
          this.inProgress = false;
        }
        this.cartProductsProgress = false;
        this.inProgress = false;
      } catch (err) {
        this.cartProductsProgress = false;
        this.inProgress = false;
        console.error(err);
      }
    },
    async signUp() {
      try {
        // if (this.$refs.signUpForm.validate()) {
        const { valid } = await this.$refs.signUpForm.validate();
        if (valid) {
          this.inProgress = true;
          try {
            this.$store.dispatch("auth/changeRedirectFlag", false);
            let status = await this.$store.dispatch("registration/signUp");
            if (status) {
              this.$store.dispatch("auth/changeRedirectFlag", false);
              await this.$store.dispatch("accountOne/basicInfo");
              if (this.builderSessionId) {
                await this.$store.dispatch(
                  "userCartProduct/cartProductList",
                  this.builderSessionId
                );
                if (this.basicInfo) {
                  await this.$store.dispatch("sessionOne/updateOne", {
                    sessionId: this.builderSessionId,
                    property: {
                      state: "saved",
                      orderState: null,
                      quoteData: null,
                      userData: {
                        customer_id: this.basicInfo.id,
                        customer_name: this.basicInfo.firstname,
                      },
                      isNameDefault: false,
                      name: this.sessionName,
                      // summaryTs: new Date().toISOString()
                    },
                  });
                }

                if (this.customAttributesOfCurrentProduct) {
                  let urlKey = customAttributeValue(
                    this.customAttributesOfCurrentProduct,
                    "url_key"
                  );
                  window.open(`${craneShopUrl}/${urlKey}`, "_self");
                }

                this.signUpForm = false;
                this.signInForm = false;
                this.dialog = false;
                this.inProgress = false;
              }
            }
            this.inProgress = false;
          } catch (err) {
            console.log(err);
            this.inProgress = false;
          }
        }
      } catch (error) {
        this.inProgress = false;
        console.error("Error occured while sign up " + error);
      }
    },
    closeSignInForm() {
      this.signInForm = false;
      this.dialog = false;
      this.$store.dispatch("auth/changeRedirectFlag", true);
    },
    closeSignUpForm() {
      this.signUpForm = false;
      this.dialog = false;
      this.$store.dispatch("auth/changeRedirectFlag", true);
    },
    openSignInDialog() {
      this.signInForm = true;
      this.signUpForm = false;
      this.dialog = false;
    },
    openSignUpDialog() {
      this.signInForm = false;
      this.dialog = false;
      this.signUpForm = true;
    },
    // end of sign and signup

    cancel() {
      this.dialog = false;
      if (this.$refs && this.$refs.sesssionForm)
        this.$refs.sesssionForm.reset();
    },
    async addSaveForLater() {
      this.checkIsValidSession();
      if (this.sessionOneDetails.isNameDefault) {
        this.sessionName = this.sessionOneDetails.name;
        this.dialog = true;
      } else {
        this.sessionName = this.sessionOneDetails.name;
        this.addToSaveForLater("no_default_name");
      }
    },
    async addToSaveForLater(option) {
      try {
        this.cartProductsProgress = true;
        this.customAttributesOfCurrentProduct =
          this.sessionProductDetails.items &&
          this.sessionProductDetails.items.length &&
          this.sessionProductDetails.items[0].customAttributes
            ? this.sessionProductDetails.items[0].customAttributes
            : null;

        if (this.userAuth) {
          let isFormValid = false;
          if (option == "no_default_name") {
            isFormValid = true;
          } else {
            const { valid } = await this.$refs.sesssionForm.validate();
            isFormValid = valid;
          }

          if (isFormValid) {
            // if (this.userAuth) {
            await this.$store.dispatch("sessionOne/updateOne", {
              sessionId: this.builderSessionId,
              property: {
                orderState: "saved",
                quoteData: null,
                userData: {
                  ...this.sessionOneDetails.userData,
                },
                isNameDefault: false,
                name: this.sessionName,
                // summaryTs: new Date().toISOString()
              },
            });

            if (this.$refs && this.$refs.sesssionForm)
              this.$refs.sesssionForm.reset();

            this.dialog = false;
            googleAanalyticsEventSaveForLatter(
              this.productDetails,
              "saved_for_later"
            );
            if (this.customAttributesOfCurrentProduct) {
              let urlKey = customAttributeValue(
                this.customAttributesOfCurrentProduct,
                "url_key"
              );
              window.open(`${craneShopUrl}/${urlKey}`, "_self");
            }
            // }
          }
        } else {
          this.signInForm = true;
        }
      } catch (err) {
        this.$snotify.error(`Error in save for later ` + err.message, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.saveIncident({
          errorObject: err,
          title: "Personalize error for save for later",
          description: "Error while user registration",
        });
        throw err;
      }
    },
    getProductCustomOptions() {
      let sessionProductItemOptions =
        this.sessionProductDetails &&
        this.sessionProductDetails.items &&
        this.sessionProductDetails.items.length &&
        this.sessionProductDetails.items[0].options
          ? this.sessionProductDetails.items[0].options
          : null;
      let productCustomOptions = [];
      if (sessionProductItemOptions) {
        for (let i = 0; i < sessionProductItemOptions.length; i++) {
          let currentOption = sessionProductItemOptions[i];
          if (currentOption.title && currentOption.title.toLowerCase() == "buildersessionid") {
            productCustomOptions.push({
              option_id: currentOption.optionId,
              option_value: this.builderSessionId,
            });
          }
        }
      }
      return productCustomOptions;
    },
    async updateQtyInSessionDetails() {
      try {
        await this.$store.dispatch("sessionOne/updateOne", {
          sessionId: this.builderSessionId,
          property: {
            productData: {
              ...this.sessionOneDetails.productData,
              quantity: this.productQuantity,
              productSubTotal: this.subTotal,
            },
            quoteData: {
              ...this.sessionOneDetails.quoteData,
              // quantity: this.productQuantity,
              productSubTotal: this.subTotal,
            },
            // summaryTs: new Date().toISOString()
          },
        });
        await this.fetchSessionAndItems();
      } catch (e) {
        console.error("Error in updating quantity in session ", e);
        throw e;
      }
    },
    checkAcceptAndApproved() {
      if (this.acceptAndApproved) this.acceptAndApproved = true;
      else this.acceptAndApproved = false;
    },
    resize() {
      let headerHeight = 56;
      this.imagePanelHeight = window.innerHeight - (headerHeight + 100);
    },
    closeSheet(value) {
      this.$store.dispatch("productOne/summarySheet", value);
    },
    fetchQuantiyArray() {
      try {
        let list = this.getCustomAttributeLable(
          this.productDetails.customAttribute,
          "qty_price"
        )
          ? JSON.parse(
              this.getCustomAttributeLable(
                this.productDetails.customAttribute,
                "qty_price"
              )
            )
          : [];
        this.qtyPriceArray = list;
        if (this.qtyPriceArray.length) this.setQuantity();
      } catch (e) {
        console.error(e);
      }
    },
    getCustomAttributeLable(customAttributes, attributeCode) {
      if (customAttributes) return getLable(customAttributes, attributeCode);
    },
    setQuantity() {
      if (this.productQuantity) {
        for (let i = 0; i < this.qtyPriceArray.length; i++) {
          let obj = this.qtyPriceArray[i];
          if (Number(obj.qty) == this.productQuantity) {
            this.selectedIndex = i;
            break;
          }
        }
        this.productQuantity = this.qtyPriceArray[this.selectedIndex]
          ? Number(this.qtyPriceArray[this.selectedIndex].qty)
          : Number(this.qtyPriceArray[0].qty);
        this.productPrice = this.qtyPriceArray[this.selectedIndex]
          ? Number(this.qtyPriceArray[this.selectedIndex].price)
          : Number(this.qtyPriceArray[0].price);
      }
    },
    async changeQuantity(index) {
      if (index >= 0 && index < this.qtyPriceArray.length) {
        //this.$snotify.clear()
        this.selectedIndex = index;
        this.productQuantity = Number(
          this.qtyPriceArray[this.selectedIndex].qty
        );
        this.productPrice = this.qtyPriceArray[this.selectedIndex].price;
        if (!this.isPromotionApplied) {
          // console.log(`Please wait while we fetch price details`)
          this.$snotify.warning(`Please wait while we fetch price details`, {
            position: "rightTop",
            showProgressBar: false,
          });
        }
        await this.productPromotionCheck();
        await this.calculateSubTotal();
      } else {
        this.$snotify.warning(`Quantity is not available`, {
          position: "rightTop",
          showProgressBar: false,
        });
      }
    },
    async calculateSubTotal() {
      try{
      this.inProgress = true;

      this.disableSubmit = true;
      let partPrice = null;
      let subTotal = 0;
      let allErrors = [];

      let isPriceEngineFailed = false;

      for (let i = 0; i < this.productSummaryDetails.length; i++) {
        isPriceEngineFailed = false;
        let part = this.productSummaryDetails[i];
        let nextPart = this.productSummaryDetails[i + 1];

        let allCombineElements = [];
        if (part.partSelected) {
          // combine part structure
          if (part && part.partStructure?.elements)
            allCombineElements = [...part.partStructure.elements];
          if (
            nextPart &&
            nextPart.currentPartSurfaceType === "alternateSurface"
          ) {
            if (nextPart.alternatePartStructure) {
              // combine part alternate structure
              if (
                nextPart.alternatePartStructure &&
                nextPart.alternatePartStructure?.elements?.length
              )
                allCombineElements = [
                  ...allCombineElements,
                  ...nextPart.alternatePartStructure.elements,
                ];
            } else {
              let nextSublines = nextPart.orderObject.Details[0].Sublines;
              part.orderObject.Details[0].Sublines =
                part.orderObject.Details[0].Sublines.concat(nextSublines);
            }
            this.productSummaryDetails.splice(i + 1, 1);
          }

          try {
            let options = {
              pricingDateTime: Date.now(),
              fallbackToOMS: false,
              compareToOMS: false,
              audit: [],
            };

            let resp = null;
            if (part.partStructure && part.partStructure.elements) {
              // all combined elements with selected paper
              part.partStructure.elements = allCombineElements;
              resp = await getPartPricByStructure({
                pricer: "cloud",
                qty: this.productQuantity,
                sessionItem: part,
                partStructure: part.partStructure,
                options,
              });
            } else if(part.orderObject && part.orderObject.Details) {
              part.orderObject.Details[0].Quantity = this.productQuantity;
              part.orderObject.Details[0].Sublines.forEach((subline) => {
                subline.Quantity = this.productQuantity;
              });
              let newObj = JSON.parse(JSON.stringify(part.orderObject));
              resp = await getPartPricByStructure({
                orderPriceObject: newObj,
                sessionItem: part,
                pricer: "cloud",
                options,
              });
            } else {
              this.$snotify.error(
                "Cloud communication error, please start again",
                {
                  position: "rightTop",
                  showProgressBar: false,
                }
              );
              await this.$store.dispatch("incidentOne/setOne");
              this.saveIncident({
                errorObject: { error : "Partstructure and order object both are not found in summary data", part_name: part.name, session_id: this.builderSessionId },
                title: "Personalize pricing error",
                description: "Partstructure or order object required in summary data ",
                toContinueSession: true,
              });
              this.isErrorInPrice = true;
              this.disableSubmit = true;
              setTimeout(() => {
                this.backToEditPart()
              }, 2000);
            }

            part.partPrice = 0;
            if (resp && resp.skuBasedError && resp.skuBasedError.length) {
              this.disableSubmit = true;
              this.isErrorInPrice = true;
              allErrors.push(...resp.skuBasedError);
              this.saveIncident({
                errorObject: {errors: resp.skuBasedError.toString()},
                title: "Personalize pricing error",
                description: resp.skuBasedError.toString(),
                toContinueSession: true,
              });
            } else if (resp && resp.newPrice) {
              part.partPrice = resp.newPrice;
            } else if (options.fallbackToOMS && resp && !resp.newPrice) {
              part.partPrice = resp.oldPrice;
            }

            if (part.partPrice == 0) {
              this.disableSubmit = true;
              this.isErrorInPrice = true;
            }
            subTotal += part.partPrice;
          } catch (error) {
            console.log(error);
            await this.$store.dispatch("incidentOne/setOne");
            this.isErrorInPrice = true;
            this.disableSubmit = true;
            this.$snotify.error(
              "Cloud communication error, please start new session",
              {
                position: "rightTop",
                showProgressBar: false,
              }
            );
            if (error) {
              this.saveIncident({
                errorObject: error,
                title: "Personalize pricing error",
                description: "Error for product pricing on summary page",
              });
            }
          }

          if (part.partPrice == 0) {
            this.disableSubmit = true;
            this.isErrorInPrice = true;
          } else {
            try {
              // updating part summary json
              for (let p = 0; p < this.builderSessionItems.length; p++) {
                if (part.id == this.builderSessionItems[p].productPartId) {
                  let summaryData = {};
                  if (
                    typeof this.builderSessionItems[p].summaryData == "string"
                  ) {
                    summaryData = JSON.parse(
                      this.builderSessionItems[p].summaryData
                    );
                    summaryData = JSON.stringify({
                      ...summaryData,
                      partStructure: part.partStructure,
                      quantity: this.productQuantity,
                      partPrice: part.partPrice,
                      orderObject: part.orderObject,
                    });
                  } else {
                    summaryData = JSON.stringify({
                      ...this.builderSessionItems[p].summaryData,
                      partStructure: part.partStructure,
                      quantity: this.productQuantity,
                      partPrice: part.partPrice,
                      orderObject: part.orderObject,
                    });
                  }
                  await this.$store.dispatch(
                    "sessionItemList/updatePropertyInStore",
                    {
                      sessionItemId: this.builderSessionItems[p].id,
                      property: {
                        summaryData: summaryData,
                      },
                    }
                  );
                  break;
                }
              }
              localStorage.setItem(`cr_${this.builderSessionId}`, JSON.stringify({ qty: this.productQuantity }));
            } catch (err) {
              this.isErrorInPrice = true;
              this.disableSubmit = true;
              throw err;
            }
          }
          this.disableSubmit = false;
        }
      }
      if (allErrors.length) {
        allErrors = [...new Set(allErrors)];
        for (let e = 0; e < allErrors.length; e++) {
          this.$snotify.error(allErrors[e], {
            position: "rightTop",
            showProgressBar: false,
          });
        }
      }
      this.disableSubmit = false;
      if (!this.isPromotionApplied) this.subTotal = subTotal;
      else if (
        this.priceAdjustmentType &&
        this.priceAdjustmentType == "discount_percentage"
      ) {
        if (Number(this.priceAdjustmentValue)) {
          subTotal = subTotal * (1 - Number(this.priceAdjustmentValue) / 100);
          this.subTotal = subTotal;
        }
      }

      this.inProgress = false;
      } catch (error) {
        console.error("Error while calculating product total ",error, { sessionId: this.builderSessionId, sku:this.productSku })
        throw error
      }
    },
    menuAction: function () {
      this.$emit("action", { showMenu: !this.showMenu });
    },
    backToEditPart() {
      this.goToRoute();
    },
    async addToCart() {
      try {
        this.checkIsValidSession();
        //this.$snotify.clear()
        this.inProgress = true;
        this.$snotify.warning(`Please wait while product gets added in cart`, {
          position: "rightTop",
          showProgressBar: false,
        });
        let cartResponse = "";

        let productCustomOptions = this.getProductCustomOptions();
        if (this.userAuth) {
          cartResponse = await this.$store.dispatch(
            "userCartProduct/addSessionProductToCart",
            {
              sku: this.productSku,
              qty: this.productQuantity,
              price: this.subTotal,
              productOption: {
                extension_attributes: { custom_options: productCustomOptions },
              },
            }
          );
          if (cartResponse) {
            // when clicked on add to cart then and only then update summary
            for (let p = 0; p < this.builderSessionItems.length; p++) {
              this.$store.dispatch("sessionItemOne/updateItem", {
                sessionItemId: this.builderSessionItems[p].id,
                property: {
                  summaryData: this.builderSessionItems[p].summaryData,
                },
              });
            }

            let { qty, price } = cartResponse;

            await this.$store.dispatch("sessionOne/updateOne", {
              sessionId: this.builderSessionId,
              property: {
                productData: {
                  ...this.sessionOneDetails.productData,
                  quantity: qty,
                },
                quoteData: {
                  ...this.sessionOneDetails.quoteData,
                  ...cartResponse,
                },
                orderState: "in_cart",
                // summaryTs: new Date().toISOString()
              },
            });
          }
        } else {
          cartResponse = await this.$store.dispatch(
            "cartProduct/addSessionProductToCart",
            {
              sku: this.productSku,
              qty: this.productQuantity,
              price: this.subTotal,
              productOption: {
                extension_attributes: { custom_options: productCustomOptions },
              },
            }
          );
          if (cartResponse) {
            // when clicked on add to cart then and only then update summary
            for (let p = 0; p < this.builderSessionItems.length; p++) {
              this.$store.dispatch("sessionItemOne/updateItem", {
                sessionItemId: this.builderSessionItems[p].id,
                property: {
                  summaryData: this.builderSessionItems[p].summaryData,
                },
              });
            }
            await this.$store.dispatch("sessionOne/updateOne", {
              sessionId: this.builderSessionId,
              property: {
                productData: {
                  ...this.sessionOneDetails.productData,
                  quantity: this.productQuantity,
                },
                quoteData: {
                  ...this.sessionOneDetails.quoteData,
                  ...cartResponse,
                },
                orderState: "in_cart",
                // summaryTs: new Date().toISOString()
              },
            });
          }
        }
        googleAanalytics(
          cartResponse,
          this.productDetails,
          this.productQuantity,
          "add_to_cart",
          "Ecommerce",
          this.categoryListIdWise
        );
        localStorage.removeItem(`cr_${this.builderSessionId}`);
        this.inProgress = false;
        this.$snotify.warning(`Item added in cart successfully`, {
          position: "rightTop",
          showProgressBar: false,
        });
        window.open(`${craneShopUrl}/cart-products`, "_self");
      } catch (err) {
        this.$snotify.error("Error while adding product to cart", {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
        this.saveIncident({
          errorObject: err,
          title: "Personalize add to cart error",
          description: "Error while add to cart",
        });
        throw err;
      }
    },
    async updateToCart() {
      try {
        this.checkIsValidSession();
        this.inProgress = true;
        //this.$snotify.clear()
        this.$snotify.warning(`Please wait as we add this item to your cart`, {
          position: "rightTop",
          showProgressBar: false,
        });

        let productCustomOptions = await this.getProductCustomOptions();
        let cartResponse = null;
        if (this.userAuth) {
          cartResponse = await this.$store.dispatch(
            "userCartProduct/updateSessionProductToCart",
            {
              sku: this.productSku,
              qty: this.productQuantity,
              price: this.subTotal,
              userQuote: this.getSessionItemOwner(this.sessionOneDetails),
              itemId: this.itemId,
              productOption: {
                extension_attributes: { custom_options: productCustomOptions },
              },
            }
          );
        } else {
          cartResponse = await this.$store.dispatch(
            "cartProduct/updateSessionProductToCart",
            {
              sku: this.productSku,
              qty: this.productQuantity,
              price: this.subTotal,
              itemId: this.itemId,
              productOption: {
                extension_attributes: { custom_options: productCustomOptions },
              },
            }
          );
        }
        if (cartResponse) {
          // when clicked on add to cart then and only then update summary
          for (let p = 0; p < this.builderSessionItems.length; p++) {
            this.$store.dispatch("sessionItemOne/updateItem", {
              sessionItemId: this.builderSessionItems[p].id,
              property: {
                summaryData: this.builderSessionItems[p].summaryData,
              },
            });
          }

          let { qty, price } = cartResponse;
          await this.$store.dispatch("sessionOne/updateOne", {
            sessionId: this.builderSessionId,
            property: {
              productData: {
                ...this.sessionOneDetails.productData,
                quantity: qty,
              },
              quoteData: {
                ...this.sessionOneDetails.quoteData,
                qty,
                price,
              },
              orderState: "in_cart",
              // summaryTs: new Date().toISOString()
            },
          });
        }
        this.inProgress = false;
        localStorage.removeItem(`cr_${this.builderSessionId}`);
        this.$snotify.warning(`Cart updated successfully`, {
          position: "rightTop",
          showProgressBar: false,
        });
        window.open(`${craneShopUrl}/cart-products`, "_self");
      } catch (err) {
        this.$snotify.error("Error while updating product to cart", {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
        this.saveIncident({
          errorObject: err,
          title: "Personalize error while updating cart",
          description: "Error while updating cart product",
        });
        throw err;
      }
    },
    goToRoute() {
      if (this.designerMode) {
        if (this.categoryId)
          this.$router.push({
            name: "ProductPersonalize",
            params: {
              sessionId: this.builderSessionId,
              designerMode: this.designerMode,
            },
            query: { categoryId: this.categoryId },
          });
        else
          this.$router.push({
            name: "ProductPersonalize",
            params: {
              sessionId: this.builderSessionId,
              designerMode: this.designerMode,
            },
          });
      } else {
        if (this.categoryId)
          this.$router.push({
            name: "ProductPersonalize",
            params: {
              sessionId: this.builderSessionId,
            },
            query: { categoryId: this.categoryId },
          });
        else {
          this.$router.push({
            name: "ProductPersonalize",
            params: {
              sessionId: this.builderSessionId,
            },
          });
        }
      }
    },
    goToLogin() {
      window.open(`${craneShopUrl}/gate/sign-in`, "_self");
    },
    goToDashboard() {
      window.open(`${craneShopUrl}/me/account-dashboard`, "_self");
    },
    goToLogout() {
      this.$store.dispatch("auth/logout");
      localStorage.removeItem("cr_checkOutTab");
    },
    goToHomePage() {
      window.open(`${craneShopUrl}/`, "_self");
    },
    checkMismatchPrintProcess() {
      try {
        if (!this.printProcessGranted) {
          let printCount = 0;
          let firstPrint = [];
          if (this.productSummaryDetails) {
            for (let i = 0; i < this.productSummaryDetails.length; i++) {
              let part = this.productSummaryDetails[i];
              if (firstPrint.length == 0) firstPrint = part.printing;
              else {
                if (part.printing.length) {
                  if (firstPrint.length == part.printing.length) {
                    for (let p1 = 0; p1 < firstPrint.length; p1++) {
                      if (part.printing.indexOf(firstPrint[p1]) == -1)
                        printCount++;
                    }
                  } else {
                    printCount++;
                  }
                }
              }
            }
          }
          if (printCount > 0) {
            this.printProcessMismatched = true;
            this.disableSubmit = true;
            return false;
          } else {
            this.printProcessMismatched = false;
            return true;
          }
        } else {
          this.printProcessMismatched = true;
          this.disableSubmit = false;
          return true;
        }
      } catch (err) {
        console.error(err);
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
      }
    },
    async productPromotionCheck() {
      try {
        let productPartsObjects = [];
        for (let i = 0; i < this.productSummaryDetails.length; i++) {
          let productPart = this.productSummaryDetails[i];
          if (productPart.partSelected) {
            productPartsObjects[i] = {};
            productPartsObjects[i].partName = productPart.name;
            productPartsObjects[i].processes = productPart.processes;
            productPartsObjects[i].linerObject = productPart.linerObject;
          }
        }
        let response = await checkPromotion({
          sku: this.productSku,
          quantity: Number(this.userQuantity),
          product: this.productOne,
          productPartsObjects: productPartsObjects,
        });
        if (response) {
          if (response.exclusiveRules.length !== response.exclusive.length)
            response.applied = false;
          else {
            if (response.appliedRules.length) {
              this.isPromotionApplied = true;
              // this.productPrice = 0;
              this.subTotal = 0;

              let sortedPromotions = [];
              for (let i = 0; i < response.appliedRules.length; i++) {
                let promotion = response.appliedRules[i];
                if (promotion.price_adjustment == "fixed") {
                  sortedPromotions.unshift(promotion);
                } else {
                  sortedPromotions.push(promotion);
                }
              }
              //fixed is on top
              for (let r = 0; r < sortedPromotions.length; r++) {
                let sortedPromotion = sortedPromotions[r];
                if (sortedPromotion.price_adjustment == "fixed") {
                  this.subTotal = sortedPromotion.price_adjustment_value;
                } else if (sortedPromotion.price_adjustment == "bump_amount") {
                  this.subTotal += sortedPromotion.price_adjustment_value;
                } else if (
                  sortedPromotion.price_adjustment == "discount_percentage"
                ) {
                  this.priceAdjustmentType = "discount_percentage";
                  this.priceAdjustmentValue =
                    sortedPromotion.price_adjustment_value;
                }
              }
              if (this.subTotal == 0 && !this.priceAdjustmentType)
                this.isPromotionApplied = false;
              if (this.isPromotionApplied)
                this.$snotify.success(`Promotion is applied successfully`, {
                  position: "rightTop",
                  showProgressBar: false,
                });
            } else {
              this.isPromotionApplied = false;
            }
          }
        }
      } catch (err) {
        console.error(err);
        this.isErrorInPrice = true;
        this.$snotify.error(
          `Cloud communication error, please start new session `,
          {
            position: "rightTop",
            showProgressBar: false,
          }
        );
        this.saveIncident({
          errorObject: err,
          title: "Personalize error while applying promotion",
          description: "Error while applying promotion on product",
        });

        throw err;
      }
    },
    async goToBuilder(partId, userSelectedFlag) {
      if (!userSelectedFlag) {
        this.userSelectedParts.push(partId);

        await this.$store.dispatch("sessionOne/updateOne", {
          sessionId: this.builderSessionId,
          property: {
            productData: {
              ...this.sessionOneDetails.productData,
              selectedPartsList: this.userSelectedParts,
            },
            // summaryTs: new Date().toISOString()
          },
        });
      }
      let path = this.$route.path;
      path = path.replace("summary", "personalize");
      window.open(path, "_self");
    },
    getSessionItemOwner(sessionItemObj) {
      if (sessionItemObj && sessionItemObj.quoteData) {
        return sessionItemObj.quoteData.quoteId;
      }
      return null;
    },
    async saveIncident({
      errorObject,
      title,
      description,
      tags,
      toContinueSession = false,
    }) {
      try {
        await this.$store.dispatch("incidentOne/setOne");
        let userAgent = navigator;

        let currentdate = await isoStringToNormalDate();
        let newErr = null;
        if (errorObject && errorObject.stack ) { // && errorObject.stack.new_price
          newErr = JSON.stringify(errorObject);
        } else {
          newErr = JSON.stringify(errorObject );
        }
        let currentSessionId = this.builderSessionId
        let incidentObj = {
          title: title,
          level: "major",
          module: "studio",
          description: description,
          tags: tags && tags.length ? tags : [],
          recorded_at: currentdate,
          metadata: {
            currentSessionId,
            errorStack: JSON.stringify(errorObject.stack || errorObject),
            error: newErr,
            platform: userAgent.platform,
            appVersion: userAgent.appVersion,
            appCodeName: userAgent.appCodeName,
          },
        };
        let savedIncidentResp = await this.$store.dispatch("incidentOne/save", {
          one: incidentObj,
        });
        console.log("savedIncidentResp", savedIncidentResp);

        let ddErr = new Error(title)
        console.error(ddErr, incidentObj.metadata)
        // console.log("Error for studio  DD_RUM"+ddErr)
        // if(window.DD_RUM){
        //   window.DD_RUM.onReady(function() {
        //     window.DD_RUM.addError(ddErr,{
        //       module: "studio",
        //       level: "major",
        //       currentSessionId,
        //       description,
        //       errorDetails: newErr
        //     });
        //   });
        // } else {
        //   console.log("window.DD_RUM  variable is NOT Available in studio ")
        // }

        if (!toContinueSession) this.setCorruptedSessionData();
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async setCorruptedSessionData() {
      try {
        await this.$store.dispatch("sessionOne/updateOne", {
          sessionId: this.builderSessionId,
          property: {
            state: "in_active",
            // summaryTs: new Date().toISOString()
          },
        });

        this.$router.push({
          name: "SessionStart",
          params: {
            sku: this.productSku,
            quantity: this.productQuantity,
          },
        });
      } catch (err) {
        this.isErrorInPrice = true;
        this.$snotify.error(
          `Cloud communication error, please start new session `,
          {
            position: "rightTop",
            showProgressBar: false,
          }
        );
        throw err;
      }
    },
    checkIsValidSession() {
      try {
        if (this.sessionOneDetails) {
          let sessionUserId = this.sessionOneDetails.userData
            ? this.sessionOneDetails.userData.customerId
            : null;
          let sessionUserName = this.sessionOneDetails.userData
            ? this.sessionOneDetails.userData.customerName
            : null;
          // if session has logged in user id but currently  user is logged out
          if (!this.userAuth && sessionUserId) {
            this.$snotify.error(`To continue this session, please login`, {
              position: "rightTop",
              showProgressBar: false,
            });

            setTimeout(() => {
              loginInterrupt(
                window.location.href,
                "To continue this session, please login"
              );
            }, 2000);
            throw "User needs to be logged in to be continue ";
          } else if (
            this.userAuth &&
            sessionUserId &&
            this.userId !== sessionUserId
          ) {
            this.$snotify.error(
              `To continue this session, please login with ${sessionUserName} user`,
              {
                position: "rightTop",
                showProgressBar: false,
              }
            );

            setTimeout(() => {
              loginInterrupt(
                window.location.href,
                "Please login with " + sessionUserName + " user"
              );
            }, 2000);
            throw (
              sessionUserName + " user needs to be logged in to be continue "
            );
          }
        }
      } catch (err) {
        this.isErrorInPrice = true;
        throw err;
      }
    },
    async fetchSessionAndItems() {
      try {
        await this.$store.dispatch("sessionItemList/fetchList", {
          sessionId: this.builderSessionId,
        });

        await this.$store.dispatch("sessionOne/fetchOne", {
          sessionId: this.builderSessionId,
        });
      } catch (err) {
        this.$snotify.error(
          `Cloud communication error, please start new session `,
          {
            position: "rightTop",
            showProgressBar: false,
          }
        );
        throw err;
      }
    },
  },
  async created() {
    await this.fetchSessionAndItems();
    
    let localQty = null
    let currentSessionLocalData = localStorage.getItem("cr_" + this.sessionOneDetails.id);
    if(currentSessionLocalData){
      currentSessionLocalData = JSON.parse(currentSessionLocalData)
      localQty = currentSessionLocalData.qty
    }
    try {
      this.inProgress = true;
      
      window.removeEventListener("beforeunload", handleBeforeUnload);
      let isValidSession = false;
      let userQuoteId = null;
      if (this.userAuth) {
        await this.$store.dispatch("userCartProduct/checkUserToken");
        await this.$store.dispatch("userCartProduct/cartProductList");
        userQuoteId = await this.$store.dispatch(
          "userCartProduct/fetchUserQuote"
        );
      } else {
        userQuoteId = await this.$store.dispatch("cartProduct/fetchGuestQuote");
         await this.$store.dispatch("cartProduct/cartProductList", userQuoteId);        
      }
      isValidSession = this.sessionOneDetails.userData
        ? this.sessionOneDetails.userData.guestId ||
          this.sessionOneDetails.userData.customerId
        : null;

      if (!isValidSession || this.sessionOneDetails.orderState == "submitted") {
        this.$snotify.error(
          `This session is already completed, please start new session`,
          {
            position: "rightTop",
            showProgressBar: false,
          }
        );
        this.$router.push({
          name: "SessionStart",
          params: {
            sku: this.productSku,
            quantity: localQty || this.userQuantity,
          },
        });
      }
      // await this.$store.dispatch("sessionOne/updateOne", {
      //   sessionId: this.builderSessionId,
      //   property: {
      //     // summaryTs: new Date().toISOString()
      //   },
      // });
      if (this.sessionOneDetails && this.sessionOneDetails.productData) {
        this.productSku = this.sessionOneDetails.productData.sku;
        this.productId = this.sessionOneDetails.productData.productId;
        this.userQuantity = this.sessionOneDetails.productData.quantity;
        this.userSelectedParts =
          this.sessionOneDetails.productData.selectedPartsList;

        // pricer bundle loaded
        if (this.productSku) {
          let skuList = [this.productSku];
          if (this.cartItems && this.cartItems.length) {
            for (let p = 0; p < this.cartItems.length; p++) {
              let product = this.cartItems[p];
              if (product && product.options) {
                let resp = this.builderSession(product);
                if (resp) skuList.push(product.sku);
              }
            }
          }
          if (skuList.length) {
            // pricer bundle loaded
            window.pricerFieldsIdx = pricerFieldsIdx;
            let currentDateInIso = new Date().toISOString();
            let bundleResp = await this.$store.dispatch(
              "pricerBundleOne/fetchPriceBundle",
              { skuList: skuList, availableAt: currentDateInIso }
            );
            if (bundleResp) {
              window.pricer = {
                ...pricerFiles,
                ...bundleResp,
              };
            }
            console.log(window.pricer);
          }
        }
        if (
          this.sessionOneDetails.productData &&
          this.sessionOneDetails.productData.assets &&
          !this.productPricerDataset
        ) {
          let assets = this.sessionOneDetails.productData.assets;
          await this.$store.dispatch("productOne/lookupPricerDataset", {
            papers: assets.papers,
            uses: assets.uses,
            occassions: assets.occassions,
          });
        }
      }
      if (this.sessionOneDetails && this.sessionOneDetails.quoteData) {
        this.itemId = this.sessionOneDetails.quoteData.itemId;
        this.cartId = this.sessionOneDetails.quoteData.quoteId;

        if (this.userAuth) {
          if (Number(this.cartId) !== userQuoteId) {
            this.cartId = null;
          }
        }

        if (this.sessionOneDetails.quoteData.qty)
          this.userQuantity = this.sessionOneDetails.quoteData.qty;
      }
    
    if (!this.productDetails.id) {
      let productDetail = await this.$store.dispatch(
        "productOne/fetchProductDetails",
        this.productSku
      );
      if (productDetail.error) {
        this.$snotify.error(`This product is not available`, {
          position: "rightTop",
          showProgressBar: false,
        });
      }
    }

    if (this.productId) {
      await this.$store.dispatch("designerOne/fetchOne", this.productId);
    } else {
      await this.$store.dispatch("designerOne/fetchOneBySku", this.productSku);
    }

    await this.$store.dispatch("productOne/fetchSessionProductDetails", {
      sku: this.productSku,
    });

    let list = await this.$store.dispatch("productPartList/fetchList", {
      productId: this.productId,
      sku: this.productSku,
    });
    list.filter((part) => {
      let flag = false;
      let thumbnailUrl = null;
      for (let p = 0; p < this.builderSessionItems.length; p++) {
        if (part.id == this.builderSessionItems[p].productPartId) {
          if (this.builderSessionItems[p].summaryData)
            this.productSummaryDetails.push({
              ...JSON.parse(this.builderSessionItems[p].summaryData),
            });
          if (this.builderSessionItems[p].surfaceType === "surface")
            thumbnailUrl = this.builderSessionItems[p].thumbnailUrl;
        }
      }
      for (let i = 0; i < this.productSummaryDetails.length; i++) {
        if (part.id == this.productSummaryDetails[i].id) {
          this.productParts.push({
            thumbnailUrl: thumbnailUrl,
            partSelected: this.productSummaryDetails[i].partSelected,
            partModified: this.productSummaryDetails[i].partModified,
            name: this.productSummaryDetails[i].name,
            id: this.productSummaryDetails[i].id,
            userSelected: true,
          });
          flag = true;
          break;
        }
      }
      if (!flag) {
        this.productParts.push({
          thumbnailUrl: part.thumbnailUrl,
          partSelected: false,
          partModified: false,
          name: part.name + " (Item not selected)",
          id: part.id,
          userSelected: false,
        });
      }
    });

    this.productQuantity = localQty ? localQty : this.userQuantity
      ? this.userQuantity
      : this.productSummaryDetails.length
      ? this.productSummaryDetails[0].quantity
      : 0;
    } catch (error) {
      console.error(error)
      this.goToRoute();
    }
    await this.fetchQuantiyArray();
    await this.productPromotionCheck();
    await this.calculateSubTotal();
    await this.checkMismatchPrintProcess();
  },
};
</script>
<style>
.summary-sheet,
.v-bottom-sheet,
.v-dialog {
  background-color: #fff;
  overflow-y: scroll;
}
.summary-border {
  border: thin solid #2850da8a !important;
}
</style>
<style src="./ProductSummaryComponent.scss" lang="scss" scopped />
