<template>
  <div class="h-100">
    <v-overlay
      :model-value="inProgress || canvasLoadingInProgress"
      scrim="#9d9a99"
      persistent
      contained
      class="align-center justify-center"
    >
      <!-- inProgress || -->
      <v-container>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              size="80"
              color="primary"
              align="center"
              justify="center"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>

    <ProductPageHeader
      :partsList="partsList"
      @changeCanvasInMobile="changeCanvasInMobile"
      @changePartSurfaceMobile="changePartSurfaceMobile"
      :selectedPart="selectedPart"
      :undoLength="partStates[selectedPartIndex].undo.length"
      :redoLength="partStates[selectedPartIndex].redo.length"
      @mobileUndoOperation="undoOperations()"
      @mobileRedoOperation="redoOperations()"
      @mobileResetOperation="resetOperation()"
      :selectedPartSurfaces="selectedPartSurfaces"
      :partSurface="partSurface"
      v-if="isMobileDevice"
      :undoRedoImages="{ redoInactive, redoActive, undoActive, undoInactive }"
    />

    <v-container
      fluid
      class="bg-white pt-sm-0 h-100 pb-0 mt-3"
      v-resize="resize"
      :class="designerModeFlag ? 'pt-0' : 'pt-0'"
    >
      <v-row class="h-100 canvas-sidebar-contains mx-n4">
        <v-col
          cols="12"
          sm="6"
          md="8"
          lg="7"
          xl="8"
          xxl="10"
          :style="
            isMobileDevice
              ? `height:` + canvasDivHeight + `px`
              : $vuetify.display.mdAndUp
              ? `width:` + canvasDivWidth + `px`
              : ''
          "
          class="h-100 productpage-background-pattern d-flex flex-column canvas-contains-div pb-0 pb-sm-0"
        >
          <v-row
            v-if="!isMobileDevice"
            class="align-center d-flex justify-start pt-2 mb-3"
            :style="`max-height:50px;`"
          >
            <v-col sm="2" md="2" class="pr-0 d-flex align-start py-0">
              <v-img
                :src="craneLogoBlue"
                class="cursor-pointer"
                @click="goToHomePage()"
                max-width="100"
                max-height="35"
                aspect-ratio="1"
              ></v-img>
            </v-col>
            <v-col
              md="8"
              class="d-flex justify-sm-start justify-md-center pa-0 pl-sm-8 pl-md-0"
            >
              <v-col sm="4" md="3" class="py-0 pr-0">
                <label
                  class="text-uppercase text-primary regular-font fontsize-10"
                  >Item
                </label>
                <v-select
                  :items="partsList"
                  class="word-break productpage-part-select select-change-icon input-select-primary-border rounded-0 overflow-btn-icon productpage-select-input"
                  hide-details
                  density="compact"
                  flat
                  variant="solo"
                  ref="partChangeButton"
                  v-model="selectedPart"
                  @update:modelValue="changeCanvas('partChanged')"
                  bg-color="transparent"
                  append-inner-icon="mdi-plus"
                  :menu-props="{ class: 'productpage-part-select-menu' }"
                >
                  <template v-slot:selection="{ item }">
                    <span
                      class="pt-1 productpage-part-select-text text-uppercase text-primary regular-font fontsize-10 text-center rounded-0"
                    >
                      {{ item && item.raw ? item.raw.text : "" }}</span
                    >
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                      :class="
                        checkIsPartActive(selectedPart, item)
                          ? 'productpage-part-select-menu--active'
                          : ''
                      "
                    >
                      <v-list-item-title
                        v-bind="props"
                        class="text-uppercase text-primary regular-font fontsize-10 rounded-0 transparent-background cursor-pointer"
                      >
                        {{ item && item.raw ? item.raw.text : "" }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col sm="4" md="3" class="py-0 pl-0">
                <label
                  class="text-uppercase text-primary regular-font fontsize-10 pl-1"
                  >Surface
                </label>
                <v-select
                  :items="selectedPartSurfaces"
                  v-model="partSurface"
                  class="word-break productpage-part-select border-left-none select-change-icon input-select-primary-border rounded-0 overflow-btn-icon productpage-select-input"
                  hide-details
                  @update:modelValue="changePartSurface"
                  density="compact"
                  flat
                  variant="solo"
                  return-object
                  item-title="name"
                  item-value="id"
                  append-inner-icon="mdi-plus"
                  bg-color="transparent"
                  :menu-props="{ class: 'productpage-part-select-menu' }"
                >
                  <template v-slot:selection="{ item }">
                    <span
                      class="pt-1 productpage-part-select-text text-uppercase text-primary regular-font fontsize-10 text-center rounded-0"
                      >{{ item && item.raw ? item.raw.name : "" }}</span
                    >
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                      :class="
                        partSurface.id == item.raw.id
                          ? 'productpage-part-select-menu--active'
                          : ''
                      "
                    >
                      <v-list-item-title
                        v-bind="props"
                        class="text-uppercase text-primary regular-font fontsize-10 rounded-0 cursor-pointer"
                      >
                        {{ item && item.raw ? item.raw.name : "" }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-col>
          </v-row>
          <div class="h-100 mx-2" no-gutters>
            <div
              :class="flipClass"
              @drop="fileDrop"
              ref="canvasContainer"
              class="h-100 d-flex align-start align-sm-center justify-center"
            >
              <canvas id="canvas" ref="canvas" class=""></canvas>
            </div>
          </div>
          <v-row
            class="align-end d-none d-sm-flex mb-2"
            :style="`max-height:60px;`"
          >
            <v-spacer></v-spacer>
            <v-col cols="auto" class="pa-0">
              <span ref="undoButton">
                <v-btn
                  class="px-0 rounded-0"
                  disabled
                  @click="undoOperations()"
                  tile
                  variant="outlined"
                  density="compact"
                  title="Undo"
                  flat
                  min-height="42"
                  color="primary"
                >
                  <img
                    v-if="partStates[selectedPartIndex].undo.length == 0"
                    :src="undoInactive"
                    height="24"
                    width="24"
                  />
                  <img
                    v-else-if="partStates[selectedPartIndex].undo.length != 0"
                    :src="undoActive"
                    height="24"
                    width="24"
                  />
                </v-btn>
              </span>
              <span>
                <v-btn
                  class="mr-0 px-3 text-primary rounded-0"
                  @click="resetOperation()"
                  tile
                  :class="
                    partStates[selectedPartIndex].undo.length > 0
                      ? 'border-left-none'
                      : partStates[selectedPartIndex].redo.length > 0
                      ? 'border-right-none'
                      : ''
                  "
                  variant="outlined"
                  density="compact"
                  title="Reset"
                  flat
                  min-height="42"
                  color="primary"
                >
                  <span class="mx-auto fontsize-10 regular-font">RESET</span>
                </v-btn>
              </span>
              <span>
                <v-btn
                  class="ml-0 px-0 rounded-0"
                  :class="
                    partStates[selectedPartIndex].redo.length > 0
                      ? ''
                      : 'border-left-none'
                  "
                  disabled
                  @click="redoOperations()"
                  tile
                  title="Redo"
                  variant="outlined"
                  density="compact"
                  flat
                  min-height="42"
                  color="primary"
                >
                  <img
                    v-if="partStates[selectedPartIndex].redo.length == 0"
                    :src="redoInactive"
                    height="24"
                    width="24"
                  />
                  <img
                    v-else-if="partStates[selectedPartIndex].redo.length != 0"
                    :src="redoActive"
                    height="24"
                    width="24"
                  />
                </v-btn>
              </span>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="5"
          xl="4"
          xxl="2"
          :class="
            $vuetify.display.mdAndUp
              ? 'sidebartool-contains-div sidebartool-panel-height'
              : ''
          "
          class="px-5 d-sm-flex flex-sm-column pt-3 pt-0 bg-white"
          ref="sideBar"
          v-resize="OnResize"
        >
          <!-- desktop right side view --->
          <v-row
            class="d-none d-sm-inline-block align-start px-3 pt-3"
            v-if="!inProgress"
          >
            <v-row
              class="pb-1 d-flex align-center"
              v-if="!isMobileDevice"
              no-gutters
            >
              <v-col cols="6">
                <p
                  class="text-uppercase fontsize-16 body ls-n01 text-primary mb-0"
                >
                  {{ productOne.name }}
                </p>
              </v-col>
              <v-col class="text-right" cols="6">
                <ProductPageHeader v-if="!isMobileDevice" />
              </v-col>
            </v-row>
            <v-row v-if="deletedElementArray.length">
              <v-col cols="12" class="">
                <v-card
                  :border="0"
                  flat
                  variant="outlined"
                  class="productpage-stylebar-border mt-1"
                >
                  <v-card-text>
                    <p
                      class="text-uppercase text-primary body-font fontsize-14 mb-0"
                    >
                      Hidden Elements List
                    </p>
                    <v-radio-group
                      v-model="userSelectedDeletedElement"
                      class="mt-0"
                      hide-details
                    >
                      <v-list
                        flat
                        dense
                        class="list-dense-height ml-7 mr-3 py-1"
                        density="compact"
                      >
                        <v-list-item
                          v-for="(item, index) in deletedElementArray"
                          :key="index"
                          class="px-0"
                        >
                          <v-radio
                            :value="item"
                            class="mt-0 mb-0 pt-0 pb-0 body-font hidden-elements-radio-label"
                            color="primary"
                            density="compact"
                            hide-details
                          >
                            <template v-slot:label>
                              <span
                                class="fontsize-16 text-uppercase body-font text-primary"
                              >
                                {{ item.name }} - ( {{ item.type }} )
                              </span>
                            </template>
                          </v-radio>
                        </v-list-item>
                      </v-list>
                    </v-radio-group>
                    <v-col cols="12" sm="6" class="py-0 pl-0" offset-sm="6">
                      <v-btn
                        block
                        :large="$vuetify.display.mdAndUp ? true : false"
                        class="text-uppercase ls-0 body"
                        :border="0"
                        flat
                        :disabled="!userSelectedDeletedElement"
                        color="primary"
                        @click="
                          displayDeletedElement(userSelectedDeletedElement)
                        "
                      >
                        SUBMIT
                      </v-btn>
                    </v-col>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-card
              :border="0"
              flat
              variant="outlined"
              class="productpage-stylebar-border mt-1 bg-white"
              v-if="!isMobileDevice"
              ref="tabPanel"
            >
              <v-row class="px-4 py-2 d-flex align-center ma-0">
                <v-col class="ml-3 pl-0">
                  <p
                    class="text-uppercase fontsize-12 regular-font text-primary mb-0 float-left"
                  >
                    {{
                      selectedPart.text ? selectedPart.text : selectedPart.name
                    }}
                    /
                    {{
                      partSurface && partSurface.name
                        ? partSurface.name
                        : partSurface
                    }}
                  </p>
                </v-col>
                <!-- to show price on every change -->
                <!-- <v-col class="mr-3 pr-0">
                  <p
                    class="text-uppercase fontsize-16 body mb-0 float-right"
                    :class="showPulseEffect ? 'pulse-element' : 'text-primary'"
                  >
                    ${{ priceFormatter(currentPartPrice) }} / ${{
                      priceFormatter(totalPartPrices)
                    }}
                  </p>
                </v-col> -->
              </v-row>
              <v-divider
                :thickness="1"
                class="border-opacity-100"
                color="primary"
              ></v-divider>
              <v-tabs
                fixed-tabs
                align-tabs="center"
                color="primary"
                class="h-100 tab-bottom-line"
                v-if="!noPropertyToChange"
                v-model="modeltype"
              >
                <v-tab
                  class="text-uppercase fontsize-12 regular-font text-primary ls-n008 px-0"
                  value="paper"
                  @click="selectZeroTypeElement('paper')"
                  :class="activeClass"
                >
                  <span
                    class="tab-underline text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                    >paper</span
                  >
                </v-tab>
                <v-tab
                  class="text-uppercase fontsize-12 regular-font text-primary ls-n008 px-0"
                  value="text"
                  @click="selectZeroTypeElement('textbox')"
                  :class="activeClass"
                >
                  <span
                    class="tab-underline text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                    >text</span
                  >
                </v-tab>
                <v-tab
                  class="text-uppercase fontsize-12 regular-font text-primary ls-n008 px-0"
                  value="artwork"
                  :class="disabledElementTab ? 'd-none' : ''"
                  @click="selectZeroTypeElement('graphics')"
                >
                  <span
                    class="tab-underline text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                    >Elements</span
                  >
                </v-tab>
                <v-tab
                  class="text-uppercase fontsize-12 regular-font text-primary ls-n008 px-0"
                  value="images"
                  :class="imageTabDisable ? 'd-none' : ''"
                  @click="selectZeroTypeElement('image')"
                >
                  <span
                    class="tab-underline text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                    >Photos</span
                  >
                </v-tab>
                <v-tab
                  class="text-uppercase fontsize-12 regular-font text-primary ls-n008 px-0"
                  value="finish"
                  v-if="showFinishTab"
                  :class="disableFinishTab ? 'd-none' : activeClass"
                  @click="setActiveFinishTab(true)"
                >
                  <span
                    class="tab-underline text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                    >Liners</span
                  >
                </v-tab>
              </v-tabs>

              <v-window
                v-model="modeltype"
                v-if="!noPropertyToChange"
                :class="scrollWindow ? 'scroll-window' : ''"
              >
                <v-window-item :value="`paper`" :class="scrollClass">
                  <v-card tile flat>
                    <v-card-text v-if="!hidePaperOption" class="px-7">
                      <v-col cols="12" class="pa-0">
                        <v-row no-gutters>
                          <v-col cols="4">
                            <h4
                              class="text-primary fontsize-12 body-font text-uppercase"
                            >
                              Stock :
                            </h4>
                          </v-col>
                          <v-col cols="8">
                            <h4
                              class="text-uppercase fontsize-12 regular-font fontcolor-grey-dark float-right"
                            >
                              {{ selectedPaper.brand }} (
                              {{ selectedPaper.weight }} )
                            </h4>
                          </v-col>
                        </v-row>
                        <div>
                          <div
                            color="text-primary"
                            class="ml-n7 mr-n7 productpage-hr-line mt-3"
                          ></div>
                          <v-row>
                            <v-col class="pb-0">
                              <h4
                                class="text-uppercase fontsize-12 body-font text-primary"
                              >
                                color :
                              </h4>
                            </v-col>
                            <v-col class="pb-0">
                              <h4
                                class="text-uppercase fontsize-12 regular-font fontcolor-grey-dark float-right"
                              >
                                {{ selectedPaper.color }}
                              </h4>
                            </v-col>
                          </v-row>
                          <v-row class="pr-1">
                            <div
                              v-for="(paper, index) in papers"
                              v-bind:key="index"
                            >
                              <!-- desktop view -->
                              <v-col class="pb-0 pr-0">
                                <v-tooltip
                                  location="bottom"
                                  :text="paper.color"
                                >
                                  <template v-slot:activator="{ props }">
                                    <div
                                      v-bind="props"
                                      class="color-circle d-flex justify-center align-center"
                                      v-bind:class="{
                                        'color-circle-border':
                                          paper.text ==
                                          (selectedPaper.name
                                            ? selectedPaper.name
                                            : selectedPaper.text),
                                      }"
                                    >
                                      <div
                                        class="productpage-color-cricle"
                                        :style="
                                          'background-color:#' + paper.colorHex
                                        "
                                        @click="setSelectedPaper(paper)"
                                      ></div>
                                    </div>
                                  </template>
                                </v-tooltip>
                              </v-col>
                            </div>
                          </v-row>
                        </div>
                      </v-col>
                    </v-card-text>
                    <v-card-text v-else>
                      <p class="text-grey">
                        <!-- Paper properties are not present to change. -->
                        Loading product toolset ...
                      </p>
                    </v-card-text>
                  </v-card>
                </v-window-item>
                <v-window-item :value="`text`" :class="scrollClass">
                  <v-card :rounded="false" flat :key="textChangedKey">
                    <v-card-text
                      :key="modeltype"
                      v-if="selectedCanvas"
                      class="px-7 pt-0"
                    >
                      <v-list
                        flat
                        density="compact"
                        class="list-dense-height py-0"
                      >
                        <template v-for="(item, index) in optionalTextElements">
                          <v-list-item
                            density="compact"
                            v-if="item.extraProperty.optional"
                            :key="index"
                            class="pa-0 mb-0"
                          >
                            <p
                              class="d-inline-block mb-0 body-font fontsize-14"
                            >
                              An additional charge will be incurred when adding
                              a {{ item.dataElementName.toLowerCase() }}.
                            </p>
                          </v-list-item>
                          <v-list-item-action
                            v-if="item.extraProperty.optional"
                            :key="index + 10"
                            class="mx-0"
                          >
                            <v-btn
                              @click="addOptionalTextToCanvas(item, index)"
                              color="primary"
                              size="small"
                              class="text-uppercase fontsize-12 body-font"
                            >
                              Add "{{ item.dataElementName }}"
                            </v-btn>
                          </v-list-item-action>
                        </template>
                      </v-list>
                      <template
                        v-if="
                          isTextElementPresent &&
                          !['false', false].includes(
                            selectedCanvas.dataOptionalAdded
                          ) &&
                          !hideTextOption
                        "
                      >
                        <v-row class="pt-3">
                          <v-col cols="12" v-if="!hideFontField">
                            <span
                              class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
                              >Font :</span
                            >
                            <v-select
                              v-model="selectedFont"
                              class="my-0 py-0 overflow-btn-icon productpage-select-input select-change-icon rounded-0 input-select-primary-border input-select-border-radius-0"
                              :items="fontList"
                              @update:modelValue="setFont()"
                              density="compact"
                              flat
                              variant="solo"
                              color="primary"
                              hide-details
                              single-line
                              item-title="name"
                              item-value="name"
                              label="Select font"
                              ref="textFont"
                              append-inner-icon="mdi-plus"
                              :disabled="checkIsStyleDisable()"
                            >
                              <template v-slot:selection="{ item }">
                                <span
                                  class="text-primary fontsize-12 regular-font text-uppercase"
                                >
                                  {{ item && item.raw ? item.raw.name : "" }}
                                </span>
                              </template>
                              <template v-slot:item="{ props, item }">
                                <v-list-item
                                  :class="
                                    selectedFont == item.raw.name
                                      ? 'productpage-part-select-menu--active'
                                      : ''
                                  "
                                >
                                  <div class="d-flex justify-space-between">
                                    <v-list-item-title
                                      v-bind="props"
                                      class="d-block text-truncate fontsize-12 regular-font text-primary text-uppercase cursor-pointer"
                                      >{{
                                        item && item.raw ? item.raw.name : ""
                                      }}
                                    </v-list-item-title>
                                    <p
                                      class="font-weight-medium mb-0 text-primary text-uppercase cursor-pointer"
                                      :style="setFontInFontList(item.raw.name)"
                                    >
                                      ABCD
                                    </p>
                                  </div>
                                </v-list-item>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row
                          align="center"
                          class="pt-3"
                          no-gutters
                          v-if="!hideFontField"
                        >
                          <v-col cols="3" class="py-1">
                            <span
                              class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
                              >Font Size :</span
                            >
                          </v-col>
                          <v-col cols="9">
                            <v-slider
                              v-model="selectedFontSize"
                              class="align-center font-size-slider"
                              color="primary"
                              density="compact"
                              @update:modelValue="setFontSize()"
                              :min="fontMin"
                              :max="fontMax"
                              :step="1"
                              hide-details
                            >
                              <template v-slot:append>
                                <v-text-field
                                  v-model="selectedFontSize"
                                  class="mt-0 pt-0 pl-3 pr-0 font-size-text-input rounded-0 input-select-primary-border input-select-border-radius-0"
                                  hide-details
                                  density="compact"
                                  variant="solo"
                                  single-line
                                  flat
                                  readonly
                                  @update:modelValue="setFontSize()"
                                  type="number"
                                  style="width: 50px"
                                ></v-text-field>
                              </template>
                            </v-slider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            v-if="hideFontField || hidePrintField"
                          >
                            <p
                              v-if="hideFontField || hidePrintField"
                              class="text-grey"
                            >
                              Loading product toolset ...
                            </p>
                          </v-col>
                        </v-row>
                        <div class="productpage-hr-line my-5 ml-n7 mr-n7"></div>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="8"
                            class="pt-0"
                            v-if="!hidePrintField"
                          >
                            <span
                              class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
                              >Print Process :</span
                            >
                            <v-select
                              v-model="selectedPrintMethod"
                              :items="printMethods"
                              @update:modelValue="setPrintMethod()"
                              hide-details
                              class="mt-0 overflow-btn-icon productpage-select-input select-change-icon rounded-0 input-select-primary-border input-select-border-radius-0"
                              target="#dropdown-example"
                              density="compact"
                              flat
                              variant="solo"
                              elevation="0"
                              ref="printMethods"
                              menu-icon="mdi-plus"
                              item-title="text"
                              item-value="text"
                              :disabled="checkIsStyleDisable()"
                            >
                              <template v-slot:selection="{ item }">
                                <span
                                  class="ml-3 text-primary fontsize-12 regular-font text-uppercase"
                                >
                                  {{ item && item.raw ? item.raw.text : "" }}
                                </span>
                              </template>
                              <template v-slot:item="{ props, item }">
                                <v-list-item
                                  :class="
                                    selectedPrintMethod == item.raw.text
                                      ? 'productpage-part-select-menu--active'
                                      : ''
                                  "
                                >
                                  <div class="d-flex justify-space-between">
                                    <v-list-item-title
                                      v-bind="props"
                                      class="d-block text-truncate fontsize-12 regular-font text-primary text-uppercase cursor-pointer"
                                      >{{
                                        item && item.raw ? item.raw.text : ""
                                      }}</v-list-item-title
                                    >
                                  </div>
                                </v-list-item>
                              </template>
                            </v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            class="pt-0"
                            v-if="showStyleControl"
                          >
                            <span
                              class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
                              >Style :</span
                            >
                            <v-switch
                              v-model="dataIsStyleLinked"
                              @update:modelValue="applyLinkedStyle"
                              density="compact"
                              hide-details
                              color="primary"
                              class="alternate-svg-switch mt-0 text-primary"
                            >
                              <template v-slot:label>
                                <span
                                  class="text-primary text-uppercase fontsize-12 body-font"
                                  >{{
                                    dataIsStyleLinked ? "LINKED" : "UNLINKED"
                                  }}</span
                                >
                              </template>
                            </v-switch>
                          </v-col>
                          <v-col cols="12" v-if="checkIsStyleDisable()">
                            <p class="text-red fontsize-14 body-font">
                              This is a styled element. To change it we
                              recommend:
                              <br />
                              - going back to {{ partStates[0].name }} and make
                              adjustments there
                              <br />
                              - OR you can Unlink from style and make your
                              changes for this item only
                            </p>
                          </v-col>
                        </v-row>
                        <v-row no-gutters class="pt-3">
                          <v-col>
                            <span
                              class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
                              >color:</span
                            >
                          </v-col>
                          <v-col v-if="printMethods.length">
                            <h4
                              class="text-uppercase regular-font fontsize-12 fontcolor-grey-dark float-right"
                            >
                              {{ selectedColorName }}
                            </h4>
                          </v-col>
                        </v-row>
                        <v-row
                          class="pr-1 pb-6"
                          v-if="printMethods.length && !checkIsStyleDisable()"
                        >
                          <ColorComponent
                            class="mx-2"
                            :key="selectedColor"
                            v-bind:colorCode="selectedColor"
                            :printMethod="selectedPrintMethod"
                            @clicked="setTextColor"
                            :colorName="selectedColorName"
                          ></ColorComponent>
                        </v-row>
                        <v-row v-else-if="checkIsStyleDisable()">
                          <v-col cols="12" class="pt-0">
                            <p class="text-red">
                              No color options available.
                            </p>
                          </v-col>
                        </v-row>
                        <v-row v-else>
                          <v-col cols="12">
                            <p class="text-red">
                              No Color is present to change.
                            </p>
                          </v-col>
                        </v-row>
                      </template>
                    </v-card-text>
                    <v-card-text v-else>
                      <v-row no-gutters>
                        <v-col cols="12" class="pb-0">
                          <p class="text-red mb-0">
                            No properties are present to change.
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-window-item>
                <v-window-item :value="`artwork`" :class="scrollClass">
                  <v-card tile flat>
                    <v-card-text class="px-7">
                      <v-tabs
                        fixed-tabs
                        v-model="selectedElementMainTypes"
                        :height="35"
                        hide-slider
                      >
                        <v-tab
                          class="productpage-tab-slider text-uppercase fontsize-12 regular-font ls-n008 px-0 rounded-0"
                          value="graphics"
                          @click="selectZeroTypeElement('graphics')"
                          >Graphics</v-tab
                        >
                        <v-tab
                          class="productpage-tab-slider text-uppercase fontsize-12 regular-font ls-n008 px-0 rounded-0"
                          value="borders"
                          disabled
                          >Borders</v-tab
                        >
                      </v-tabs>
                      <v-window v-model="selectedElementMainTypes">
                        <v-window-item :value="`graphics`">
                          <v-tabs
                            fixed-tabs
                            v-model="elementType"
                            class="mt-4"
                            :height="35"
                            hide-slider
                            align-tabs="center"
                          >
                            <v-tab
                              class="productpage-tab-slider text-uppercase fontsize-12 regular-font ls-n008 px-0 rounded-0"
                              value="motifs"
                              :disabled="hideMotifsTab"
                              @click="selectZeroTypeElement('motif')"
                              >Motifs</v-tab
                            >
                            <v-tab
                              class="productpage-tab-slider text-uppercase fontsize-12 regular-font ls-n008 px-0 rounded-0"
                              value="monograms"
                              :disabled="hideMonogramTab"
                              @click="selectZeroTypeElement('monograms')"
                              >Monograms</v-tab
                            >
                          </v-tabs>
                          <v-window v-model="elementType">
                            <v-window-item :value="`motifs`">
                              <ElementsComponent
                                @change="setDrawer"
                                @clicked="setSelectedMotif"
                                @changeMotifColor="changeMotifColor"
                                @changePrintMethod="setPrintMethod"
                                @removeSelectedMotif="removeSelectedMotif"
                                @setStyleForAllMatched="setStyleForAllMatched"
                                :selected-object="selectedCanvas"
                                :is-style-disabled="checkIsStyleDisable()"
                                :first-part-name="partStates[0].name"
                                :elementsProperty="elementsProperty"
                              ></ElementsComponent>
                            </v-window-item>
                            <v-window-item :value="`monograms`">
                              <MonogramComponent
                                :monogramsProperty="monogramsProperty"
                                @clickedOnSelectedMonogram="setSelectedMonogram"
                                @clicked="updateSelectedMonogramOnCanvas"
                                @changePrintMethod="setPrintMethod"
                                @changeMonogramColor="changeMonogramColor"
                                @setStyleForAllMatched="setStyleForAllMatched"
                                @removeSelectedMonogram="removeSelectedMonogram"
                                :selected-object="selectedCanvas"
                                :is-style-disabled="checkIsStyleDisable()"
                                :first-part-name="partStates[0].name"
                              ></MonogramComponent>
                            </v-window-item>
                          </v-window>
                        </v-window-item>
                        <v-window-item :value="`borders`"></v-window-item>
                      </v-window>
                    </v-card-text>
                  </v-card>
                </v-window-item>
                <v-window-item :value="`images`" :class="scrollClass">
                  <ImagesComponent
                    @changePrintMethod="setPrintMethod"
                    @clicked="setSelectedImage"
                    @uploadImage="setSelectedImage"
                    @removeImage="removeSelectedImage"
                    @pageLoader="setPageLoader"
                    @imageFlip="imageFlip"
                    @imageZoom="imageZoom"
                    @imageRotate="imageRotate"
                    @setStyleForAllMatched="setStyleForAllMatched"
                    :selected-object="selectedCanvas"
                    :selectedImageName="selectedImageName"
                    :imagesProperty="imagesProperty"
                    :is-style-disabled="checkIsStyleDisable()"
                    :first-part-name="partStates[0].name"
                    ref="imgComponent"
                  ></ImagesComponent>
                </v-window-item>
                <v-window-item :value="`finish`" :class="scrollClass">
                  <v-card tile flat>
                    <v-card-text class="px-7">
                      <v-tabs
                        fixed-tabs
                        v-model="linersType"
                        class="mt-1"
                        :height="35"
                        hide-slider
                      >
                        <v-tab
                          class="productpage-tab-slider text-uppercase fontsize-12 regular-font ls-n008 px-0 rounded-0"
                          value="linersColors"
                          >Solid Colors</v-tab
                        >
                        <v-tab
                          class="productpage-tab-slider text-uppercase fontsize-12 regular-font ls-n008 px-0 rounded-0 border-left-none"
                          value="linersPattern"
                          >Patterns</v-tab
                        >
                      </v-tabs>
                      <v-window v-model="linersType">
                        <v-window-item :value="`linersColors`">
                          <div
                            class="productpage-hr-line my-5 ml-n7 mr-n7"
                          ></div>
                          <v-row no-gutters class="pt-3">
                            <v-col>
                              <h4 class="text-uppercase body-font text-primary">
                                color:
                              </h4>
                            </v-col>
                            <v-col>
                              <h4
                                class="text-uppercase body-font fontcolor-grey-dark float-right"
                              >
                                {{ selectedLinerName }}
                              </h4>
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="linerSolidList.length"
                            class="py-4 finish-tab-height"
                            no-gutters
                          >
                            <v-col cols="auto">
                              <v-tooltip location="bottom" text="Remove Liner">
                                <template v-slot:activator="{ props }">
                                  <v-avatar
                                    v-bind="props"
                                    @click="removeFinishPattern()"
                                    rounded
                                    size="35"
                                    class="cursor-pointer ma-1 circular-danger-border"
                                  >
                                    <v-btn icon title="Remove Liner">
                                      <v-icon class="text-red"
                                        >mdi-window-close</v-icon
                                      >
                                    </v-btn>
                                  </v-avatar>
                                </template>
                              </v-tooltip>
                            </v-col>
                            <v-col
                              cols="auto"
                              v-for="(item, index) in linerSolidList"
                              v-bind:key="index"
                            >
                              <v-tooltip location="bottom" :text="item.name">
                                <template v-slot:activator="{ props }">
                                  <v-avatar
                                    v-if="item.url"
                                    v-bind="props"
                                    @click="setFinishPattern(item)"
                                    rounded
                                    size="35"
                                    :class="
                                      item.name == selectedLinerName
                                        ? 'circular-primary-border'
                                        : ''
                                    "
                                    class="cursor-pointer ma-1"
                                  >
                                    <img :src="item.url" :alt="item.name" />
                                  </v-avatar>
                                </template>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                          <v-col
                            cols="12"
                            class="pt-4 pa-0"
                            v-if="linerSolidList.length === 0"
                          >
                            <p
                              class="text-red mb-0"
                              v-if="linerSolidList.length === 0"
                            >
                              Solid patterns not present to change
                            </p>
                          </v-col>
                        </v-window-item>
                        <v-window-item :value="`linersPattern`">
                          <div
                            class="productpage-hr-line my-5 ml-n7 mr-n7"
                          ></div>
                          <v-row no-gutters class="pt-3">
                            <v-col>
                              <h4 class="text-uppercase body-font text-primary">
                                Pattern:
                              </h4>
                            </v-col>
                            <v-col>
                              <h4
                                class="text-uppercase body-font fontcolor-grey-dark float-right"
                              >
                                {{ selectedLinerName }}
                              </h4>
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="linerPatternList.length"
                            class="py-4"
                            no-gutters
                          >
                            <v-col cols="3" class="pb-1">
                              <v-tooltip location="bottom" text="Remove Liner">
                                <template v-slot:activator="{ props }">
                                  <div
                                    @click="removeFinishPattern()"
                                    v-bind="props"
                                    class="productpage-liners-library square-danger-border cursor-pointer d-flex align-center justify-center"
                                  >
                                    <v-avatar
                                      class="cursor-pointer ma-1 liners-pattern"
                                    >
                                      <v-icon
                                        :size="
                                          $vuetify.display.mdAndDown ? 60 : 80
                                        "
                                        class="text-red"
                                        >mdi-window-close</v-icon
                                      >
                                    </v-avatar>
                                  </div>
                                </template>
                              </v-tooltip>
                            </v-col>
                            <v-col
                              cols="3"
                              v-for="(item, index) in linerPatternList"
                              v-bind:key="index"
                              class="pb-1"
                            >
                              <v-tooltip location="bottom" :text="item.name">
                                <template
                                  v-if="item.name"
                                  v-slot:activator="{ props }"
                                >
                                  <div
                                    v-if="item.thumbnailUrl"
                                    v-bind="props"
                                    @click="setFinishPattern(item, index)"
                                    v-bind:class="{
                                      'productpage-shape-border':
                                        item.name == selectedLinerName,
                                    }"
                                    class="motifs-library cursor-pointer d-flex align-center justify-center"
                                  >
                                    <v-avatar class="liners-pattern">
                                      <v-img :src="item.thumbnailUrl" contain />
                                    </v-avatar>
                                  </div>
                                  <div
                                    v-else-if="item.url && item.name"
                                    @click="setFinishPattern(item)"
                                    v-bind:class="{
                                      'productpage-liners-border':
                                        item.name == selectedLinerName,
                                    }"
                                    class="productpage-liners-library cursor-pointer d-flex align-center justify-center"
                                  >
                                    <v-avatar class="liners-pattern">
                                      <v-img :src="item.url"></v-img>
                                    </v-avatar>
                                  </div>
                                </template>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                          <v-col
                            cols="12"
                            class="pt-4 pa-0"
                            v-if="linerPatternList.length === 0"
                          >
                            <p
                              class="text-red mb-0"
                              v-if="linerPatternList.length === 0"
                            >
                              Liner patterns not present to change
                            </p>
                          </v-col>
                        </v-window-item>
                      </v-window>
                    </v-card-text>
                  </v-card>
                </v-window-item>
              </v-window>
              <div class="pl-4 mb-2 pt-2 pt-sm-0" v-if="colorMismatched">
                <v-checkbox
                  v-model="mismatchedColorGranted"
                  class="mt-1 ml-0 color-checkbox text-uppercase pr-2"
                  hide-details
                  color="primary"
                  label="Looking beautiful! your customized item uses more than one ink color and we just want to make sure this is what you wanted. using multiple ink colors can affect your order's price or processing time. if it looks right to you, just check the box to proceed."
                  :value="true"
                ></v-checkbox>
              </div>
              <div class="pl-4 mb-2 pt-2 pt-sm-0" v-if="noModifiedText">
                <v-checkbox
                  v-model="modifiedTextGranted"
                  class="mt-1 ml-0 color-checkbox text-uppercase pr-2"
                  hide-details
                  color="primary"
                  label="Looking beautiful! we noticed that you did not change the default text in one or more areas on this item. Remember, it will be printed exactly as shown. If it looks right to you, just check the box to proceed."
                  :value="true"
                ></v-checkbox>
              </div>
            </v-card>
          </v-row>

          <!-------   mobile view Started ------>
          <div class="mobile-view-div" v-if="isMobileDevice && !inProgress">
            <v-card
              tile
              flat
              outlined
              class="productpage-stylebar-border mobile-view-tab-card rounded-0"
            >
              <v-card-title class="pa-0 ma-0 justify-space-between">
                <p
                  class="text-uppercase fontsize-12 pl-4 regular-font ls-n01 text-primary mb-0 text-truncate display-initial"
                >
                  {{ productOne.name }}
                </p>
                <v-btn
                  color="primary"
                  depressed
                  v-if="selectedCanvas"
                  variant="outlined"
                  :height="40"
                  :min-width="40"
                  small
                  tile
                  @click="closeMobileMenu()"
                  class="border-bottom-none border-right-none border-top-none rounded-0 float-right"
                >
                  <v-icon small size="15">mdi mdi-minus</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  depressed
                  v-if="!selectedCanvas"
                  outlined
                  :height="40"
                  :min-width="40"
                  small
                  tile
                  @click="selectZeroTypeElement('paper')"
                  class="border-bottom-none border-right-none border-top-none rounded-0 float-right"
                >
                  <v-icon small size="15">mdi mdi-plus</v-icon>
                </v-btn>
              </v-card-title>
              <div class="productpage-hr-line"></div>
              <v-card-text class="px-0 pt-1 pb-1">
                <v-row no-gutters>
                  <v-col cols="12" class="pt-0">
                    <v-tabs
                      fixed-tabs
                      centered
                      :height="28"
                      color="primary"
                      class="height tab-bottom-line"
                      v-if="!noPropertyToChange"
                      v-model="modeltype"
                    >
                      <v-tab
                        value="paper"
                        @click="selectZeroTypeElement('paper')"
                      >
                        <span
                          class="tab-underline text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                          >paper</span
                        >
                      </v-tab>
                      <v-tab
                        value="text"
                        @click="selectZeroTypeElement('textbox')"
                      >
                        <span
                          class="tab-underline text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                          >text</span
                        >
                      </v-tab>
                      <v-tab
                        value="artwork"
                        :class="disabledElementTab ? 'd-none' : ''"
                        @click="selectZeroTypeElement('graphics')"
                      >
                        <span
                          class="tab-underline text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                          >Elements</span
                        >
                      </v-tab>
                      <v-tab
                        value="images"
                        :class="imageTabDisable ? 'd-none' : ''"
                        @click="selectZeroTypeElement('image')"
                      >
                        <span
                          class="tab-underline text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                          >Photos</span
                        >
                      </v-tab>
                      <v-tab
                        value="finish"
                        v-if="showFinishTab"
                        :class="disableFinishTab ? 'd-none' : activeClass"
                        :disabled="disableFinishTab"
                        @click="setActiveFinishTab(true)"
                      >
                        <span
                          class="tab-underline text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                          >Liners</span
                        >
                      </v-tab>
                    </v-tabs>
                    <v-window :touch="false" v-model="modeltype">
                      <!--  paper tab  -->
                      <v-window-item :value="`paper`" class="max-height-25">
                        <v-card tile flat>
                          <v-card-text
                            v-if="selectedCanvas && !hidePaperOption"
                            class="px-0 pb-0 pt-2"
                          >
                            <v-tabs
                              fixed-tabs
                              v-model="paperTabType"
                              grow
                              :height="35"
                              hide-slider
                            >
                              <v-tab
                                class="productpage-tab-slider text-primary mx-0 text-uppercase fontsize-12 regular-font rounded-0"
                                value="stock"
                                >Stock</v-tab
                              >
                            </v-tabs>
                            <v-window :touch="false" v-model="paperTabType">
                              <v-window-item :value="`stock`">
                                <v-card tile flat>
                                  <v-card-text class="pb-0">
                                    <v-row no-gutters>
                                      <v-col
                                        cols="12"
                                        class="d-flex pb-2 justify-space-between"
                                      >
                                        <h4
                                          class="text-uppercase fontsize-12 regular-font text-primary float-right"
                                        >
                                          {{ selectedPaper.brand }} (
                                          {{ selectedPaper.weight }} )
                                        </h4>
                                        <h4
                                          class="text-uppercase regular-font fontsize-12 fontcolor-grey-dark float-right"
                                        >
                                          {{ selectedPaper.color }}
                                        </h4>
                                      </v-col>
                                    </v-row>
                                    <div>
                                      <div
                                        color="text-primary"
                                        class="ml-n4 mr-n4 productpage-hr-line"
                                      ></div>
                                      <v-row
                                        class="pt-3 pb-2 d-flex align-center"
                                        no-gutters
                                      >
                                        <v-col class="pb-0" cols="3">
                                          <span
                                            class="text-uppercase fontsize-12 regular-font text-primary"
                                            >color:
                                          </span>
                                        </v-col>
                                        <v-col
                                          class="pb-2 pr-0 d-inline d-flex flex-row max-width-90"
                                          cols="9"
                                        >
                                          <span
                                            class="px-1 d-flex flex-column justify-center align-center"
                                            v-for="(paper, index) in papers"
                                            v-bind:key="index"
                                          >
                                            <div
                                              class="color-circle d-flex justify-center align-center"
                                              v-bind:class="{
                                                'color-circle-border':
                                                  paper.text ==
                                                  (selectedPaper.name
                                                    ? selectedPaper.name
                                                    : selectedPaper.text),
                                              }"
                                            >
                                              <div
                                                class="productpage-color-cricle"
                                                :style="
                                                  'background-color:#' +
                                                  paper.colorHex
                                                "
                                                @click="setSelectedPaper(paper)"
                                              ></div>
                                            </div>
                                            <p
                                              class="mb-0 text-uppercase regular-font fontsize-8 text-center text-truncate"
                                            >
                                              {{ paper.color }}
                                            </p>
                                          </span>
                                        </v-col>
                                      </v-row>
                                    </div>
                                  </v-card-text>
                                </v-card>
                              </v-window-item>
                            </v-window>
                          </v-card-text>
                          <v-card-text v-if="papers.length == 0">
                            <p class="text-grey mb-0">
                              Loading product toolset ...
                              <!-- Paper properties are not present to change. -->
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-window-item>
                      <!---  text tab --->
                      <v-window-item
                        :value="`text`"
                        class="max-height-25"
                        v-if="selectedCanvas"
                      >
                        <v-card tile flat :key="textChangedKey">
                          <v-card-text class="px-0 pb-0 pt-2">
                            <v-list flat dense class="list-dense-height py-0">
                              <template
                                v-for="(item, index) in optionalTextElements"
                              >
                                <v-list-item
                                  v-if="item.extraProperty.optional"
                                  :key="index"
                                  class="pa-0 mb-0"
                                >
                                  <p
                                    class="d-inline-block mb-0 body-font fontsize-14 pa-2"
                                  >
                                    An additional charge will be incurred when
                                    adding a
                                    {{ item.dataElementName.toLowerCase() }}.
                                  </p>
                                </v-list-item>
                                <v-list-item-action
                                  v-if="item.extraProperty.optional"
                                  :key="index + 10"
                                  class="mx-0"
                                >
                                  <v-btn
                                    @click="
                                      addOptionalTextToCanvas(item, index)
                                    "
                                    color="primary"
                                    small
                                    class="text-uppercase fontsize-12 body-font ml-2 rounded-0"
                                  >
                                    Add "{{ item.dataElementName }}"
                                  </v-btn>
                                </v-list-item-action>
                              </template>
                            </v-list>
                            <template
                              v-if="
                                isTextElementPresent &&
                                !['false', false].includes(
                                  selectedCanvas.dataOptionalAdded
                                ) &&
                                !hideTextOption
                              "
                            >
                              <v-tabs
                                fixed-tabs
                                :height="35"
                                hide-slider
                                v-model="textTabType"
                              >
                                <v-tab
                                  class="productpage-tab-slider text-primary text-uppercase fontsize-12 regular-font rounded-0"
                                  :value="`font`"
                                  >Font</v-tab
                                >
                                <v-tab
                                  class="productpage-tab-slider text-uppercase fontsize-12 regular-font text-primary rounded-0"
                                  :value="`colors`"
                                  >Colors</v-tab
                                >
                              </v-tabs>
                              <v-window :touch="false" v-model="textTabType">
                                <v-window-item :value="`font`">
                                  <v-card tile flat>
                                    <v-card-text class="pb-0">
                                      <p
                                        class="text-uppercase regular-font fontsize-12 text-primary mb-3"
                                      >
                                        Font :
                                      </p>
                                      <v-row>
                                        <v-col
                                          class="d-flex justify-space-between pt-0"
                                        >
                                          <v-select
                                            v-model="selectedFont"
                                            v-if="!hideFontField"
                                            class="mt-0 py-0 mr-2 overflow-btn-icon primary-outline select-change-icon productpage-select-input rounded-0 input-select-primary-border input-select-border-radius-0"
                                            :items="fontList"
                                            @update:modelValue="setFont()"
                                            density="compact"
                                            flat
                                            variant="solo"
                                            color="primary"
                                            hide-details
                                            single-line
                                            item-title="name"
                                            item-value="name"
                                            label="Select font"
                                            ref="textFont"
                                            append-inner-icon="mdi-plus"
                                            :disabled="checkIsStyleDisable()"
                                          >
                                            <template
                                              v-slot:selection="{ item }"
                                            >
                                              <span
                                                class="text-primary fontsize-12 regular-font text-uppercase"
                                                >{{
                                                  item && item.raw
                                                    ? item.raw.name
                                                    : ""
                                                }}
                                              </span>
                                            </template>
                                            <template
                                              v-slot:item="{ props, item }"
                                            >
                                              <v-list-item
                                                :class="
                                                  selectedFont == item.raw.name
                                                    ? 'productpage-part-select-menu--active'
                                                    : ''
                                                "
                                              >
                                                <div
                                                  class="d-flex justify-space-between"
                                                >
                                                  <v-list-item-title
                                                    v-bind="props"
                                                    class="d-block text-truncate fontsize-12 regular-font text-primary text-uppercase cursor-pointer"
                                                    >{{
                                                      item && item.raw
                                                        ? item.raw.name
                                                        : ""
                                                    }}
                                                  </v-list-item-title>
                                                  <p
                                                    class="font-weight-medium mb-0 text-primary text-uppercase cursor-pointer"
                                                    :style="
                                                      setFontInFontList(
                                                        item.raw.name
                                                      )
                                                    "
                                                  >
                                                    ABCD
                                                  </p>
                                                </div>
                                              </v-list-item>
                                            </template>
                                          </v-select>
                                          <v-btn
                                            color="primary"
                                            small
                                            tile
                                            :height="40"
                                            :min-width="37"
                                            :width="37"
                                            depressed
                                            class="rounded-0"
                                            @click="openEditTextarea()"
                                          >
                                            <v-icon size="auto"
                                              >mdi mdi-pencil</v-icon
                                            >
                                          </v-btn>
                                        </v-col>
                                      </v-row>

                                      <v-row
                                        align="center"
                                        no-gutters
                                        class="d-flex justify-space-between"
                                      >
                                        <span
                                          v-if="!hideFontField"
                                          class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
                                          >Font Size :</span
                                        >
                                        <v-slider
                                          v-if="!hideFontField"
                                          v-model="selectedFontSize"
                                          class="align-center font-size-slider mr-0"
                                          color="primary"
                                          density="compact"
                                          @update:modelValue="setFontSize()"
                                          :min="fontMin"
                                          :max="fontMax"
                                          hide-details
                                          :step="1"
                                        >
                                          <template v-slot:append>
                                            <v-text-field
                                              v-model="selectedFontSize"
                                              class="mt-2 pl-1 font-size-text-input rounded-0 float-right input-select-primary-border input-select-border-radius-0"
                                              hide-details
                                              solo
                                              flat
                                              dense
                                              color="primary"
                                              readonly
                                              @change="setFontSize()"
                                              single-line
                                              type="number"
                                              style="
                                                width: 37px;
                                                max-width: 39px;
                                              "
                                            ></v-text-field>
                                          </template>
                                        </v-slider>
                                      </v-row>
                                      <v-row>
                                        <v-col
                                          cols="12"
                                          v-if="hideFontField || hidePrintField"
                                        >
                                          <p
                                            v-if="
                                              hideFontField || hidePrintField
                                            "
                                            class="text-grey"
                                          >
                                            Loading product toolset ...
                                          </p>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-window-item>
                                <v-window-item :value="`colors`">
                                  <v-card tile flat>
                                    <v-card-text class="pb-0">
                                      <v-row>
                                        <v-col
                                          cols="12"
                                          class="py-0 pt-1"
                                          v-if="!hidePrintField"
                                        >
                                          <span
                                            class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
                                            >Print Process :</span
                                          >
                                          <v-select
                                            v-model="selectedPrintMethod"
                                            :items="printMethods"
                                            @update:modelValue="
                                              setPrintMethod()
                                            "
                                            hide-details
                                            class="mt-0 overflow-btn-icon primary-outline select-change-icon productpage-select-input rounded-0 input-select-primary-border input-select-border-radius-0"
                                            target="#dropdown-example"
                                            density="compact"
                                            flat
                                            variant="solo"
                                            elevation="0"
                                            item-title="text"
                                            item-value="text"
                                            ref="printMethods"
                                            menu-icon="mdi-plus"
                                            :disabled="checkIsStyleDisable()"
                                          >
                                            <template
                                              v-slot:selection="{ item }"
                                            >
                                              <span
                                                class="ml-3 text-primary fontsize-12 regular-font text-uppercase"
                                                >{{
                                                  item && item.raw
                                                    ? item.raw.text
                                                    : ""
                                                }}
                                              </span>
                                            </template>
                                            <template
                                              v-slot:item="{ props, item }"
                                            >
                                              <v-list-item
                                                :class="
                                                  selectedPrintMethod ==
                                                  item.raw.text
                                                    ? 'productpage-part-select-menu--active'
                                                    : ''
                                                "
                                              >
                                                <div
                                                  class="d-flex justify-space-between"
                                                >
                                                  <v-list-item-title
                                                    v-bind="props"
                                                    class="d-block text-truncate fontsize-12 regular-font text-primary text-uppercase cursor-pointer"
                                                    >{{
                                                      item && item.raw
                                                        ? item.raw.text
                                                        : ""
                                                    }}</v-list-item-title
                                                  >
                                                </div>
                                              </v-list-item>
                                            </template>
                                          </v-select>
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          class="pt-0"
                                          v-if="showStyleControl"
                                        >
                                          <span
                                            class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
                                            >Style :</span
                                          >
                                          <v-switch
                                            v-model="dataIsStyleLinked"
                                            @update:modelValue="
                                              applyLinkedStyle
                                            "
                                            density="compact"
                                            hide-details
                                            color="primary"
                                            class="alternate-svg-switch mt-0 text-primary"
                                          >
                                            <template v-slot:label>
                                              <span
                                                class="text-primary text-uppercase fontsize-12 body-font"
                                                >{{
                                                  dataIsStyleLinked
                                                    ? "LINKED"
                                                    : "UNLINKED"
                                                }}</span
                                              >
                                            </template>
                                          </v-switch>
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          v-if="checkIsStyleDisable()"
                                        >
                                          <p
                                            class="text-red fontsize-14 body-font"
                                          >
                                            This is a styled element. To change
                                            it we recommend:
                                            <br />
                                            - going back to
                                            {{ partStates[0].name }} and make
                                            adjustments there
                                            <br />
                                            - OR you can Unlink from style and
                                            make your changes for this item only
                                          </p>
                                        </v-col>
                                      </v-row>
                                      <v-row
                                        class="pr-1 pt-5 d-flex"
                                        no-gutters
                                        v-if="
                                          printMethods.length &&
                                          !checkIsStyleDisable()
                                        "
                                      >
                                        <v-col
                                          cols="2"
                                          class="d-flex align-center"
                                        >
                                          <span
                                            class="text-uppercase regular-font fontsize-12 text-primary"
                                            >color:</span
                                          >
                                        </v-col>
                                        <v-col
                                          cols="10"
                                          class="d-flex align-center max-width-70 pb-2"
                                        >
                                          <ColorComponent
                                            class="mx-2"
                                            v-bind:colorCode="selectedColor"
                                            :colorName="selectedColorName"
                                            :printMethod="selectedPrintMethod"
                                            @clicked="setTextColor"
                                            :key="selectedColor"
                                          ></ColorComponent>
                                        </v-col>
                                      </v-row>
                                      <v-row v-else-if="checkIsStyleDisable()">
                                         <v-col cols="12" class="pb-0">
                                          <p class="text-red mb-0">
                                            No color options available.
                                          </p>
                                        </v-col>
                                      </v-row>
                                      <v-row v-else>
                                        <v-col cols="12" class="pb-0">
                                          <p class="text-red mb-0">
                                            No color is present to change.
                                          </p>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-window-item>
                              </v-window>
                            </template>
                          </v-card-text>
                        </v-card>
                      </v-window-item>
                      <!---  element tab --->
                      <v-window-item
                        :value="`artwork`"
                        class="max-height-25"
                        v-if="selectedCanvas"
                      >
                        <v-card tile flat>
                          <v-card-text
                            v-if="isMobileDevice"
                            class="px-0 pb-0 pt-2"
                          >
                            <v-tabs
                              fixed-tabs
                              v-model="selectedElementMainTypes"
                              class="paper-tab-full-width"
                              :height="35"
                              hide-slider
                            >
                              <v-tab
                                class="productpage-tab-slider text-primary text-uppercase fontsize-12 regular-font ls-n008 px-0 rounded-0"
                                :value="`graphics`"
                                @click="selectZeroTypeElement('graphics')"
                                >Graphics</v-tab
                              >
                            </v-tabs>
                            <v-window
                              :touch="false"
                              v-model="selectedElementMainTypes"
                            >
                              <v-window-item :value="`graphics`">
                                <v-tabs
                                  fixed-tabs
                                  v-model="elementType"
                                  class="mt-4"
                                  :height="35"
                                >
                                  <v-tab
                                    class="productpage-tab-slider text-uppercase fontsize-12 regular-font ls-n008 px-0 rounded-0 text-primary"
                                    :value="`motifs`"
                                    :disabled="hideMotifsTab"
                                    @click="selectZeroTypeElement('motif')"
                                    >Motifs</v-tab
                                  >
                                  <v-tab
                                    class="productpage-tab-slider text-uppercase fontsize-12 regular-font ls-n008 px-0 rounded-0 text-primary"
                                    :value="`monograms`"
                                    :disabled="hideMonogramTab"
                                    @click="selectZeroTypeElement('monograms')"
                                    >Monograms</v-tab
                                  >
                                </v-tabs>
                                <v-window :touch="false" v-model="elementType">
                                  <v-window-item :value="`motifs`">
                                    <ElementsComponent
                                      @change="setDrawer"
                                      @clicked="setSelectedMotif"
                                      @changeMotifColor="changeMotifColor"
                                      @changePrintMethod="setPrintMethod"
                                      @setStyleForAllMatched="
                                        setStyleForAllMatched
                                      "
                                      :selected-object="selectedCanvas"
                                      :elementsProperty="elementsProperty"
                                      :is-style-disabled="checkIsStyleDisable()"
                                      :first-part-name="partStates[0].name"
                                    ></ElementsComponent>
                                  </v-window-item>
                                  <v-window-item :value="`monograms`">
                                    <MonogramComponent
                                      :monogramsProperty="monogramsProperty"
                                      @clickedOnSelectedMonogram="
                                        setSelectedMonogram
                                      "
                                      @changePrintMethod="setPrintMethod"
                                      @clicked="updateSelectedMonogramOnCanvas"
                                      @changeMonogramColor="changeMonogramColor"
                                      @setStyleForAllMatched="
                                        setStyleForAllMatched
                                      "
                                      :selected-object="selectedCanvas"
                                      :is-style-disabled="checkIsStyleDisable()"
                                      :first-part-name="partStates[0].name"
                                    ></MonogramComponent>
                                  </v-window-item>
                                </v-window>
                              </v-window-item>
                            </v-window>
                          </v-card-text>
                        </v-card>
                      </v-window-item>
                      <!---  images tab --->
                      <v-window-item
                        :value="`images`"
                        v-if="selectedCanvas"
                        class="max-height-25"
                      >
                        <ImagesComponent
                          @changePrintMethod="setPrintMethod"
                          @clicked="setSelectedImage"
                          @uploadImage="setSelectedImage"
                          @removeImage="removeSelectedImage"
                          @pageLoader="setPageLoader"
                          @imageFlip="imageFlip"
                          @imageZoom="imageZoom"
                          @imageRotate="imageRotate"
                          @setStyleForAllMatched="setStyleForAllMatched"
                          :selected-object="selectedCanvas"
                          :selectedImageName="selectedImageName"
                          :imagesProperty="imagesProperty"
                          :is-style-disabled="checkIsStyleDisable()"
                          :first-part-name="partStates[0].name"
                          ref="imgComponent"
                        ></ImagesComponent>
                      </v-window-item>
                      <!---  finish tab --->
                      <v-window-item
                        :value="`finish`"
                        v-if="activeFinishTab"
                        class="max-height-25"
                      >
                        <v-card tile flat>
                          <v-card-text class="px-0 pb-0 pt-2">
                            <v-tabs
                              fixed-tabs
                              v-model="linersType"
                              class="mt-1"
                              :height="35"
                              hide-slider
                            >
                              <v-tab
                                class="productpage-tab-slider text-primary text-uppercase fontsize-12 regular-font ls-n008 px-0 rounded-0"
                                :value="`linersColors`"
                                >Solid Colors</v-tab
                              >
                              <v-tab
                                class="productpage-tab-slider text-uppercase fontsize-12 regular-font text-primary ls-n008 px-0 rounded-0"
                                :value="`linersPattern`"
                                >Patterns</v-tab
                              >
                            </v-tabs>
                            <v-window :touch="false" v-model="linersType">
                              <v-window-item :value="`linersColors`">
                                <v-card tile flat>
                                  <v-card-text class="py-0">
                                    <v-row no-gutters class>
                                      <v-col>
                                        <p
                                          class="text-uppercase pt-3 fontsize-12 regular-font text-primary mb-0"
                                        >
                                          Liner:
                                        </p>
                                      </v-col>
                                      <v-col>
                                        <v-row
                                          no-gutters
                                          class="d-flex justify-end"
                                        >
                                          <p
                                            class="text-uppercase pt-3 fontsize-12 regular-font mb-0 d-block text-truncate fontcolor-grey-dark float-right"
                                          >
                                            {{ selectedLinerName }}
                                          </p>
                                        </v-row>
                                      </v-col>
                                    </v-row>

                                    <div
                                      v-if="linerSolidList.length"
                                      class="d-inline d-flex flex-row max-width-90 pt-2"
                                    >
                                      <span class="d-inline">
                                        <v-tooltip bottom>
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-avatar
                                              v-bind="attrs"
                                              v-on="on"
                                              @click="removeFinishPattern()"
                                              size="35"
                                              class="cursor-pointer ma-1 circular-danger-border"
                                            >
                                              <v-btn icon title="Remove Liner">
                                                <v-icon class="text-red"
                                                  >mdi-window-close</v-icon
                                                >
                                              </v-btn>
                                            </v-avatar>
                                          </template>
                                          <span>Remove Liner</span>
                                        </v-tooltip>
                                      </span>
                                      <span
                                        class="d-inline"
                                        v-for="(item, index) in linerSolidList"
                                        v-bind:key="index"
                                      >
                                        <v-tooltip bottom>
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-avatar
                                              v-if="item.url"
                                              v-bind="attrs"
                                              v-on="on"
                                              @click="setFinishPattern(item)"
                                              size="35"
                                              :class="
                                                item.name == selectedLinerName
                                                  ? 'circular-primary-border'
                                                  : ''
                                              "
                                              class="cursor-pointer ma-1"
                                            >
                                              <img
                                                :src="item.url"
                                                :alt="item.name"
                                              />
                                            </v-avatar>
                                          </template>
                                          <span>{{ item.name }}</span>
                                        </v-tooltip>
                                      </span>
                                    </div>
                                    <v-col
                                      cols="12"
                                      class="pt-4 pa-0"
                                      v-if="linerSolidList.length === 0"
                                    >
                                      <p
                                        class="text-red mb-0"
                                        v-if="linerSolidList.length === 0"
                                      >
                                        Solid patterns not present to change
                                      </p>
                                    </v-col>
                                  </v-card-text>
                                </v-card>
                              </v-window-item>
                              <v-window-item :value="`linersPattern`">
                                <v-card tile flat>
                                  <v-card-text class="py-0">
                                    <v-row no-gutters class>
                                      <v-col>
                                        <p
                                          class="text-uppercase pt-3 fontsize-12 regular-font text-primary mb-0"
                                        >
                                          Liner:
                                        </p>
                                      </v-col>
                                      <v-col>
                                        <v-row
                                          no-gutters
                                          class="d-flex justify-end"
                                        >
                                          <p
                                            class="text-uppercase pt-3 fontsize-12 regular-font mb-0 d-block text-truncate fontcolor-grey-dark float-right"
                                          >
                                            {{ selectedLinerName }}
                                          </p>
                                        </v-row>
                                      </v-col>
                                    </v-row>

                                    <div
                                      v-if="linerPatternList.length"
                                      class="d-inline d-flex flex-row max-width-90 pt-3"
                                    >
                                      <span class="d-inline pb-1 mr-1">
                                        <v-tooltip bottom>
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <div
                                              @click="removeFinishPattern()"
                                              v-bind="attrs"
                                              v-on="on"
                                              class="productpage-liners-library square-danger-border cursor-pointer d-flex align-center justify-center"
                                            >
                                              <v-avatar
                                                class="cursor-pointer liners-pattern"
                                              >
                                                <v-icon
                                                  size="80"
                                                  class="text-red"
                                                  >mdi-window-close</v-icon
                                                >
                                              </v-avatar>
                                            </div>
                                          </template>
                                          <span>Remove Liner</span>
                                        </v-tooltip>
                                      </span>
                                      <span
                                        class="d-inline pb-1 mx-1"
                                        v-for="(
                                          item, index
                                        ) in linerPatternList"
                                        v-bind:key="index"
                                      >
                                        <div
                                          v-if="item.url && item.name"
                                          @click="setFinishPattern(item)"
                                          v-bind:class="{
                                            'productpage-liners-border':
                                              item.name == selectedLinerName,
                                          }"
                                          class="productpage-liners-library cursor-pointer d-flex align-center justify-center"
                                        >
                                          <v-avatar
                                            class="liners-pattern"
                                            size="80"
                                          >
                                            <v-img
                                              :src="item.url"
                                              width="80"
                                            ></v-img>
                                          </v-avatar>
                                        </div>
                                      </span>
                                    </div>
                                    <v-col
                                      cols="12"
                                      class="pt-4 pa-0"
                                      v-if="linerPatternList.length === 0"
                                    >
                                      <p
                                        class="text-red mb-0"
                                        v-if="linerPatternList.length === 0"
                                      >
                                        Liner patterns not present to change
                                      </p>
                                    </v-col>
                                  </v-card-text>
                                </v-card>
                              </v-window-item>
                            </v-window>
                          </v-card-text>
                        </v-card>
                      </v-window-item>
                    </v-window>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider color="text-primary"></v-divider>
            </v-card>
          </div>
          <!------------- mobile view end -------------->
          <v-row
            class="d-flex max-height-150 px-3"
            :class="[
              isMobileDevice ? 'align-start' : 'align-center',
              scrollWindow ? 'mt-0' : 'mt-2',
            ]"
            v-if="!inProgress"
          >
            <v-col class="pa-0 mt-1">
              <v-row v-if="isMobileDevice">
                <v-col class="pb-0 pt-1">
                  <div class="pl-4 mb-2 pt-2 pt-sm-0" v-if="colorMismatched">
                    <v-checkbox
                      v-model="mismatchedColorGranted"
                      class="mt-1 ml-0 mb-3 color-checkbox"
                      hide-details
                      color="primary"
                      label="Looking beautiful! your customized item uses more than one ink color and we just want to make sure this is what you wanted. using multiple ink colors can affect your order's price or processing time. if it looks right to you, just check the box to proceed."
                      :value="true"
                    ></v-checkbox>
                  </div>
                  <div class="pl-4 mb-2 pt-1 pt-sm-0" v-if="noModifiedText">
                    <v-checkbox
                      v-model="modifiedTextGranted"
                      class="mt-0 ml-0 mb-3 color-checkbox"
                      color="primary"
                      hide-details
                      label="Looking beautiful! we noticed that you did not change the default text in one or more areas on this item. Remember, it will be printed exactly as shown. If it looks right to you, just check the box to proceed."
                      :value="true"
                    ></v-checkbox>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pb-1" :class="scrollWindow ? 'mt-0' : 'mt-2'">
                <v-col cols="6" class="py-0 pr-0">
                  <div>
                    <v-btn
                      block
                      :ripple="false"
                      :size="
                        this.$vuetify.display.mdAndUp ? 'x-large' : 'default'
                      "
                      class="part-change-btns text-uppercase ls-0 pb-1 px-1 rounded-0"
                      :class="
                        isMobileDevice ? 'regular-font fontsize-14' : 'body'
                      "
                      variant="outlined"
                      color="primary"
                      :disabled="canvasLoadingInProgress"
                      @click="changeToPreviousPart(selectedPartIndex)"
                      >BACK</v-btn
                    >
                  </div>
                </v-col>
                <v-col cols="6" class="py-0 pl-0">
                  <div>
                    <v-btn
                      block
                      tile
                      :ripple="false"
                      :size="
                        this.$vuetify.display.mdAndUp ? 'x-large' : 'default'
                      "
                      :disabled="canvasLoadingInProgress"
                      class="part-change-btns text-uppercase ls-0 pb-1 px-1 rounded-0"
                      :class="
                        isMobileDevice ? 'regular-font fontsize-14' : 'body'
                      "
                      variant="flat"
                      color="primary"
                      @click="changeToNextPart(selectedPartIndex)"
                      >NEXT</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="editTextareaModal"
      max-width="800"
      content-class="dailog-position-bottom d-sm-none"
    >
      <v-card tile flat class="all-side-primary-border">
        <div class="d-flex justify-space-between align-center">
          <v-btn
            variant="text"
            color="primary"
            depressed
            tile
            @click="editTextareaModal = false"
            >Cancel</v-btn
          >
          <v-btn
            variant="text"
            color="primary"
            depressed
            tile
            @click="setEditedTextOnCavas"
            >Done</v-btn
          >
        </div>
        <v-card-text class="px-4">
          <v-textarea
            @keydown="editSVGText($event)"
            @input="setEditedText($event)"
            v-model="selectedText"
            v-bind:style="{
              fontFamily: selectedCanvas ? selectedCanvas.fontFamily : null,
              fontSize: selectedCanvas ? selectedCanvas.fontSize + 'px' : null,
              fontWeight: selectedCanvas ? selectedCanvas.fontWeight : null,
            }"
            ref="editMessage"
            hide-details
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="optionalElementModal"
      origin="left top"
      hide-overlay
      persistent
      max-width="600px"
      attach
    >
      <v-card tile flat :key="textChangedKey" class="pt-5 pb-0 pl-5 pr-1">
        <v-card-title class="pb-0">
          <v-row no-gutters>
            <v-col
              cols="12"
              v-if="
                singleOptionalElement &&
                singleOptionalElement.extraProperty &&
                singleOptionalElement.extraProperty.optional
              "
            >
              <p class="body mt-1 mb-0 fontcolor-black-light p-word-break">
                Would you like to add a Return Address to your envelope
                {{
                  partSurface && partSurface.name
                    ? partSurface.name
                    : partSurface
                }}
                before proceeding? If yes, an additional charge will incur.
              </p>
            </v-col>
            <v-col cols="12" v-else>
              <p class="body mt-1 mb-0 fontcolor-black-light p-word-break">
                No optional element is present to add.
              </p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions class="px-4 pt-4">
          <v-row no-gutters>
            <v-col
              cols="12"
              class="text-right"
              v-if="
                singleOptionalElement &&
                singleOptionalElement.extraProperty &&
                singleOptionalElement.extraProperty.optional
              "
            >
              <v-btn
                @click="addOptionalTextToCanvas(singleOptionalElement)"
                color="primary"
                tile
                variant="flat"
                class="text-uppercase fontsize-12 body-font ma-2 rounded-0"
              >
                Yes
              </v-btn>
              <v-btn
                class="text-uppercase ma-2 rounded-0"
                variant="outlined"
                color="primary"
                @click="onOptionalModalAction(singleOptionalElement, false)"
              >
                No
              </v-btn>
            </v-col>
            <v-col cols="12" class="text-right" v-else>
              <v-btn
                class="text-uppercase ma-2 rounded-0"
                color="primary"
                variant="outlined"
                @click="onOptionalModalAction(singleOptionalElement, false)"
              >
                Close & continue
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmationDialogFlag"
      origin="left top"
      hide-overlay
      persistent
      max-width="800px"
      attach
    >
      <v-card tile flat>
        <v-card-title class="pb-0">
          <v-row no-gutters>
            <v-col cols="11">
              <p class="body mt-1 mb-0 fontcolor-black-light">
                {{ confirmation.confirmationMessage }}
              </p>
            </v-col>
            <v-col cols="1" class="text-right">
              <router-link
                to
                class="text-decoration-none text-black fontcolor-black-light"
              >
                <div @click="onResetDialogCancel()">X</div>
              </router-link>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions class="px-4 pt-0">
          <v-row no-gutters>
            <v-col cols="12">
              <v-btn
                class="text-uppercase ma-2"
                color="primary"
                variant="elevated"
                tile
                flat
                @click="onResetDialogCancel()"
                >CANCEL</v-btn
              >
              <v-btn
                class="text-uppercase ma-2"
                color="error"
                variant="outlined"
                tile
                flat
                @click="resetSVGContent()"
              >
                OK
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- monogram dailogs -->
    <!-- desktop first dialog  -->
    <v-dialog
      v-model="monogramStudioDailog"
      persistent
      max-width="600"
      v-if="!isMobileDevice"
      attach
    >
      <v-card tile flat class="all-side-primary-border rounded-0">
        <v-card-title class="py-0 pr-0">
          <v-row class="py-3 mr-0">
            <v-col cols="10">
              <p
                class="text-uppercase fontsize-12 regular-font ls-n007 line-height-24 text-primary mb-0"
              >
                crane monogram studio
              </p>
              <p
                class="text-primary fontsize-10 body-font line-height-16 ls-n006 mb-0"
              >
                Create your own unique mark with Crane's Monogram Studio
              </p>
            </v-col>
            <v-col cols="2" class="d-flex justify-end pa-0">
              <v-btn
                color="primary"
                flat
                variant="outlined"
                :height="40"
                :min-width="40"
                size="small"
                rounded="false"
                @click="closeMonogramMenu()"
                class="border-right-none border-top-none rounded-0"
              >
                <v-icon small size="15">mdi mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <div class="productpage-hr-line"></div>
        <v-card-text>
          <v-row class v-if="monogramLetters">
            <v-col
              class="d-flex justify-center align-center pb-0"
              :key="selectedMonogramDesignName"
            >
              <h1
                v-if="setSelectedMonogramStyle"
                class="font-weight-medium my-2 fontsize-50"
                :style="setSelectedMonogramStyle"
                v-html="generatedMonogramPreview"
              ></h1>
            </v-col>
          </v-row>
          <div class="productpage-hr-line mx-n6"></div>
          <v-row class="d-flex justify-center" v-if="monogramsLength">
            <v-col cols="12" sm="10">
              <v-tabs fixed-tabs v-model="setMonogramActiveTab" color="primary">
                <v-tab
                  class="text-uppercase fontsize-14 regular-font ls-n008 px-0"
                  :disabled="setMonogramLetters == 1 ? false : true"
                  :value="0"
                  >ONE</v-tab
                >
                <v-tab
                  class="text-uppercase fontsize-14 regular-font ls-n008 px-0"
                  :disabled="setMonogramLetters == 2 ? false : true"
                  :value="1"
                  >TWO</v-tab
                >
                <v-tab
                  class="text-uppercase fontsize-14 regular-font ls-n008 px-0"
                  :disabled="setMonogramLetters == 3 ? false : true"
                  :value="2"
                  >THREE</v-tab
                >
                <v-tab
                  class="text-uppercase fontsize-14 regular-font ls-n008 px-0"
                  :disabled="setMonogramLetters == 4 ? false : true"
                  :value="3"
                  >FOUR</v-tab
                >
              </v-tabs>
              <v-window touchless v-model="setMonogramActiveTab">
                <v-window-item :value="0">
                  <v-row justify="center">
                    <v-col cols="3" class="text-center">
                      <v-text-field
                        v-model="monogramLetters[0]"
                        density="compact"
                        flat
                        class="text-h5 text-primary font-weight-medium productpage-monogram-size"
                        :maxlength="1"
                        @keypress="allowAlphaOnly"
                        @input="generatePreview"
                        :rules="alphaRequiredRule"
                        ref="editMessage1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-window-item>
                <v-window-item :value="1">
                  <v-row align="center" justify="center">
                    <v-col
                      cols="3"
                      v-for="(items, index) in setMonogramLetters"
                      v-bind:key="index"
                      class="text-center"
                    >
                      <v-text-field
                        v-model="monogramLetters[index]"
                        density="compact"
                        flat
                        class="text-h5 text-primary font-weight-medium productpage-monogram-size"
                        :maxlength="1"
                        @keypress="allowAlphaOnly"
                        @input="generatePreview"
                        :rules="alphaRequiredRule"
                        ref="editMessage2"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-window-item>
                <v-window-item :value="2">
                  <v-row align="center" justify="center">
                    <v-col
                      cols="3"
                      v-for="(items, index) in setMonogramLetters"
                      v-bind:key="index"
                      class="text-center"
                    >
                      <v-text-field
                        v-model="monogramLetters[index]"
                        density="compact"
                        flat
                        class="text-h5 text-primary font-weight-medium productpage-monogram-size"
                        :maxlength="1"
                        @load="setFocus()"
                        @keypress="allowAlphaOnly"
                        @input="generatePreview"
                        :rules="alphaRequiredRule"
                        ref="editMessage3"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-window-item>
                <v-window-item :value="3">
                  <v-row align="center" justify="center">
                    <v-col
                      cols="3"
                      v-for="(items, index) in setMonogramLetters"
                      v-bind:key="index"
                      class="text-center"
                    >
                      <v-text-field
                        v-model="monogramLetters[index]"
                        density="compact"
                        flat
                        class="text-h5 text-primary font-weight-medium productpage-monogram-size"
                        :maxlength="1"
                        @keypress="allowAlphaOnly"
                        @input="generatePreview"
                        :rules="alphaRequiredRule"
                        ref="editMessage4"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-0 pt-3">
          <v-row no-gutters>
            <v-col class="px-0">
              <v-btn
                flat
                rounded="0"
                block
                size="large"
                variant="outlined"
                @click="closeMonogramMenu()"
                color="primary"
                >EXIT</v-btn
              >
            </v-col>
            <v-col class="px-0">
              <v-btn
                flat
                rounded="0"
                block
                variant="elevated"
                size="large"
                @click="addMonogramOnCanvas()"
                color="primary"
                >ADD TO DESIGN</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- desktop inner dialog  -->
    <v-dialog
      v-model="monogramStudioExitDailog"
      persistent
      max-width="400"
      v-if="!isMobileDevice"
      attach
    >
      <v-card tile flat class="all-side-primary-border">
        <v-card-title class="pr-0">
          <v-row class="pb-1 mr-0">
            <v-col cols="12" class="d-flex justify-end pa-0">
              <v-btn
                color="primary"
                flat
                :height="30"
                :min-width="30"
                :width="30"
                size="small"
                rounded="0"
                @click="continueMonogramExitMenu()"
                class="border-right-none border-top-none"
              >
                <v-icon size="small">mdi mdi-window-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pb-2">
          <v-row justify="center">
            <v-col cols="10" class>
              <p
                class="text-uppercase mb-5 regular-font fontsize-16 text-primary ls-n01"
              >
                YOUR ARE ABOUT TO EXIT CRANE’S MONOGRAM STUDIO
              </p>
              <p class="mb-3 body-font fontsize-12 text-primary ls-n007">
                We wanted to let you know that your beautiful Monogram has not
                been saved. However we understand that sometimes these things
                take time.
              </p>
              <p class="mb-3 body-font fontsize-12 text-primary ls-n007">
                Add your Monogram to your design to save before exiting
              </p>
            </v-col>
          </v-row>
          <div class="productpage-hr-line"></div>
        </v-card-text>
        <v-card-actions class="px-6">
          <v-row no-gutters>
            <v-col class="px-0 pb-3">
              <v-btn
                flat
                rounded="0"
                size="small"
                block
                variant="outlined"
                @click="closeMonogramExitMenu()"
                color="primary"
                >EXIT</v-btn
              >
            </v-col>
            <v-col class="px-0">
              <v-btn
                flat
                rounded="0"
                block
                size="small"
                variant="elevated"
                @click="continueMonogramExitMenu()"
                color="primary"
                >CONTINUE</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---  mobile view --->
    <v-dialog
      v-model="monogramStudioDailog"
      persistent
      max-width="600"
      v-if="isMobileDevice"
      :overlay-opacity="0.5"
      attach
    >
      <v-card tile flat class="all-side-primary-border rounded-0">
        <v-card-title class="py-3 pr-0">
          <v-row class="pb-1 mr-0">
            <v-col cols="10">
              <p
                class="text-uppercase fontsize-12 regular-font ls-n007 line-height-24 text-primary mb-0"
              >
                crane monogram studio
              </p>
              <p
                class="text-primary fontsize-10 body-font line-height-16 ls-n006 mb-0 p-word-break"
              >
                Create your own unique mark with Crane's Monogram Studio
              </p>
            </v-col>
            <v-col cols="2" class="d-flex justify-end pa-0">
              <v-btn
                color="primary"
                flat
                variant="outlined"
                :height="40"
                :min-width="40"
                size="small"
                rounded="false"
                @click="closeMonogramMenu()"
                class="border-right-none border-top-none rounded-0"
              >
                <v-icon size="small">mdi mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <div class="productpage-hr-line"></div>
        <v-card-text class="py-3">
          <v-tabs
            fixed-tabs
            align-tabs="center"
            :height="28"
            color="primary"
            class="height tab-bottom-line monogram-mobile-tabs"
            v-model="monogramActiveTab"
          >
            <v-tab :value="`style`" class="monogram-tab-border">
              <span
                class="text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0 tab-active-border-none"
                >styles</span
              >
            </v-tab>
            <v-tab :value="`initials`" class="monogram-tab-border-y">
              <span
                class="text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                >initials</span
              >
            </v-tab>
            <v-tab :value="`color`" class="monogram-tab-border">
              <span
                class="text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                >color</span
              >
            </v-tab>
          </v-tabs>
          <v-window touchless v-model="monogramActiveTab">
            <v-window-item :value="`style`">
              <v-row no-gutters class="pt-1 mb-3">
                <v-col cols="6">
                  <h4
                    class="text-uppercase fontsize-12 font-weight-300 body-font text-primary"
                  >
                    Monogram:
                  </h4>
                </v-col>
                <v-col cols="6" class="text-right">
                  <h4
                    class="text-uppercase d-block font-weight-300 body-font fontsize-12 fontcolor-grey-darken text-truncate"
                  >
                    {{ selectedMonogramDesignName }}
                  </h4>
                </v-col>
              </v-row>
              <v-row class="mb-3" v-if="monogramLetters">
                <v-col
                  class="d-flex justify-center align-center pa-0"
                  :key="selectedMonogramDesignName"
                >
                  <h1
                    v-if="setSelectedMonogramStyle"
                    class="font-weight-medium fontsize-50"
                    :style="setSelectedMonogramStyle"
                    v-html="generatedMonogramPreview"
                  ></h1>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <div class="productpage-hr-line mx-n6"></div>
                </v-col>
              </v-row>
              <v-row v-if="monogramsLength" class="max-height-25 mx-0 mt-0">
                <v-col
                  cols="4"
                  align="center"
                  class="px-0 py-2"
                  v-for="(items, index) in monograms"
                  v-bind:key="index"
                >
                  <div
                    v-if="items.thumbnailUrl"
                    @click="setSelectedMonogram(items, true)"
                    v-bind:class="{
                      'productpage-shape-border':
                        items.name == selectedMonogramDesignName,
                    }"
                    class="productpage-monogram-library cursor-pointer d-flex align-center"
                  >
                    <v-avatar class="cursor-pointer user-image ma-1">
                      <v-img :src="items.thumbnailUrl" />
                    </v-avatar>
                  </div>
                </v-col>
              </v-row>
              <div v-else>
                <p class="text-red py-2 mb-0">
                  Monograms are not present to change
                </p>
              </div>
            </v-window-item>
            <v-window-item :value="`initials`">
              <v-row class="d-flex justify-center" v-if="monogramsLength">
                <v-col cols="12" sm="10">
                  <v-tabs
                    v-model="setMonogramActiveTab"
                    hide-slider
                    :center-active="true"
                    class="lettersTabs"
                  >
                    <v-tab
                      :disabled="setMonogramLetters == 1 ? false : true"
                      :value="`#0`"
                    >
                      <span
                        class="tab-underline text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                        >one</span
                      >
                    </v-tab>
                    <v-tab
                      :disabled="setMonogramLetters == 2 ? false : true"
                      :value="`#1`"
                    >
                      <span
                        class="tab-underline text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                        >two</span
                      >
                    </v-tab>
                    <v-tab
                      :disabled="setMonogramLetters == 3 ? false : true"
                      :value="`#2`"
                    >
                      <span
                        class="tab-underline text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                        >three</span
                      >
                    </v-tab>
                    <v-tab
                      :disabled="setMonogramLetters == 4 ? false : true"
                      :value="`#3`"
                    >
                      <span
                        class="tab-underline text-uppercase fontsize-12 text-primary regular-font ls-n008 px-0"
                        >four</span
                      >
                    </v-tab>
                  </v-tabs>
                  <v-window touchless v-model="setMonogramActiveTab">
                    <v-window-item :value="0">
                      <v-row justify="center">
                        <v-col cols="3" class="text-center">
                          <v-text-field
                            v-model="monogramLetters[0]"
                            density="compact"
                            flat
                            color="primary"
                            class="display-2 text-primary font-weight-medium productpage-monogram-size px-0 text-center"
                            :maxlength="1"
                            @keypress="allowAlphaOnly"
                            @input="generatePreview"
                            :rules="alphaRequiredRule"
                            ref="editMessage1"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-window-item>

                    <v-window-item :value="1">
                      <v-row align="center" justify="center">
                        <v-col
                          cols="3"
                          v-for="(items, index) in setMonogramLetters"
                          v-bind:key="index"
                          class="text-center"
                        >
                          <v-text-field
                            v-model="monogramLetters[index]"
                            density="compact"
                            flat
                            color="primary"
                            class="display-2 text-primary font-weight-medium productpage-monogram-size px-0 text-center"
                            :maxlength="1"
                            @keypress="allowAlphaOnly"
                            @input="generatePreview"
                            :rules="alphaRequiredRule"
                            ref="editMessage2"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-window-item>

                    <v-window-item :value="2">
                      <v-row align="center" justify="center">
                        <v-col
                          cols="3"
                          v-for="(items, index) in setMonogramLetters"
                          v-bind:key="index"
                          class="text-center"
                        >
                          <v-text-field
                            v-model="monogramLetters[index]"
                            density="compact"
                            flat
                            class="display-2 text-primary font-weight-medium productpage-monogram-size px-0 text-center"
                            :maxlength="1"
                            @keypress="allowAlphaOnly"
                            @input="generatePreview"
                            @load="setFocus()"
                            :rules="alphaRequiredRule"
                            ref="editMessage3"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-window-item>

                    <v-window-item :value="3">
                      <v-row align="center" justify="center">
                        <v-col
                          cols="3"
                          v-for="(items, index) in setMonogramLetters"
                          v-bind:key="index"
                          class="text-center"
                        >
                          <v-text-field
                            v-model="monogramLetters[index]"
                            density="compact"
                            flat
                            class="display-2 text-primary font-weight-medium productpage-monogram-size px-0 text-center"
                            :maxlength="1"
                            @keypress="allowAlphaOnly"
                            @input="generatePreview"
                            :rules="alphaRequiredRule"
                            ref="editMessage4"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-window-item>
                  </v-window>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="`color`">
              <v-row no-gutters class="pt-1 mb-3">
                <v-col cols="6">
                  <h4
                    class="text-uppercase fontsize-12 font-weight-300 body-font text-primary"
                  >
                    Monogram:
                  </h4>
                </v-col>
                <v-col cols="6" class="text-right">
                  <h4
                    class="text-uppercase d-block font-weight-300 body-font fontsize-12 fontcolor-grey-darken text-truncate"
                  >
                    {{ selectedMonogramDesignName }}
                  </h4>
                </v-col>
              </v-row>
              <v-row class="mb-3" v-if="monogramLetters">
                <v-col
                  class="d-flex justify-center align-center pa-0"
                  :key="selectedMonogramDesignName"
                >
                  <h1
                    v-if="setSelectedMonogramStyle"
                    class="font-weight-medium fontsize-50"
                    :style="setSelectedMonogramStyle"
                    v-html="generatedMonogramPreview"
                  ></h1>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <div class="productpage-hr-line mx-n6"></div>
                </v-col>
              </v-row>
              <v-row
                class="pr-1 pb-0 max-height-25"
                v-if="monogramsLength && !isStyleDisabled"
              >
                <ColorComponent
                  class="mx-3"
                  :key="selectedMonogramColor"
                  v-bind:colorCode="selectedMonogramColor"
                  :printMethod="monogramsProperty.selectedPrintMethod"
                  @clicked="setMonogramColor"
                  :colorName="selectedMonogramColorName"
                  :monogramFlag="true"
                ></ColorComponent>
              </v-row>
            </v-window-item>
          </v-window>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-row no-gutters>
            <v-col class="px-0">
              <v-btn
                block
                variant="outlined"
                rounded="0"
                @click="changeToNextTab()"
                color="primary"
                >SAVE</v-btn
              >
            </v-col>
            <v-col class="px-0">
              <v-btn
                v-if="monogramActiveTab == 'color'"
                rounded="0"
                block
                variant="flat"
                @click="openCongratsModal()"
                color="primary"
                >DONE</v-btn
              >
              <v-btn
                v-else
                rounded="0"
                block
                variant="flat"
                @click="changeToNextTab()"
                color="primary"
                >NEXT</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="monogramStudioExitDailog"
      persistent
      max-width="400"
      v-if="isMobileDevice"
      attach
    >
      <v-card tile flat class="all-side-primary-border rounded-0">
        <v-card-text class="py-2 pt-8">
          <v-row justify="center" class="mb-3">
            <v-col cols="10" class="pa-0">
              <p
                class="text-uppercase mb-5 regular-font fontsize-16 text-primary ls-n01"
              >
                YOUR ARE ABOUT TO EXIT CRANE’S MONOGRAM STUDIO
              </p>
              <p
                class="mb-3 body-font fontsize-12 text-primary ls-n007 line-height-16"
              >
                We wanted to let you know that you’re unique Monogram has not
                been saved. However we understand that sometimes these things
                take time so you can either save it for another day or complete
                your design now.
              </p>
            </v-col>
          </v-row>
          <v-row class="mb-3" v-if="monogramLetters">
            <v-col
              class="d-flex justify-center align-center pa-0"
              :key="selectedMonogramDesignName"
            >
              <h1
                v-if="setSelectedMonogramStyle"
                class="font-weight-medium fontsize-50"
                :style="setSelectedMonogramStyle"
                v-html="generatedMonogramPreview"
              ></h1>
            </v-col>
          </v-row>
          <div class="productpage-hr-line"></div>
        </v-card-text>
        <v-card-actions class="px-6 pb-5">
          <v-row no-gutters>
            <v-col cols="12" class="pa-0">
              <v-btn flat rounded="0" size="small" block color="primary"
                >COMPLETE YOUR MONOGRAM</v-btn
              >
            </v-col>
            <v-col class="px-0 pb-3">
              <v-btn
                flat
                rounded="0"
                size="small"
                block
                variant="outlined"
                @click="closeMonogramExitMenu()"
                color="primary"
                >EXIT</v-btn
              >
            </v-col>
            <v-col class="px-0">
              <v-btn
                flat
                rounded="0"
                block
                size="small"
                variant="outlined"
                @click="addMonogramOnCanvas()"
                color="primary"
                >SAVE</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="monogramStudioCongratsDailog"
      persistent
      max-width="600"
      v-if="isMobileDevice"
      attach
    >
      <v-card tile flat class="all-side-primary-border rounded-0">
        <v-card-title class="py-0 pr-0">
          <v-row class="py-3 mr-0">
            <v-col cols="10">
              <p
                class="text-uppercase fontsize-12 regular-font ls-n007 line-height-24 text-primary mb-0"
              >
                crane monogram studio
              </p>
              <p
                class="text-primary fontsize-10 body-font line-height-16 ls-n006 mb-0 p-word-break"
              >
                Create your own unique mark with Crane's Monogram Studio
              </p>
            </v-col>
            <v-col cols="2" class="d-flex justify-end pa-0">
              <v-btn
                color="primary"
                flat
                variant="outlined"
                :height="40"
                :min-width="40"
                size="small"
                rounded="0"
                @click="closeMonogramMenu()"
                class="border-right-none border-top-none"
              >
                <v-icon size="small">mdi mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <div class="productpage-hr-line"></div>
        <v-card-text class="py-3">
          <v-row no-gutters class="pt-1 mb-3">
            <v-col cols="6">
              <h4
                class="text-uppercase fontsize-12 font-weight-300 body-font text-primary"
              >
                Monogram:
              </h4>
            </v-col>
            <v-col cols="6" class="text-right">
              <h4
                class="text-uppercase d-block font-weight-300 body-font fontsize-12 fontcolor-grey-darken text-truncate"
              >
                {{ selectedMonogramDesignName }}
              </h4>
            </v-col>
          </v-row>
          <v-row class="mb-3" v-if="monogramLetters">
            <v-col
              class="d-flex justify-center align-center pa-0"
              :key="selectedMonogramDesignName"
            >
              <h1
                v-if="setSelectedMonogramStyle"
                class="font-weight-medium fontsize-50"
                :style="setSelectedMonogramStyle"
                v-html="generatedMonogramPreview"
              ></h1>
            </v-col>
          </v-row>
          <div class="productpage-hr-line mx-n6 mb-3"></div>
          <h4
            class="text-uppercase mb-4 font-weight-300 regular-font ls-n01 fontsize-16 text-primary"
          >
            CONGRATULATIONS
          </h4>
          <p class="body-font ls-n007 fontsize-12 line-height-16 text-primary">
            This Monogram is now a unique part of you. A part of you that you
            can share with friends, family and more. Something that will create
            a connection and leave a lasting impression whether in person or in
            the mail. Please enjoy, share and celebrate.
          </p>
          <p class="body-font ls-n007 fontsize-12 line-height-16 text-primary">
            We will look after your Monogram and save it to your account so you
            can add it to all the personalized items you desire.
          </p>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-row no-gutters>
            <v-col class="px-0">
              <v-btn
                rounded="0"
                block
                variant="outlined"
                @click="addMonogramOnCanvas()"
                color="primary"
                >SAVE</v-btn
              >
            </v-col>
            <v-col class="px-0">
              <v-btn
                flat
                rounded="0"
                block
                variant="flat"
                @click="addMonogramOnCanvas()"
                color="primary"
                >ADD</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { fabric } from "fabric";
const storeFrontUrl = import.meta.env.VITE_APP_CRANE_DAILY_URL;
import { getCurrentSvgPartStructure } from "crane-pricer";
import WebFontLoader from "webfontloader";
import cloneDeep from "lodash/cloneDeep";
import ProductPageHeader from "@/components/layout/header/ProductPageHeader.vue";
import ColorComponent from "@/components/personalize/product-detail/ColorComponent.vue";
import ElementsComponent from "@/components/personalize/product-detail/ElementsComponent.vue";
import ImagesComponent from "@/components/personalize/product-detail/ImagesComponent.vue";
import MonogramComponent from "@/components/personalize/product-detail/MonogramComponent.vue";
import {
  getAlignmentOptions,
  getFontSizeOptions,
  getPrintOptions,
  getPaperOptions,
  getSVGParts,
  SublineTypeIds,
  getLable,
  processDetails,
  surfaceIds,
  handleBeforeUnload,
  altCharCodes,
  priceFormatter,
  isoStringToNormalDate,
} from "@/services/util.service";

import { createHelpers } from "vuex-map-fields";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import opentype from "opentype.js";
const { mapFields } = createHelpers({
  getterType: "productOne/getField",
  mutationType: "productOne/updateField",
});
const { mapFields: mapProductPartsFields } = createHelpers({
  getterType: "productPartList/getField",
  mutationType: "productPartList/updateField",
});
const { mapFields: mapImageFields } = createHelpers({
  getterType: "imageOne/getField",
  mutationType: "imageOne/updateField",
});
const imageFolderName = import.meta.env.VITE_APP_IMAGE_UPLOAD_FOLDER_NAME;
const s3URL = import.meta.env.VITE_APP_S3_URL;
import { Buffer } from "buffer";
import craneLogoBlue from "@/assets/Crane_Logo_blue.png";
import redoInactive from "@/assets/redo-inactive.png";
import redoActive from "@/assets/redo-active.png";
import undoActive from "@/assets/undo-active.png";
import undoInactive from "@/assets/undo-inactive.png";

import { pricerFieldsIdx, pricerFiles } from "crane-pricer";
fabric.Object.prototype.objectCaching = false;

let userAgent = navigator;
console.log(userAgent);

/*eslint-disable*/
export default {
  name: "ProductDetailsComponent",
  components: {
    ProductPageHeader,
    ColorComponent,
    ElementsComponent,
    ImagesComponent,
    MonogramComponent,

    ConfirmationDialog,
  },
  data: () => ({
    priceFormatter,
    craneLogoBlue,
    redoInactive,
    redoActive,
    undoActive,
    undoInactive,
    mobileTabOptions: false,
    lookupCatelog: null,
    canvasDialog: true,
    summaryDetails: {
      productName: "",
    },
    summaryDetailsFlag: false,
    localParts: [],
    innerPropartys: {
      partId: "",
      partName: "",
      partObjects: [],
    },
    state: "",
    monogramList: [],
    selectedMonogram: "",
    undo: [],
    redo: [],
    allSVGFonts: [{ name: "Arial", url: `${s3URL}/fonts/roboto.woff` }],
    activeClass: "",
    selectedCanvas: "",
    imageData: "",
    canvas: "",
    flipClass: "",
    selectedTextAlign: "center",
    textAlign: "center",
    anchorAlignment: "",
    blue: "primary",
    white: "white",
    grey: "#0000001f",
    modeltype: "paper",
    elementType: "motifs",
    textType: "",
    paper: "",
    selected: "",
    selectedPaper: "",
    selectedPaperColor: "",
    selectedPaperColorName: "",
    selectedColor: "#ced700",
    selectedColorName: "",
    selectedShape: "portrait",
    selectedTextWeight: "",
    selectedWeight: "",
    selectedTexture: "",
    selectedFont: "helvetica-LT",
    selectedText: "",
    setSelectedText: "",
    selectedFontSize: 50,
    selectedPrintMethod: "ENGRAVING",
    selectedSize: "",
    dataMaxLines: null,
    selectedTextareaAlign: "left",
    width: 50,
    sideWindow: "",

    papers: [],
    hidePaperOption: true,
    hidePaperTabOption: true,
    hideTextOption: true,
    hideElementOption: true,
    hideImageOption: true,
    hideFinishOption: true,
    hideMonogramTab: true,
    printMethods: [],
    fontList: [],
    textAlignments: [],
    textFontSizeOptions: [],
    elementsProperty: {
      motifs: [],
      selectedPrintMethod: "",
      selectedColor: "",
      printOptions: [],
      selectedColorName: "",
      selectedMotifsName: "",
      dataIsStyleLinked: false,
      showStyleControl: false,
    },
    imagesProperty: {
      selectedPrintMethod: "",
      selectedColor: "",
      printOptions: [],
      selectedColorName: "",
      selectedImageName: "",
      imageHeight: 0,
      imageWidth: 0,
      dataIsStyleLinked: false,
      showStyleControl: false,
    },
    monogramsProperty: {
      monograms: [],
      selectedPrintMethod: "",
      selectedColor: "",
      printOptions: [],
      selectedObjectLetters: [],
      selectedColorName: "",
      monogramObject: null,
      selectedMonogramsName: "",
      userText: "",
      dataMonogramCharacters: [],
      dataIsStyleLinked: false,
      showStyleControl: false,
    },
    hideTextAlignField: true,
    hideMaxlineField: true,
    hideFontField: true,
    hidePrintField: true,
    noPropertyToChange: false,

    paperSheetModel: false,
    textSheetModel: false,
    selectedPaperText: "",
    selectedPaperType: "",
    surfaceId: "",
    price: " ",
    qty: "",
    product: "",
    selectedPartIndex: 0,
    selectedPart: {},
    canvasWidth: null,
    canvasHeight: null,
    allObjects: [],
    maxlength: "",
    partStates: { 0: { undo: [], redo: [], state: null } },
    setQty: 30,
    elementMainTypes: ["GRAPHICS"], // "UPLOADS", "BACKGROUND"
    selectedElementMainTypes: "graphics",
    productSummaryDetails: [],
    fontMax: 0,
    fontMin: 0,
    tabsHeight: "",
    canvasDivHeight: "",
    canvasDivWidth: "0",
    partsList: [],
    canvasLoadingInProgress: true,
    editTextareaModal: false,
    disabledElementTab: true,
    dataColor: {},
    defaultColor: {},
    showFinishTab: false,
    activeFinishTab: false,
    selectedFinishTypes: "liners",
    linersType: "",
    selectedLinerName: "",
    paperObject: null,
    liners: {
      isLiner: false,
      linerTags: [],
      selectedLiner: {},
    },
    selectedImageName: "",
    image: {
      isImage: false,
      imageTags: [],
      selectedImage: {},
      visible: true,
    },
    disableFinishTab: false,
    imageTabDisable: true,
    isPageLoader: false,
    pageLoader: false,
    isPartBtnActive: true,
    isModeActive: true,
    isFontActive: true,
    isPrintActive: true,
    processingTime: null,
    envelopeObject: null,
    qtyPriceArray: [],
    envelopeHeight: 0,
    envelopeWidth: 0,
    canvasOriginalHeight: 0,
    canvasOriginalWidth: 0,
    isEnvelopeActive: false,
    confirmationDialogFlag: false,
    confirmation: {
      confirmationMessage: "",
    },
    scaleRatio: 1,
    paperTabType: null,
    textTabType: null,
    finishTabType: true,
    hideMotifsTab: true,
    surfaceIds: surfaceIds,
    selectedPartSurfaces: [],
    partSurface: {},
    currentPartSurface: {},
    currentCanvasPart: "",
    colorMismatched: false,
    mismatchedColorGranted: false,
    svgAllTexts: [],
    noModifiedText: false,
    modifiedTextGranted: false,
    isNewSvgLoaded: false,
    productUrley: "",
    deletedElementArray: [],
    userSelectedDeletedElement: "",
    isSaveYourDesignClicked: false,
    generatedPartName: null,
    currentTimeStamp: null,
    optionalTextElements: [],
    isTextElementPresent: true,
    optionalElementModal: false,
    isOptionalTextSkipped: false,
    nextPartIndex: -1,
    textChangedKey: false,
    singleOptionalElement: null,
    isCalledFromChangedCanvas: false,
    isChangedToPrevPart: false,
    svgHasError: false,

    productSku: null,
    itemId: null,
    userQuantity: null,
    currentBuilderSessionItem: null,
    canvasRefreshKey: false,

    dataIsStyleLinked: false,
    showStyleControl: false,
    scrollWindow: false,
    scrollClass: "max-height-50",
    showPulseEffect: false,
    totalPartPrices: 0,
    currentPartPrice: 0,
    allPartOrderObjects: [],

    // monogram properties for dailogs
    monogramLetters: [],
    monogramStudioDailog: false,
    monogramStudioExitDailog: false,
    monogramStudioCongratsDailog: false,
    setSelectedMonogramStyle: {},
    monogramActiveTab: "style",
    selectedMonogramsName: {},
    colorObject: "",
    reservedChars: ["1", "2", "3", "4", "5"],
    generatedMonogramPreview: "",
  }),
  computed: {
    builderSessionId: function () {
      return this.$route.params.sessionId;
    },
    // magento v1 product details
    sessionProductDetails: function () {
      return this.$store.state.productOne.sessionProductDetails;
    },
    // builder session details
    sessionOneDetails: function () {
      return this.$store.state.sessionOne.one;
    },
    cartItems() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductList;
      } else {
        return this.$store.state.cartProduct.cartProductList;
      }
    },
    categoryId: function () {
      return this.$route.query.categoryId;
    },
    designerModeFlag: function () {
      return this.$route.params.designerMode;
    },
    isMobileDevice() {
      //this.mobileTabOptions = this.$vuetify.display.xs ? true : false;
      return this.$vuetify.display.xs ? true : false;
    },
    productOne: function () {
      return this.$store.state.designerOne.one;
    },
    orignalParts() {
      return this.$store.state.productPartList.list;
    },
    productParts: function () {
      return this.$store.state.productPartList.list.filter((obj) => {
        if (
          this.userSelectedParts.find((part) => {
            return obj.id == part;
          })
        )
          return obj;
      });
    },
    linerPatternList() {
      return this.$store.state.productOne.linerPatternList;
    },
    linerSolidList() {
      return this.$store.state.productOne.linerSolidList;
    },
    searchInputWidth() {
      return this.$vuetify.display.xs ? "100%" : "256";
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    userId: function () {
      if (this.userAuth && this.$store.state.accountOne.one)
        return this.$store.state.accountOne.one.id;
      return "";
    },
    productAllDetails: function () {
      return this.$store.state.productOne.productDetails;
    },
    colors() {
      return this.$store.state.productOne.colorList;
    },
    isDirty() {
      return this.$store.state.productOne.isDirty;
    },
    isSaveInProgress() {
      return this.$store.state.productOne.isSaveInProgress;
    },
    productStyle() {
      return this.$store.state.productOne.productStyle;
    },
    // monogram computeds
    monograms() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.selectedMonogramsName = this.monogramsProperty.selectedMonogramsName;
      if (this.monogramsProperty.monograms)
        return this.monogramsProperty.monograms.filter((obj) => {
          if (obj.url) return obj;
        });
      return [];
    },
    monogramsLength() {
      return this.monograms.length;
    },
    selectedMonogramDesignName: {
      get: function () {
        return this.monogramsProperty.selectedMonogramsName;
      },
      set: function (newMonogram) {
        return (this.monogramsProperty.selectedMonogramsName = newMonogram);
      },
    },
    setMonogramLetters: {
      get: function () {
        let lettersCount = this.monograms
          .filter((obj) => obj.name == this.selectedMonogramDesignName)
          .map((obj) => obj.lettersCount)
          .find((obj) => obj);
        if (!lettersCount) return 0;
        return Number(lettersCount);
      },
      set: function () {
        //  return (this.monogramsProperty.selectedMonogramsName = newMonogram);
      },
    },
    setMonogramActiveTab: {
      get: function () {
        return this.setMonogramLetters - 1;
      },
      set: function (val) {
        return val;
      },
    },
    selectedMonogramColor: {
      get: function () {
        return this.monogramsProperty.selectedMonogramColor;
      },
      set: function (newColor) {
        return (this.monogramsProperty.selectedMonogramColor = newColor);
      },
    },
    selectedMonogramColorName: {
      get: function () {
        return this.monogramsProperty.selectedMonogramColorName;
      },
      set: function (newColorName) {
        return (this.monogramsProperty.selectedMonogramColorName =
          newColorName);
      },
    },
    printLength() {
      return this.monogramsProperty.printOptions.length;
    },
    productPricerDataset: function () {
      return this.$store.state.productOne.productPricerDataset;
    },
    ...mapFields(["inProgress"]),
    ...mapProductPartsFields(["userSelectedParts"]),
    ...mapImageFields([
      "one",
      "one.name",
      "one.url",
      "one.ownerId",
      // "inProgress",
    ]),
  },
  watch: {
    async productParts(list) {
      this.updatePage(list);
    },
    productAllDetails() {
      this.productUrley = this.getCustomAttributeLable(
        this.productAllDetails.customAttribute,
        "url_key"
      );
    },
    colorMismatched() {
      if (this.colorMismatched && this.noModifiedText) {
        this.scrollClass = "max-height-50-two";
      } else if (!this.colorMismatched && this.noModifiedText) {
        this.scrollClass = "max-height-50-one";
      } else if (this.colorMismatched && !this.noModifiedText) {
        this.scrollClass = "max-height-50-one";
      } else {
        this.scrollClass = "max-height-50";
      }
    },
    noModifiedText() {
      if (this.colorMismatched && this.noModifiedText) {
        this.scrollClass = "max-height-50-two";
      } else if (!this.colorMismatched && this.noModifiedText) {
        this.scrollClass = "max-height-50-one";
      } else if (this.colorMismatched && !this.noModifiedText) {
        this.scrollClass = "max-height-50-one";
      } else {
        this.scrollClass = "max-height-50";
      }
    },
    // for monogram
    setMonogramLetters: function () {
      this.setMonogramActiveTab = this.setMonogramLetters - 1;
    },
  },
  methods: {
    // monogram methods
    allowAlphaOnly(event) {
      let char = String.fromCharCode(event.keyCode);
      if (/^[A-Za-z]+$/.test(char)) {
        return true;
      } else event.preventDefault();
    },
    getTextByLetterCount(lettersCount) {
      let text = null;
      switch (Number(lettersCount)) {
        case 1:
          text = "a";
          break;
        case 2:
          text = "ab";
          break;
        case 3:
          text = "abc";
          break;
        case 4:
          text = "abcd";
          break;
        case 5:
          text = "abcde";
          break;
        default:
          text = "abc";
      }
      return text;
    },
    changeToNextTab() {
      if (this.monogramActiveTab == "style")
        this.monogramActiveTab = "initials";
      else if (this.monogramActiveTab == "initials")
        this.monogramActiveTab = "color";
      else if (this.monogramActiveTab == "color")
        this.monogramStudioCongratsDailog = true;
    },
    openCongratsModal() {
      this.monogramStudioCongratsDailog = true;
    },
    removeSelectedMonogram() {
      const ref = this.productFabric;
      this.selectedCanvas.visible = false;
      this.selectedCanvas.dataVisible = false;
      if (this.selectedCanvas.dataVisible == false) {
        let deletedMonogram = {
          name: this.selectedCanvas.dataSelectedMonogramName,
          type: "monogram",
          visible: false,
          elementIndex: "",
          lettersCount: this.selectedCanvas.dataSelectedMonogram.lettersCount,
        };
        this.deletedElementArray.push(deletedMonogram);
      }
      this.selectedCanvas.dataSelectedMotifUrl = "";
      this.selectedCanvas.dataSelectedMonogramName = "";
      this.selectedCanvas.setCoords();
      ref.canvas.discardActiveObject();
      ref.canvas.requestRenderAll();
      this.modeltype = "paper";
      this.userMadeChangesInSvg();
    },
    generatePreview() {
      try {
        let previewString = "";
        let monogramBuildString = this.selectedMonogram.buildString
          ? this.selectedMonogram.buildString.trim()
          : "";
        for (let i = 0; i < monogramBuildString.length; i++) {
          let stringChar = monogramBuildString[i];
          if (this.reservedChars.indexOf(stringChar) == -1)
            previewString += stringChar;
          else {
            let nextChar = monogramBuildString[i + 1];
            let secondNexChar = monogramBuildString[i + 2];
            if (nextChar == "A" && secondNexChar == "l") {
              let previewChar =
                this.monogramLetters[
                  this.reservedChars[this.reservedChars.indexOf(stringChar)] - 1
                ];
              let altCode = altCharCodes.filter(
                (altcode) => altcode.char == previewChar.toUpperCase()
              )[0];
              if (altCode) previewString += altCode.altCode;
              i = i + 3;
            } else if (nextChar == "A") {
              previewString += this.monogramLetters[stringChar - 1]
                ? this.monogramLetters[stringChar - 1].toUpperCase()
                : "";
              i = i + 1;
            } else if (nextChar == "a") {
              previewString += this.monogramLetters[stringChar - 1]
                ? this.monogramLetters[stringChar - 1].toLowerCase()
                : "";
              i = i + 1;
            } else {
              this.$snotify.warning(`Error in generating Monogram`, {
                position: "rightTop",
                showProgressBar: false,
              });
            }
          }
        }
        this.generatedMonogramPreview = previewString;
        console.log("Generated preview string ", previewString);
      } catch (err) {
        console.log("Error while generating preview string ", err, { monogram: this.selectedMonogram, });
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        // this.setCorruptedSessionData()
        throw err;
      }
    },
    async setSelectedMonogram(item, mobileView) {
      try {
        if (!item) {
          item = this.monograms[0];
        }
        if (item) {
          if (!item.name) {
            item = this.monograms.filter((obj) => {
              if (obj.name == item) return obj;
            })[0];
          }
          this.setSelectedMonogramStyle = "";
          this.selectedMonogramDesignName = item.name;
          this.selectedMonogram = item;
          this.setMonogramLetters = Number(item.lettersCount);
          if (this.selectedMonogramColor && this.isMobileDevice)
            this.setSelectedMonogramStyle =
              `line-height:normal;font-family:` +
              item.name +
              `; color:` +
              this.selectedMonogramColor;
          else
            this.setSelectedMonogramStyle =
              `line-height:normal; font-family:` + item.name;
          //   MOBILE VIEW
          if (mobileView) {
            let oldMonoChars = this.monogramLetters;
            this.monogramLetters = [];
            for (let i = 0; i < this.setMonogramLetters; i++) {
              this.monogramLetters.push(oldMonoChars[i]);
            }
            this.generatePreview();
          } else {
            this.monogramLetters = [];
            if (this.monogramsProperty.userText) {
              if (
                this.monogramsProperty.userText.length > this.setMonogramLetters
              ) {
                let subText = this.monogramsProperty.userText.substring(
                  0,
                  this.setMonogramLetters
                );
                this.monogramLetters = subText.split("");
              } else {
                this.monogramLetters =
                  this.monogramsProperty.userText.split("");
              }
            } else {
              if (this.monogramsProperty.dataMonogramCharacters) {
                for (let i = 0; i < this.setMonogramLetters; i++) {
                  this.monogramLetters.push(
                    this.monogramsProperty.dataMonogramCharacters[i]
                  );
                }
              } else {
                this.monogramLetters = await this.getTextByLetterCount(
                  this.setMonogramLetters
                ).split("");
              }
            }
            this.monogramStudioDailog = true;
            this.monogramActiveTab = "style";
            this.generatePreview();
          }
        }
      } catch (err) {
        console.log("Error while rendering monogram on canvas ", err, { monogram: item, });
        this.$snotify.error(`Cloud communication error`, {
          position: "rightTop",
          showProgressBar: false,
        });
        throw err;
      }
    },
    addMonogramOnCanvas() {
      this.monogramStudioDailog = false;
      this.monogramStudioExitDailog = false;
      this.monogramStudioCongratsDailog = false;
      let userText = this.monogramLetters.toString().split(",").join("");
      this.updateSelectedMonogramOnCanvas({
        monogram: this.selectedMonogram,
        userText: userText,
        userPreviewText: this.generatedMonogramPreview,
      });
      if (this.isMobileDevice && this.colorObject)
        this.changeMonogramColor(this.colorObject);
    },
    closeMonogramMenu() {
      this.monogramStudioExitDailog = true;
    },
    closeMonogramExitMenu() {
      this.monogramStudioDailog = false;
      this.monogramStudioExitDailog = false;
      this.monogramStudioCongratsDailog = false;
      this.monogramLetters = [];
    },
    continueMonogramExitMenu() {
      this.monogramStudioExitDailog = false;
    },
    setMonogramColor(color) {
      this.selectedMonogramColor = color.colorHex;
      this.selectedMonogramColorName = color.colorName;
      this.colorObject = color;

      if (this.isMobileDevice) {
        this.setSelectedMonogramStyle =
          `line-height:normal; font-family:` +
          this.selectedMonogramDesignName +
          `; color:` +
          this.selectedMonogramColor;
      } else this.$emit("changeMonogramColor", color);
    },

    // monogram methods ends
    checkIsStyleDisable() {
      let isDisabled = false;
      if (
        this.partStates &&
        this.selectedCanvas &&
        this.selectedCanvas.dataIsStyleLinked &&
        this.showStyleControl &&
        this.selectedPartIndex > 0
      ) {
        // &&
        isDisabled = true;
      } else {
        isDisabled = false;
      }
      return isDisabled;
    },
    async setCanvasInitialStyle(currentObj, update) {
      // update  = true when user  has change color/ print/font in UI
      // update = false when this method calls initially
      this.isPageBlock = true;
      if (![undefined, "undefined"].includes(currentObj.dataColorStyle)) {
        if (
          update ||
          !this.productStyle["color"] ||
          !this.productStyle["color"][currentObj.dataColorStyle]
        ) {
          await this.$store.dispatch("productOne/setProductStyles", {
            styleTypeFor: "color",
            name: currentObj.dataColorStyle,
            style: currentObj.defaultColor,
          });
          if (update) {
            currentObj.defaultColor =
              this.productStyle["color"][currentObj.dataColorStyle];
            currentObj.dataColor =
              this.productStyle["color"][currentObj.dataColorStyle];
            currentObj.fill =
              this.productStyle["color"][currentObj.dataColorStyle].colorHex;

            if (this.selectedCanvas.dataType == currentObj.dataType)
              this.selectedCanvas = currentObj;

            await this.setColorStyleForAllMatched({
              currentObject: currentObj,
            });
          }
        } else {
          currentObj.defaultColor =
            this.productStyle["color"][currentObj.dataColorStyle];
          currentObj.dataColor =
            this.productStyle["color"][currentObj.dataColorStyle];
          currentObj.fill =
            this.productStyle["color"][currentObj.dataColorStyle].colorHex;

          if (this.selectedCanvas.dataType == currentObj.dataType)
            this.selectedCanvas = currentObj;

          await this.setColorStyleForAllMatched({ currentObject: currentObj });
        }
      }

      if (![undefined, "undefined"].includes(currentObj.dataProcessStyle)) {
        if (
          update ||
          !this.productStyle["process"] ||
          !this.productStyle["process"][currentObj.dataProcessStyle]
        ) {
          await this.$store.dispatch("productOne/setProductStyles", {
            styleTypeFor: "process",
            name: currentObj.dataProcessStyle,
            style: {
              dataSelectedPrintType: currentObj.dataSelectedPrintType,
              dataSelectedPrintShortName: currentObj.dataSelectedPrintShortName,
            },
          });

          if (update) {
            currentObj.dataSelectedPrintType =
              this.productStyle["process"][
                currentObj.dataProcessStyle
              ].dataSelectedPrintType;

            if (this.selectedCanvas.dataType == currentObj.dataType)
              this.selectedCanvas = currentObj;

            await this.setProcessStyleForAllMatched({
              currentObject: currentObj,
            });
          }
        } else {
          currentObj.dataSelectedPrintType =
            this.productStyle["process"][
              currentObj.dataProcessStyle
            ].dataSelectedPrintType;

          if (this.selectedCanvas.dataType == currentObj.dataType)
            this.selectedCanvas = currentObj;

          await this.setProcessStyleForAllMatched({
            currentObject: currentObj,
          });
        }
      }

      if (![undefined, "undefined"].includes(currentObj.dataFontStyle)) {
        if (
          update ||
          !this.productStyle["font"] ||
          !this.productStyle["font"][currentObj.dataFontStyle]
        ) {
          this.fontList = currentObj.dataFont.fontOptions;
          await this.$store.dispatch("productOne/setProductStyles", {
            styleTypeFor: "font",
            name: currentObj.dataFontStyle,
            style: {
              name: currentObj.dataSelectedFontFamily,
              defaultValue: currentObj.dataSelectedFontSize,
              letterSpacing: currentObj.dataSelectedLetterSpacing,
              lineHeight: currentObj.dataSelectedLineHeight,
            },
          });

          if (update) {
            if (this.selectedCanvas.dataType == currentObj.dataType)
              this.selectedCanvas = currentObj;

            await this.setFontStyleForAllMatched({
              currentObject: currentObj,
              selectedFont:
                this.productStyle["font"][currentObj.dataFontStyle].name,
            });
          }
        } else {
          this.fontList = currentObj.dataFont.fontOptions;
          if (this.selectedCanvas.dataType == currentObj.dataType)
            this.selectedCanvas = currentObj;

          await this.setFontStyleForAllMatched({
            currentObject: currentObj,
            selectedFont:
              this.productStyle["font"][currentObj.dataFontStyle].name,
          });
        }
      }

      return { newCurrentObj: currentObj };
    },
    async setStyleForAllMatched(data) {
      if (data) {
        this.selectedCanvas = data.currentObject;
        this.selectedCanvas.dataIsStyleLinked = data.isStyleApplied;
        this.dataIsStyleLinked = data.isStyleApplied;
        await this.applyLinkedStyle();
      }
    },
    async applyLinkedStyle() {
      // this method is calling from switch change
      this.selectedCanvas.dataIsStyleLinked = this.dataIsStyleLinked;
      if (this.selectedCanvas.dataIsStyleLinked) {
        if (this.productStyle[this.selectedCanvas.dataColorStyle]) {
          this.selectedCanvas.set({
            defaultColor: this.productStyle[this.selectedCanvas.dataColorStyle],
            dataColor: this.productStyle[this.selectedCanvas.dataColorStyle],
            fill: this.productStyle[this.selectedCanvas.dataColorStyle]
              .colorHex,
          });
        }
        if (this.productStyle[this.selectedCanvas.dataProcessStyle]) {
          this.selectedCanvas.set({
            dataSelectedPrintType:
              this.productStyle[this.selectedCanvas.dataProcessStyle]
                .dataSelectedPrintType,
            dataSelectedPrintShortName:
              this.productStyle[this.selectedCanvas.dataProcessStyle]
                .dataSelectedPrintShortName,
          });
        }

        // setting ui vars
        this.selectedPrintMethod = this.selectedCanvas.dataSelectedPrintType;
        this.selectedColorName = this.selectedCanvas.dataColor.name;
        this.selectedColor = this.selectedCanvas.dataColor.colorHex;

        if (
          this.selectedCanvas.textLines &&
          this.selectedCanvas.textLines.length &&
          this.productStyle[this.selectedCanvas.dataFontStyle]
        ) {
          let fontObj = {};
          let currentStyle =
            this.productStyle[this.selectedCanvas.dataFontStyle];
          this.selectedFontSize = Number(currentStyle.defaultValue);

          fontObj.name = currentStyle.name;
          fontObj.defaultValue = Number(currentStyle.defaultValue);
          fontObj.letterSpacing = currentStyle.letterSpacing;
          fontObj.lineHeight = currentStyle.lineHeight;

          if (fontObj.name)
            this.updateTextFont(this.selectedCanvas, fontObj, "font");
        }
        this.objSelected(); // this is called to reset its original vals
        this.productFabric.canvas.requestRenderAll();
      }
    },
    checkIsPartActive(selectedPart, item) {
      let partId = null;
      if (selectedPart.value) partId = selectedPart.value.id;
      else partId = selectedPart.id;

      if (item.raw.value) {
        return partId == item.raw.value.id ? true : false;
      }
      return false;
    },
    async addOptionalTextToCanvas(item, index) {
      let canvasObjects = this.productFabric.canvas._objects;
      if (canvasObjects.length) {
       await this.selectZeroTypeElement("paper");
        for (let i = 0; i < canvasObjects.length; i++) {
          let currentObject = canvasObjects[i];
          if (currentObject.dataElementName == item.dataElementName) {
            this.productFabric.canvas._objects[i] = item;
            this.productFabric.canvas._objects[i].set({
              dataText: item.extraProperty.originalText,
              visible: true,
              dataOptionalAdded: true,
              selectable: true,
              text: item.extraProperty.originalText.join("\n"),
              textLines: item.extraProperty.originalText.join("\n"),
            });

            this.selectedCanvas = null;
            this.selectedCanvas = this.productFabric.canvas._objects[i];
            break;
          }
        }
        if (index !== undefined)
          this.optionalTextElements[index].extraProperty.optional = false;
        else {
          for (let i = 0; i < this.optionalTextElements.length; i++) {
            let ele = this.optionalTextElements[i];
            if (ele.dataElementName == item.dataElementName) {
              this.optionalTextElements[i].extraProperty.optional = false;
              break;
            }
          }
        }

        this.isTextElementPresent = true;
        this.textChangedKey = !this.textChangedKey;
        this.productFabric.canvas.setActiveObject(this.selectedCanvas);
      }

      this.userMadeChangesInSvg();
      this.optionalElementModal = false;
      this.isOptionalTextSkipped = false;
      this.productFabric.canvas.requestRenderAll();
    },
    fileDrop(event) {
      event.preventDefault();
      if (
        !this.selectedCanvas ||
        this.selectedCanvas.type !== "image" ||
        this.selectedCanvas.dataType == "paper"
      ) {
        this.$snotify.error(`Please select image object first`, {
          position: "rightTop",
          showProgressBar: false,
        });
      } else {
        this.$refs.imgComponent.saveUploadImage(event);
      }
    },
    fetchSurfaceObject(id, type) {
      let surfaceObj = this.surfaceIds.filter((obj) => {
        if (obj.id == id) return obj;
      })[0];
      surfaceObj.type = type;
      return surfaceObj;
    },
    getCustomAttributeLable(customAttributes, attributeCode) {
      if (customAttributes) return getLable(customAttributes, attributeCode);
    },
    setFontInFontList(fontName) {
      return {
        fontFamily: fontName,
      };
    },
    getJsonFromSvg(svg) {
      return new Promise((resolve, reject) => {
        fabric.loadSVGFromString(
          svg,
          function (objects) {
            if (objects) {
              resolve(objects);
            } else {
              reject(new Error("Failed"));
            }
          },
          this.reviver
        );
      });
    },
    async getCurrentPartOrderObject({ isSummaryUpdate = false }) {
      let vm = this;
      this.allPartOrderObjects = [];
      for (const key of Object.keys(vm.partStates)) {
        let originalPart = "";
        for (let i = 0; i < this.orignalParts.length; i++) {
          if (
            this.partStates[key] &&
            this.orignalParts[i].id === this.partStates[key].id
          )
            originalPart = this.orignalParts[i];
        }

        let id = originalPart.id;
        let name = originalPart.name;
        let size = null;
        let stock = null;
        let printing = [];
        let modified = true;
        let partSelected = true;
        let svgCode = null;
        let alternateSvg = null;
        let partPrice = null;
        let part = vm.partStates[key];
        let surfaceId = originalPart.surfaceId;
        let orientationId = originalPart.orientationId;
        let response = "";
        let inks = [];
        let surfaceSvgSummary = null;
        let alternateSvgSummary = null;

        let currentPartSurfaceType = vm.partStates[`${part.index}`].isSurface
          ? "surface"
          : "alternateSurface";

        vm.ownerId = this.getSessionItemOwner(this.sessionOneDetails);

        let quantity = Number(this.userQuantity);
        let orderObject = {};
        let currentTime = Date.now();
        let linerObject = null;
        let alternatePartStructure,
          partStructure = {};

        if (part) {
          let surfaceSvgData = await this.$store.dispatch(
            "sessionItemOne/fetchOne",
            {
              sessionId: this.builderSessionId,
              itemId: id,
              surface: originalPart.surfaceId,
              surfaceType: "surface",
            }
          );
          svgCode = surfaceSvgData.response
            ? surfaceSvgData.response.svgContent
            : "";
          if (svgCode) surfaceSvgSummary = surfaceSvgData.response.summaryData;
          let alternateSurfaceSvgData = await this.$store.dispatch(
            "sessionItemOne/fetchOne",
            {
              sessionId: this.builderSessionId,
              itemId: id,
              surface: originalPart.alternateSurfaceId,
              surfaceType: "alternateSurface",
            }
          );
          alternateSvg =
            alternateSurfaceSvgData && alternateSurfaceSvgData.response
              ? alternateSurfaceSvgData.response.svgContent
              : "";

          if (alternateSvg)
            alternateSvgSummary = alternateSurfaceSvgData.response.summaryData;

          let summaryData =
            surfaceSvgSummary && typeof surfaceSvgSummary === "object"
              ? surfaceSvgSummary
              : surfaceSvgSummary && typeof surfaceSvgSummary === "string"
              ? JSON.parse(surfaceSvgSummary)
              : {};
          if (surfaceSvgSummary) {
            partStructure = summaryData.partStructure;
          }

          let alternateSummaryData =
            alternateSvgSummary && typeof alternateSvgSummary === "object"
              ? alternateSvgSummary
              : alternateSvgSummary && typeof alternateSvgSummary === "string"
              ? JSON.parse(alternateSvgSummary)
              : {};
          if (alternateSvgSummary) {
            alternatePartStructure =
              alternateSummaryData.alternatePartStructure;
          }

          let jsonObjects = null;

          jsonObjects = await this.getJsonFromSvg(this.decodeSvg(svgCode));
          if (vm.partStates[`${part.index}`].isSurface)
            response = await this.updateOrderObject(
              jsonObjects,
              // orderObject,
              originalPart,
              currentTime,
              surfaceId,
              quantity,
              modified,
              printing,
              size,
              stock,
              linerObject,
              inks
            );
        } else {
          console.error("Product is not getting updated, please check your internet connection")
          vm.$snotify.error(
            "Product is not getting updated, please check your internet connection",
            {
              position: "rightTop",
              showProgressBar: false,
              timeout: 3000,
            }
          );
          window.location.reload();
          break;
        }

        // alternate surface svg code save to summary details
        if (!vm.partStates[`${part.index}`].isSurface) {
          let jsonObjects = null;
          let alternatePart = vm.partStates[`${part.index}`];

          jsonObjects = await this.getJsonFromSvg(this.decodeSvg(alternateSvg));
          surfaceId = alternatePart.surfaceId;

          response = await this.updateOrderObject(
            jsonObjects,
            // orderObject,
            originalPart,
            currentTime,
            surfaceId,
            quantity,
            modified,
            printing,
            size,
            stock,
            linerObject,
            inks
          );
        }
        if (svgCode || alternateSvg) {
          let svgString = null;

          if (svgCode) {
            if (this.checkEncodeURI(svgCode)) svgCode = this.decodeSvg(svgCode);
            svgString = svgCode;
          } else {
            if (this.checkEncodeURI(alternateSvg))
              alternateSvg = this.decodeSvg(alternateSvg);
            svgString = alternateSvg;
          }

          if (
            svgString.includes("NaN ") ||
            svgString.includes("NaNpt") ||
            svgString.includes("undefined ")
          ) {
            console.error(
              "svgString.includes('NaN NaN')  will reject promise",
              { partName: name,
              currentPartSurfaceType,
              svgString }
            );
            this.$snotify.error("Something went wrong, please try again", {
              position: "rightTop",
              showProgressBar: false,
              timeout: 3000,
            });
            window.location.reload();
            break;
          }

          // this will only call for saveSummaryDetails()
          if (isSummaryUpdate) {
            let summaryData = {};
            summaryData = {
              alternatePartStructure,
              partStructure,
              orientationId: orientationId,
              id: id,
              quantity: quantity,
              size: response.size,
              stock: response.stock,
              partPrice: partPrice ? partPrice.PriceResult.Total : 0,
              name: name,
              printing: printing,
              linerObject: response.linerObject,
              partModified: modified,
              partSelected: partSelected,
              surfaceId: originalPart.surfaceId,
              alternateSurfaceId: originalPart.alternateSurfaceId,
              processes: response.processes,
              ink: response.inks,
              currentPartSurfaceType: currentPartSurfaceType,
              currentSessionId: this.builderSessionId
            };
            console.log("updated currentItemData.summaryData", summaryData);
            await this.updateBuilderSessionItemSummary({
              summaryData: JSON.stringify(summaryData),
              productPartId: id,
              surfaceType: currentPartSurfaceType,
              surfaceId:
                currentPartSurfaceType == "surface"
                  ? originalPart.surfaceId
                  : originalPart.alternateSurfaceId,
            });
          }
        } else {
          console.error("SVG || AlternateSvg is not present");
          this.$snotify.error("Something went wrong, please try again", {
            position: "rightTop",
            showProgressBar: false,
            timeout: 3000,
          });

          window.location.reload();
          break;
        }
      }
      if (isSummaryUpdate == false) await this.calculateAllPartsTotal();

      return true;
    },
    async calculateAllPartsTotal() {
      try {
        // for (let ap = 0; ap < this.allPartOrderObjects.length; ap++) {
        //   let orderObject = this.allPartOrderObjects[ap].orderObject;
        //   let newObj = JSON.parse(JSON.stringify(orderObject));
        //   let resp = await pricingEngine({ pricingOrder: newObj });
        //   console.log("price Response for part", resp);
        //   let currentPartPrice = resp.Total * (resp.RetailMultiplier / 100);
        //   this.allPartOrderObjects[ap].price = currentPartPrice;
        // }
      } catch (err) {
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.canvasLoadingInProgress = false;
        this.inProgress = false;
        console.log(err);
      }
    },
    async getCurrentSessionPartStructure() {
      try{
      let currentPartObj = this.partStates[this.selectedPartIndex];

      let partAssets = { papers: [], elements: [] };
      let svgCode = this.productFabric.canvas.toSVG({
        width: (currentPartObj.canvasWidth * 72) / fabric.DPI + "pt",
        height: (currentPartObj.canvasHeight * 72) / fabric.DPI + "pt",
        viewBox: {
          x: 0,
          y: 0,
          width: currentPartObj.canvasWidth,
          height: currentPartObj.canvasHeight,
        },
      });
      let partStructure = await getCurrentSvgPartStructure(
        svgCode,
        partAssets,
        this.currentBuilderSessionItem
      );

      let summaryData =
        this.currentBuilderSessionItem.summaryData &&
        typeof this.currentBuilderSessionItem.summaryData === "object"
          ? this.currentBuilderSessionItem.summaryData
          : this.currentBuilderSessionItem.summaryData &&
            typeof this.currentBuilderSessionItem.summaryData === "string"
          ? JSON.parse(this.currentBuilderSessionItem.summaryData)
          : {};
      if (this.currentBuilderSessionItem.surfaceType === "alternateSurface")
        summaryData.alternatePartStructure = {
          ...this.part.alternateStructure,
          ...partStructure.partAssets,
        };

      if (this.currentBuilderSessionItem.surfaceType === "surface")
        summaryData.partStructure = {
          ...this.part.structure,
          ...partStructure.partAssets,
        };

      await this.updateBuilderSessionItem({
        summaryData: JSON.stringify(summaryData),
      });
      console.log("Current session summary data", summaryData);
      } catch (err){
        console.error("Error while creating part structure", err, { sku: this.productSku, sessionId: this.builderSessionId });
      }
    },
    async fetchPriceForCurrentPart() {
      try {
        // this function calculate the part price based on svg
        /* let currentPartObj = this.partStates[this.selectedPartIndex];
        let svgCode = this.productFabric.canvas.toSVG({
          width: (currentPartObj.canvasWidth * 72) / fabric.DPI + "pt",
          height: (currentPartObj.canvasHeight * 72) / fabric.DPI + "pt",
          viewBox: {
            x: 0,
            y: 0,
            width: currentPartObj.canvasWidth,
            height: currentPartObj.canvasHeight,
          },
        });

        let quantity = Number(this.userQuantity);
        let orderObject = {};
        let currentTime = Date.now();
        let response = "";
        let jsonObjects = await this.getJsonFromSvg(this.decodeSvg(svgCode));

        let originalPart = this.selectedPart;
        let surfaceId = originalPart.surfaceId;
        let modified = true;
        let size = null;
        let stock = null;
        let printing = [];
        let linerObject = null;
        let inks = [];

        orderObject = {
          RefId: currentTime.toString(),
          CustomerNumber: 338000,
          OrderDate: getCurrentDate(),
          //    OrderNumber: "D123456",
          Details: [
            {
              RefId: currentTime.toString(),
              OrderedItemNumber: null,
              Quantity: quantity,
              UseId: 2,
              OccasionId: 2,
              KitParent: false,
              KitComponent: false,
              BrandId: 1,
              VerseId: 1,
              Sublines: [],
            },
          ],
        };
        response = await this.updateOrderObject(
          jsonObjects,
          orderObject,
          originalPart,
          currentTime,
          surfaceId,
          quantity,
          modified,
          printing,
          size,
          stock,
          linerObject,
          inks
        );
        console.log(response);*/
        try {
          this.currentPartPrice = 0;
          this.totalPartPrices = 0;
          // temporary comment
          // let newObj = JSON.parse(JSON.stringify(response.orderObject));
          // let resp = await pricingEngine({ pricingOrder: newObj });
          // console.log("price Response ", resp);
          // this.currentPartPrice = resp.Total * (resp.RetailMultiplier / 100);
          // this.totalPartPrices = this.currentPartPrice;

          // for (let p = 0; p < this.allPartOrderObjects.length; p++) {
          //   if (this.selectedPartIndex == p) continue;

          //   let currentObjPrice = this.allPartOrderObjects[p].price;
          //   this.totalPartPrices =
          //     Number(this.totalPartPrices) + Number(currentObjPrice);
          // }
          // temporary comment end

          /* let partPrice = await this.$store.dispatch(
              "orderOne/fetchPartPrice",
              response.orderObject
            );

            this.currentPartPrice = partPrice.PriceResult.Total * (partPrice.PriceResult.RetailMultiplier / 100);*/
        } catch (err) {
          this.$snotify.error(
            `Cloud communication error, please start new session `,
            {
              position: "rightTop",
              showProgressBar: false,
            }
          );
          console.log("pricer error", err);
          if (err) {
            console.error("pricer error stack ", err.stack);
            await this.saveIncident({
              errorObject: err,
              title: "Personalize pricing error",
              description: "Personalize pricing error on builder page",
              isReloadNeeded: false,
            });
            this.inProgress = false;
            // throw err
          }
        }
      } catch (err) {
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.canvasLoadingInProgress = false;
        this.inProgress = false;
        console.log(err);
      }
    },
    saveSummaryDetails() {
      try {
        let vm = this;
        this.canvasLoadingInProgress = true;
        this.svgHasError = false;
        this.productSummaryDetails = [];
        return new Promise(async (resolve, reject) => {
          let response = await vm.getCurrentPartOrderObject({
            isSummaryUpdate: true,
          });
          if (response) resolve("resolved");
          else reject("Something went wrong");

          this.canvasLoadingInProgress = false;
        });
      } catch (err) {
        console.error("error while updating summary details ",err, { sku: this.productSku, sessionId: this.builderSessionId });
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.canvasLoadingInProgress = false;
        this.inProgress = false;
        window.location.reload();
        console.log(err);
      }
    },
    updateOrderObject(
      jsonObjects,
      //  orderObject,
      orignalPart,
      currentTime,
      surfaceId,
      quantity,
      modified,
      printing,
      size,
      stock,
      linerObject,
      inks
    ) {
      try {
        let processes = {};
        if (jsonObjects) {
          for (let j = 0; j < jsonObjects.length; j++) {
            let obj = jsonObjects[j];
            let objType = obj.type;
            let promotionConditionFlag = true;
            if (objType == "path" || objType == "text") {
              if (objType == "path" && obj.dataVisible == "false")
                promotionConditionFlag = false;
              else if (
                objType == "text" &&
                (obj.text.length == 0 ||
                  (["true", true].includes(obj.dataOptional) &&
                    !["true", true].includes(obj.dataOptionalAdded)))
              )
                promotionConditionFlag = false;
              else {
                promotionConditionFlag = true;
              }
            }
            if (promotionConditionFlag) {
              if (
                obj.dataSelectedPrintType &&
                obj.dataSelectedPrintType !== "Digital"
              ) {
                if (!processes[obj.dataSelectedPrintType]) {
                  processes[obj.dataSelectedPrintType] = {};
                  processes[obj.dataSelectedPrintType].colors = [];
                  if (obj.dataColor) {
                    processes[obj.dataSelectedPrintType].colors.push(
                      obj.dataColor.colorHex
                    );
                    processes[obj.dataSelectedPrintType].passes = 1;
                  }
                } else {
                  if (
                    !processes[obj.dataSelectedPrintType].colors.includes(
                      obj.dataColor.colorHex
                    )
                  ) {
                    processes[obj.dataSelectedPrintType].passes += 1;
                    processes[obj.dataSelectedPrintType].colors.push(
                      obj.dataColor.colorHex
                    );
                  }
                }
              } else {
                if (obj.dataSelectedPrintType == "Digital") {
                  processes[obj.dataSelectedPrintType] = {};
                  processes[obj.dataSelectedPrintType].colors = [];
                  if (obj.dataColor)
                    processes[obj.dataSelectedPrintType].colors.push(
                      obj.dataColor.colorHex
                    );
                  processes[obj.dataSelectedPrintType].passes = 1;
                }
              }
            }

            if (objType == "image" && obj.dataType == "paper") {
              if (obj.dataSelectedPaperType) {
                size =
                  obj.dataSelectedPaperType.height +
                  " X " +
                  obj.dataSelectedPaperType.width +
                  " inches";
                stock = obj.dataSelectedPaperType;
              }
              if (obj.dataFinish && obj.dataFinish.selectedLiner) {
                linerObject = obj.dataFinish;
              }
            } else {
              let objColorId = 0;
              let sublineTypeId =
                SublineTypeIds[obj.dataType ? obj.dataType : obj.type];
              if (sublineTypeId) {
                let sublineText = "";
                if (obj.type == "text") {
                  if (
                    obj.text.length == 0 ||
                    (["true", true].includes(obj.dataOptional) &&
                      !["true", true].includes(obj.dataOptionalAdded))
                  ) {
                    continue;
                  }
                  if (
                    JSON.stringify(obj.dataText) ==
                    JSON.stringify(obj.dataDefaultText)
                  ) {
                    this.svgHasError = true;
                  }

                  sublineText = obj.text;
                  if (!inks.includes(obj.dataColor.name))
                    inks.push(obj.dataColor.name);
                }
                if (obj.type == "path" && obj.dataType == "motif") {
                  if (obj.dataVisible == "false") continue;
                  if (obj.dataSelectedMotifName) {
                    sublineText = obj.dataSelectedMotifName;
                    if (!inks.includes(obj.dataColor.name))
                      inks.push(obj.dataColor.name);
                  }
                }
                if (obj.type == "path" && obj.dataType == "clipart") {
                  // if (obj.dataSelectedMotifName) {
                  sublineText = "clipart"; //obj.dataSelectedMotifName;
                  if (
                    !inks.includes(obj.dataColor.name) &&
                    obj.dataColor.name !== "NO SPOT COLOR"
                  )
                    inks.push(obj.dataColor.name);
                  //}
                }
                if (obj.type == "path" && obj.dataType == "monogram") {
                  if (obj.dataVisible == "false" || obj.dataVisible == false)
                    continue;
                  if (!obj.dataMonogramUserText) {
                    let text = obj.dataMonogramCharacters
                      .toString()
                      .split(",")
                      .join("");
                    obj.text = text.substring(
                      0,
                      Number(obj.dataSelectedMonogramLetterCount)
                    );
                  }
                  sublineText = obj.dataMonogramUserText
                    ? obj.dataMonogramUserText
                    : obj.text;
                  if (!inks.includes(obj.dataColor.name))
                    inks.push(obj.dataColor.name);
                }
                if (obj.type == "image" && obj.dataType == "image") {
                  if (obj.dataVisible == "false" || obj.dataVisible == false)
                    continue;
                  if (obj.dataImage && obj.dataImage.selectedImage) {
                    sublineText = obj.dataImage.selectedImage.id;
                  }
                  objColorId = 94;
                }
              }
            }

            if (modified) {
              if (
                obj.dataRequired == "false" ||
                obj.dataRequired == "undefined"
              )
                modified = true;
              else if (
                obj.dataIsModified == true ||
                obj.dataIsModified == "true"
              ) {
                modified = true;
              } else modified = false;
            }
            if (obj.dataSelectedPrintType) {
              if (printing.indexOf(obj.dataSelectedPrintType) == -1)
                printing.push(obj.dataSelectedPrintType);
            }
          }
        }
        return {
          size: size,
          stock: stock,
          processes: processes,
          linerObject,
          inks,
        };
      } catch (err) {
        console.error("Error in update order object", err, { sessionId: this.builderSessionId, sku:this.productSku });
        this.$snotify.error(`Cloud communication error`, {
          position: "rightTop",
          showProgressBar: false,
        });

       // console.log(err);
        this.canvasLoadingInProgress = false;

        window.location.reload();
        throw err;
      }
    },
    async changeToPreviousPart(partIndex) {
      try {
        this.canvasLoadingInProgress = true;
        this.isSaveYourDesignClicked = true;
        await this.removedObjectFromCanvas();
        await this.selectZeroTypeElement("paper");
        this.userMadeChangesInSvg();
        await this.saveState(); //saving current part to s3
        await this.getCurrentSessionPartStructure();
        if (partIndex == 0) {
          //save canvas in API
          await this.uploadCanvasAsImage();
          if (this.productUrley)
            window.open(`${storeFrontUrl}/${this.productUrley}`, "_self");
          else this.$router.go(-1);
          this.canvasLoadingInProgress = false;
        } else {
          partIndex -= 1;
          let vm = this;
          let selectedPart = this.partStates[partIndex];
          this.productParts.filter((obj) => {
            if (selectedPart.id == obj.id) vm.selectedPart = obj;
          });
          if (!selectedPart.isSurface) {
            vm.selectedPartIndex = partIndex;
            this.partSurface = this.fetchSurfaceObject(
              vm.selectedPart.alternateSurfaceId,
              "alternateSurface"
            );
            this.dataSurface = this.partSurface;
            await vm.changeCanvas();
          } else await vm.changeCanvas();
        }
      } catch (err) {
        console.error("Error in navigating to back part ",err, {  sessionId: this.builderSessionId, sku:this.productSku });
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        window.location.reload();
        throw err;
      }
      // }
    },
    async changeToNextPart(partIndex) {
      try {
        this.canvasLoadingInProgress = true
        if (!this.isOptionalTextSkipped) {
          this.isCalledFromChangedCanvas = false;
          this.isChangedToPrevPart = false;
          await this.checkOptionalTextModification(partIndex);
          this.canvasLoadingInProgress = false;
          return false;
        }
        if (this.isOptionalTextSkipped) {
          this.canvasLoadingInProgress = true;
          this.checkEmptyOPtions().then(async(res) => {
            if (res == false) return false;
            else await this.changePartOperations(partIndex);
          });
        }
        this.scrollWindow = true;
      } catch (err) {
        console.error("Error in changing to next part ",err, { sessionId: this.builderSessionId, sku:this.productSku });
        this.$snotify.error(`Cloud communication error`, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.canvasLoadingInProgress = false;

        window.location.reload();

        throw err;
      }
    },
    async changePartOperations(partIndex) {
      try {
        let noColorMismatched = await this.checkFieldsMismatchColor();
        let allTextsModified = await this.checkModifiedTexts();
        if (noColorMismatched && allTextsModified) {
          this.isSaveYourDesignClicked = true;
          this.canvasLoadingInProgress = true;
          await this.removedObjectFromCanvas();
          await this.selectZeroTypeElement("paper");
          this.userMadeChangesInSvg();
          await this.saveState(); //saving current part to s3
          await this.getCurrentSessionPartStructure();

          if (partIndex + 1 >= Object.keys(this.partStates).length) {
            //save canvas in API
            await this.uploadCanvasAsImage();
            this.inProgress = true;
            await this.saveSummaryDetails();
            this.inProgress = false;

            if (this.designerModeFlag) {
              if (this.categoryId)
                this.$router.push({
                  name: "ProductSummary",
                  params: {
                    designerMode: "true",
                    sessionId: this.builderSessionId,
                  },
                  query: { categoryId: this.categoryId },
                });
              else
                this.$router.push({
                  name: "ProductSummary",
                  params: {
                    sessionId: this.builderSessionId,
                    designerMode: "true",
                  },
                });
            } else if (this.categoryId)
              this.$router.push({
                name: "ProductSummary",
                params: { sessionId: this.builderSessionId },
                query: { categoryId: this.categoryId },
              });
            else
              this.$router.push({
                name: "ProductSummary",
                params: { sessionId: this.builderSessionId },
              });
           // this.canvasLoadingInProgress = false;
          } else {
            partIndex += 1;
            let vm = this;
            let selectedPart = this.partStates[partIndex];
            this.productParts.filter((obj) => {
              if (selectedPart.id == obj.id) vm.selectedPart = obj;
            });
            if (!selectedPart.isSurface) {
              vm.selectedPartIndex = partIndex;
              this.partSurface = this.fetchSurfaceObject(
                vm.selectedPart.alternateSurfaceId,
                "alternateSurface"
              );
              this.dataSurface = this.partSurface;
              await vm.changeCanvas(true);
            } else await vm.changeCanvas();
          }
        }
      } catch (err) {
        console.error("Error in part operation ", err,{ sessionId: this.builderSessionId, sku:this.productSku });

        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.canvasLoadingInProgress = false;
        this.inProgress = false;

        window.location.reload();
        throw err;
      }
    },
    async onOptionalModalAction(currentElement) {
      try {
        let nextElement = null;
        for (let i = 0; i < this.optionalTextElements.length; i++) {
          let ele = this.optionalTextElements[i];
          if (ele.dataElementName == currentElement.dataElementName) {
            nextElement = this.optionalTextElements[i + 1];
            break;
          }
        }
        if (
          nextElement &&
          nextElement.extraProperty &&
          nextElement.extraProperty.optional
        ) {
          this.singleOptionalElement = nextElement;
          this.textChangedKey = !this.textChangedKey;
          return false;
        } else {
          this.isOptionalTextSkipped = true;
          this.optionalElementModal = false;

          this.isChangedToPrevPart = false;
          this.isCalledFromChangedCanvas = false;
          await this.changeToNextPart(this.nextPartIndex);
        }
      } catch (err) {
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        console.log(err);
        this.inProgress = false;

        throw err;
      }
    },
    async checkOptionalTextModification(partIndex) {
      try {
        this.canvasLoadingInProgress = true;
        let canvas = this.productFabric.canvas;
        let canvasJson = canvas.getObjects();
        let optionalTextNotModified = 0;

        for (let i = 0; i < canvasJson.length; i++) {
          let obj = canvasJson[i];

          if (obj.type == "text" || obj.type == "textbox") {
            // ask user about add optional text elements
            if (
              ["true", true].includes(obj.dataOptional) &&
              (!["true", true].includes(obj.dataOptionalAdded) ||
                obj.text.length == 0)
            ) {
              this.singleOptionalElement = obj;
              this.singleOptionalElement.extraProperty = {
                name: obj.dataElementName,
                objectIndex: i,
                optional: true,
                originalText:
                  obj.text.length > 0 ? obj.dataText : obj.dataDefaultText,
              };
              optionalTextNotModified++;
              break;
            }
          }
        }
        this.nextPartIndex = partIndex;

        if (optionalTextNotModified) {
          this.optionalElementModal = true;
          this.isOptionalTextSkipped = false;
          return false;
        } else {
          this.isOptionalTextSkipped = true;

          await this.changeToNextPart(this.nextPartIndex);
          this.canvasLoadingInProgress = false;
          return true;
        }
      } catch (err) {
        console.error("Error in optional text add operation ",err, {  sessionId: this.builderSessionId, sku:this.productSku });
        this.$snotify.error(`Cloud communication error`, {
          position: "rightTop",
          showProgressBar: false,
        });
        // console.log(err);
        this.inProgress = false;

        throw err;
      }
    },
    checkFieldsMismatchColor() {
      try {
        if (this.mismatchedColorGranted) {
          return true;
        } else {
          let vm = this;
          let canvas = vm.productFabric.canvas;
          let canvasJson = canvas.getObjects();
          let firstColor = "";
          let colorCnt = 0;
          for (let i = 0; i < canvasJson.length; i++) {
            let obj = canvasJson[i];
            if (obj.dataColor && obj.dataColor.name) {
              if (
                obj.type == "path" &&
                obj.dataSelectedPrintType !== "Digital"
              ) {
                if (
                  obj.dataVisible == undefined ||
                  obj.dataVisible == "undefined" ||
                  obj.dataVisible == true ||
                  obj.dataVisible == "true"
                ) {
                  if (firstColor == "") firstColor = obj.dataColor.name;
                  else {
                    if (firstColor != obj.dataColor.name) colorCnt++;
                  }
                }
              } else if (
                (obj.type == "text" || obj.type == "textbox") &&
                obj.text &&
                obj.text.length
              ) {
                if (
                  (["true", true].includes(obj.dataOptional) &&
                    ["true", true].includes(obj.dataOptionalAdded)) ||
                  !["true", true].includes(obj.dataOptional)
                ) {
                  if (firstColor == "") firstColor = obj.dataColor.name;
                  else {
                    if (firstColor != obj.dataColor.name) colorCnt++;
                  }
                }
              }
            }
          }
         // this.canvasLoadingInProgress = false;
          if (colorCnt > 0) {
            this.colorMismatched = true;
            // return false;
          } else {
            this.colorMismatched = false;
            return true;
          }
        }
      } catch (err) {
        this.$snotify.error(`Cloud communication error`, {
          position: "rightTop",
          showProgressBar: false,
        });
        console.log(err);
        this.inProgress = false;
        // this.setCorruptedSessionData()
        throw err;
      }
    },
    checkModifiedTexts() {
      try {
        if (this.isNewSvgLoaded) {
          if (this.modifiedTextGranted) {
            return true;
          } else {
            let vm = this;
            let canvas = vm.productFabric.canvas;
            let canvasJson = canvas.getObjects();
            let modified = 0;
            for (let i = 0; i < canvasJson.length; i++) {
              let obj = canvasJson[i];
              if (obj.type == "text" || obj.type == "textbox") {
                if (
                  (["true", true].includes(obj.dataOptional) &&
                    ["true", true].includes(obj.dataOptionalAdded)) ||
                  !["true", true].includes(obj.dataOptional)
                ) {
                  // check if user forget to modify dummy text
                  if (this.svgAllTexts.length && obj.text !== "") {
                    let text =
                      obj.dataText.length > 1
                        ? obj.dataText.join("\n")
                        : obj.dataText.toString();
                    if (this.svgAllTexts.indexOf(text) > -1) modified++;
                  }
                }
              }
            }
            // this.canvasLoadingInProgress = false;
            if (modified > 0) {
              this.noModifiedText = true;
              return false;
            } else {
              this.noModifiedText = false;
              return true;
            }
          }
        } else {
          this.noModifiedText = false;
          return true;
        }
      } catch (err) {
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        console.log(err);
        this.inProgress = false;
      }
    },
    checkEmptyOPtions() {
      try {
        return new Promise((resolve) => {
          this.canvasLoadingInProgress = true;
          let vm = this;
          let canvas = vm.productFabric.canvas;
          let canvasJson = canvas.getObjects();
          for (let i = 0; i < canvasJson.length; i++) {
            let obj = canvasJson[i];
            if (obj.dataType == "paper") {
              if (!obj.dataSelectedPaperType) {
                this.$snotify.error("Please select atleast one paper option", {
                  position: "rightTop",
                  showProgressBar: false,
                  timeout: 3000,
                });
                resolve(false);
                break;
              }
            } else if (obj.type == "path") {
              if (obj.dataType == "motif") {
                let motifsNew = obj.get("dataMotif");
                if (motifsNew && !motifsNew.motifOptions) {
                  this.$snotify.error(
                    "Please select atleast one motifs option",
                    {
                      position: "rightTop",
                      showProgressBar: false,
                      timeout: 3000,
                    }
                  );
                  resolve(false);
                  break;
                }
                if (!obj.dataColor || typeof obj.dataColor != "object") {
                  this.$snotify.error(
                    "Please select atleast one color for motifs",
                    {
                      position: "rightTop",
                      showProgressBar: false,
                      timeout: 3000,
                    }
                  );
                  resolve(false);
                  break;
                }
                if (!obj.dataPrint || !obj.dataPrint.defaultPrintType) {
                  this.$snotify.error(
                    "Please select atleast one process for motifs",
                    {
                      position: "rightTop",
                      showProgressBar: false,
                      timeout: 3000,
                    }
                  );
                  resolve(false);
                  break;
                }
              }
            } else if (obj.type == "i-text") {
              if (obj.dataType == "monogram") {
                let monograms = obj.get("dataMonogram");
                if (!monograms) {
                  this.$snotify.error(
                    "Please select atleast one monograms option",
                    {
                      position: "rightTop",
                      showProgressBar: false,
                      timeout: 3000,
                    }
                  );
                  resolve(false);
                  break;
                }
                if (!obj.dataColor || typeof obj.dataColor != "object") {
                  this.$snotify.error(
                    "Please select atleast one color for Monogram '" +
                      obj.text +
                      "'",
                    {
                      position: "rightTop",
                      showProgressBar: false,
                      timeout: 3000,
                    }
                  );
                  resolve(false);
                  break;
                }
                if (!obj.dataPrint || !obj.dataPrint.defaultPrintType) {
                  this.$snotify.error(
                    "Please select atleast one process for monogram'" +
                      obj.text +
                      "'",
                    {
                      position: "rightTop",
                      showProgressBar: false,
                      timeout: 3000,
                    }
                  );
                  resolve(false);
                  break;
                }
              }
            } else if (obj.type == "textbox") {
              let fonts = obj.get("dataFont");
              if (!fonts) {
                this.$snotify.error(
                  "Please select atleast one font option '" + obj.text + "'",
                  {
                    position: "rightTop",
                    showProgressBar: false,
                    timeout: 3000,
                  }
                );
                resolve(false);
                break;
              }
              if (!obj.dataColor || typeof obj.dataColor != "object") {
                this.$snotify.error(
                  "Please select atleast one color for text '" + obj.text + "'",
                  {
                    position: "rightTop",
                    showProgressBar: false,
                    timeout: 3000,
                  }
                );
                resolve(false);
                break;
              }
              if (!obj.dataPrint || !obj.dataPrint.defaultPrintType) {
                this.$snotify.error(
                  "Please select atleast one process for text '" +
                    obj.text +
                    "'",
                  {
                    position: "rightTop",
                    showProgressBar: false,
                    timeout: 3000,
                  }
                );
                resolve(false);
                break;
              }
            }
          }
          this.canvasLoadingInProgress = false;
          resolve(true);
        });
      } catch (err) {
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });

        console.log(err);
        this.inProgress = false;
      }
    },
    createDynamicStyleTag(fonts) {
      if (fonts.length > 0) {
        let fontCss = "";
        let styleTag = document.createElement("style");
        styleTag.type = "text/css";
        let matchedFonts = [];
        matchedFonts = fonts.map((obj) => {
          if (obj.otfUrl) {
            fontCss +=
              `@font-face{
              font-family: '` +
              obj.name +
              `';
              src: url('` +
              obj.otfUrl +
              `') format("opentype");
              font-weight: 400;
              font-style: normal
            }\n`;
          } else {
            fontCss +=
              `@font-face{
              font-family: '` +
              obj.name +
              `';
              src: url('` +
              obj.url +
              `') format("woff2");
              font-weight: 400;
              font-style: normal
            }\n`;
          }
          return obj.name;
        });

        if (styleTag.styleSheet) styleTag.styleSheet.cssText = fontCss;
        else styleTag.appendChild(document.createTextNode(fontCss));

        document.getElementsByTagName("head")[0].appendChild(styleTag);
        this.loadSVGFontByWebfont(matchedFonts);
      }
    },
    loadSVGFontByWebfont(fontNames) {
      try {
        let vm = this;
        WebFontLoader.load({
          custom: {
            families: fontNames,
          },
          active: function () {
            this.custom.families.forEach((family) => {
              fabric.util.clearFabricFontCache(family);
            });
            vm.loadAllObjectsInCanvas();
          },
          inactive: function (familyName) {
            vm.$snotify.error(`Error in ${familyName} font loading`, {
              position: "rightTop",
              showProgressBar: false,
            });
            vm.loadAllObjectsInCanvas();
          },
        });
      } catch (error) {
        console.log(error);
        this.$snotify.error(error, {
          position: "rightTop",
          showProgressBar: false,
          timeout: 3000,
        });
        //throw error;
      }
    },
    sideBarWindow() {
      return this.$refs && this.$refs.sideBar
        ? this.$refs.sideBar.clientWidth
        : 0;
    },
    setDrawer(value) {
      this.$store.dispatch("productOne/setNavigation", value);
    },
    summarySheet(value) {
      (this.summaryDetails = {
        productName: this.productOne.name,
      }),
        this.$store.dispatch("productOne/summarySheet", value);
    },
    objectDeselect() {
      this.selectedCanvas = "";
    },
    closeMobileMenu() {
      if (this.selectedCanvas) {
        this.selectedCanvas = null;
        this.productFabric.canvas.discardActiveObject();
      }
      this.activeFinishTab = false;
      this.productFabric.canvas.renderAll();
    },
    removedObjectFromCanvas() {
      let canvasObjects = this.productFabric.canvas._objects;
      for (let i = 0; i < canvasObjects.length; i++) {
        let obj = canvasObjects[i];
        if (
          obj.dataRequired == "NotValid" ||
          (obj.dataId &&
            (obj.dataId == "LinersObject" || obj.dataId == "TransparentObject"))
        ) {
          this.productFabric.canvas.remove(obj);
          i = -1;
        } else {
          obj.opacity = 1;
        }
      }
      this.productFabric.canvas.renderAll();
    },
    async setActiveFinishTab(flag) {
      try {
        this.activeFinishTab = flag;
        this.inProgress = true;
        this.paperObject = null;
        let linerObjectFlag = null;
        //let envelopeObjectFlag = null;
        let linerObject = {};
        let envelopeObject = {};
        let canvasObjects = this.productFabric.canvas._objects;
        for (let i = 0; i < canvasObjects.length; i++) {
          let obj = canvasObjects[i];
          if (flag) {
            if (!obj.dataId) {
              obj.set({
                opacity: 0,
                selectable: false,
                evented: false,
              });
            } else {
              obj.set({
                opacity: 1,
                selectable: true,
                evented: false,
              });
            }
            let objType = obj.get("type");
            if (objType == "image") {
              if (obj.dataType == "paper") {
                this.paperObject = obj;
              }
            }
            this.productFabric.canvas.discardActiveObject();
          } else {
            if (!obj.dataId) {
              obj.set({
                opacity: 1,
                selectable: true,
                evented: true,
              });
            } else {
              obj.set({
                opacity: 0,
                selectable: false,
                evented: false,
              });
            }
          }
          if (obj.dataId == "LinersObject") {
            linerObjectFlag = true;
            // obj.center();
          }
          if (
            this.paperObject &&
            this.paperObject.dataFinish.selectedLiner &&
            obj.dataId &&
            obj.dataId == "LinersObject"
          ) {
            linerObject = obj;
          }
          if (obj.dataId && obj.dataId == "TransparentObject") {
            envelopeObject = obj;
            this.envelopeObject = obj;
          }
        }

        if (flag && this.paperObject) {
          if (
            this.paperObject.dataSelectedPaperType &&
            (this.paperObject.dataSelectedPaperType
              .openEnvelopeTransparentUrl ||
              this.paperObject.dataSelectedPaperType.openEnvelopeUrl)
          ) {
            if (this.liners.selectedLiner) {
              await this.setFinishImageOnCanvas(
                this.paperObject,
                this.liners.selectedLiner.url,
                "LinersObject",
                ""
              );
            }

            let envelopeUrl = this.liners.selectedLiner
              ? this.paperObject.dataSelectedPaperType
                  .openEnvelopeTransparentUrl
              : this.paperObject.dataSelectedPaperType.openEnvelopeUrl;
            await this.setFinishImageOnCanvas(
              this.paperObject,
              envelopeUrl,
              "TransparentObject",
              ""
            );
          }
        }
        if (linerObject.dataId) {
          this.productFabric.canvas.moveTo(linerObject, 0);
        }
        if (envelopeObject.dataId) {
          if (!linerObjectFlag && flag) {
            let envelopeUrl =
              this.paperObject.dataSelectedPaperType.openEnvelopeUrl;
            await this.setFinishImageOnCanvas(
              this.paperObject,
              envelopeUrl,
              "TransparentObject",
              ""
            );
            this.productFabric.canvas.moveTo(envelopeObject, 0);
          } else {
            this.productFabric.canvas.moveTo(envelopeObject, 1);
          }
        }

        this.inProgress = false;
        if (flag) await this.resize();
        this.productFabric.canvas.renderAll();
        if (flag) {
          let canvasObjects = this.productFabric.canvas._objects;
          canvasObjects.filter((obj) => {
            let objType = obj.get("type");
            if (objType == "image") {
              if (obj.dataType == "paper") {
                obj.dataFinish = this.liners;
                return;
              }
            }
          });
        } else {
          await this.resize();
        }
        this.userMadeChangesInSvg();
      } catch (err) {
        console.error("Error in setting liner  ", err, { sessionId: this.builderSessionId, sku:this.productSku });
        this.$snotify.error(`Cloud communication error`, {
          position: "rightTop",
          showProgressBar: false,
        });

       // console.log(err);
        this.inProgress = false;
      }
    },
    async setFinishImageOnCanvas(paperObject, url, dataId, objIndex) {
      try {
        this.inProgress = true;
        const ref = this.productFabric;
        let vm = this;
        await this.getImageFromUrl(url).then(async (paperImage) => {
          if (paperImage._element != null) {
            if (dataId == "TransparentObject") {
              paperImage.set({
                left: paperObject.left,
                top: paperObject.top,
                scaleX: 1,
                scaleY: 1,
                selectable: false,
                hasControls: false,
                transparentCorners: true,
                lockMovementX: true,
                lockMovementY: true,
                evented: false,
                borderColor: "#00000",
                dataId: dataId,
                dataRequired: "NotValid",
              });
              vm.envelopeObject = paperImage;
              ref.canvas.setDimensions({
                width: paperImage.width,
                height: paperImage.height,
              });
              ref.canvas.setZoom(1);
              ///canvas.calcOffset();
              ref.canvas.renderAll();
            } else {
              let scaleY =
                this.partStates[this.selectedPartIndex].canvasHeight /
                paperImage.height;
              let scaleX =
                this.partStates[this.selectedPartIndex].canvasWidth /
                paperImage.width;
              paperImage.set({
                left: paperObject.left,
                top: paperObject.top,
                scaleX: scaleX,
                scaleY: scaleY,
                selectable: false,
                hasControls: false,
                transparentCorners: true,
                lockMovementX: true,
                lockMovementY: true,
                evented: false,
                borderColor: "#00000",
                dataId: dataId,
                dataRequired: "NotValid",
              });
              paperImage.left = vm.envelopeObject ? vm.envelopeObject.left : 0;
              paperImage.top = vm.envelopeObject ? vm.envelopeObject.top : 0;
            }
            paperImage.centerV();
            paperImage.setCoords();
            if (objIndex) {
              ref.canvas._objects.splice(objIndex, 0, paperImage);
            } else {
              ref.canvas.add(paperImage);
            }
          }
        });
      } catch (err) {
        console.error("Error in setting liner  ",err, { sessionId: this.builderSessionId, sku:this.productSku, url });
        this.$snotify.error(`Cloud communication error`, {
          position: "rightTop",
          showProgressBar: false,
        });

       // console.log(err);
        this.inProgress = false;
      }
    },
    async setFinishPattern(item) {
      try {
        this.inProgress = true;
        let linerPresent = false;
        let ref = this.productFabric;
        let canvasObjects = ref.canvas._objects;
        for (let i = 0; i < canvasObjects.length; i++) {
          let obj = canvasObjects[i];
          if (obj.dataId && obj.dataId == "TransparentObject") {
            let indexPosition = -1;
            let allObj = ref.canvas.getObjects();
            for (let i = 0; i < allObj.length; i++) {
              if (JSON.stringify(allObj[i]) === JSON.stringify(obj)) {
                indexPosition = i;
                break;
              }
            }
            ref.canvas.remove(obj);
            let envelopeUrl =
              this.paperObject.dataSelectedPaperType.openEnvelopeTransparentUrl;
            await this.setFinishImageOnCanvas(
              this.paperObject,
              envelopeUrl,
              "TransparentObject",
              indexPosition
            );
          }
          if (obj.dataId && obj.dataId == "LinersObject") {
            this.liners.selectedLiner = item;
            this.dataFinish = this.liners;
            this.selectedLinerName = item.name;

            let indexPosition = -1;
            let allObj = ref.canvas.getObjects();
            for (let i = 0; i < allObj.length; i++) {
              if (JSON.stringify(allObj[i]) === JSON.stringify(obj)) {
                indexPosition = i;
                break;
              }
            }
            ref.canvas.remove(obj);
            await this.setFinishImageOnCanvas(
              this.paperObject,
              item.url,
              "LinersObject",
              indexPosition
            );
            linerPresent = true;
            break;
          }
        }
        if (!linerPresent) {
          this.liners.selectedLiner = item;
          this.selectedLinerName = item.name;
          await this.setFinishImageOnCanvas(
            this.paperObject,
            this.liners.selectedLiner.url,
            "LinersObject",
            ""
          );
        }
        await this.setActiveFinishTab(true);
        this.inProgress = false;

        this.userMadeChangesInSvg();
      } catch (err) {
        console.error("Error in setting liner  ", err, { sessionId: this.builderSessionId, sku:this.productSku, selectedLinerName : item.name });
        this.$snotify.error(`Cloud communication error`, {
          position: "rightTop",
          showProgressBar: false,
        });

        // console.log(err);
        this.inProgress = false;
      }
    },
    async removeFinishPattern() {
      try {
        this.inProgress = true;
        let ref = this.productFabric;
        let canvasObjects = ref.canvas._objects;
        canvasObjects.filter((obj) => {
          let objType = obj.get("type");
          if (objType == "image") {
            if (obj.dataType == "paper") {
              obj.dataFinish.selectedLiner = null;
              this.liners.selectedLiner = null;
              this.selectedLinerName = null;
            }
            if (obj.dataId && obj.dataId == "LinersObject") {
              ref.canvas.remove(obj);
            }
          }
        });

       await this.setActiveFinishTab(true);
        this.inProgress = false;
      } catch (err) {
        this.$snotify.error(`Cloud communication error`, {
          position: "rightTop",
          showProgressBar: false,
        });

        console.log(err);
        this.inProgress = false;
      }
    },
    async selectZeroTypeElement(type) {
      try {
        let canvasObjects = this.productFabric.canvas._objects;
        this.selectedCanvas = null;
        let newObject = null;
        let firstMotif = "";
        let motifCount = 0;
        for (let i = 0; i < canvasObjects.length; i++) {
          newObject = "";
          let obj = canvasObjects[i];
          let objType = obj.get("type");
          if (objType == "image" && type == "paper") {
            if (obj.dataType == "paper") {
              newObject = obj;
              break;
            }
          } else if (objType == "image" && type == "image") {
            if (obj.dataType == "image") {
              newObject = obj;
              break;
            }
          } else if (
            (objType == "text" || objType == "textbox") &&
            type == "textbox"
          ) {
            newObject = obj;
            break;
          } else if (objType == "path" && type == "graphics") {
            newObject = obj;
            break;
          } else if (
            objType == "path" &&
            type == "monograms" &&
            obj.dataType == "monogram"
          ) {
            newObject = obj;
            break;
          } else if (objType == "path" && type == "motif") {
            if (obj.dataType == "motif") {
              if (motifCount == 0) firstMotif = obj;
              if (obj.dataVisible == "false") {
                firstMotif = obj;
                break;
              }
              motifCount++;
            }
          }
        }
        if (type == "motif") {
          newObject = firstMotif;
        }

        if (newObject) {
          this.selectedCanvas = newObject;
          this.productFabric.canvas.setActiveObject(this.selectedCanvas);
         await  this.setActiveFinishTab(false);
          this.productFabric.canvas.renderAll();
        }
      } catch (err) {
        this.$snotify.error(`Cloud communication error`, {
          position: "rightTop",
          showProgressBar: false,
        });

        console.log(err);
        this.inProgress = false;
      }
    },
    objSelected(e) {
      try {
        if (e && e.selected) {
          this.selectedCanvas = null;
          this.selectedCanvas = e.selected[0];
        }
        if (e && e.target) {
          this.selectedCanvas = null;
          this.selectedCanvas = e.target;
        }
        if (this.selectedCanvas) {
          window.addEventListener("beforeunload", handleBeforeUnload);
          this.maxlength = null;

          this.showStyleControl =
            this.selectedCanvas.dataColorStyle ||
            this.selectedCanvas.dataProcessStyle ||
            this.selectedCanvas.dataFontStyle
              ? true
              : false;

          this.dataIsStyleLinked =
            this.selectedCanvas.dataIsStyleLinked == true ||
            this.selectedCanvas.dataIsStyleLinked == "true"
              ? true
              : false;

          this.selectedColorName = "";
          const type = this.selectedCanvas.get("type");
          if (this.selectedCanvas.dataType == "monogram") {
            this.maxlength = this.selectedCanvas.maxlength;
            let monogramPrints = "";
            if (this.selectedCanvas.dataPrint) {
              monogramPrints = getPrintOptions(
                this.selectedCanvas.dataPrint.printTypeOptions
              );
              if (this.selectedCanvas.dataSelectedPrintType)
                this.selectedPrintMethod =
                  this.selectedCanvas.dataSelectedPrintType;
              else
                this.selectedPrintMethod =
                  this.selectedCanvas.dataPrint.defaultPrintType;
            }
            let monogramColor = "";

            if (this.selectedCanvas.defaultColor) {
              let colorObj = this.setPrintMethodBasedColor(
                this.selectedCanvas.defaultColor,
                this.selectedPrintMethod
              );
              if (colorObj) {
                monogramColor = colorObj.colorHex;
                this.selectedColorName = colorObj.colorName;
                this.selectedCanvas.dataColor = {
                  name: colorObj.colorName,
                  cid: colorObj.colorId,
                  colorHex: colorObj.colorHex,
                  colorShort: colorObj.colorShort
                    ? colorObj.colorShort
                    : colorObj.shortName,
                };
                this.selectedCanvas.defaultColor = {
                  name: colorObj.colorName,
                  cid: colorObj.colorId,
                  colorHex: colorObj.colorHex,
                  colorShort: colorObj.colorShort
                    ? colorObj.colorShort
                    : colorObj.shortName,
                };
              }
            }
            if (this.selectedCanvas.dataMonogram) {
              this.monogramList =
                this.selectedCanvas.dataMonogram.monogramOptions;

              this.monogramsProperty = {
                monograms: this.monogramList,
                printOptions: monogramPrints,
                selectedPrintMethod: this.selectedPrintMethod,
                selectedColor: monogramColor,
                selectedColorName: this.selectedColorName,
                monogramObject: this.selectedCanvas,
                selectedMonogramObject:
                  this.selectedCanvas.dataSelectedMonogram,
                selectedMonogramsName:
                  this.selectedCanvas.dataSelectedMonogramName,
                userText: this.selectedCanvas.dataMonogramUserText,
                userPreviewText:
                  this.selectedCanvas.dataMonogramUserPreviewText,
                dataMonogramCharacters:
                  this.selectedCanvas.dataMonogramCharacters,
                dataIsStyleLinked: this.dataIsStyleLinked,
                showStyleControl: this.showStyleControl,
              };
            }

            this.modeltype = "artwork";
            this.elementType = "monograms";
            this.selectedText = this.selectedCanvas.text;
            this.selectedMonogram =
              this.selectedCanvas.dataSelectedMonogramName;
            this.hideMonogramTab = false;
            this.hideElementOption = true;
            this.hideImageOption = true;
          } else if (type == "textbox") {
            this.textChangedKey = !this.textChangedKey;
            if (this.isMobileDevice) {
              this.selectedCanvas.editable = false;
            } else {
              this.selectedCanvas.editable = true;
            }
            if (this.selectedCanvas.text.length) {
              if (
                ["true", true].includes(this.selectedCanvas.dataOptionalAdded)
              )
                this.dataOptionalAdded = true;
              else this.dataOptionalAdded = false;

              this.paperSheetModel = false;
              this.selectedText = this.selectedCanvas.text;
              this.selectedFontSize =
                (Number(this.selectedCanvas.fontSize) * 72) / fabric.DPI;
              this.dataFont = this.selectedCanvas.dataFont;
              if (this.selectedCanvas.dataAlignProperty) {
                this.textAlignments = getAlignmentOptions(
                  this.selectedCanvas.dataAlignProperty.alignOptions
                );
                if (this.textAlignments.length > 0) {
                  this.hideTextAlignField = false;
                } else {
                  this.hideTextAlignField = true;
                }
                this.textAlign =
                  this.selectedCanvas.dataAlignProperty.defaultAlign;
                this.anchorAlignment =
                  this.selectedCanvas.dataAlignProperty.anchorAlignment;
              } else if (!this.selectedCanvas.dataAlignProperty) {
                this.hideTextAlignField = true;
              }
              if (this.selectedCanvas.dataFont) {
                let selectedFamily = this.selectedCanvas.fontFamily;
                let selectedFontArray =
                  this.selectedCanvas.dataFont.fontOptions.filter(function (
                    font
                  ) {
                    if (font.name == selectedFamily) return font;
                  });
                this.fontMin = selectedFontArray[0].fontMin;
                this.fontMax = selectedFontArray[0].fontMax;
                this.textFontSizeOptions = getFontSizeOptions(
                  selectedFontArray[0]
                );
                this.fontList = this.selectedCanvas.dataFont.fontOptions;
                this.selectedFont = this.selectedCanvas.fontFamily;
                this.hideFontField = false;
              } else this.hideFontField = true;

              if (this.selectedCanvas.dataPrint) {
                this.printMethods = getPrintOptions(
                  this.selectedCanvas.dataPrint.printTypeOptions
                );
                if (this.printMethods.length > 0) {
                  this.hidePrintField = false;
                } else {
                  this.hidePrintField = true;
                }
                if (this.selectedCanvas.dataSelectedPrintType)
                  this.selectedPrintMethod =
                    this.selectedCanvas.dataSelectedPrintType;
                else
                  this.selectedPrintMethod =
                    this.selectedCanvas.dataPrint.defaultPrintType;
                if (this.selectedCanvas.defaultColor) {
                  let colorObj = this.setPrintMethodBasedColor(
                    this.selectedCanvas.defaultColor,
                    this.selectedPrintMethod
                  );
                  if (colorObj) {
                    this.selectedColor = colorObj.colorHex;
                    this.selectedColorName = colorObj.colorName;
                    this.selectedCanvas.dataColor = {
                      name: colorObj.colorName,
                      cid: colorObj.colorId,
                      colorHex: colorObj.colorHex,
                      colorShort: colorObj.colorShort
                        ? colorObj.colorShort
                        : colorObj.shortName,
                    };
                    this.selectedCanvas.defaultColor = {
                      name: colorObj.colorName,
                      cid: colorObj.colorId,
                      colorHex: colorObj.colorHex,
                      colorShort: colorObj.colorShort
                        ? colorObj.colorShort
                        : colorObj.shortName,
                    };
                  }
                } else {
                  this.selectedColor = this.selectedCanvas.fill;
                }
              } else this.hidePrintField = true;

              if (!this.selectedCanvas.dataMaxLines)
                this.hideMaxlineField = true;
              else {
                this.dataMaxLines = this.selectedCanvas.dataMaxLines;
                this.hideMaxlineField = false;
              }

              this.modeltype = "text";
              this.dataMaxWidth = this.selectedCanvas.dataMaxWidth;
              this.hideTextOption = false;
              this.noPropertyToChange = false;
              this.activeClass = "productpage-tab-slider-mobile";
              this.selectedText = this.selectedCanvas.text;
            }
          } else if (
            type == "path" &&
            this.selectedCanvas.dataType == "motif"
          ) {
            if (this.selectedCanvas.dataType == "motif") {
              if (this.selectedCanvas.dataMotif) {
                this.motif = this.selectedCanvas.dataMotif.motifOptions;
                this.selectedMotif = this.motif[0];
                if (this.selectedCanvas.dataPrint) {
                  this.printMethods = getPrintOptions(
                    this.selectedCanvas.dataPrint.printTypeOptions
                  );
                  if (this.selectedCanvas.dataSelectedPrintType)
                    this.selectedPrintMethod =
                      this.selectedCanvas.dataSelectedPrintType;
                  else
                    this.selectedPrintMethod =
                      this.selectedCanvas.dataPrint.defaultPrintType;
                }
                let motifColor = "";
                if (this.selectedCanvas.defaultColor) {
                  let colorObj = this.setPrintMethodBasedColor(
                    this.selectedCanvas.defaultColor,
                    this.selectedPrintMethod
                  );
                  if (colorObj) {
                    motifColor = colorObj.colorHex;
                    this.selectedColorName = colorObj.colorName;
                    this.selectedCanvas.dataColor = {
                      name: colorObj.colorName,
                      cid: colorObj.colorId,
                      colorHex: colorObj.colorHex,
                      colorShort: colorObj.colorShort
                        ? colorObj.colorShort
                        : colorObj.shortName,
                    };
                    this.selectedCanvas.defaultColor = {
                      name: colorObj.colorName,
                      cid: colorObj.colorId,
                      colorHex: colorObj.colorHex,
                      colorShort: colorObj.colorShort
                        ? colorObj.colorShort
                        : colorObj.shortName,
                    };
                  }
                }
                this.elementsProperty = {}; // resetting all props

                this.elementsProperty = {
                  motifs: this.motif,
                  printOptions: this.printMethods,
                  selectedPrintMethod: this.selectedPrintMethod,
                  selectedColor: motifColor,
                  selectedColorName: this.selectedColorName,
                  selectedMotifsName: this.selectedCanvas.dataSelectedMotifName,
                  dataIsStyleLinked: this.dataIsStyleLinked,
                  showStyleControl: this.showStyleControl,
                };
                this.hideElementOption = false;
              } else {
                this.hideElementOption = true;
              }
              this.modeltype = "artwork";
              this.elementType = "motifs";
              this.hideMotifsTab = false;
              this.noPropertyToChange = false;
            }
            this.hideImageOption = true;
            this.activeClass = "productpage-tab-slider-mobile";
          } else if (type == "image") {
            if (this.selectedCanvas.dataType == "paper") {
              if (this.selectedCanvas.dataPaper) {
                this.papers = getPaperOptions(
                  this.selectedCanvas.dataPaper.paperOptions
                );
                if (this.selectedCanvas.dataSelectedPaperType) {
                  this.selectedPaper =
                    this.selectedCanvas.dataSelectedPaperType;
                  this.selectedWeight =
                    this.selectedCanvas.dataSelectedPaperType.weight;
                  this.selectedTexture =
                    this.selectedCanvas.dataSelectedPaperType.texture;
                } else {
                  this.selectedPaper = this.papers[0];
                  this.selectedWeight = this.papers[0].weight;
                  this.selectedTexture = this.papers[0].texture;
                }
                if (this.selectedCanvas.dataFinish)
                  this.dataFinish = this.selectedCanvas.dataFinish;

                if (this.selectedCanvas.dataColorTags)
                  this.dataColorTags = this.selectedCanvas.dataColorTags;

                this.hidePaperOption = false;
                this.hidePaperTabOption = false;
              } else {
                this.hidePaperOption = true;
                this.hidePaperTabOption = false;
              }
              this.hideElementOption = true;
              this.modeltype = "paper";
            } else if (this.selectedCanvas.dataType == "image") {
              if (this.selectedCanvas.dataImage) {
                this.dataImage = cloneDeep(this.selectedCanvas.dataImage);
                this.dataVisible = this.selectedCanvas.dataVisible;
                this.hideImageOption = false;
                this.imageTabDisable = false;

                if (this.selectedCanvas.dataPrint) {
                  this.printMethods = getPrintOptions(
                    this.selectedCanvas.dataPrint.printTypeOptions
                  );
                  if (this.selectedCanvas.dataSelectedPrintType)
                    this.selectedPrintMethod =
                      this.selectedCanvas.dataSelectedPrintType;
                  else
                    this.selectedPrintMethod =
                      this.selectedCanvas.dataPrint.defaultPrintType;
                }
                this.imagesProperty = {
                  selectedPrintMethod: this.selectedPrintMethod,
                  printOptions: this.printMethods,
                  imageHeight: fabric.util.parseUnit(
                    Number(this.selectedCanvas.dataHeight) + "pt"
                  ),
                  imageWidth: fabric.util.parseUnit(
                    Number(this.selectedCanvas.dataWidth) + "pt"
                  ),
                  cropOffsetX: this.selectedCanvas.dataImage.selectedImage
                    ? this.selectedCanvas.dataImage.selectedImage.cropOffsetX
                    : 1,
                  cropOffsetY: this.selectedCanvas.dataImage.selectedImage
                    ? this.selectedCanvas.dataImage.selectedImage.cropOffsetY
                    : fabric.util.parseUnit(
                        Number(this.selectedCanvas.dataWidth) + "pt"
                      ),
                  cropImageScale: this.selectedCanvas.dataImage.selectedImage
                    ? this.selectedCanvas.dataImage.selectedImage.cropImageScale
                    : 1,
                  dataIsStyleLinked: this.dataIsStyleLinked,
                  showStyleControl: this.showStyleControl,
                };
              }
              this.modeltype = "images";
            } else {
              this.imageTabDisable = true;
              this.hidePaperOption = true;
              this.hidePaperTabOption = false;
            }
            this.hideTextOption = true;
            this.hideElementOption = true;
            this.noPropertyToChange = false;
            this.activeClass = "productpage-tab-slider-mobile";
          }
          // as clipart is path or image
          if (this.selectedCanvas.dataType == "clipart") {
            let canvasObjects = this.productFabric.canvas.getObjects();

            let indexPosition = -1;
            for (let i = 0; i < canvasObjects.length; i++) {
              if (
                JSON.stringify(canvasObjects[i]) ===
                JSON.stringify(this.selectedCanvas)
              ) {
                indexPosition = i;
                break;
              }
            }
            for (let i = indexPosition; i < canvasObjects.length; i++) {
              let obj = canvasObjects[i];
              if (obj.dataType != "clipart") {
                this.selectedCanvas = obj;
                break;
              }
              //if selected clipart is last element then assigned it 1 (as 0th is paper)
              if (i + 1 == canvasObjects.length) i = 1;
            }

            this.productFabric.canvas.setActiveObject(this.selectedCanvas);
            this.productFabric.canvas.renderAll();
          }
          //common for All objects
          if (this.selectedCanvas.dataIsModified)
            this.dataIsModified = this.selectedCanvas.dataIsModified;
          if (this.selectedCanvas.dataRequired)
            this.dataRequired = this.selectedCanvas.dataRequired;
          if (this.selectedCanvas.dataColor)
            this.dataColor = this.selectedCanvas.dataColor;
          if (this.selectedCanvas.defaultColor)
            this.defaultColor = this.selectedCanvas.defaultColor;
        }
      } catch (err) {
        // console.error(err);
        console.error("Error in svg element selection  ",err, { sessionId: this.builderSessionId, sku:this.productSku });
        this.$snotify.error(`Cloud communication error`, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
      }
    },
    setTextareaAlign() {
      this.selectedCanvas.set("textAlign", this.textAlign);
      this.productFabric.canvas.renderAll();
    },

    setTextAlignments() {
      let val = this.selectedTextAlign;
      const ref = this.productFabric;
      switch (val) {
        case "left":
          this.selectedCanvas.set({
            left: 0,
          });
          this.selectedCanvas.set("originX", "left");
          break;
        case "right":
          this.selectedCanvas.set({
            left: ref.canvas.width,
          });
          this.selectedCanvas.set("originX", "right");

          break;
        case "center":
          this.selectedCanvas.set({
            left: ref.canvas.width / 2,
          });
          this.selectedCanvas.set("originX", "center");
          break;
      }
      this.selectedCanvas.setCoords();
      this.selectedCanvas.dataIsModified = true;
      this.productFabric.canvas.renderAll();
      this.productFabric.canvas.setActiveObject(this.selectedCanvas);
    },
    setJustifyAlignments() {
      let val = this.textAlign;
      switch (val) {
        case "left":
          this.selectedCanvas.set("textAlign", "left");
          break;
        case "right":
          this.selectedCanvas.set("textAlign", "right");
          break;
        case "center":
          this.selectedCanvas.set("textAlign", "center");
          break;
      }
      this.selectedCanvas.setCoords();
      this.selectedCanvas.dataIsModified = true;
      this.productFabric.canvas.renderAll();
      this.productFabric.canvas.setActiveObject(this.selectedCanvas);
    },
    setText() {
      const ref = this.productFabric;
      this.selectedCanvas.set({
        text: this.selectedText,
        dataIsModified: true,
      });
      ref.canvas.add(this.selectedCanvas);
    },
    upDate() {
      this.productFabric.canvas.discardActiveObject();
      this.productFabric.canvas.renderAll();
      this.selectedCanvas = null;
      console.log("canvas data", this.productFabric.canvas.toJSON());
      console.log(
        "canvas to svg",
        this.productFabric.canvas.toSVG(null, this.deleteTspan)
      );
    },
    loadPdf() {},
    selectedMode(part, index) {
      this.selected = index;

      this.flipPage(this.selected);
    },
    getImageFromUrl(image) {
      return new Promise((resolve, reject) => {
        let imageUrl = image + "?" + new Date().getTime();
        new fabric.Image.fromURL(
          imageUrl,
          (urlImage) => {
            if (urlImage._element == null) {
              let defaultUrl = {
                url: require("@/assets/1591275651976.jpg"),
              };
              resolve(this.getImageFromUrl(defaultUrl.url));
            }
            if (urlImage) {
              resolve(urlImage);
            } else {
              reject(new Error("Failed to load"));
            }
          },
          {
            crossOrigin: "Anonymous",
          }
        );
      });
    },
    getSelectedPaperColor(paperObj) {
      let colorHex = paperObj.colorHex ? paperObj.colorHex.trim() : "#FFFFFF";
      if (colorHex.indexOf("#") == -1) colorHex = "#" + colorHex;
      return colorHex;
    },
    async setPaper() {
      try {
        this.inProgress = true;
        this.selectedWeight = this.selectedPaper.weight;
        this.selectedTexture = this.selectedPaper.texture;
        let paperObject = "";
        if (this.selectedPaper.text) {
          let newPaper = this.selectedCanvas.dataPaper.paperOptions.filter(
            (obj) => {
              if (obj.name == this.selectedPaper.text) return obj;
            }
          );
          paperObject = newPaper[0];
        } else {
          paperObject = this.selectedPaper;
        }
        const ref = this.productFabric;

        let imgUrl =
          this.selectedPaper &&
          this.selectedPaper.currentSelectedSurface &&
          this.selectedPaper.currentSelectedSurface.url
            ? this.selectedPaper.currentSelectedSurface.url
            : this.selectedPaper.img;

        let paperImage = await this.getImageFromUrl(imgUrl);

        paperImage.set({
          left: this.selectedCanvas.left,
          top: this.selectedCanvas.top,
          scaleX:
            this.partStates[this.selectedPartIndex].canvasWidth /
            paperImage.width,
          scaleY:
            this.partStates[this.selectedPartIndex].canvasHeight /
            paperImage.height,
          selectable: true,
          hasControls: false,
          transparentCorners: true,
          lockMovementX: true,
          lockMovementY: true,
          borderColor: "#00000",
          dataType: this.selectedCanvas.dataType,
          dataPaper: this.selectedCanvas.dataPaper,
          dataSelectedPaperType: paperObject,
          dataIsModified: true,
          dataFinish: this.selectedCanvas.dataFinish,
          dataColorTags: this.selectedCanvas.dataColorTags,
          dataSurface: this.partSurface,
        });

        let indexPosition = -1;
        let allObj = ref.canvas.getObjects();
        for (let i = 0; i < allObj.length; i++) {
          if (
            JSON.stringify(allObj[i]) === JSON.stringify(this.selectedCanvas)
          ) {
            indexPosition = i;
            break;
          }
        }

        await ref.canvas.insertAt(paperImage, indexPosition, true);
        if (paperImage.dataSelectedPaperType.openEnvelopeTransparentUrl) {
          this.disableFinishTab = false;
        } else {
          this.disableFinishTab = true;
        }
        this.selectedCanvas = paperImage;
        this.selectedCanvas.setCoords();
        await ref.canvas.requestRenderAll();
        await this.userMadeChangesInSvg();
        this.inProgress = false;
      } catch (error) {
        console.error("Error while setting paper on canvas  ", error, { sessionId: this.builderSessionId, sku:this.productSku, paper : this.selectedPaper });
        this.inProgress = false;
        this.$snotify.error(error, {
          position: "rightTop",
          showProgressBar: false,
          timeout: 3000,
        });
      }
    },
    setTexture() {},
    setWeight() {},
    // Font Properties Start
    setFontSize() {
      let fontObj = {};
      this.fontList.filter((obj) => {
        if (obj.name == this.selectedCanvas.fontFamily) {
          fontObj.name = this.selectedCanvas.fontFamily;
          fontObj.defaultValue = this.selectedFontSize;
          fontObj.letterSpacing = obj.letterSpacing;
          fontObj.lineHeight = obj.lineHeight;
        }
      });

      this.selectedCanvas.dataIsModified = true;

      this.updateTextFont(this.selectedCanvas, fontObj, "font");

      this.userMadeChangesInSvg();
    },
    clearFabricFontCache: function (fontFamily) {
      fontFamily = (fontFamily || "").toLowerCase();
      if (!fontFamily) {
        fabric.charWidthsCache = {};
      } else if (fabric.charWidthsCache[fontFamily]) {
        delete fabric.charWidthsCache[fontFamily];
      }
    },
    setTextbox() {},
    async setFont() {
      let fontObj = {};
      if (this.selectedFont) {
        this.fontList.filter((obj) => {
          if (obj.name == this.selectedFont) {
            this.selectedFontSize = Number(obj.defaultValue);
            fontObj.name = this.selectedFont;

            fontObj.defaultValue = this.selectedFontSize;
            fontObj.letterSpacing = obj.letterSpacing;
            fontObj.lineHeight = obj.lineHeight;
          }
        });
        this.selectedCanvas.dataIsModified = true;
        this.updateTextFont(this.selectedCanvas, fontObj, "font");

        if (this.selectedCanvas.dataIsStyleLinked)
          await this.setCanvasInitialStyle(this.selectedCanvas, true);

        this.userMadeChangesInSvg();
      }
      this.mobileTabOptions = false;
    },
    async setPrintMethod(motifPrintMethod) {
      let printMethod = motifPrintMethod
        ? motifPrintMethod
        : this.selectedPrintMethod;
      this.selectedCanvas.set({
        dataSelectedPrintType: printMethod,
        dataIsModified: true,
        dataSelectedPrintShortName: await this.getPrintShortName(printMethod),
      });

      if (this.selectedCanvas.dataIsStyleLinked)
        await this.setCanvasInitialStyle(this.selectedCanvas, true);

      this.productFabric.canvas.renderAll();
      this.userMadeChangesInSvg();
    },
    getPrintShortName(printMethod) {
      if (printMethod) {
        let selectedProcess = processDetails.filter((obj) => {
          if (obj.name == printMethod) return obj;
        });
        if (selectedProcess.length) return selectedProcess[0].shortName;
        else return "";
      } else return "";
    },
    setProcessStyleForAllMatched({ currentObject }) {
      try {
        if (
          currentObject.dataIsStyleLinked == true ||
          currentObject.dataIsStyleLinked == "true"
        ) {
          let canvasObjects = this.productFabric.canvas._objects;
          for (let i = 0; i < canvasObjects.length; i++) {
            let obj = canvasObjects[i];
            if (obj.dataType != "paper") {
              if (
                currentObject.dataProcessStyle == obj.dataProcessStyle &&
                currentObject.defaultColor &&
                ["true", true].includes(obj.dataIsStyleLinked)
              ) {
                // if current style is in store
                if (this.productStyle[currentObject.dataProcessStyle]) {
                  obj.set({
                    dataSelectedPrintType:
                      this.productStyle[currentObject.dataProcessStyle]
                        .dataSelectedPrintType,
                    dataSelectedPrintShortName:
                      this.productStyle[currentObject.dataProcessStyle]
                        .dataSelectedPrintShortName,
                  });
                } else {
                  let fillColor = this.setPrintMethodBasedColor(
                    obj.defaultColor, // to set current obj default color => currentObject.defaultColor
                    currentObject.dataSelectedPrintType
                  );
                  if (fillColor && fillColor.colorHex) {
                    obj.set({
                      fill: fillColor.colorHex,
                      dataSelectedPrintType:
                        currentObject.dataSelectedPrintType,
                      dataSelectedPrintShortName:
                        currentObject.dataSelectedPrintShortName,
                      dataColor: {
                        name: fillColor.colorName,
                        cid: fillColor.colorId,
                        colorHex: fillColor.colorHex,
                        colorShort: fillColor.shortName,
                      },
                      defaultColor: {
                        name: fillColor.colorName,
                        cid: fillColor.colorId,
                        colorHex: fillColor.colorHex,
                        colorShort: fillColor.shortName,
                      },
                    });
                  } else if (
                    currentObject.dataSelectedPrintType &&
                    currentObject.dataProcessStyle == obj.dataProcessStyle &&
                    ["true", true].includes(obj.dataIsStyleLinked)
                  ) {
                    obj.set({
                      dataSelectedPrintType:
                        currentObject.dataSelectedPrintType,
                      dataSelectedPrintShortName:
                        currentObject.dataSelectedPrintShortName,
                    });
                  }
                }
              }
            }
          }
        }
      } catch (err) {
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        console.error(err);
      }
    },
    setColorStyleForAllMatched({ currentObject }) {
      try {
        if ([true, "true"].includes(currentObject.dataIsStyleLinked)) {
          let canvasObjects = this.productFabric.canvas._objects;
          for (let i = 0; i < canvasObjects.length; i++) {
            let obj = canvasObjects[i];
            if (obj.dataType != "paper") {
              if (
                currentObject.defaultColor &&
                obj.dataColorStyle === currentObject.dataColorStyle &&
                ["true", true].includes(obj.dataIsStyleLinked)
              ) {
                // if current style is in store
                if (this.productStyle[currentObject.dataColorStyle]) {
                  obj.set({
                    fill: this.productStyle[currentObject.dataColorStyle]
                      .colorHex,
                    dataColor: this.productStyle[currentObject.dataColorStyle],
                    defaultColor:
                      this.productStyle[currentObject.dataColorStyle],
                  });
                } else {
                  let fillColor = this.setPrintMethodBasedColor(
                    currentObject.defaultColor,
                    obj.dataSelectedPrintType
                  );
                  if (fillColor && fillColor.colorHex) {
                    obj.set({
                      fill: fillColor.colorHex,
                      dataColor: {
                        name: fillColor.colorName,
                        cid: fillColor.colorId,
                        colorHex: fillColor.colorHex,
                        colorShort: fillColor.shortName,
                      },
                      defaultColor: {
                        name: fillColor.colorName,
                        cid: fillColor.colorId,
                        colorHex: fillColor.colorHex,
                        colorShort: fillColor.shortName,
                      },
                    });
                  } else {
                    this.$snotify.error("Error while setting print process", {
                      position: "rightTop",
                      showProgressBar: false,
                      timeout: 3000,
                    });
                  }
                }
              }
            }
          }
        }
      } catch (err) {
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });

        console.error(err);
      }
    },
    setFontStyleForAllMatched({ currentObject, selectedFont }) {
      try {
        if (
          selectedFont &&
          (currentObject.dataIsStyleLinked == true ||
            (currentObject.dataIsStyleLinked == "true" &&
              this.fontList &&
              this.fontList.length))
        ) {
          let canvasObjects = this.productFabric.canvas._objects;
          for (let i = 0; i < canvasObjects.length; i++) {
            let obj = canvasObjects[i];
            if (obj.dataType != "paper") {
              if (
                (obj.type == "text" || obj.type == "textbox") &&
                obj.dataFontStyle === currentObject.dataFontStyle &&
                ["true", true].includes(obj.dataIsStyleLinked) &&
                selectedFont
              ) {
                // if current style is in store
                if (this.productStyle[currentObject.dataFontStyle]) {
                  let fontObj = {};
                  let currentStyle =
                    this.productStyle[currentObject.dataFontStyle];
                  this.selectedFontSize = Number(currentStyle.defaultValue);

                  fontObj.name = currentStyle.name;
                  fontObj.defaultValue = Number(currentStyle.defaultValue);
                  fontObj.letterSpacing = currentStyle.letterSpacing;
                  fontObj.lineHeight = currentStyle.lineHeight;

                  if (fontObj.name) this.updateTextFont(obj, fontObj, "font");
                } else {
                  let fontObj = {};
                  for (let f = 0; f < this.fontList.length; f++) {
                    let fObj = this.fontList[f];
                    if (fObj.name == selectedFont) {
                      this.selectedFontSize = Number(fObj.defaultValue);
                      fontObj.name = selectedFont;
                      fontObj.defaultValue = this.selectedFontSize;
                      fontObj.letterSpacing = fObj.letterSpacing;
                      fontObj.lineHeight = fObj.lineHeight;
                    }
                  }
                  if (fontObj.name) this.updateTextFont(obj, fontObj, "font");
                }
              }
            }
          }
        }
      } catch (err) {
        console.error(err);
        this.$snotify.error(`Cloud communication error`, {
          position: "rightTop",
          showProgressBar: false,
        });
      }
    },
    getRandomNumberBetween50And99() {
      const randomDecimal = Math.random();
      const scaledNumber = randomDecimal * 50;
      const randomNumber = Math.floor(scaledNumber) + 50;

      return randomNumber;
    },
    showPulseEffectForPrice(flag) {
      console.log(flag);
      if (flag) {
        this.showPulseEffect = true;
      } else {
        setTimeout(() => {
          this.showPulseEffect = false;
        }, 6000);
      }
    },
    async setTextColor(color) {
      if (typeof this.defaultColor == "object") {
        this.showPulseEffectForPrice(true);
        this.selectedColor = color.colorHex;
        this.selectedColorName = color.colorName;
        this.dataColor = {
          name: color.colorName,
          cid: color.colorId,
          colorHex: color.colorHex,
          colorShort: color.shortName,
        };
        this.defaultColor = {
          name: color.colorName,
          cid: color.colorId,
          colorHex: color.colorHex,
          colorShort: color.shortName,
        };
        this.selectedCanvas.set({
          fill: color.colorHex,
          dataIsModified: true,
          dataColor: this.dataColor,
          defaultColor: this.defaultColor,
        });

        this.userMadeChangesInSvg();

        if (this.selectedCanvas.dataIsStyleLinked)
          await this.setCanvasInitialStyle(this.selectedCanvas, true);

        if (this.selectedFont) {
          let fontObj = {};
          this.fontList.filter((obj) => {
            if (obj.name == this.selectedFont) {
              fontObj.name = this.selectedFont;

              fontObj.defaultValue =
                (this.selectedCanvas.fontSize * 72) / fabric.DPI;
              fontObj.letterSpacing = obj.letterSpacing;
              fontObj.lineHeight = obj.lineHeight;
            }
          });
          this.updateTextFont(this.selectedCanvas, fontObj, "font");
          this.showPulseEffectForPrice(false);
        }
      }
    },
    setPaperColor(color) {
      this.selectedPaperColor = color.colorHex;
      this.selectedPaperColorName = color.colorName;
      this.selectedCanvas.set({
        fill: color.colorHex,
        dataIsModified: true,
      });

      this.productFabric.canvas.renderAll();
      this.userMadeChangesInSvg();
    },
    setPropertyToObject(obj) {
      let val = obj.get("type");
      switch (val) {
        case "i-text":
          if (obj) {
            let iTextNew = {
              type: "i-text",
              text: obj.text,
              left: obj.left,
              top: obj.top,
              fill: obj.fill,
              fontFamily: obj.fontFamily,
              fontSize: obj.fontSize,
              dataPrint: obj.dataPrint ? obj.dataPrint : {},
            };
            return iTextNew;
          }
          break;
        case "text":
          if (obj) {
            let textboxNew = {
              type: "text",
              text: obj.text,
              left: obj.left,
              top: obj.top,
              fill: obj.fill,
              fontFamily: obj.fontFamily,
              fontSize: obj.fontSize,
              dataPrint: obj.dataPrint ? obj.dataPrint : {},
            };
            return textboxNew;
          }
          break;
        case "textbox":
          if (obj) {
            let textboxNew = {
              type: "text",
              text: obj.text,
              left: obj.left,
              top: obj.top,
              fill: obj.fill,
              fontFamily: obj.fontFamily,
              fontSize: obj.fontSize,
              dataPrint: obj.dataPrint ? obj.dataPrint : {},
            };
            return textboxNew;
          }
          break;
        case "path":
          if (obj) {
            let pathNew = {
              type: obj.dataType ? obj.dataType : "path",
              left: obj.left,
              top: obj.top,
              fill: obj.fill,
              dataSelectedMotifName: obj.dataSelectedMotifName
                ? obj.dataSelectedMotifName
                : "",
              dataSelectedMotifUrl: obj.dataSelectedMotifUrl
                ? obj.dataSelectedMotifUrl
                : "",
              dataPrint: obj.dataPrint ? obj.dataPrint : {},
            };
            return pathNew;
          }
          break;
        case "image":
          if (obj.get("dataType") == "paper") {
            let paperNew = {
              type: obj.dataType ? obj.dataType : "image",
              left: obj.left,
              top: obj.top,
              fill: obj.fill,
              selectedPaper: this.selectedPaper ? this.selectedPaper : {},
              dataPrint: obj.dataPrint ? obj.dataPrint : {},
            };
            return paperNew;
          }
          break;
      }
    },
    async save() {
      /* let vm = this;

      for (let i = 0; i < vm.productParts.length; i++) {
        vm.innerproperty = {
          partId: vm.productParts[i].id,
          partName: vm.productParts[i].name,
          partObjects: [],
        };

        if (vm.partStates[i].state) {
          vm.productFabric.canvas.loadFromJSON(
            vm.partStates[vm.selectedPartIndex].state,
            function () {},
            function (o, object) {
              let newObj = vm.setPropertyToObject(object);
              vm.innerproperty.partObjects.push(newObj);
            }
          );
        }
      }
      if (vm.localParts) localStorage.setItem("cr_saveObjects", "");

      localStorage.setItem("cr_saveObjects", JSON.stringify(vm.localParts));*/
    },
    next() {},
    OnResize() {
      if (this.$vuetify.display.smAndUp) {
        this.sideWindow = this.sideBarWindow();
      } else {
        this.sideWindow = this.sideBarWindow();
      }
    },
    setSelectedShape(value) {
      this.selectedShape = value;
      const ref = this.productFabric;
      this.canvas = ref.canvas;
      this.canvas.add(
        new fabric.Rect({
          left: 100,
          top: 100,
          fill: "#ff0000",
          width: 55,
          height: 95,
        })
      );
    },
    updateSelectedMonogramOnCanvas(monogramObj) {
      try {
        let vm = this;
        if (monogramObj) {
          if (
            this.selectedCanvas &&
            this.selectedCanvas.dataSelectedMonogram &&
            this.deletedElementArray.length
          ) {
            this.deletedElementArray = this.deletedElementArray.filter(
              (item) => {
                if (
                  item.type === "monogram" &&
                  item.name !== this.selectedCanvas.dataSelectedMonogram.name
                ) {
                  return item;
                }
              }
            );
          }

          vm.selectedCanvas.set({
            maxlength: monogramObj.monogram.lettersCount,
            monogramName: monogramObj.monogram.name,
            dataIsModified: true,
            dataSelectedMonogramName: monogramObj.monogram.name,
            dataSelectedMonogram: monogramObj.monogram,
            dataMonogramUserText: monogramObj.userText,
            dataMonogramUserPreviewText: monogramObj.userPreviewText,
            text: monogramObj.userText,
            fontSize: fabric.util.parseUnit(
              monogramObj.monogram.pointSize + "pt"
            ),
          });
          this.maxlength = monogramObj.monogram.lettersCount;
          this.updateSelected(
            vm.selectedCanvas,
            monogramObj.monogram,
            "monogram"
          );
        }
      } catch (err) {
        console.error(err, {monogram: monogramObj, sessionId: this.builderSessionId, sku:this.productSku});
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
      }
    },
    changeMonogramColor(color) {
      try {
        this.showPulseEffectForPrice(true);
        const ref = this.productFabric;
        this.dataColor = {
          name: color.colorName,
          cid: color.colorId,
          colorHex: color.colorHex,
          colorShort: color.shortName,
        };
        this.defaultColor = {
          name: color.colorName,
          cid: color.colorId,
          colorHex: color.colorHex,
          colorShort: color.shortName,
        };
        this.selectedCanvas.set({
          fill: color.colorHex,
          dataIsModified: true,
          dataColor: this.dataColor,
          defaultColor: this.defaultColor,
        });

        if (this.selectedCanvas.dataIsStyleLinked)
          this.setCanvasInitialStyle(this.selectedCanvas, true);

        ref.canvas.requestRenderAll();
        this.userMadeChangesInSvg();
        this.showPulseEffectForPrice(false);
      } catch (err) {
        console.log(err);
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
      }
    },
    async changeMotifColor(color) {
      try {
        this.showPulseEffectForPrice(true);
        if (typeof this.defaultColor == "object") {
          const ref = this.productFabric;
          this.dataColor = {
            name: color.colorName,
            cid: color.colorId,
            colorHex: color.colorHex,
            colorShort: color.shortName,
          };
          this.defaultColor = {
            name: color.colorName,
            cid: color.colorId,
            colorHex: color.colorHex,
            colorShort: color.shortName,
          };
          this.selectedCanvas.set({
            fill: color.colorHex,
            dataIsModified: true,
            dataColor: this.dataColor,
            defaultColor: this.defaultColor,
          });
          this.selectedColorName = color.colorName;

          if (this.selectedCanvas.dataIsStyleLinked)
            await this.setCanvasInitialStyle(this.selectedCanvas, true);
          ref.canvas.requestRenderAll();
          this.userMadeChangesInSvg();
          this.showPulseEffectForPrice(false);
        }
      } catch (err) {
        console.log(err);
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
      }
    },
    setSelectedMotif(motifImage) {
      if (
        this.selectedCanvas &&
        this.selectedCanvas.dataSelectedMotif &&
        this.deletedElementArray.length
      ) {
        this.deletedElementArray = this.deletedElementArray.filter((item) => {
          if (
            item.type === "motif" &&
            item.name !== this.selectedCanvas.dataSelectedMotif.name
          ) {
            return item;
          }
        });
      }
      this.updateSelected(this.selectedCanvas, motifImage, "motif");
      this.mobileTabOptions = false;
    },
    async removeSelectedMotif() {
      const ref = this.productFabric;
      this.selectedCanvas.visible = false;
      this.selectedCanvas.dataVisible = false;
      if (this.selectedCanvas.dataVisible == false) {
        let deletedMotifs = {
          name: this.selectedCanvas.dataSelectedMotifName,
          type: "motif",
          visible: false,
          elementIndex: "",
        };
        this.deletedElementArray.push(deletedMotifs);
      }
      this.selectedCanvas.dataSelectedMotifUrl = "";
      this.selectedCanvas.dataSelectedMotifName = "";
      this.selectedCanvas.setCoords();
      ref.canvas.discardActiveObject();
      ref.canvas.requestRenderAll();
      this.modeltype = "paper";
      this.userMadeChangesInSvg();
    },
    setPageLoader(flag) {
      this.inProgress = flag;
      return true;
    },
    async displayDeletedElement(selectedObject) {
      let elementDeleted = false;
      if (selectedObject.elementIndex) {
        if (
          this.productFabric.canvas &&
          this.productFabric.canvas._objects.length
        ) {
          let obj =
            this.productFabric.canvas._objects[selectedObject.elementIndex];
          obj.visible = true;
          obj.dataVisible = true;
          obj.dataSelectedMotifUrl = obj.dataSelectedMotif.url;
          obj.dataSelectedMotifName = obj.dataSelectedMotif.name;
          elementDeleted = true;
        }
      } else {
        // if (selectedObject.type) {
        let allObjects = this.productFabric.canvas._objects;
        for (let i = 0; i < allObjects.length; i++) {
          let obj = allObjects[i];
          if (obj.get("dataType") == "motif") {
            if (
              obj.dataSelectedMotif &&
              !obj.visible &&
              obj.dataSelectedMotif.name == selectedObject.name
            ) {
              obj.visible = true;
              obj.dataVisible = true;
              obj.dataSelectedMotifUrl = obj.dataSelectedMotif.url;
              obj.dataSelectedMotifName = obj.dataSelectedMotif.name;
              elementDeleted = true;
            }
          } else if (obj.get("dataType") == "monogram") {
            if (
              obj.dataSelectedMonogram &&
              !obj.visible &&
              obj.dataSelectedMonogram.name == selectedObject.name
            ) {
              obj.visible = true;
              obj.dataVisible = true;
              obj.dataSelectedMonogramName = obj.dataSelectedMonogram.name;
              elementDeleted = true;
              this.selectedCanvas = obj;
              await this.updateSelectedMonogramOnCanvas({
                monogram: obj.dataSelectedMonogram,
                userText: obj.dataMonogramUserText,
                userPreviewText: obj.dataMonogramUserPreviewText,
              });
            }
          }
        }
        // }
      }
      if (elementDeleted) {
        let list = this.deletedElementArray.filter((obj) => {
          if (obj.name !== selectedObject.name) {
            return obj;
          }
        });
        this.deletedElementArray = list.length ? list : [];
      }
      this.productFabric.canvas.requestRenderAll();
      this.userMadeChangesInSvg();
    },
    async removeSelectedImage() {
      try {
        const ref = this.productFabric;
        this.selectedCanvas.set({
          visible: false,
          left: this.selectedCanvas.left,
          top: this.selectedCanvas.top,
        });
        this.selectedCanvas.dataVisible = false;
        this.image.visible = false;
        this.image.selectedImage = {};
        this.selectedImageName = "";
        this.selectedCanvas.setCoords();
        ref.canvas.discardActiveObject();
        ref.canvas.requestRenderAll();
        this.modeltype = "paper";
        this.userMadeChangesInSvg();
      } catch (err) {
        console.error(err);
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
      }
    },
    async setSelectedImage(selectedImageObject) {
      try {
        if (this.selectedCanvas) {
          let image = selectedImageObject.response; // selectedImage.item;
          this.inProgress = true;
          const ref = this.productFabric;
          const newImage = await this.getImageFromUrl(image.url);
          this.image.selectedImage = {
            name: image.name,
            url: image.url,
            id: image.id,
          };
          this.image.visible = true;
          this.selectedImageName = image.name;
          this.selectedCanvas.dataImage.selectedImage = {
            name: image.name,
            url: image.url,
            id: image.id,
            cropOffsetX: selectedImageObject.cropOffsetX,
            cropOffsetY: selectedImageObject.cropOffsetY,
            cropImageScale: selectedImageObject.cropImageScale,
          };
          this.selectedCanvas.dataVisible = true;
          let scaleX, scaleY, newLeft, newTop;
          if (this.selectedCanvas.dataImage) {
            let imgHeight = fabric.util.parseUnit(
              Number(this.selectedCanvas.dataHeight) + "pt"
            );
            let imgWidth = fabric.util.parseUnit(
              Number(this.selectedCanvas.dataWidth) + "pt"
            );

            scaleX = imgWidth / newImage.width;
            scaleY = imgHeight / newImage.height;
            newLeft = fabric.util.parseUnit(
              Number(this.selectedCanvas.dataLeft) + "pt"
            );
            newTop = fabric.util.parseUnit(
              Number(this.selectedCanvas.dataTop) + "pt"
            );
          }
          newImage.set({
            left: newLeft,
            top: newTop,
            scaleX: scaleX ? scaleX : newImage.scaleX,
            scaleY: scaleY ? scaleY : newImage.scaleY,
            opacity: 1,
            selectable: true,
            hasControls: false,
            transparentCorners: true,
            lockMovementX: true,
            lockMovementY: true,
            dataType: "image",
            visible: true,
            dataPrint: this.selectedCanvas.dataPrint,
            dataImage: this.selectedCanvas.dataImage,
            dataVisible: this.selectedCanvas.dataVisible,
            dataIsModified: true,
            dataRequired: this.selectedCanvas.dataRequired,
            dataElementName: this.selectedCanvas.dataElementName,
            dataIsStyleLinked:
              this.selectedCanvas.dataIsStyleLinked == undefined
                ? true
                : this.selectedCanvas.dataIsStyleLinked,
            dataColorStyle: this.selectedCanvas.dataColorStyle,
            dataProcessStyle: this.selectedCanvas.dataProcessStyle,
            dataFontStyle: this.selectedCanvas.dataFontStyle,
            dataLeft: this.selectedCanvas.dataLeft,
            dataTop: this.selectedCanvas.dataTop,
            dataHeight: this.selectedCanvas.dataHeight,
            dataWidth: this.selectedCanvas.dataWidth,
            dataSelectedPrintShortName:
              this.selectedCanvas.dataSelectedPrintShortName,
            dataSelectedPrintType: this.selectedCanvas.dataSelectedPrintType,
            dataImgBottom: this.selectedCanvas.dataImgBottom,
            dataImgRight: this.selectedCanvas.dataImgRight,
          });

          let indexPosition = -1;
          let allObj = ref.canvas.getObjects();
          for (let i = 0; i < allObj.length; i++) {
            if (
              JSON.stringify(allObj[i]) === JSON.stringify(this.selectedCanvas)
            ) {
              indexPosition = i;
              break;
            }
          }

          this.selectedCanvas = newImage;
          ref.canvas.insertAt(newImage, indexPosition, true);
          ref.canvas.setActiveObject(newImage);
          this.selectedCanvas.setCoords();
          ref.canvas.requestRenderAll();
          this.userMadeChangesInSvg();
          this.inProgress = false;
        }
      } catch (error) {
        this.inProgress = false;
        this.$snotify.error(error, {
          position: "rightTop",
          showProgressBar: false,
          timeout: 3000,
        });
      }
    },
    async imageRotate(side) {
      if (side == "left") {
        let angle = this.selectedCanvas.angle;
        if (angle == 0) angle = 360;
        else if (angle == -360) angle = 360;
        this.selectedCanvas.rotate(angle - 90);
      } else {
        let angle = this.selectedCanvas.angle;
        if (angle == 360) angle = 0;
        this.selectedCanvas.rotate(angle + 90);
      }
      this.selectedCanvas.setCoords();
      this.productFabric.canvas.requestRenderAll();
      this.userMadeChangesInSvg();
    },
    imageZoom() {},
    async imageFlip(side) {
      if (side == "x") {
        this.selectedCanvas.flipX = !this.selectedCanvas.flipX;
        this.selectedCanvas.flipY = this.selectedCanvas.flipY;
      } else {
        this.selectedCanvas.flipX = this.selectedCanvas.flipX;
        this.selectedCanvas.flipY = !this.selectedCanvas.flipY;
      }
      this.selectedCanvas.setCoords();
      this.productFabric.canvas.requestRenderAll();
      this.userMadeChangesInSvg();
    },
    async saveUserChanges() {
      try {
        this.isSaveYourDesignClicked = true;
        await this.saveState();
        this.$snotify.success("Your changes has been saved", {
          position: "rightTop",
          showProgressBar: false,
        });
        window.removeEventListener("beforeunload", handleBeforeUnload);
      } catch (error) {
        this.$snotify.error(error.message, {
          position: "rightTop",
          showProgressBar: false,
        });
      }
    },
    async saveState() {
      this.partStates[this.selectedPartIndex].redo = [];
      this.redoFlag = true;
      if (this.partStates[this.selectedPartIndex].state) {
        this.partStates[this.selectedPartIndex].undo.push(
          this.partStates[this.selectedPartIndex].state
        );
        this.undoFlag = false;
      }
      this.partStates[this.selectedPartIndex].state = await this.saveStateData(
        this.partStates[this.selectedPartIndex]
      );
    },
    async replayState(playStack, saveStack) {
      saveStack.push(this.partStates[this.selectedPartIndex].state);
      this.partStates[this.selectedPartIndex].state = playStack.splice(
        -1,
        1
      )[0];
      // playStack[playStack.lenght-1]
      const ref = await this.productFabric.canvas.clear()
      // ref.canvas.clear();
      let vm = this;
      let svgCode = null;
      svgCode = this.partStates[this.selectedPartIndex].state;
      if (this.checkEncodeURI(svgCode)) svgCode = this.decodeSvg(svgCode);

      // check svg code has NaN
      let hasNaN = this.checkSvgHasNaN(svgCode);
      if (hasNaN) {
        await this.saveIncident({
          errorObject: {message: "Product svg contained NaN string", part_name: this.partStates[this.selectedPartIndex].name, session_id: this.builderSessionId },
          title: "Personalize product svg error",
          description: "Personalize product svg has NaN ",
        });
      }

      fabric.loadSVGFromString(
        svgCode,
        async function (objects, options) {
          vm.partStates[vm.selectedPartIndex].canvasWidth = options.width;
          vm.partStates[vm.selectedPartIndex].canvasHeight = options.height;
          vm.canvasOriginalHeight = options.height;
          vm.canvasOriginalWidth = options.width;
          if (objects) {
            await vm.updateCanvas(vm.productFabric.canvas, objects);
            await vm.resize();
          }
        },
        this.reviver
      );
      svgCode = btoa(unescape(encodeURIComponent(svgCode)));
      await this.updateBuilderSessionItem({ svgCode });
    },
    async saveStateData(obj) {
      if (this.productFabric) {
        let svgCode = this.productFabric.canvas.toSVG({
          width: (obj.canvasWidth * 72) / fabric.DPI + "pt",
          height: (obj.canvasHeight * 72) / fabric.DPI + "pt",
          viewBox: {
            x: 0,
            y: 0,
            width: obj.canvasWidth,
            height: obj.canvasHeight,
          },
        });

        svgCode = btoa(unescape(encodeURIComponent(svgCode)));
        await this.updateBuilderSessionItem({ svgCode });

        return svgCode;
      }
    },
    undoOperations() {
      this.replayState(
        this.partStates[this.selectedPartIndex].undo,
        this.partStates[this.selectedPartIndex].redo
      );
    },
    redoOperations() {
      this.replayState(
        this.partStates[this.selectedPartIndex].redo,
        this.partStates[this.selectedPartIndex].undo
      );
    },
    onResetDialogCancel() {
      this.confirmationDialogFlag = false;
    },
    resetOperation() {
      this.confirmationDialogFlag = true;
      this.confirmation = {
        confirmationMessage: "Are you sure you want to reset your changes ?",
      };
    },
    async resetSVGContent() {
      try {
        this.canvasLoadingInProgress = true;
        //if (action.submit) {
        this.selectedPart = this.selectedPart.id
          ? this.selectedPart
          : this.selectedPart.value;
        this.partStates[this.selectedPartIndex].state = null;
        this.partStates[this.selectedPartIndex].undo = [];
        this.partStates[this.selectedPartIndex].redo = [];

        this.isSaveYourDesignClicked = true; //saving svg code immediately to s3
        let svgCode = null;
        let part = null;
        let isAlternateSurface = false;

        for (let i = 0; i < this.orignalParts.length; i++) {
          part = this.orignalParts[i];
          this.selectedPartIndex = i;
          if (part.id == this.selectedPart.id) break;
        }

        if (this.partSurface.type == "surface") {
          svgCode = part.svg;
        } else if (this.partSurface.type == "alternateSurface") {
          isAlternateSurface = true;
          svgCode = part.alternateSvg;
        }
        if (!this.checkEncodeURI(svgCode))
          svgCode = btoa(unescape(encodeURIComponent(svgCode)));

        await this.updateBuilderSessionItem({ svgCode });
        await this.changeCanvas();
        this.canvasLoadingInProgress = false;
        //}
        this.confirmationDialogFlag = false;
      } catch (err) {
        console.error("Error in reset svg ", err, { sessionId: this.builderSessionId, sku:this.productSku });
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
      }
    },
    async updateCanvas(canvas, objects) {
      try {
        this.canvasLoadingInProgress = true;
        this.disabledElementTab = true;
        this.optionalTextElements = [];
        this.inProgress = true;
        let fonts = [];
        let filteredObjects = [];
        await this.productFabric.canvas.clear();
        canvas.preserveObjectStacking = true;
        for (let i = 0; i < objects.length; i++) {
          let obj = objects[i];
          if (obj.get("type") == "text") {
            let svgFonts = obj.get("dataFont");
            if (svgFonts) {
              let fontIds = [];
              svgFonts.fontOptions.filter((objFont) => {
                if (objFont.name !== "Select font") {
                  //let obj = Object.assign({}, objFont);
                  fonts.push(objFont);
                  fontIds.push(objFont.id);
                  //this.allSVGFonts.push(obj);
                }
              });
            }
          } else if (obj.dataType == "monogram") {
            let svgMonograms = obj.get("dataMonogram");
            if (svgMonograms) {
              let monogramIds = [];
              obj.dataMonogram.monogramOptions.filter((monoObj) => {
                if (monoObj.name !== "Select monogram" && monoObj.id) {
                  monogramIds.push(monoObj.id);
                  fonts.push(monoObj);
                }
              });
              if (monogramIds.length) {
                this.$store
                  .dispatch("productOne/fetchMonogramByIds", monogramIds)
                  .then((monograms) => {
                    if (!monograms) monograms = [];
                    obj.dataMonogram.monogramOptions = monograms;
                  });
              }
            }
          } else if (obj.get("dataType") === "paper") {
            if (obj.dataPaper && obj.dataPaper.paperOptions) {
              if (obj.dataSelectedPaperType) {
                let paperId = [];
                paperId.push(obj.dataSelectedPaperType.id);
                this.$store
                  .dispatch("productOne/fetchPaperByIds", paperId)
                  .then((paper) => {
                    let colorHex = paper[0].colorHex
                      ? paper[0].colorHex.trim()
                      : "#FFFFFF";
                    if (colorHex.indexOf("#") == -1) colorHex = "#" + colorHex;

                    paper[0].colorHex = colorHex;
                    paper[0].currentSelectedSurface =
                      obj.dataSelectedPaperType.currentSelectedSurface;
                    obj.dataSelectedPaperType = paper[0];
                  });
              }

              let paperIds = [];
              obj.dataPaper.paperOptions.filter((paperObj) => {
                paperIds.push(paperObj.id);
              });
              if (paperIds.length) {
                this.$store
                  .dispatch("productOne/fetchPaperByIds", paperIds)
                  .then((papers) => {
                    let sortedPaper = [];
                    for (
                      let i = 0;
                      i < obj.dataPaper.paperOptions.length;
                      i++
                    ) {
                      for (let j = 0; j < papers.length; j++) {
                        if (obj.dataPaper.paperOptions[i].id == papers[j].id) {
                          // need to change here for current surface id
                          papers[j].currentSelectedSurface =
                            obj.dataPaper.paperOptions[
                              i
                            ].currentSelectedSurface;
                          sortedPaper.push(papers[j]);
                          break;
                        }
                      }
                    }
                    obj.dataPaper.paperOptions = sortedPaper;
                  });
              }
            }
          }
          if (obj.dataRequired == "NotValid") {
            continue;
          }
          filteredObjects.push(obj);
        }
        this.allObjects = filteredObjects;

        if (fonts.length > 0) this.createDynamicStyleTag(fonts);
        else this.loadAllObjectsInCanvas();
        this.scrollWindow = false;
        // this.canvasLoadingInProgress = false;
      } catch (err) {
        console.error("Error in update canvas ", err, { sessionId: this.builderSessionId, sku:this.productSku });
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.canvasLoadingInProgress = false;
        this.inProgress = false;
      }
    },
    reviver(ele, obj) {
      try {
        if (ele.getAttribute("data-font")) {
          obj.dataFont = JSON.parse(
            ele.getAttribute("data-font").replace(/'/g, '"')
          );
        }

        if (ele.getAttribute("data-selected-font-family")) {
          obj.dataSelectedFontFamily = ele.getAttribute(
            "data-selected-font-family"
          );
        }

        if (ele.getAttribute("data-selected-font-size")) {
          obj.dataSelectedFontSize = ele.getAttribute(
            "data-selected-font-size"
          );
        }

        if (ele.getAttribute("data-selected-letter-spacing")) {
          obj.dataSelectedLetterSpacing = ele.getAttribute(
            "data-selected-letter-spacing"
          );
        }
        if (ele.getAttribute("data-selected-line-height")) {
          obj.dataSelectedLineHeight = ele.getAttribute(
            "data-selected-line-height"
          );
        }

        if (ele.getAttribute("data-monogram")) {
          obj.dataMonogram = JSON.parse(
            ele.getAttribute("data-monogram").replace(/'/g, '"')
          );
        }
        if (ele.getAttribute("data-selected-monogram")) {
          obj.dataSelectedMonogram = JSON.parse(
            ele.getAttribute("data-selected-monogram").replace(/'/g, '"')
          );
        }
        if (ele.getAttribute("data-selected-monogram-name")) {
          obj.dataSelectedMonogramName = ele.getAttribute(
            "data-selected-monogram-name"
          );
        }
        if (ele.getAttribute("data-selected-monogram-point-size")) {
          obj.dataSelectedMonogramPointSize = ele.getAttribute(
            "data-selected-monogram-point-size"
          );
        }
        if (ele.getAttribute("data-selected-monogram-letter-count")) {
          obj.dataSelectedMonogramLetterCount = ele.getAttribute(
            "data-selected-monogram-letter-count"
          );
        }

        if (ele.getAttribute("data-monogram-user-text")) {
          obj.dataMonogramUserText = unescape(
            ele.getAttribute("data-monogram-user-text")
          );
        }
        if (ele.getAttribute("data-monogram-user-preview-text")) {
          obj.dataMonogramUserPreviewText = unescape(
            ele.getAttribute("data-monogram-user-preview-text")
          );
        }
        if (
          ele.getAttribute("data-monogram-characters") &&
          JSON.parse(
            ele.getAttribute("data-monogram-characters").replace(/'/g, '"')
          ) instanceof Array
        ) {
          obj.dataMonogramCharacters = JSON.parse(
            ele.getAttribute("data-monogram-characters").replace(/'/g, '"')
          );
          obj.dataMonogramCharacters = obj.dataMonogramCharacters.map((char) =>
            unescape(char)
          );
        }
        if (ele.getAttribute("data-h-anchor")) {
          obj.dataHAnchor = unescape(ele.getAttribute("data-h-anchor"));
        }
        if (ele.getAttribute("data-v-anchor")) {
          obj.dataVAnchor = unescape(ele.getAttribute("data-v-anchor"));
        }

        if (ele.getAttribute("data-align-property")) {
          obj.dataAlignProperty = JSON.parse(
            ele.getAttribute("data-align-property").replace(/'/g, '"')
          );
        }

        if (ele.getAttribute("data-print")) {
          obj.dataPrint = JSON.parse(
            ele.getAttribute("data-print").replace(/'/g, '"')
          );
        }

        if (ele.getAttribute("data-selected-print-type")) {
          obj.dataSelectedPrintType = ele.getAttribute(
            "data-selected-print-type"
          );
        }

        if (ele.getAttribute("data-max-lines")) {
          obj.dataMaxLines = ele.getAttribute("data-max-lines");
        }

        if (ele.getAttribute("data-max-width")) {
          obj.dataMaxWidth = ele.getAttribute("data-max-width");
        }

        if (ele.getAttribute("data-type")) {
          obj.dataType = ele.getAttribute("data-type");
        }
        if (ele.getAttribute("data-color")) {
          obj.dataColor = JSON.parse(
            ele.getAttribute("data-color").replace(/'/g, '"')
          );
        }

        if (ele.getAttribute("default-color")) {
          obj.defaultColor = JSON.parse(
            ele.getAttribute("default-color").replace(/'/g, '"')
          );
        }

        if (ele.getAttribute("data-width")) {
          obj.dataWidth = ele.getAttribute("data-width");
        }

        if (ele.getAttribute("data-height")) {
          obj.dataHeight = ele.getAttribute("data-height");
        }
        if (ele.getAttribute("data-element-name")) {
          obj.dataElementName = unescape(ele.getAttribute("data-element-name"));
        }

        if (ele.getAttribute("data-is-style-linked")) {
          obj.dataIsStyleLinked = unescape(
            ele.getAttribute("data-is-style-linked")
          );
        }
        if (ele.getAttribute("data-color-style")) {
          obj.dataColorStyle = unescape(ele.getAttribute("data-color-style"));
        }
        if (ele.getAttribute("data-process-style")) {
          obj.dataProcessStyle = unescape(
            ele.getAttribute("data-process-style")
          );
        }
        if (ele.getAttribute("data-font-style")) {
          obj.dataFontStyle = unescape(ele.getAttribute("data-font-style"));
        }

        if (ele.getAttribute("data-paper")) {
          obj.dataPaper = JSON.parse(
            ele.getAttribute("data-paper").replace(/'/g, '"')
          );
        }

        if (ele.getAttribute("data-finish")) {
          obj.dataFinish = JSON.parse(
            ele.getAttribute("data-finish").replace(/'/g, '"')
          );
        }

        if (ele.getAttribute("data-color-tags")) {
          obj.dataColorTags = JSON.parse(
            ele.getAttribute("data-color-tags").replace(/'/g, '"')
          );
        }

        if (ele.getAttribute("data-image")) {
          obj.dataImage = JSON.parse(
            ele.getAttribute("data-image").replace(/'/g, '"')
          );
        }

        if (ele.getAttribute("data-selected-print-type")) {
          obj.dataSelectedPrintType = ele.getAttribute(
            "data-selected-print-type"
          );
        }
        if (ele.getAttribute("data-selected-print-short-name")) {
          obj.dataSelectedPrintShortName = ele.getAttribute(
            "data-selected-print-short-name"
          );
        }

        if (ele.getAttribute("data-left")) {
          obj.dataLeft = ele.getAttribute("data-left");
        }

        if (ele.getAttribute("data-top")) {
          obj.dataTop = ele.getAttribute("data-top");
        }
        if (ele.getAttribute("data-bottom")) {
          obj.dataBottom = ele.getAttribute("data-bottom");
        }
        if (ele.getAttribute("data-line-height")) {
          obj.dataLineHeight = ele.getAttribute("data-line-height");
        }
        if (
          ele.getAttribute("data-text") &&
          JSON.parse(
            ele.getAttribute("data-text").replace(/'/g, '"')
          ) instanceof Array
        ) {
          obj.dataText = JSON.parse(
            ele.getAttribute("data-text").replace(/'/g, '"')
          );
          obj.dataText = obj.dataText.map((text) => unescape(text));
        }

        if (ele.getAttribute("data-motif")) {
          obj.dataMotif = JSON.parse(
            ele.getAttribute("data-motif").replace(/'/g, '"')
          );
        }

        if (ele.getAttribute("data-selected-motif-name")) {
          obj.dataSelectedMotifName = ele.getAttribute(
            "data-selected-motif-name"
          );
        }

        if (ele.getAttribute("data-selected-motif")) {
          obj.dataSelectedMotif = JSON.parse(
            ele.getAttribute("data-selected-motif").replace(/'/g, '"')
          );
        }

        if (ele.getAttribute("data-selected-motif-url")) {
          obj.dataSelectedMotifUrl = ele.getAttribute(
            "data-selected-motif-url"
          );
        }

        if (ele.getAttribute("data-selected-paper-type")) {
          obj.dataSelectedPaperType = JSON.parse(
            ele.getAttribute("data-selected-paper-type").replace(/'/g, '"')
          );
        }

        if (ele.getAttribute("data-required")) {
          obj.dataRequired = ele.getAttribute("data-required");
        }
        if (ele.getAttribute("data-optional")) {
          obj.dataOptional = ele.getAttribute("data-optional");
        }
        if (ele.getAttribute("data-optional-added")) {
          obj.dataOptionalAdded = ele.getAttribute("data-optional-added");
        }

        if (
          ele.getAttribute("data-default-text") &&
          JSON.parse(
            ele.getAttribute("data-default-text").replace(/'/g, '"')
          ) instanceof Array
        ) {
          obj.dataDefaultText = JSON.parse(
            ele.getAttribute("data-default-text").replace(/'/g, '"')
          );
          obj.dataDefaultText = obj.dataDefaultText.map((text) =>
            unescape(text)
          );
        }

        if (ele.getAttribute("data-visible")) {
          obj.dataVisible = ele.getAttribute("data-visible");
        }
        if (ele.getAttribute("data-is-modified")) {
          obj.dataIsModified = ele.getAttribute("data-is-modified");
        }

        if (ele.getAttribute("data-img-bottom")) {
          obj.dataImgBottom = ele.getAttribute("data-img-bottom");
        }

        if (ele.getAttribute("data-img-right")) {
          obj.dataImgRight = ele.getAttribute("data-img-right");
        }
        if (ele.getAttribute("data-clipart")) {
          obj.dataClipart = JSON.parse(
            ele.getAttribute("data-clipart").replace(/'/g, '"')
          );
        }
        if (ele.getAttribute("data-existing-die")) {
          obj.dataExistingDie = ele.getAttribute("data-existing-die");
        }

        if (ele.getAttribute("data-default-colour")) {
          obj.dataDefaultColour = ele.getAttribute("data-default-colour");
        }

        if (
          ele.getAttribute("data-surface") &&
          typeof ele.getAttribute("data-surface") != "string"
        ) {
          obj.dataSurface = JSON.parse(
            ele.getAttribute("data-surface").replace(/'/g, '"')
          );
        } else if (ele.getAttribute("data-surface")) {
          obj.dataSurface = ele.getAttribute("data-surface");
        }
      } catch (error) {
        console.log("error", error);
      }

      return;
    },
    setSelectedPaper(paper) {
      this.selectedPaper = paper;
      this.selectedPaperText = paper.text;
      this.selectedPaperType = paper.texture;
      this.selectedWeight = paper.weight;
      this.setPaper();
      this.mobileTabOptions = false;
    },

    openEditTextarea() {
      this.editTextareaModal = true;
    },
    async resize() {
      this.canvasRefreshKey = !this.canvasRefreshKey;
      if (!this.productFabric) return;
      const ref = this.productFabric;
      const canvas = this.productFabric.canvas;
      let containerWidth = this.$refs.canvasContainer
        ? this.$refs.canvasContainer.clientWidth
        : 300;
      let extraHeight = 150;
      let containerHeight = window.innerHeight - extraHeight;

      this.scaleRatio = 1;
      if (this.activeFinishTab) {
        this.scaleRatio = Math.min(
          containerWidth / this.envelopeObject.width,
          containerHeight / this.envelopeObject.height
        );
        ref.canvas.setDimensions({
          width: this.envelopeObject.width * this.scaleRatio,
          height: this.envelopeObject.height * this.scaleRatio,
        });
        ref.canvas.setZoom(this.scaleRatio);
      } else {
        if (!this.partStates[this.selectedPartIndex].canvasWidth) return;
        let canvasWidth = this.partStates[this.selectedPartIndex].canvasWidth;
        let canvasHeight = this.partStates[this.selectedPartIndex].canvasHeight;

        this.scaleRatio = Math.min(
          containerWidth / canvasWidth,
          containerHeight / canvasHeight
        );
        canvas.setDimensions({
          width: canvasWidth * this.scaleRatio,
          height: canvasHeight * this.scaleRatio,
        });
        canvas.setZoom(this.scaleRatio);
      }

      await canvas.requestRenderAll();
      this.sideBarHeight();
    },
    sideBarHeight() {
      this.tabsHeight = window.innerHeight;
      this.canvasDivHeight = window.innerHeight - 100;
      let sideBarWidth = this.$refs.sideBar
        ? this.$refs.sideBar.clientWidth
        : 0;
      this.canvasDivWidth = window.innerWidth - (sideBarWidth + 100);
    },
    fetchSelectedPartIndex() {
      let index;
      let len = Object.keys(this.partStates).length;
      for (let i = 0; i < len; i++) {
        if (this.selectedPart.id == this.partStates[i].id) {
          index = i;
          break;
        }
      }
      return index;
    },
    async uploadCanvasAsImage() {
      try {
        let vm = this;
        this.inProgress = true;
        let base64String = this.productFabric.canvas.toDataURL({
          format: "png",
        });

        let svgCode = this.productFabric.canvas.toSVG();
        vm.$snotify.info("Please wait for item refresh", {
          position: "rightTop",
          showProgressBar: false,
        });

        vm.ownerId = this.getSessionItemOwner(this.sessionOneDetails);
        let data = await this.$store.dispatch("sessionItemOne/fetchOne", {
          sessionId: this.builderSessionId,
          itemId: vm.part.id,
          surface: vm.currentPartSurface.id,
          surfaceType: vm.currentPartSurface.type,
        });

        this.currentBuilderSessionItem = data.response;

        this.generatedPartName =
          vm.productSku +
          "_" +
          vm.part.id +
          "_" +
          vm.currentPartSurface.type +
          "_" +
          vm.currentPartSurface.id +
          "_" +
          vm.ownerId +
          "_" +
          this.currentTimeStamp;
        let imgName =
          imageFolderName + "images/" + this.generatedPartName + ".png";
        let contentType = "image/png";

        // commented out while re-designing in vuetify 3

        base64String = new Buffer.from(
          base64String.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );

        await vm.$store.dispatch("imageOne/uploadToS3", {
          bucketName: "cdn.crane.com",
          bucketRegion: "us-east-1",
          name: imgName,
          type: contentType,
          data: base64String,
        });
        vm.url = `${s3URL}/${imgName}`;

        await this.updateBuilderSessionItem({ thumbnailUrl: vm.url });
        await vm.resize();
        this.inProgress = false;
      } catch (err) {
        console.error(err);
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
      }
    },
    getSessionItemOwner(sessionItemObj) {
      if (sessionItemObj && sessionItemObj.userData) {
        if (this.userAuth) {
          return sessionItemObj.userData.customerId;
        } else {
          return sessionItemObj.userData.guestId;
        }
      }
      return null;
    },
    async changePartSurface() {
      this.selectedPart = this.selectedPart.id
        ? this.selectedPart
        : this.selectedPart.value;
      this.selectedPartIndex = this.fetchSelectedPartIndex();
      await this.saveState(); //saving current part to s3          
      await this.getCurrentSessionPartStructure();
      if (this.partSurface.type == "alternateSurface"){
        this.selectedPartIndex += 1;
      }
      await this.changeCanvas(true);
    },
    async changePartSurfaceMobile(partSelected) {
      this.selectedPart = partSelected.selectedPart;
      this.partSurface = partSelected.partSurface;
      this.dataSurface = this.partSurface;

      this.selectedPartIndex = this.fetchSelectedPartIndex();
      if (this.partSurface.type == "alternateSurface")
        this.selectedPartIndex += 1;
      await this.changeCanvas(true);
    },
    async changeCanvasInMobile(partSelected) {
      this.selectedPart = partSelected.selectedPart;
      this.partSurface = partSelected.partSurface;
      this.dataSurface = this.partSurface;
      await this.changeCanvas();
    },
    async changeCanvas(partSurfaceChanged) {
      try {
        this.canvasLoadingInProgress = true;
        let vm = this;
        
        //canvas will save to s3 when user change a part only from dropdown
        // other times canvas were saved when user clicked NEXT btn
        if (partSurfaceChanged == "partChanged") {
          this.isSaveYourDesignClicked = true;
          await this.removedObjectFromCanvas();
          await this.selectZeroTypeElement("paper");
          await this.saveState(); //saving current part to s3          
          await this.getCurrentSessionPartStructure();
        }
        //save canvas in API
        await this.uploadCanvasAsImage();
        await this.productFabric.canvas.clear();   // clear the current canvas
        this.colorMismatched = false;
        this.mismatchedColorGranted = false;
        this.modifiedTextGranted = false;
        this.isNewSvgLoaded = false;
        this.noModifiedText = false;
        this.optionalTextElements = [];
        this.part = this.selectedPart;
        this.scrollWindow = false;

        if (!partSurfaceChanged || partSurfaceChanged == "partChanged") {
          this.selectedPartSurfaces = [];
          if (this.selectedPart) {
            let surfaceObj = await this.fetchSurfaceObject(
              this.selectedPart.surfaceId,
              "surface"
            );
            this.selectedPartSurfaces.push(surfaceObj);
            if (this.selectedPart.alternateSurfaceId) {
              let alternateSurfaceObj = await this.fetchSurfaceObject(
                this.selectedPart.alternateSurfaceId,
                "alternateSurface"
              );
              this.selectedPartSurfaces.push(alternateSurfaceObj);
            }
          }
          this.partSurface = this.selectedPartSurfaces[0];
          this.dataSurface = this.partSurface;

          this.selectedPartIndex = this.fetchSelectedPartIndex();
        }
        this.currentPartSurface = this.partSurface;
        this.dataSurface = this.partSurface;

        // when part is changed
        let data = await this.$store.dispatch("sessionItemOne/fetchOne", {
          sessionId: this.builderSessionId,
          itemId: this.selectedPart.id,
          surface:  this.partSurface.id, // this.selectedPart.surfaceId,
          surfaceType: this.partSurface.type //"surface",
        });
        this.currentBuilderSessionItem = data.response;

        this.imageTabDisable = true;
        this.disableFinishTab = true;
        this.activeFinishTab = false;
        this.hideMotifsTab = true;
        this.hideMonogramTab = true;
        // if (this.partStates[this.selectedPartIndex].state) {
        //   await this.productFabric.canvas.clear()
        //   let svgCode = null;
        //   if (this.checkEncodeURI(vm.partStates[vm.selectedPartIndex].state))
        //     svgCode = this.decodeSvg(vm.partStates[vm.selectedPartIndex].state);
        //   else svgCode = vm.partStates[vm.selectedPartIndex].state;

        //   // check svg code has NaN
        //   let hasNaN = this.checkSvgHasNaN(svgCode);
        //   if (hasNaN) {
        //     await this.saveIncident({
        //       errorObject: {message: "Product svg contained NaN string", part_name: vm.partStates[vm.selectedPartIndex].name, session_id: this.builderSessionId },
        //       title: "Personalize product svg error",
        //       description: "Personalize product svg has NaN ",
        //     });
        //     // this.setCorruptedSessionData()
        //   }

        //   fabric.loadSVGFromString(
        //     svgCode,
        //     async function (objects, options) {
        //      await vm.productFabric.canvas.clear();
        //       vm.partStates[vm.selectedPartIndex].canvasWidth = options.width;
        //       vm.partStates[vm.selectedPartIndex].canvasHeight = options.height;
        //       vm.canvasOriginalHeight = options.height;
        //       vm.canvasOriginalWidth = options.width;
        //       if (objects) {
        //         await vm.updateCanvas(vm.productFabric.canvas, objects);
        //         await vm.resize();
        //         vm.productFabric.canvas.requestRenderAll()
        //       }
        //     },
        //     this.reviver
        //   );
        // } else {
          let svgCode = null;
          svgCode = this.currentBuilderSessionItem.svgContent; //localStorage.getItem(`cr_${this.productSku}_${this.selectedPart.id}`);
          let crUniqueId = this.getSessionItemOwner(
            this.currentBuilderSessionItem
          ); // localStorage.getItem(uniqueId);

          let defaultSvg = "";
          if (this.partSurface.type === "alternateSurface") {
            let data = await this.$store.dispatch("sessionItemOne/fetchOne", {
              sessionId: this.builderSessionId,
              itemId: this.selectedPart.id,
              surface: this.selectedPart.alternateSurfaceId,
              surfaceType: "alternateSurface",
            });

            this.currentBuilderSessionItem = data.response;
            defaultSvg = this.currentBuilderSessionItem.svgContent; // this.selectedPart.alternateSvg;
          } else defaultSvg = this.currentBuilderSessionItem.svgContent; // this.selectedPart.svg;

          if (this.partSurface.type === "alternateSurface") {
            this.isNewSvgLoaded = true;
            if (this.checkEncodeURI(defaultSvg))
              svgCode = this.decodeSvg(defaultSvg);
            else svgCode = defaultSvg;

            // check svg code has NaN
            let hasNaN = this.checkSvgHasNaN(svgCode);
            if (hasNaN) {
              await this.saveIncident({
                errorObject: {message: "Product svg (alternateSurface) contained NaN string", part_name: this.selectedPart.name, session_id: this.builderSessionId },
                title: "Personalize product svg error",
                description: "Personalize product svg has NaN ",
              });
            }

            await this.updateBuilderSessionItem({
              svgCode: btoa(unescape(encodeURIComponent(svgCode))),
            });
          } else {
            svgCode = this.decodeSvg(svgCode);
            this.isNewSvgLoaded = true;

            // check svg code has NaN
            let hasNaN = this.checkSvgHasNaN(svgCode);
            if (hasNaN) {
              await this.saveIncident({
                errorObject: {message: "Product svg contained NaN string", part_name: this.selectedPart.name, session_id: this.builderSessionId },
                title: "Personalize product svg error",
                description: "Personalize product svg has NaN ",
              });
              // this.setCorruptedSessionData()
            }

            await this.updateBuilderSessionItem({
              svgCode: btoa(unescape(encodeURIComponent(svgCode))),
            });
          }

          // check svg code has NaN
          let hasNaN = this.checkSvgHasNaN(svgCode);
          if (hasNaN) {
            await this.saveIncident({
              errorObject: {message: "Product svg contained NaN string", part_name: this.selectedPart.name, session_id: this.builderSessionId },
              title: "Personalize product svg error",
              description: "Personalize product svg has NaN ",
            });
            // this.setCorruptedSessionData()
          }

          fabric.loadSVGFromString(
            new String(svgCode),
            async function (objects, options) {
              await vm.productFabric.canvas.clear();
              vm.partStates[vm.selectedPartIndex].canvasWidth = options.width;
              vm.partStates[vm.selectedPartIndex].canvasHeight = options.height;
              vm.canvasOriginalHeight = options.height;
              vm.canvasOriginalWidth = options.width;
              if (objects) {
                await vm.updateCanvas(vm.productFabric.canvas, objects);
                await vm.resize();
              }
            },
            this.reviver
          );
       // }
        await this.resize();
      //  this.canvasLoadingInProgress = false;
      } catch (err) {
        console.error("Error in change canvas ",err, {  sessionId: this.builderSessionId, sku:this.productSku });
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
        this.canvasLoadingInProgress = false;

        throw err;
      }
    },
    async updateSelected(canvasObject, data, property) {
      try {
        let vm = this;
        if (property === "font") {
          let selectedFontArray = canvasObject.dataFont.fontOptions.filter(
            function (font) {
              if (font.name == data.name) return font;
            }
          );
          this.clearFabricFontCache(data.name);
          this.fontMin = selectedFontArray[0].fontMin;
          this.fontMax = selectedFontArray[0].fontMax;
          this.textFontSizeOptions = getFontSizeOptions(selectedFontArray[0]);
          let fontSize = fabric.util.parseUnit(
            Number(data.defaultValue) + "pt"
          );
          let parsedSpacing =
            (fabric.util.parseUnit(Number(data.letterSpacing), fontSize) /
              fontSize) *
            1000;
          canvasObject.set({
            fontSize: fontSize,
            fontFamily: data.name,
            charSpacing: parsedSpacing,
            stroke: canvasObject.fill,
            lineHeight: data.lineHeight
              ? data.lineHeight
              : canvasObject.dataLineHeight,
            dataSelectedFontFamily: data.name,
            dataSelectedFontSize: data.defaultValue,
            dataSelectedLetterSpacing: data.letterSpacing,
            dataSelectedLineHeight: data.lineHeight,
          });
          canvasObject._getLeftOffset();
        } else if (property === "monogram") {
          if (data && data.name && data.name != "Select Monogram") {
            canvasObject.visible = true;
            canvasObject.dataVisible = true;

            let monogram = await vm.setMonogramOnCanvas(
              canvasObject,
              data,
              false
            );
            let canvas = this.productFabric.canvas;

            let indexPosition = -1;
            let allObj = canvas.getObjects();
            for (let i = 0; i < allObj.length; i++) {
              if (JSON.stringify(allObj[i]) === JSON.stringify(canvasObject)) {
                indexPosition = i;
                break;
              }
            }
            await canvas.insertAt(monogram, indexPosition, true);

            canvas.setActiveObject(monogram);
            canvas.requestRenderAll();
            canvasObject = monogram;
            this.userMadeChangesInSvg();
          }
        } else if (property === "textAlign")
          canvasObject.set("textAlign", data);
        else if (property === "lineHeight") {
        } else if (property === "motif") {
          canvasObject.visible = true;
          canvasObject.dataVisible = true;
          let motif = await this.setMotifOnCanvas(canvasObject, data);
          motif.set({
            dataIsModified: true,
          });

          const ref = this.productFabric;
          let indexPosition = -1;
          let allObj = ref.canvas.getObjects();
          for (let i = 0; i < allObj.length; i++) {
            if (
              JSON.stringify(allObj[i]) === JSON.stringify(this.selectedCanvas)
            ) {
              indexPosition = i;
              break;
            }
          }
          await ref.canvas.insertAt(motif, indexPosition, true);

          this.selectedCanvas = motif;

          ref.canvas.setActiveObject(motif);
          this.selectedCanvas.setCoords();
          this.userMadeChangesInSvg();
        } else if (property === "imgBottom") {
          let bottomInPixel = fabric.util.parseUnit(Number(data) + "pt");
          let topInPixel = canvasObject.top;
          let height = bottomInPixel - topInPixel;
          canvasObject.set({
            dataHeight: (height * 72) / fabric.DPI,
            scaleY: height / canvasObject.height,
          });
        } else if (property === "imgRight") {
          let rightInPixel = fabric.util.parseUnit(Number(data) + "pt");
          let leftInPixel = canvasObject.left;
          let width = rightInPixel - leftInPixel;
          canvasObject.set({
            dataWidth: (width * 72) / fabric.DPI,
            scaleX: width / canvasObject.width,
          });
        } else canvasObject.set(property, fabric.util.parseUnit(data + "pt"));

        canvasObject.dataText = canvasObject.textLines;
        canvasObject.setCoords();
        this.productFabric.canvas.renderAll();
      } catch (err) {
        console.error(err);
        this.$snotify.error(`Cloud communication error`, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;

        window.location.reload();
      }
    },
    calculateLineWidth(textObject, characterSpacing) {
      let totalWidth = 0;
      let characters = textObject.text.split("");
      let fontSize = textObject.fontSize;
      let font = new fabric.Text("", {
        fontSize: fontSize,
        fontFamily: textObject.fontFamily,
      });

      characters.forEach(function (char) {
        font.text = char;
        totalWidth += font.width * (fontSize / font.fontSize); // Adjusting for font size changes
        totalWidth += characterSpacing;
      });
      // Remove the last extra spacing from the last character
      totalWidth -= characterSpacing;

      return totalWidth;
    },
    updateTextFont(canvasObject, data) {
      try {
        if (
          data &&
          canvasObject &&
          canvasObject.dataFont &&
          canvasObject.dataFont.fontOptions
        ) {
          let oldHeight = canvasObject.height;
          let selectedFontArray = canvasObject.dataFont.fontOptions.filter(
            function (font) {
              if (font.name == data.name) return font;
            }
          );
          this.clearFabricFontCache(data.name);
          if (selectedFontArray.length) {
            this.fontMin = selectedFontArray[0].fontMin;
            this.fontMax = selectedFontArray[0].fontMax;
            this.textFontSizeOptions = getFontSizeOptions(selectedFontArray[0]);
            data.letterSpacing = data.letterSpacing ? data.letterSpacing : 0;
            canvasObject.dataStrokeWidth = selectedFontArray[0].strokeWidth;
          }
          let fontSize = fabric.util.parseUnit(
            Number(data.defaultValue) + "pt"
          );
          let parsedSpacing =
            (fabric.util.parseUnit(Number(data.letterSpacing), fontSize) /
              fontSize) *
            1000;

          canvasObject.set({
            fontSize: fontSize,
            fontFamily: data.name,
            charSpacing: parsedSpacing
              ? parsedSpacing
              : canvasObject.charSpacing
              ? canvasObject.charSpacing
              : 0,

            stroke: canvasObject.fill,
            lineHeight: data.lineHeight
              ? data.lineHeight
              : canvasObject.dataLineHeight
              ? canvasObject.dataLineHeight
              : 1.16,
            dataSelectedFontFamily: data.name,
            dataSelectedFontSize: data.defaultValue,
            dataSelectedLetterSpacing: data.letterSpacing,
            dataSelectedLineHeight: data.lineHeight
              ? data.lineHeight
              : canvasObject.dataLineHeight
              ? canvasObject.dataLineHeight
              : 1.16,
          });
          canvasObject._getLeftOffset();

          if (
            canvasObject.dataAlignProperty &&
            canvasObject.dataAlignProperty.anchorAlignment &&
            canvasObject.dataAlignProperty.anchorAlignment === "bottom"
          ) {
            let newHeight = canvasObject.height;
            if (oldHeight > newHeight)
              canvasObject.top += oldHeight - newHeight;
            else if (oldHeight < newHeight)
              canvasObject.top -= newHeight - oldHeight;
            canvasObject.dataTop = (canvasObject.top * 72) / fabric.DPI;
            canvasObject.dataIsModified = true;
          }

          canvasObject.oldLineCount = canvasObject.__lineHeights.length;
          // commentted bcz - on refresh of page text is shifting to upwards
          // canvasObject.dataBottom =
          //   ((canvasObject.top + canvasObject.height) * 72) / fabric.DPI;
          canvasObject.dataText = canvasObject.textLines;

          canvasObject.setCoords();
          this.productFabric.canvas.renderAll();
        }
      } catch (err) {
        console.error(err);
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
        window.location.reload();
        // throw err
      }
    },
    getPathsProperties(obj) {
      let isStyleAvailable =
        obj.get("dataColorStyle") ||
        obj.get("dataProcessStyle") ||
        obj.get("dataFontStyle")
          ? true
          : false;
      let isStyleLinked =
        isStyleAvailable &&
        [undefined, "true", true].includes(obj.get("dataIsStyleLinked"))
          ? true
          : false;

      return {
        selectable: true,
        lockMovementX: true,
        lockMovementY: true,
        hasControls: false,
        hasBorders: true,
        opacity: 1,
        visible: obj.visible,
        borderColor: "#00000",
        dataMotif: obj.get("dataMotif"),
        dataType: obj.get("dataType"),
        dataColor: obj.get("dataColor"),
        defaultColor: obj.get("defaultColor"),
        dataPrint: obj.get("dataPrint"),
        dataWidth: obj.get("dataWidth"),
        dataHeight: obj.get("dataHeight"),
        dataLeft: obj.get("dataLeft"),
        dataTop: obj.get("dataTop"),
        dataText: obj.get("dataText"),
        dataElementName: obj.get("dataElementName"),
        dataIsStyleLinked: isStyleLinked,
        dataColorStyle: obj.get("dataColorStyle"),
        dataProcessStyle: obj.get("dataProcessStyle"),
        dataFontStyle: obj.get("dataFontStyle"),
        dataSelectedPrintType: obj.get("dataSelectedPrintType"),
        dataSelectedMotifName: obj.get("dataSelectedMotifName"),
        dataSelectedMotif: obj.get("dataSelectedMotif"),
        dataIsModified: obj.get("dataIsModified"),
        dataRequired: obj.get("dataRequired"),
        dataVisible: obj.get("dataVisible"),
        dataSelectedPrintShortName: obj.get("dataSelectedPrintShortName"),
        dataHAnchor: obj.get("dataHAnchor"),
        dataVAnchor: obj.get("dataVAnchor"),
      };
    },
    setMotifOnCanvas(selectedObject, newMotif) {
      try {
        return new Promise((resolve, reject) => {
          let vm = this;
          selectedObject.flipY = false;
          let siteUrl = null;
          if (newMotif.dataSelectedMotifUrl)
            siteUrl =
              newMotif.dataSelectedMotifUrl + "?" + new Date().getTime();
          else siteUrl = newMotif.url + "?" + new Date().getTime();

          let motifName = newMotif.name
            ? newMotif.name
            : newMotif.dataSelectedMotifName;

          let motifUrl = newMotif.url
            ? newMotif.url
            : newMotif.dataSelectedMotifUrl;

          let motifObject = newMotif.id ? newMotif : "";
          if (newMotif.dataMotif && newMotif.dataMotif.motifOptions) {
            motifObject = newMotif.dataMotif.motifOptions.filter((obj) => {
              if (obj.name == motifName) return obj;
            });
            motifObject = motifObject[0];
          }

          fabric.loadSVGFromURL(siteUrl, function (objects) {
            if (objects && objects.length > 0) {
              let newLeft = null;
              let newTop = null;

              let width = fabric.util.parseUnit(
                Number(objects[0].width * objects[0].scaleX)
              );
              let height = fabric.util.parseUnit(
                Number(objects[0].height * objects[0].scaleY)
              );

              let widthInPixel = fabric.util.parseUnit(Number(width) + "pt");
              let heightInPixel = fabric.util.parseUnit(Number(height) + "pt");

              let defaultScaleX = widthInPixel / objects[0].width;
              let defaultScaleY = heightInPixel / objects[0].height;

              objects[0].set(vm.getPathsProperties(selectedObject));
              if (selectedObject.dataMotif.fixedMotif == true) {
                newLeft = selectedObject.left;
                newTop = selectedObject.top;
                let widthInPixel = fabric.util.parseUnit(
                  Number(selectedObject.dataWidth) + "pt"
                );
                let heightInPixel = fabric.util.parseUnit(
                  Number(selectedObject.dataHeight) + "pt"
                );

                objects[0].set({
                  scaleX: widthInPixel / objects[0].width,
                  scaleY: heightInPixel / objects[0].height,
                });
              } else {
                objects[0].set({
                  scaleX: defaultScaleX,
                  scaleY: defaultScaleY,
                });

                objects[0].dataMotif.defaultScaleX = Number(defaultScaleX);
                objects[0].dataMotif.defaultScaleY = Number(defaultScaleX);

                const { left, top } = vm.changeAnchor(
                  selectedObject,
                  objects[0]
                );
                newLeft = left;
                newTop = top;
              }

              objects[0].set({
                left: newLeft,
                top: newTop,
                visible: selectedObject.visible,
                dataLeft: selectedObject.dataLeft,
                dataTop: selectedObject.dataTop,
                dataWidth: selectedObject.dataWidth,
                dataHeight: selectedObject.dataHeight,
                fill: selectedObject.fill,
                dataSelectedMotifUrl:
                  selectedObject.dataVisible == "false" ? "" : motifUrl,
                dataSelectedMotifName:
                  selectedObject.dataVisible == "false" ? "" : motifName,
                dataVisible: selectedObject.dataVisible,
                dataSelectedMotif: motifObject,
              });

              resolve(objects[0]);
            } else {
              vm.$snotify.error(
                `Error in selected motifs image, please select another motifs image`,
                {
                  position: "rightTop",
                  showProgressBar: false,
                }
              );
            }
            reject(new Error("Failed"));
          });
        });
      } catch (err) {
        console.error("Error while setting motif on canvas ",err);
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
        window.location.reload();
        // throw err
      }
    },
    async setMonogramOnCanvas(canvasObject, newMonogram, root) {
      try {
        let vm = this;
        return new Promise(async (resolve, reject) => {
          if (newMonogram) {
            let monogramLetters = [];
            if (!canvasObject.dataMonogramUserText) {
              if (
                canvasObject.dataMonogramCharacters &&
                canvasObject.dataMonogramCharacters.length
              ) {
                for (let i = 0; i < newMonogram.lettersCount; i++) {
                  monogramLetters.push(canvasObject.dataMonogramCharacters[i]);
                }
                monogramLetters = monogramLetters
                  .toString()
                  .split(",")
                  .join("");
              } else {
                monogramLetters = await vm.getTextByLetterCount(
                  newMonogram.lettersCount
                );
              }
            } /*eslint-disable*/
            canvasObject.set({
              fontSize: fabric.util.parseUnit(newMonogram.pointSize + "pt"),
              fontFamily: newMonogram.name,
              fill: canvasObject.defaultColor
                ? canvasObject.defaultColor.colorHex
                : "#000",
              text: canvasObject.dataMonogramUserText
                ? canvasObject.dataMonogramUserText
                : monogramLetters,
            });
            try {
              let otfUrl = newMonogram.otfUrl + "?" + new Date().getTime();
              const font = await opentype.load(otfUrl);
              let textForPreview = canvasObject.dataMonogramUserPreviewText
                ? canvasObject.dataMonogramUserPreviewText
                : canvasObject.dataMonogramUserText
                ? canvasObject.dataMonogramUserText
                : canvasObject.text;
              const path = font.getPath(
                textForPreview,
                canvasObject.left,
                canvasObject.top,
                canvasObject.fontSize
              );
              if (path.commands.length) {
                let array = path.commands.map((obj) => Object.values(obj));
                let path1 = new fabric.Path(array);

                path1.set(vm.getMonogramProperties(canvasObject));
                let left, top;
                if (
                  canvasObject.dataMonogram &&
                  canvasObject.dataMonogram.fixedMonogram == true &&
                  !root
                ) {
                  left = canvasObject.left;
                  top = canvasObject.top;
                  path1.set({
                    scaleX:
                      (canvasObject.width * canvasObject.scaleX) / path1.width,
                    scaleY:
                      (canvasObject.height * canvasObject.scaleY) /
                      path1.height,
                  });
                } else {
                  // for 1st monogram and  fixedmonogram==false this logic is applied
                  let position = vm.changeAnchor(canvasObject, path1);
                  left = position.left;
                  top = position.top;
                }
                path1.set({
                  left: left,
                  top: top,
                  dataLeft: (Number(left) * 72) / fabric.DPI,
                  dataTop: (Number(top) * 72) / fabric.DPI,
                  dataSelectedMonogramName:
                    canvasObject.dataVisible == "false" ? "" : newMonogram.name,
                  dataVisible: canvasObject.dataVisible,
                });
                vm.dataTop = (top * 72) / fabric.DPI;
                vm.dataLeft = (left * 72) / fabric.DPI;
                resolve(path1);
              } else {
                vm.$snotify.error(`Error in selected monogram file`, {
                  position: "rightTop",
                  showProgressBar: false,
                });
              }
            } catch (error) {
              vm.$snotify.error(`Error in Monogram Url`, {
                position: "rightTop",
                showProgressBar: false,
              });
              console.log("Monogram error", error);
              reject(new Error("Failed"));
            }
          } else {
            vm.$snotify.error(
              `Error in selected Monogram, please select another monogram`,
              {
                position: "rightTop",
                showProgressBar: false,
              }
            );
          }
          reject(new Error("Failed"));
        });
      } catch (err) {
        console.error("Error while setting motif on canvas ",err);
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;

        window.location.reload();
      }
    },
    getTextByLetterCount(lettersCount) {
      let text = null;
      switch (Number(lettersCount)) {
        case 1:
          text = "a";
          break;
        case 2:
          text = "ab";
          break;
        case 3:
          text = "abc";
          break;
        case 4:
          text = "abcd";
          break;
        case 5:
          text = "abcde";
          break;
        default:
          text = "abc";
      }
      return text;
    },
    getMonogramProperties(obj) {
      let isStyleAvailable =
        obj.get("dataColorStyle") ||
        obj.get("dataProcessStyle") ||
        obj.get("dataFontStyle")
          ? true
          : false;
      let isStyleLinked =
        isStyleAvailable &&
        [undefined, "true", true].includes(obj.get("dataIsStyleLinked"))
          ? true
          : false;

      return {
        left: obj.left,
        top: obj.top,
        fill: obj.fill ? obj.fill : "#515a6d",
        scaleX: 1,
        scaleY: 1,
        visible: obj.visible,
        hasControls: false,
        selectable: true,
        lockMovementX: true,
        lockMovementY: true,
        borderColor: "#00000",
        breakWords: true,
        dataMaxWidth: obj.get("dataMaxWidth"),
        dataPrint: obj.get("dataPrint"),
        dataType: obj.get("dataType"),
        dataWidth: obj.get("dataWidth"),
        dataHeight: obj.get("dataHeight"),
        dataLeft: obj.get("dataLeft"),
        dataTop: obj.get("dataTop"),
        dataElementName: obj.get("dataElementName"),
        dataIsStyleLinked: isStyleLinked,
        dataColorStyle: obj.get("dataColorStyle"),
        dataProcessStyle: obj.get("dataProcessStyle"),
        dataFontStyle: obj.get("dataFontStyle"),
        dataMonogram: obj.get("dataMonogram"),
        dataBottom: obj.get("dataBottom"),
        dataRequired: obj.get("dataRequired"),
        dataColor: obj.get("dataColor"),
        defaultColor: obj.get("defaultColor"),
        dataSelectedMonogram: obj.get("dataSelectedMonogram"),
        dataSelectedMonogramName: obj.get("dataSelectedMonogramName"),
        dataSelectedMonogramPointSize: obj.get("dataSelectedMonogramPointSize"),
        dataSelectedMonogramLetterCount: obj.get(
          "dataSelectedMonogramLetterCount"
        ),
        dataSelectedPrintShortName: obj.get("dataSelectedPrintShortName"),
        dataSelectedPrintType: obj.get("dataSelectedPrintType"),
        dataMonogramUserText: obj.get("dataMonogramUserText"),
        dataMonogramUserPreviewText: obj.get("dataMonogramUserPreviewText"),
        dataMonogramCharacters: obj.get("dataMonogramCharacters"),
        dataHAnchor: obj.get("dataHAnchor"),
        dataVAnchor: obj.get("dataVAnchor"),
        dataVisible: obj.get("dataVisible"),
      };
    },
    setPrintMethodBasedColor(colorObject, printMethod) {
      try {
        let textColor = colorObject.name ? colorObject.name : colorObject;
        let print = this.getPrintShortName(printMethod);
        if (this.colors.length) {
          let colorList = cloneDeep(this.colors);
          let defaultColor = colorList.filter((obj) => {
            if (
              obj.colorName
                ? obj.colorName === textColor
                : obj.name === textColor
            )
              return obj;
          });
          if (defaultColor.length) {
            defaultColor[0].colorHex =
              "#" + defaultColor[0].processHex[print.toLowerCase()];
            defaultColor[0].colorName = defaultColor[0].name;
            return defaultColor[0];
          }
        }
      } catch (err) {
        console.log(err);
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;

        window.location.reload();
      }
    },
    async loadMonogramObject(obj) {
      try {
        let vm = this;
        let newMonogram = "";
        if (obj.dataSelectedMonogramName) {
          newMonogram = obj.dataMonogram.monogramOptions.filter((monogram) => {
            if (monogram.name == obj.dataSelectedMonogramName) return monogram;
          })[0];
        } else {
          newMonogram = obj.dataMonogram.monogramOptions[0];
        }
        if (!obj.dataSelectedPrintType && obj.dataPrint) {
          obj.dataSelectedPrintType = obj.dataPrint.defaultPrintType;
        }
        if (obj.dataSelectedPrintType && obj.dataPrint) {
          if (obj.dataColor || obj.defaultColor) {
            let colorObj = this.setPrintMethodBasedColor(
              obj.dataColor ? obj.dataColor : obj.defaultColor,
              obj.dataSelectedPrintType
                ? obj.dataSelectedPrintType
                : obj.dataPrint.defaultPrintType
            );
            if (colorObj) {
              obj.fill = colorObj.colorHex;
              this.selectedColorName = colorObj.colorName;
              obj.dataColor = {
                name: colorObj.colorName,
                cid: colorObj.colorId,
                colorHex: colorObj.colorHex,
                colorShort: colorObj.colorShort
                  ? colorObj.colorShort
                  : colorObj.shortName,
              };
              obj.defaultColor = {
                name: colorObj.colorName,
                cid: colorObj.colorId,
                colorHex: colorObj.colorHex,
                colorShort: colorObj.colorShort
                  ? colorObj.colorShort
                  : colorObj.shortName,
              };
            }
          }
          if (obj.dataPrint) {
            let processName = obj.dataSelectedPrintType
              ? obj.dataSelectedPrintType
              : obj.dataPrint.defaultPrintType;
            obj.dataSelectedPrintShortName =
              this.getPrintShortName(processName);
          }
        }
        let monogram = await vm.setMonogramOnCanvas(obj, newMonogram, true);

        if (monogram.dataMonogram)
          monogram.dataMonogram.fixedMonogram =
            monogram.dataMonogram.fixedMonogram == true ? true : false;

        return monogram;
      } catch (err) {
        console.error(err);
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;

        window.location.reload();
      }
    },
    async loadAllObjectsInCanvas() {
      try {
        this.canvasLoadingInProgress = true;
        let newObj = "";
        this.liners = {
          isLiner: false,
          linerTags: [],
          selectedLiner: {},
        };
        let vm = this;
        this.deletedElementArray = [];
        this.optionalTextElements = [];
        this.svgAllTexts = [];
        this.isOptionalTextSkipped = false;
        this.isTextElementPresent = true;
        let totalTextElementCounter = 0;
        let canvas = this.productFabric.canvas;
        await canvas.clear();
        canvas.selection = false;
        canvas.hoverCursor = "pointer";
        canvas.preserveObjectStacking = true;
        for (let i = 0; i < this.allObjects.length; i++) {
          let obj = this.allObjects[i];
          //insert default print option in printOptions prop if not exist
          if (
            obj.dataPrint &&
            obj.dataPrint.printTypeOptions &&
            obj.dataPrint.printTypeOptions.length &&
            !obj.dataPrint.printTypeOptions.includes(
              obj.dataPrint.defaultPrintType
            )
          )
            obj.dataPrint.printTypeOptions.push(obj.dataPrint.defaultPrintType);
          else if (obj.dataPrint && !obj.dataPrint.printTypeOptions.length)
            obj.dataPrint.printTypeOptions.push(obj.dataPrint.defaultPrintType);

          if (
            obj.get("type") == "path" &&
            (obj.get("dataType") === "motif" ||
              obj.get("dataType") === "monogram")
          ) {
            if (!obj.dataLeft)
              obj.dataLeft = (Number(obj.left) * 72) / fabric.DPI;
            else obj.left = fabric.util.parseUnit(Number(obj.dataLeft) + "pt");

            if (!obj.dataTop) obj.dataTop = (Number(obj.top) * 72) / fabric.DPI;
            else obj.top = fabric.util.parseUnit(Number(obj.dataTop) + "pt");
            if (obj.get("dataType") == "motif") {
              this.disabledElementTab = false;
              this.hideMotifsTab = false;
              try {
                if (obj.dataMotif && obj.dataMotif.motifOptions.length) {
                  let newMotif = obj.dataSelectedMotifName
                    ? obj
                    : obj.dataMotif.motifOptions[0];
                  let motif = await vm.setMotifOnCanvas(obj, newMotif);
                  obj = motif;
                  if (obj.dataSelectedPrintType && obj.dataPrint) {
                    if (obj.defaultColor) {
                      let colorObj = this.setPrintMethodBasedColor(
                        obj.dataColor ? obj.dataColor : obj.defaultColor,
                        obj.dataSelectedPrintType
                          ? obj.dataSelectedPrintType
                          : obj.dataPrint.defaultPrintType
                      );
                      if (colorObj) {
                        obj.fill = colorObj.colorHex;
                        this.selectedColorName = colorObj.colorName;
                        obj.dataColor = {
                          name: colorObj.colorName,
                          cid: colorObj.colorId,
                          colorHex: colorObj.colorHex,
                          colorShort: colorObj.colorShort
                            ? colorObj.colorShort
                            : colorObj.shortName,
                        };
                        obj.defaultColor = {
                          name: colorObj.colorName,
                          cid: colorObj.colorId,
                          colorHex: colorObj.colorHex,
                          colorShort: colorObj.colorShort
                            ? colorObj.colorShort
                            : colorObj.shortName,
                        };
                      }
                    }
                    if (obj.dataPrint) {
                      let processName = obj.dataSelectedPrintType
                        ? obj.dataSelectedPrintType
                        : obj.dataPrint.defaultPrintType;
                      obj.dataSelectedPrintShortName =
                        this.getPrintShortName(processName);
                    }
                  }
                  if (obj.dataMotif)
                    obj.dataMotif.fixedMotif =
                      obj.dataMotif.fixedMotif == true ? true : false;

                  obj.setCoords();
                } else {
                  obj.set(this.getPathsProperties(obj));
                }
                let isStyleLinked = [
                  undefined,
                  "undefined",
                  "true",
                  true,
                ].includes(obj.get("dataIsStyleLinked"))
                  ? true
                  : false;

                // apply data style linked var
                obj.dataIsStyleLinked = isStyleLinked;

                if (obj.dataVisible == false || obj.dataVisible == "false") {
                  let deletedMotifs = {
                    name: obj.dataSelectedMotifName
                      ? obj.dataSelectedMotifName
                      : obj.dataSelectedMotif.name,
                    type: "motif",
                    visible: false,
                    elementIndex: i,
                  };
                  this.deletedElementArray.push(deletedMotifs);
                }
                canvas.add(obj);
              } catch (error) {
                this.$snotify.error(
                  "error in setting default motif: " + error,
                  {
                    position: "rightTop",
                    showProgressBar: false,
                    timeout: 3000,
                  }
                );
                obj.set(this.getPathsProperties(obj));
                canvas.add(obj);
              }
            } else if (obj.get("dataType") == "monogram") {
              this.disabledElementTab = false;
              this.hideMonogramTab = false;
              if (obj.dataMonogram && obj.dataMonogram.monogramOptions.length) {
                try {
                  let monogram = await this.loadMonogramObject(obj);

                  let isStyleLinked = [
                    undefined,
                    "undefined",
                    "true",
                    true,
                  ].includes(monogram.get("dataIsStyleLinked"))
                    ? true
                    : false;

                  // apply data style linked var
                  monogram.dataIsStyleLinked = isStyleLinked;

                  if (obj.dataVisible == false || obj.dataVisible == "false") {
                    let deletedMonogram = {
                      name: obj.dataSelectedMonogram
                        ? obj.dataSelectedMonogram.name
                        : "",
                      type: "monogram",
                      visible: false,
                      elementIndex: "",
                      lettersCount: obj.dataSelectedMonogram.lettersCount,
                    };
                    this.deletedElementArray.push(deletedMonogram);
                  }
                  canvas.add(monogram);
                } catch (error) {
                  this.$snotify.error(`Error in setting default monogram`, {
                    position: "rightTop",
                    showProgressBar: false,
                  });
                  console.log("", error);
                  canvas.add(obj);
                }
              } else {
                canvas.add(obj);
              }
            } else {
              canvas.add(obj);
            }
            obj.set({
              transparentCorners: true,
              lockMovementX: true,
              lockMovementY: true,
              hasControls: false,
              borderColor: "#00000",
            });
            canvas.renderAll();
          } else if (obj.get("type") == "text") {
            if (obj.get("dataType") == "monogram") {
              this.disabledElementTab = false;
              this.hideMonogramTab = false;
              if (obj.dataMonogram && obj.dataMonogram.monogramOptions.length) {
                try {
                  let monogram = await this.loadMonogramObject(obj);
                  let isStyleLinked = [
                    undefined,
                    "undefined",
                    "true",
                    true,
                  ].includes(monogram.get("dataIsStyleLinked"))
                    ? true
                    : false;

                  // apply data style linked var
                  monogram.dataIsStyleLinked = isStyleLinked;

                  if (obj.dataVisible == false || obj.dataVisible == "false") {
                    let deletedMonogram = {
                      name: obj.dataSelectedMonogram
                        ? obj.dataSelectedMonogram.name
                        : "",
                      type: "monogram",
                      visible: false,
                      elementIndex: "",
                      lettersCount: obj.dataSelectedMonogram.lettersCount,
                    };
                    this.deletedElementArray.push(deletedMonogram);
                  }
                  canvas.add(monogram);
                } catch (error) {
                  this.$snotify.error(`Error in setting default monogram`, {
                    position: "rightTop",
                    showProgressBar: false,
                  });
                  console.log("", error);
                  canvas.add(obj);
                }
              } else {
                canvas.add(obj);
              }
              obj.set({
                transparentCorners: true,
                lockMovementX: true,
                lockMovementY: true,
                hasControls: false,
                borderColor: "#00000",
              });
            } else {
              let optionsopt = obj.toObject();
              newObj = {
                originX: optionsopt.originX,
                originY: optionsopt.originY,
                left: optionsopt.left,
                top: optionsopt.top,
                width: optionsopt.width + optionsopt.fontSize,
                height: optionsopt.height,
                fill: optionsopt.fill,
                stroke: optionsopt.stroke,
                strokeWidth: optionsopt.strokeWidth,
                strokeDashArray: optionsopt.strokeDashArray,
                strokeLineCap: optionsopt.strokeLineCap,
                strokeDashOffset: optionsopt.strokeDashOffset,
                strokeLineJoin: optionsopt.strokeLineJoin,
                strokeMiterLimit: optionsopt.strokeMiterLimit,
                scaleX: optionsopt.scaleX,
                scaleY: optionsopt.scaleY,
                paintFirst: optionsopt.paintFirst,
                globalCompositeOperation: optionsopt.globalCompositeOperation,
                skewX: optionsopt.skewX,
                skewY: optionsopt.skewY,
                fontSize: optionsopt.fontSize,
                fontWeight: optionsopt.fontWeight,
                fontFamily: optionsopt.fontFamily,
                fontStyle: optionsopt.fontStyle,
                lineHeight: optionsopt.lineHeight,
                textAlign: optionsopt.textAlign,
                textBackgroundColor: optionsopt.textBackgroundColor,
                charSpacing: optionsopt.charSpacing,
                hasControls: false,
                breakWords: true,
                opacity: 1,
                hierarchyIndex: obj.hierarchyIndex,
                dataFont: obj.get("dataFont"),
                dataMaxLines: obj.get("dataMaxLines"),
                dataMaxWidth: obj.get("dataMaxWidth"),
                dataAlignProperty: obj.get("dataAlignProperty"),
                dataPrint: obj.get("dataPrint"),
                dataType: obj.get("dataType"),
                dataColor: obj.get("dataColor"),
                defaultColor: obj.get("defaultColor"),
                dataWidth: obj.get("dataWidth"),
                dataHeight: obj.get("dataHeight"),
                dataLeft: obj.get("dataLeft"),
                dataTop: obj.get("dataTop"),
                dataText: obj.get("dataText"),
                dataLineHeight: obj.get("dataLineHeight"),
                dataElementName: obj.get("dataElementName"),
                dataColorStyle: obj.get("dataColorStyle"),
                dataProcessStyle: obj.get("dataProcessStyle"),
                dataFontStyle: obj.get("dataFontStyle"),
                dataBottom: obj.get("dataBottom"),
                dataMonogram: obj.get("dataMonogram"),
                dataSelectedPrintType: obj.get("dataSelectedPrintType"),
                dataSelectedFontFamily: obj.get("dataSelectedFontFamily"),
                dataSelectedFontSize: obj.get("dataSelectedFontSize"),
                dataSelectedLetterSpacing: obj.get("dataSelectedLetterSpacing"),
                dataSelectedLineHeight: obj.get("dataSelectedLineHeight"),
                dataIsModified: obj.get("dataIsModified"),
                dataRequired: obj.get("dataRequired"),
                dataOptional: obj.get("dataOptional"),
                dataOptionalAdded: obj.get("dataOptionalAdded"),
                dataDefaultText: obj.get("dataDefaultText"),
                dataSelectedPrintShortName: obj.get(
                  "dataSelectedPrintShortName"
                ),
                //dataStrokeWidth: obj.get("dataStrokeWidth"),
              };

              totalTextElementCounter++;

              if (!newObj.dataLeft)
                newObj.dataLeft = (Number(newObj.left) * 72) / fabric.DPI;
              else
                newObj.left = fabric.util.parseUnit(
                  Number(newObj.dataLeft) + "pt"
                );

              if (!newObj.dataTop)
                newObj.dataTop = (Number(newObj.top) * 72) / fabric.DPI;
              else
                newObj.top = fabric.util.parseUnit(
                  Number(newObj.dataTop) + "pt"
                );

              let newEditableText = null;
              newEditableText = new fabric.Textbox(optionsopt.text, newObj);
              if (!newEditableText.dataText) {
                newEditableText.dataText = newEditableText.textLines;
              } else {
                newEditableText.set(
                  "text",
                  newEditableText.dataText.join("\n")
                );
                this.svgAllTexts.push(newEditableText.dataText.join("\n"));
              }

              if (newEditableText.dataMaxWidth)
                this.updateSelected(
                  newEditableText,
                  newEditableText.dataMaxWidth,
                  "width"
                );

              if (!newEditableText.dataAlignProperty)
                newEditableText.dataAlignProperty = {
                  defaultAlign: newEditableText.textAlign,
                  alignOptions: [newEditableText.textAlign],
                };

              this.updateSelected(
                newEditableText,
                newEditableText.dataAlignProperty.defaultAlign,
                "textAlign"
              );

              if (
                !newEditableText.dataSelectedPrintType &&
                newEditableText.dataPrint
              ) {
                newEditableText.dataSelectedPrintType =
                  newEditableText.dataPrint.defaultPrintType;
              }
              if (
                newEditableText.dataSelectedPrintType &&
                newEditableText.dataPrint
              ) {
                if (newEditableText.defaultColor) {
                  let colorObj = this.setPrintMethodBasedColor(
                    newEditableText.defaultColor,
                    newEditableText.dataSelectedPrintType
                      ? newEditableText.dataSelectedPrintType
                      : newEditableText.dataPrint.defaultPrintType
                  );
                  if (colorObj) {
                    newEditableText.fill = colorObj.colorHex;
                    this.selectedColorName = colorObj.colorName;
                    newEditableText.dataColor = {
                      name: colorObj.colorName,
                      cid: colorObj.colorId,
                      colorHex: colorObj.colorHex,
                      colorShort: colorObj.colorShort
                        ? colorObj.colorShort
                        : colorObj.shortName,
                    };
                    newEditableText.defaultColor = {
                      name: colorObj.colorName,
                      cid: colorObj.colorId,
                      colorHex: colorObj.colorHex,
                      colorShort: colorObj.colorShort
                        ? colorObj.colorShort
                        : colorObj.shortName,
                    };
                  }
                }
                if (newEditableText.dataPrint) {
                  let processName = newEditableText.dataSelectedPrintType
                    ? newEditableText.dataSelectedPrintType
                    : newEditableText.dataPrint.defaultPrintType;
                  newEditableText.dataSelectedPrintShortName =
                    this.getPrintShortName(processName);
                }
              }

              if (newEditableText.dataSelectedFontFamily) {
                this.updateTextFont(newEditableText, {
                  defaultValue: newEditableText.dataSelectedFontSize,
                  name: newEditableText.dataSelectedFontFamily,
                  letterSpacing: newEditableText.dataSelectedLetterSpacing,

                  stroke: newEditableText.fill,
                  lineHeight:
                    newEditableText.dataSelectedLineHeight &&
                    newEditableText.dataSelectedLineHeight != "undefined"
                      ? newEditableText.dataSelectedLineHeight
                      : newEditableText.dataLineHeight,
                });
              } else if (newEditableText.dataFont) {
                this.updateTextFont(
                  newEditableText,
                  newEditableText.dataFont.fontOptions[0]
                );
              }

              if (
                newEditableText.dataAlignProperty.anchorAlignment == "bottom"
              ) {
                let positionDiff = null;
                let bottomInPixel = fabric.util.parseUnit(
                  Number(newEditableText.dataBottom) + "pt"
                );
                if (
                  newEditableText.top + newEditableText.height <
                  bottomInPixel
                ) {
                  positionDiff =
                    bottomInPixel -
                    (newEditableText.top + newEditableText.height);
                  newEditableText.top = newEditableText.top + positionDiff;
                  newEditableText.dataTop =
                    (Number(newEditableText.top) * 72) / fabric.DPI;
                } else if (
                  newEditableText.top + newEditableText.height >
                  bottomInPixel
                ) {
                  positionDiff =
                    newEditableText.top +
                    newEditableText.height -
                    bottomInPixel;
                  newEditableText.top = newEditableText.top - positionDiff;
                  newEditableText.dataTop =
                    (Number(newEditableText.top) * 72) / fabric.DPI;
                }
              } else {
                newEditableText.dataBottom =
                  ((newEditableText.top + newEditableText.height) * 72) /
                  fabric.DPI;
              }

              vm.lockMovement(newEditableText);

              newEditableText.oldLineCount =
                newEditableText.__lineHeights.length;
              if (!newEditableText.dataMaxLines)
                newEditableText.dataMaxLines =
                  newEditableText.__lineHeights.length;

              fabric.charWidthsCache = {};

              if (!newEditableText.dataDefaultText) {
                newEditableText.dataDefaultText = newEditableText.dataText;
              }

              let isStyleLinked = [
                undefined,
                "undefined",
                "true",
                true,
              ].includes(obj.get("dataIsStyleLinked"))
                ? true
                : false;

              // apply data style linked var
              newEditableText.dataIsStyleLinked = isStyleLinked;

              if (
                ["true", true].includes(newEditableText.dataOptional) &&
                (!["true", true].includes(newEditableText.dataOptionalAdded) ||
                  newEditableText.text.length == 0)
              ) {
                this.optionalTextElements.push(newEditableText);
                this.optionalTextElements[
                  this.optionalTextElements.length - 1
                ].extraProperty = {
                  name: newEditableText.dataElementName,
                  objectIndex: i,
                  optional: true,
                  originalText:
                    newEditableText.text.length > 0
                      ? newEditableText.dataText
                      : newEditableText.dataDefaultText, // dataElementName.split("\n")
                };
                newEditableText.visible = false;
                newEditableText.selectable = false;
              }
              canvas.add(newEditableText);
            }
          } else if (
            obj.get("type") == "image" &&
            obj.get("dataType") === "paper"
          ) {
            try {
              if (
                obj.dataPaper &&
                obj.dataPaper.paperOptions[0].name !== "Select Paper"
              ) {
                let selectedPart = this.partStates[this.selectedPartIndex];
                let imageUrl =
                  obj.dataSelectedPaperType.currentSelectedSurface &&
                  obj.dataSelectedPaperType.currentSelectedSurface.url
                    ? obj.dataSelectedPaperType.currentSelectedSurface.url
                    : obj.dataSelectedPaperType.img
                    ? obj.dataSelectedPaperType.img
                    : obj.dataSelectedPaperType.url;

                let paperObject = obj.dataSelectedPaperType
                  ? obj.dataSelectedPaperType
                  : "";

                if (!selectedPart.isSurface && paperObject) {
                  if (paperObject.alternateUrl)
                    imageUrl =
                      paperObject.alternateUrl + "?" + new Date().getTime();
                }
                imageUrl = imageUrl + "?" + new Date().getTime();

                let paperImage = await this.getImageFromUrl(imageUrl);

                paperImage.set({
                  left: 0,
                  top: 0,
                  scaleX:
                    this.partStates[this.selectedPartIndex].canvasWidth /
                    paperImage.width,
                  scaleY:
                    this.partStates[this.selectedPartIndex].canvasHeight /
                    paperImage.height,
                  selectable: true,
                  dirty: true,
                  hasControls: false,
                  transparentCorners: true,
                  lockMovementX: true,
                  lockMovementY: true,
                  dataType: "paper",
                  opacity: 1,
                  dataPaper: obj.dataPaper,
                  dataSelectedPaperType: obj.dataSelectedPaperType,
                  dataIsModified: obj.get("dataIsModified"),
                  dataRequired: obj.get("dataRequired"),
                  dataColor: obj.get("dataColor"),
                  dataFinish: obj.dataFinish,
                  dataColorTags: obj.dataColorTags,
                  dataSurface: obj.dataSurface,
                });
                canvas.add(paperImage);
                if (obj.dataSelectedPaperType.openEnvelopeTransparentUrl) {
                  this.disableFinishTab = false;
                } else {
                  this.disableFinishTab = true;
                }
                if (paperImage.dataFinish) {
                  this.liners.isLiner = paperImage.dataFinish.isLiner;
                  if (this.liners.isLiner) {
                    this.showFinishTab = true;
                    this.liners.linerTags = paperImage.dataFinish.linerTags;
                    if (this.liners.linerTags.length)
                      await this.$store.dispatch(
                        "productOne/fetchLiners",
                        this.liners.linerTags
                      );

                    this.liners.selectedLiner =
                      paperImage.dataFinish.selectedLiner;
                    if (this.liners.selectedLiner) {
                      this.selectedLinerName = this.liners.selectedLiner.name;
                      if (this.linerPatternList.length) {
                        this.linerPatternList.filter((liner) => {
                          if (this.selectedLinerName == liner.name) {
                            this.linersType = "linersPattern";
                            return;
                          }
                        });
                      }
                    }
                  } else {
                    this.showFinishTab = false;
                  }
                }
                if (paperImage.dataColorTags) {
                  if (paperImage.dataColorTags.length) {
                    await this.$store.dispatch(
                      "productOne/fetchColors",
                      paperImage.dataColorTags
                    );
                    await this.modifyColorObject(this.colorList);
                  }
                }
                canvas.setActiveObject(paperImage);
              } else {
                if (!obj.dataSurface) obj.set("dataSurface", this.partSurface);
                canvas.add(obj);
                canvas.setActiveObject(obj);
              }
            } catch (error) {
              canvas.add(obj);
              canvas.setActiveObject(obj);
            }
            vm.lockMovement(obj);
            this.hidePaperTabOption = false;
            this.activeClass = "";
          } else if (
            obj.get("type") == "image" &&
            obj.get("dataType") === "image"
          ) {
            let newImageObj = obj;
            let newLeft, newTop;

            if (obj.dataImage) {
              if (obj.dataImage.selectedImage) {
                newImageObj = await this.getImageFromUrl(
                  obj.dataImage.selectedImage.url
                );
              } else if (obj.dataImage.default) {
                newImageObj = await this.getImageFromUrl(obj.dataImage.default);
              }

              newLeft = fabric.util.parseUnit(Number(obj.dataLeft) + "pt");
              newTop = fabric.util.parseUnit(Number(obj.dataTop) + "pt");
            }
            newImageObj.set({
              selectable: true,
              dirty: false,
              left: newLeft,
              top: newTop,
              scaleX: obj.scaleX,
              scaleY: obj.scaleY,
              hasControls: false,
              transparentCorners: true,
              lockMovementX: true,
              lockMovementY: true,
              dataType: "image",
              opacity: 1,
              visible: obj.visible,
              dataImage: obj.dataImage,
              dataVisible: obj.dataVisible,
              dataPrint: obj.dataPrint,
              dataRequired: obj.dataRequired,
              dataElementName: obj.dataElementName,
              dataColorStyle: obj.dataColorStyle,
              dataProcessStyle: obj.dataProcessStyle,
              dataFontStyle: obj.dataFontStyle,
              dataLeft: obj.dataLeft,
              dataTop: obj.dataTop,
              dataHeight: obj.dataHeight,
              dataWidth: obj.dataWidth,
              dataSelectedPrintShortName: obj.dataSelectedPrintShortName,
              dataSelectedPrintType: obj.dataSelectedPrintType,
              dataImgRight: obj.get("dataImgRight"),
              dataImgBottom: obj.get("dataImgBottom"),
            });

            canvas.add(newImageObj);

            if (newImageObj.dataImage) {
              this.image.isImage = newImageObj.dataImage.isImage;
              if (this.image.isImage) {
                this.image.imageTags = newImageObj.dataImage.imageTags;
                if (
                  this.image &&
                  this.image.imageTags &&
                  this.image.imageTags.length
                )
                  await this.$store.dispatch(
                    "imageOne/fetchImages",
                    this.image.imageTags
                  );
                this.image.selectedImage = newImageObj.dataImage.selectedImage;
                this.image.visible = newImageObj.dataVisible;
                if (this.image.selectedImage) {
                  this.selectedImageName = this.image.selectedImage.name;
                }
                this.imageTabDisable = false;
              } else {
                this.imageTabDisable = true;
              }
              if (!newImageObj.dataSelectedPrintType && newImageObj.dataPrint) {
                newImageObj.dataSelectedPrintType =
                  newImageObj.dataPrint.defaultPrintType;
              }
              if (newImageObj.dataPrint) {
                let processName = newImageObj.dataSelectedPrintType
                  ? newImageObj.dataSelectedPrintType
                  : newImageObj.dataPrint.defaultPrintType;
                newImageObj.dataSelectedPrintShortName =
                  this.getPrintShortName(processName);
              }

              let isStyleLinked = [
                undefined,
                "undefined",
                "true",
                true,
              ].includes(obj.get("dataIsStyleLinked"))
                ? true
                : false;

              // apply data style linked var
              newImageObj.dataIsStyleLinked = isStyleLinked;
            }
            vm.lockMovement(newImageObj);
            this.hidePaperTabOption = false;
          } else if (obj.get("dataType") === "clipart") {
            obj.set({
              //  evented: false, // to prevent all events get fired
              selectable: true,
              dirty: true,
              angle: obj.angle,
              flipY: obj.flipY,
              flipX: obj.flipX,
              hasControls: false,
              transparentCorners: true,
              lockMovementX: true,
              lockMovementY: true,
              scaleX: obj.scaleX,
              scaleY: obj.scaleY,
              dataType: "clipart",
              dataClipart: obj.dataClipart,
              dataPrint: obj.dataPrint,
              dataLeft: obj.get("dataLeft"),
              dataTop: obj.get("dataTop"),
              hierarchyIndex: obj.hierarchyIndex,
              dataImgRight: obj.get("dataImgRight"),
              dataImgBottom: obj.get("dataImgBottom"),
              dataColor: obj.get("dataColor"),
              defaultColor: obj.get("defaultColor"),
              dataExistingDie: obj.get("dataExistingDie"),
              dataDefaultColour: obj.get("dataDefaultColour"),
            });
            obj.dataExistingDie =
              obj.dataExistingDie == "true" || obj.dataExistingDie == true
                ? true
                : false;

            if (obj.dataPrint)
              obj.dataSelectedPrintShortName = this.getPrintShortName(
                obj.dataPrint.defaultPrintType
              );

            if (obj.dataExistingDie === true) {
              obj.dataColor = {
                name: "NO SPOT COLOR",
                colorHex: obj.dataDefaultColour,
                cid: "94",
                colorShort: "DIGPHOTO",
              };
            }

            if (!obj.dataLeft)
              obj.dataLeft = (Number(obj.left) * 72) / fabric.DPI;
            else obj.left = fabric.util.parseUnit(Number(obj.dataLeft) + "pt");

            if (!obj.dataTop) obj.dataTop = (Number(obj.top) * 72) / fabric.DPI;
            else obj.top = fabric.util.parseUnit(Number(obj.dataTop) + "pt");
            if (obj.dataImgRight)
              await this.updateSelected(obj, obj.dataImgRight, "imgRight");

            if (obj.dataImgBottom)
              await this.updateSelected(obj, obj.dataImgBottom, "imgBottom");
            if (obj.dataExistingDie) obj.set("fill", obj.dataDefaultColour);

            canvas.add(obj);
          } else {
            vm.lockMovement(obj);
            canvas.add(obj);
          }
        } // end of added canvas object

        canvas.requestRenderAll();
        for (let i = 0; i < this.productFabric.canvas._objects.length; i++) {
          let obj = this.productFabric.canvas._objects[i];
          let isStyleLinked = [undefined, "undefined", "true", true].includes(
            obj.get("dataIsStyleLinked")
          )
            ? true
            : false;

          // apply data style linked var
          obj.dataIsStyleLinked = isStyleLinked;
          if (isStyleLinked) {
            // newObj.dataProcessStyle || newObj.dataFontStyle ? true : false
            let { newCurrentObj } = await this.setCanvasInitialStyle(obj);
            // applied style changes to current obj
            obj = newCurrentObj;
            this.productFabric.canvas._objects[i] = obj;
          }
        }

        if (totalTextElementCounter == this.optionalTextElements.length) {
          this.isTextElementPresent = false;
        }

        if (this.optionalTextElements.length) {
          this.singleOptionalElement = this.optionalTextElements[0];
        }

        this.inProgress = false;
        this.activeClass = "productpage-tab-slider-mobile";
        canvas.renderAll();
        this.canvasLoadingInProgress = false;
      } catch (err) {
        console.error("Error while loading canvas ",err, { sessionId: this.builderSessionId, sku:this.productSku });
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
        this.canvasLoadingInProgress = false;

        window.location.reload();
      }
    },
    getPosition(oldObject, newObj) {
      let leftCenter =
        oldObject.left + (oldObject.width * oldObject.scaleX) / 2;
      let topCenter = oldObject.top + (oldObject.height * oldObject.scaleY) / 2;
      let newLeft = leftCenter - (newObj.width * newObj.scaleX) / 2;
      let newTop = topCenter - (newObj.height * newObj.scaleY) / 2;

      return { left: newLeft, top: newTop };
    },
    changeAnchor(oldObject, newObj) {
      try {
        let hAnchor = oldObject.dataHAnchor ? oldObject.dataHAnchor : "center";
        let vAnchor = oldObject.dataVAnchor ? oldObject.dataVAnchor : "center";
        let dataTop = fabric.util.parseUnit(Number(newObj.dataTop) + "pt");
        let dataLeft = fabric.util.parseUnit(Number(newObj.dataLeft) + "pt");
        let dataWidth = fabric.util.parseUnit(Number(newObj.dataWidth) + "pt");
        let dataHeight = fabric.util.parseUnit(
          Number(newObj.dataHeight) + "pt"
        );
        let defaultScaleX =
          newObj.dataMotif && newObj.dataMotif.defaultScaleX
            ? newObj.dataMotif.defaultScaleX
            : newObj.scaleX;
        let defaultScaleY =
          newObj.dataMotif && newObj.dataMotif.defaultScaleY
            ? newObj.dataMotif.defaultScaleY
            : newObj.scaleY;

        let left, top;
        if (hAnchor && vAnchor) {
          if (newObj.dataType === "motif") {
            if (hAnchor == "left") {
              left = dataLeft;
              if (vAnchor == "top") {
                top = dataTop;
              }
              if (vAnchor == "center") {
                top =
                  dataTop +
                  dataHeight / 2 -
                  (newObj.height * defaultScaleY) / 2;
              }
              if (vAnchor == "bottom") {
                top = dataTop + dataHeight - newObj.height * defaultScaleY;
              }
            }
            if (hAnchor == "center") {
              left =
                dataLeft + dataWidth / 2 - (newObj.width * defaultScaleX) / 2;
              if (vAnchor == "top") {
                top = dataTop;
              }
              if (vAnchor == "center") {
                top =
                  dataTop +
                  dataHeight / 2 -
                  (newObj.height * defaultScaleY) / 2;
              }
              if (vAnchor == "bottom") {
                top = dataTop + dataHeight - newObj.height * defaultScaleY;
              }
            }
            if (hAnchor == "right") {
              left = dataLeft + dataWidth - newObj.width * defaultScaleX;
              if (vAnchor == "top") {
                top = dataTop;
              }
              if (vAnchor == "center") {
                top =
                  dataTop +
                  dataHeight / 2 -
                  (newObj.height * defaultScaleY) / 2;
              }
              if (vAnchor == "bottom") {
                top = dataTop + dataHeight - newObj.height * defaultScaleY;
              }
            }
          } else {
            //for other elements than motif
            if (hAnchor == "left") {
              left = oldObject.left;
              if (vAnchor == "top") {
                top = oldObject.top;
              }
              if (vAnchor == "center") {
                let topCenter =
                  oldObject.top + (oldObject.height * oldObject.scaleY) / 2;
                top = topCenter - (newObj.height * newObj.scaleY) / 2;
              }
              if (vAnchor == "bottom") {
                let heightDiffrence =
                  newObj.height * newObj.scaleY -
                  oldObject.height * oldObject.scaleY;
                top = oldObject.top - heightDiffrence;
              }
            }
            if (hAnchor == "center") {
              let leftCenter =
                oldObject.left + (oldObject.width * oldObject.scaleX) / 2;
              left = leftCenter - (newObj.width * newObj.scaleX) / 2;
              if (vAnchor == "top") {
                top = oldObject.top;
              }
              if (vAnchor == "center") {
                let topCenter =
                  oldObject.top + (oldObject.height * oldObject.scaleY) / 2;
                top = topCenter - (newObj.height * newObj.scaleY) / 2;
              }
              if (vAnchor == "bottom") {
                let heightDiffrence =
                  newObj.height * newObj.scaleY -
                  oldObject.height * oldObject.scaleY;
                top = oldObject.top - heightDiffrence;
              }
            }
            if (hAnchor == "right") {
              let widthDiffrence =
                newObj.width * newObj.scaleX -
                oldObject.width * oldObject.scaleX;
              left = oldObject.left - widthDiffrence;
              if (vAnchor == "top") {
                top = oldObject.top;
              }
              if (vAnchor == "center") {
                let topCenter =
                  oldObject.top + (oldObject.height * oldObject.scaleY) / 2;
                top = topCenter - (newObj.height * newObj.scaleY) / 2;
              }
              if (vAnchor == "bottom") {
                let heightDiffrence =
                  newObj.height * newObj.scaleY -
                  oldObject.height * oldObject.scaleY;
                top = oldObject.top - heightDiffrence;
              }
            }
          }

          return { left: left, top: top };
        } else {
          return { left: oldObject.left, top: oldObject.top };
        }
      } catch (err) {
        console.log(err);
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;

        window.location.reload();
      }
    },
    lockMovement(object) {
      object.set({
        lockMovementX: true,
        lockMovementY: true,
        hasControls: false,
        transparentCorners: true,
      });
    },
    setEditedTextOnCavas() {
      const ref = this.productFabric;
      let canvasObject = this.selectedCanvas;
      let oldHeight = canvasObject.height;
      canvasObject.set({
        text: this.selectedText,
        dataIsModified: true,
      });

      if (
        canvasObject.dataAlignProperty &&
        canvasObject.dataAlignProperty.anchorAlignment &&
        canvasObject.dataAlignProperty.anchorAlignment === "bottom"
      ) {
        let newHeight = canvasObject.height;
        if (oldHeight > newHeight) canvasObject.top += oldHeight - newHeight;
        else if (oldHeight < newHeight)
          canvasObject.top -= newHeight - oldHeight;
        canvasObject.dataTop = (canvasObject.top * 72) / fabric.DPI;
        canvasObject.dataIsModified = true;
      }

      canvasObject.oldLineCount = canvasObject.__lineHeights.length;
      // commentted bcz - on refresh of page text is shifting to upwards
      // canvasObject.dataBottom = ((canvasObject.top + canvasObject.height) * 72) / fabric.DPI;
      canvasObject.dataText = canvasObject.textLines;

      canvasObject.setCoords();
      ref.canvas.renderAll();
      this.editTextareaModal = false;
      this.userMadeChangesInSvg();
    },
    setEditedText(val) {
      let newCanvas = this.productFabric.canvas;
      let canvasObject = this.selectedCanvas;
     // this.clearFabricFontCache(canvasObject.fontFamily);
      canvasObject.set({
        text: val?.target?.value || canvasObject.text,
        fontSize: canvasObject.fontSize,
        fontWeight: canvasObject.fontWeight,
        fontFamily: canvasObject.fontFamily,
      });
      canvasObject.setCoords();
      newCanvas.renderAll();
    },
    editSVGText(event) {
      // eslint-disable-next-line no-unused-vars
      let text = event.target.value;
      let char = "";
      if (event.key.length == 1) {
        text += event.key;
        char = event.key;
      }
      let canvasObject = this.selectedCanvas;
      let lineWidth = Number(canvasObject.width);
      if (Number(canvasObject.dataMaxLines) == canvasObject.textLines.length) {
        let lastLineWidth = canvasObject.measureLine(
          canvasObject.textLines.length - 1
        ).width;
        let letterWidth = Math.ceil(
          (canvasObject.getMeasuringContext().measureText(char).width *
            Math.ceil(canvasObject.fontSize)) /
            canvasObject.CACHE_FONT_SIZE
        );
        if (lastLineWidth + letterWidth >= lineWidth) {
          if (event.keyCode != 8) {
            event.preventDefault();
            event.stopPropagation();
          }
        }
      }
      if (event.keyCode == 13) {
        if (
          canvasObject.textLines.length >
          Number(canvasObject.dataMaxLines) - 1
        ) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
      if (canvasObject.textLines.length > Number(canvasObject.dataMaxLines)) {
        if (event.keyCode != 8) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    },
    goToLogin() {
      window.open(`${storeFrontUrl}/gate/sign-in`, "_self");
    },
    goToDashboard() {
      window.open(`${storeFrontUrl}/me/account-dashboard`, "_self");
    },
    goToLogout() {
      this.$store.dispatch("auth/logout");
      localStorage.removeItem("cr_checkOutTab");
    },
    goToHomePage() {
      window.open(`${storeFrontUrl}/`, "_self");
    },
    GotoPage() {},
    checkEncodeURI(str) {
      try {
        decodeURIComponent(escape(window.atob(str)));
        return true;
      } catch (e) {
        return false;
      }
    },
    decodeSvg(str) {
      try {
        return decodeURIComponent(escape(window.atob(str)));
      } catch (e) {
        return str;
      }
    },
    async updatePage(list) {
      try {
        let vm = this;
        this.inProgress = true;
        this.canvasLoadingInProgress = true;
        this.isNewSvgLoaded = false;
        if (list.length > 0) {
          this.partsList = getSVGParts(list);
          this.selectedPart = this.partsList[0].value;

          let data = await this.$store.dispatch("sessionItemOne/fetchOne", {
            sessionId: this.builderSessionId,
            itemId: this.selectedPart.id,
            surface: this.selectedPart.surfaceId,
            surfaceType: "surface",
          });

          this.currentBuilderSessionItem = data.response;

          this.selectedPartSurfaces = [];

          if (this.selectedPart) {
            let surfaceObj = await this.fetchSurfaceObject(
              this.selectedPart.surfaceId,
              "surface"
            );
            this.selectedPartSurfaces.push(surfaceObj);
            if (this.selectedPart.alternateSurfaceId) {
              let alternateSurfaceObj = await this.fetchSurfaceObject(
                this.selectedPart.alternateSurfaceId,
                "alternateSurface"
              );
              this.selectedPartSurfaces.push(alternateSurfaceObj);
            }
          }

          this.partSurface = this.selectedPartSurfaces[0];
          this.currentPartSurface = this.selectedPartSurfaces[0];
          this.dataSurface = this.partSurface;

          let count = 0;
          list.forEach((part) => {
            if (part.alternateSurfaceId) {
              this.partStates[count] = {
                id: part.id,
                undo: [],
                redo: [],
                state: null,
                canvasWidth: null,
                canvasHeight: null,
                svg: null,
                surfaceId: part.surfaceId,
                isSurface: true,
                index: count,
                name: part.name,
              };
              count++;
              this.partStates[count] = {
                id: part.id,
                undo: [],
                redo: [],
                state: null,
                canvasWidth: null,
                canvasHeight: null,
                svg: null,
                surfaceId: part.alternateSurfaceId,
                isSurface: false,
                index: count,
                name: part.name,
              };
            } else {
              this.partStates[count] = {
                id: part.id,
                undo: [],
                redo: [],
                state: null,
                canvasWidth: null,
                canvasHeight: null,
                svg: null,
                surfaceId: part.surfaceId,
                index: count,
                isSurface: true,
                name: part.name,
              };
            }
            count++;
          });

          this.selectedPartIndex = 0;
          this.part = list[0];
          let svgCode = this.currentBuilderSessionItem.svgContent; //localStorage.getItem(`cr_${this.productSku}_${this.part.id}`);

          if (!svgCode) {
            this.isNewSvgLoaded = true;
            if (this.checkEncodeURI(this.part.svg))
              svgCode = this.decodeSvg(this.part.svg);
            else svgCode = this.part.svg;

            let hasNaN = this.checkSvgHasNaN(svgCode);
            if (hasNaN) {
              await this.saveIncident({
                errorObject: {message: "Product svg contained NaN string", part_name: this.part.name, session_id: this.builderSessionId },
                title: "Personalize product svg error",
                description: "Personalize product svg has NaN ",
              });
              // this.setCorruptedSessionData()
            }

            await this.updateBuilderSessionItem({
              svgCode: btoa(unescape(encodeURIComponent(svgCode))),
            });
          } else {
            svgCode = this.decodeSvg(svgCode);
            this.isNewSvgLoaded = true;

            let hasNaN = this.checkSvgHasNaN(svgCode);
            if (hasNaN) {
              await this.saveIncident({
                errorObject: {message: "Product svg contained NaN string", part_name: this.part.name, session_id: this.builderSessionId },
                title: "Personalize product svg error",
                description: "Personalize product svg has NaN ",
              });
              // this.setCorruptedSessionData()
            }

            await this.updateBuilderSessionItem({
              svgCode: btoa(unescape(encodeURIComponent(svgCode))),
            });
          }

          this.partStates[this.selectedPartIndex].svg = svgCode;

          if (this.partStates[this.selectedPartIndex].state == null)
            this.partStates[this.selectedPartIndex].state = svgCode;

          let hasNaN = this.checkSvgHasNaN(svgCode);
          if (hasNaN) {
            await this.saveIncident({
              errorObject: {message: "Product svg contained NaN string", part_name: this.partStates[this.selectedPartIndex].name, session_id: this.builderSessionId },
              title: "Personalize product svg error",
              description: "Personalize product svg has NaN ",
            });
            // this.setCorruptedSessionData()
          }

          fabric.loadSVGFromString(
            svgCode,
            async function (objects, options) {
              vm.partStates[vm.selectedPartIndex].canvasWidth = options.width;
              vm.partStates[vm.selectedPartIndex].canvasHeight = options.height;
              vm.canvasOriginalHeight = options.height;
              vm.canvasOriginalWidth = options.width;
              if (objects) {
                await vm.updateCanvas(vm.productFabric.canvas, objects);
                await vm.resize();
              }
            },
            this.reviver
          );
        }
      } catch (err) {
        console.error("Error while updating canvas on page ",err, { sessionId: this.builderSessionId, sku:this.productSku });
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
        this.canvasLoadingInProgress = false;
        // this.setCorruptedSessionData()
        throw err;
      }
    },
    checkSvgHasNaN(svgCode) {
      if (
        svgCode.includes("NaN ") ||
        svgCode.includes("NaNpt") ||
        svgCode.includes("undefined ")
      ) {
        console.log(
          "svgString.includes('NaN NaN')  will create new session",
          svgCode
        );
        this.$snotify.error("Something went wrong, please try again", {
          position: "rightTop",
          showProgressBar: false,
          timeout: 3000,
        });
        return true;
      }
      return false;
    },
    async modifyColorObject(colorList) {
      let printShortName = this.getPrintShortName(
        this.printMethod
      ).toLowerCase();
      await this.$store.dispatch("designerOne/filterColorsByPrintName", {
        colorList,
        printShortName,
      });
      return this.colorList;
    },
    async userMadeChangesInSvg() {
      await this.$store.dispatch("productOne/userMadeChangesInSvg", {
        isDirty: true,
      });

      this.fetchPriceForCurrentPart();
    },
    async updateBuilderSessionItemSummary({
      summaryData,
      productPartId,
      surfaceType,
      surfaceId,
      sessionItemData,
    }) {
      try {
        let property = {};
        await this.$store.dispatch("sessionItemOne/updateItemProperties", {
          sessionId: this.builderSessionId,
          productPartId,
          surfaceType,
          surfaceId,
          property: {
            summaryData: summaryData,
          },
        });
      } catch (err) {
        console.error("Personalize error while updating summary",err,{ sessionId: this.builderSessionId, sku:this.productSku });
        this.$snotify.error(
          `Cloud communication error, please start new session `,
          {
            position: "rightTop",
            showProgressBar: false,
          }
        );
        this.inProgress = false;
        await this.saveIncident({
          errorObject: err,
          title: "Personalize error while updating summary",
          description: "Error while updating part summary for session",
        });
        // this.setCorruptedSessionData()
        throw err;
      }
    },
    async saveIncident({
      errorObject,
      title,
      description,
      isReloadNeeded = true,
    }) {
      try {
        await this.$store.dispatch("incidentOne/setOne");
        let currentdate = await isoStringToNormalDate();
        let currentSessionId = this.builderSessionId
        let incidentObj = {
          title: title,
          level: "major",
          module: "studio",
          description: description,
          tags: [],
          recorded_at: currentdate,
          metadata: {
            currentSessionId,
            errorStack: JSON.stringify(errorObject.stack||errorObject),
            error: JSON.stringify(errorObject.stack ? errorObject.toLocaleString() : errorObject),
            //  errorObject: errorObject,
            platform: userAgent.platform,
            appVersion: userAgent.appVersion,
            appCodeName: userAgent.appCodeName,
          },
        };
        let savedIncidentResp = await this.$store.dispatch("incidentOne/save", {
          one: incidentObj,
        });
        console.log("savedIncidentResp", savedIncidentResp);

       // console.error(title,  errorObject)

        let newErr = new Error(title)
        console.error(newErr, incidentObj.metadata)
        // console.log("Error for studio  DD_RUM"+newErr)
        // if(window.DD_RUM){
        //   window.DD_RUM.onReady(function() {
        //     window.DD_RUM.addError(newErr,{
        //       module: "studio",
        //       level: "major",
        //       currentSessionId,
        //       description,
        //       errorDetails: JSON.stringify(errorObject.stack ? errorObject.toLocaleString() : errorObject)
        //     });
        //   });
        // } else {
        //   console.log("window.DD_RUM  variable is NOT Available in studio ")
        // }

        if (isReloadNeeded){
          // reload blocked for pricing api
          this.setCorruptedSessionData();
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async setCorruptedSessionData() {
      try {
        await this.$store.dispatch("sessionOne/updateOne", {
          sessionId: this.builderSessionId,
          property: {
            state: "in_active",
          },
        });

        this.$router.push({
          name: "SessionStart",
          params: {
            sku: this.productSku,
            quantity: this.userQuantity,
          },
        });
      } catch (err) {
        console.error(err);
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;
        throw err;
      }
    },
    async updateBuilderSessionItem({ svgCode, thumbnailUrl, summaryData }) {
      try {
        let property = {};
        if (svgCode) property.svgContent = svgCode;

        if (thumbnailUrl) property.thumbnailUrl = thumbnailUrl;
        if (summaryData) property.summaryData = summaryData;

        await this.$store.dispatch("sessionItemOne/updateItem", {
          sessionItemId: this.currentBuilderSessionItem.id,
          property,
        });

        return true;
      } catch (err) {
        console.error("Error in updating builder session item ",err, { sessionId: this.builderSessionId, sku:this.productSku });
        this.$snotify.error(`Cloud communication error`, {
          position: "rightTop",
          showProgressBar: false,
        });
        this.inProgress = false;

        // only setting in_active when patching failed for svg code in item
        if (svgCode) await this.setCorruptedSessionData();

        throw err;
      }
    },
    goToPartSelectPage(userSvgFailed) {
      //userSvgFailed  is set when user svg is failed to save while changing part
      let partArray = this.sessionOneDetails.productData.selectedPartsList;
      if (!partArray || userSvgFailed) {
        if (this.designerMode) {
          if (this.categoryId) {
            this.$router.push({
              name: "PartSelection",
              params: {
                designerMode: this.designerMode,
                sessionId: this.builderSessionId,
              },
              query: { categoryId: this.categoryId },
            });
          } else {
            this.$router.push({
              name: "PartSelection",
              params: {
                designerMode: this.designerMode,
                sessionId: this.builderSessionId,
              },
            });
          }
        } else {
          if (this.categoryId) {
            this.$router.push({
              name: "PartSelection",
              params: {
                sessionId: this.builderSessionId,
              },
              query: { categoryId: this.categoryId },
            });
          } else {
            this.$router.push({
              name: "PartSelection",
              params: {
                sessionId: this.builderSessionId,
              },
            });
          }
        }
      }
    },
  },
  beforeDestroy() {
    this.currentTimeStamp = null;
    window.removeEventListener("beforeunload", handleBeforeUnload);
  },
  mounted: function () {
    this.currentTimeStamp = new Date().getTime();
    let vm = this;

    this.productFabric = {};
    this.productFabric.canvas = new fabric.Canvas(this.$refs.canvas.id, {
      // ...this.definedProps
    });

    this.productFabric.canvas.on({
      "selection:created": (e) => vm.objSelected(e),
      "selection:updated": (e) => vm.objSelected(e),
      "mouse:dblclick": (e) => vm.objSelected(e),
      // 'selection:cleared': (e)=>vm.objectDeselect(e),
    });

    this.updatePage(this.productParts);

    fabric.IText.prototype._toSVG = (function (_toSVG) {
      return function () {
        let svg = _toSVG.call(this);

        let styleIndex = svg.indexOf('style="');
        let dataAttributes = '" ';

        if (this.dataType) {
          dataAttributes += ` data-type="${this.dataType}" `;
        }
        if (this.dataPrint) {
          dataAttributes += ` data-print="${JSON.stringify(
            this.dataPrint
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataSelectedPrintType) {
          dataAttributes += ` data-selected-print-type="${this.dataSelectedPrintType}" `;
        }
        if (this.dataSelectedPrintShortName) {
          dataAttributes += ` data-selected-print-short-name="${this.dataSelectedPrintShortName}" `;
        }

        if (this.dataLeft) {
          dataAttributes += ` data-left="${this.dataLeft}" `;
        }

        if (this.dataTop) {
          dataAttributes += ` data-top="${this.dataTop}" `;
        }

        if (this.dataBottom) {
          dataAttributes += ` data-bottom="${this.dataBottom}" `;
        }

        if (this.dataText && typeof this.dataText != "string") {
          dataAttributes += ` data-text="${JSON.stringify(
            this.dataText.map((text) => escape(text))
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataElementName) {
          dataAttributes += ` data-element-name="${escape(
            this.dataElementName
          )}" `;
        }

        // if (this.dataIsStyleLinked) {
          dataAttributes += ` data-is-style-linked="${escape(
            this.dataIsStyleLinked
          )}" `;
       // }
        if (this.dataColorStyle) {
          dataAttributes += ` data-color-style="${escape(
            this.dataColorStyle
          )}" `;
        }
        if (this.dataProcessStyle) {
          dataAttributes += ` data-process-style="${escape(
            this.dataProcessStyle
          )}" `;
        }
        if (this.dataFontStyle) {
          dataAttributes += ` data-font-style="${escape(this.dataFontStyle)}" `;
        }

        if (this.dataColor) {
          dataAttributes += ` data-color="${JSON.stringify(
            this.dataColor
          ).replace(/"/g, "'")}" `;
        }
        if (this.defaultColor) {
          dataAttributes += ` default-color="${JSON.stringify(
            this.defaultColor
          ).replace(/"/g, "'")}" `;
        }

        dataAttributes += ` data-visible="${this.dataVisible}" `;
        dataAttributes += ` data-required="${this.dataRequired}" `;
        dataAttributes += ` data-optional="${this.dataOptional}" `;
        dataAttributes += ` data-optional-added="${this.dataOptionalAdded}" `;
        dataAttributes += ` data-is-modified="${this.dataIsModified}" `;

        svg[styleIndex + 2] = dataAttributes;

        return svg;
      };
    })(fabric.IText.prototype._toSVG);

    fabric.Textbox.prototype._toSVG = (function (_toSVG) {
      return function () {
        let svg = _toSVG.call(this);

        let styleIndex = svg.indexOf('style="');
        let dataAttributes = '" ';

        if (this.dataFont) {
          dataAttributes += ` data-font="${JSON.stringify(
            this.dataFont
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataSelectedFontFamily) {
          dataAttributes += ` data-selected-font-family="${this.dataSelectedFontFamily}" `;
        }

        if (this.dataSelectedFontSize) {
          dataAttributes += ` data-selected-font-size="${this.dataSelectedFontSize}" `;
        }

        if (Number(this.dataSelectedLetterSpacing) >= 0) {
          dataAttributes += ` data-selected-letter-spacing="${this.dataSelectedLetterSpacing}" `;
        }
        if (Number(this.dataSelectedLineHeight) >= 0) {
          dataAttributes += ` data-selected-line-height="${this.dataSelectedLineHeight}" `;
        }

        if (this.dataType) {
          dataAttributes += ` data-type="${this.dataType}" `;
        }

        if (this.dataMaxLines) {
          dataAttributes += ` data-max-lines="${this.dataMaxLines}" `;
        }

        if (this.dataAlignProperty) {
          dataAttributes += ` data-align-property="${JSON.stringify(
            this.dataAlignProperty
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataPrint) {
          dataAttributes += ` data-print="${JSON.stringify(
            this.dataPrint
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataSelectedPrintType) {
          dataAttributes += ` data-selected-print-type="${this.dataSelectedPrintType}" `;
        }

        if (this.dataSelectedPrintShortName) {
          dataAttributes += ` data-selected-print-short-name="${this.dataSelectedPrintShortName}" `;
        }

        if (this.dataMaxWidth) {
          dataAttributes += ` data-max-width="${this.dataMaxWidth}" `;
        }

        if (this.dataType) {
          dataAttributes += ` data-type="${this.dataType}" `;
        }

        if (this.dataLeft) {
          dataAttributes += ` data-left="${this.dataLeft}" `;
        }

        if (this.dataTop) {
          dataAttributes += ` data-top="${this.dataTop}" `;
        }

        if (this.dataBottom) {
          dataAttributes += ` data-bottom="${this.dataBottom}" `;
        }

        if (this.dataLineHeight) {
          dataAttributes += ` data-line-height="${this.dataLineHeight}" `;
        }

        if (this.dataText && typeof this.dataText != "string") {
          dataAttributes += ` data-text="${JSON.stringify(
            this.dataText.map((text) => escape(text))
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataElementName) {
          dataAttributes += ` data-element-name="${escape(
            this.dataElementName
          )}" `;
        }

       // if (this.dataIsStyleLinked) {
          dataAttributes += ` data-is-style-linked="${escape(
            this.dataIsStyleLinked
          )}" `;
       // }
        if (this.dataColorStyle) {
          dataAttributes += ` data-color-style="${escape(
            this.dataColorStyle
          )}" `;
        }
        if (this.dataProcessStyle) {
          dataAttributes += ` data-process-style="${escape(
            this.dataProcessStyle
          )}" `;
        }
        if (this.dataFontStyle) {
          dataAttributes += ` data-font-style="${escape(this.dataFontStyle)}" `;
        }

        if (this.dataColor) {
          dataAttributes += ` data-color="${JSON.stringify(
            this.dataColor
          ).replace(/"/g, "'")}" `;
        }
        if (this.defaultColor) {
          dataAttributes += ` default-color="${JSON.stringify(
            this.defaultColor
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataDefaultText && typeof this.dataDefaultText != "string") {
          dataAttributes += ` data-default-text="${JSON.stringify(
            this.dataDefaultText.map((text) => escape(text))
          ).replace(/"/g, "'")}" `;
        }

        dataAttributes += ` data-required="${this.dataRequired}" `;
        dataAttributes += ` data-optional="${this.dataOptional}" `;
        dataAttributes += ` data-optional-added="${this.dataOptionalAdded}" `;
        // dataAttributes += ` data-default-text="${this.dataDefaultText}" `;
        dataAttributes += ` data-is-modified="${this.dataIsModified}" `;

        dataAttributes += ` data-visible="${this.dataVisible}" `;
        if (this.dataMonogram) {
          dataAttributes += ` data-monogram="${JSON.stringify(
            this.dataMonogram
          ).replace(/"/g, "'")}" `;
        }

        svg[styleIndex + 2] = dataAttributes;

        return svg;
      };
    })(fabric.Textbox.prototype._toSVG);

    fabric.Image.prototype._toSVG = (function (_toSVG) {
      return function () {
        let svg = _toSVG.call(this);

        let dataAttributes = "<image  ";

        if (this.dataWidth) {
          dataAttributes += ` data-width="${this.dataWidth}" `;
        }

        if (this.dataHeight) {
          dataAttributes += ` data-height="${this.dataHeight}" `;
        }

        if (this.dataPaper) {
          dataAttributes += ` data-paper="${JSON.stringify(
            this.dataPaper
          ).replace(/"/g, "'")}" `;
        }
        if (this.dataFinish) {
          dataAttributes += ` data-finish="${JSON.stringify(
            this.dataFinish
          ).replace(/"/g, "'")}" `;
        }
        if (this.dataColorTags) {
          dataAttributes += ` data-color-tags="${JSON.stringify(
            this.dataColorTags
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataImage) {
          dataAttributes += ` data-image="${JSON.stringify(
            this.dataImage
          ).replace(/"/g, "'")}" `;
        }

        dataAttributes += ` data-visible="${this.dataVisible}" `;
        dataAttributes += ` data-existing-die="${this.dataExistingDie}" `;

        if (this.dataDefaultColour) {
          dataAttributes += ` data-default-colour="${this.dataDefaultColour}" `;
        }

        if (this.dataSelectedPaperType) {
          dataAttributes += ` data-selected-paper-type="${JSON.stringify(
            this.dataSelectedPaperType
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataType) {
          dataAttributes += ` data-type="${this.dataType}" `;
        }

        if (this.dataPrint) {
          dataAttributes += ` data-print="${JSON.stringify(
            this.dataPrint
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataSelectedPrintType) {
          dataAttributes += ` data-selected-print-type="${this.dataSelectedPrintType}" `;
        }

        if (this.dataSelectedPrintShortName) {
          dataAttributes += ` data-selected-print-short-name="${this.dataSelectedPrintShortName}" `;
        }

        if (this.dataLeft) {
          dataAttributes += ` data-left="${this.dataLeft}" `;
        }

        if (this.dataTop) {
          dataAttributes += ` data-top="${this.dataTop}" `;
        }

        if (this.dataBottom) {
          dataAttributes += ` data-bottom="${this.dataBottom}" `;
        }

        if (this.dataElementName) {
          dataAttributes += ` data-element-name="${escape(
            this.dataElementName
          )}" `;
        }

        // if (this.dataIsStyleLinked) {
          dataAttributes += ` data-is-style-linked="${escape(
            this.dataIsStyleLinked
          )}" `;
       // }
        if (this.dataColorStyle) {
          dataAttributes += ` data-color-style="${escape(
            this.dataColorStyle
          )}" `;
        }
        if (this.dataProcessStyle) {
          dataAttributes += ` data-process-style="${escape(
            this.dataProcessStyle
          )}" `;
        }
        if (this.dataFontStyle) {
          dataAttributes += ` data-font-style="${escape(this.dataFontStyle)}" `;
        }

        if (this.dataColor) {
          dataAttributes += ` data-color="${JSON.stringify(
            this.dataColor
          ).replace(/"/g, "'")}" `;
        }
        if (this.defaultColor) {
          dataAttributes += ` default-color="${JSON.stringify(
            this.defaultColor
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataImgRight) {
          dataAttributes += ` data-img-right="${this.dataImgRight}" `;
        }

        if (this.dataImgBottom) {
          dataAttributes += ` data-img-bottom="${this.dataImgBottom}" `;
        }
        if (this.dataClipart) {
          dataAttributes += ` data-clipart="${JSON.stringify(
            this.dataClipart
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataSurface && typeof this.dataSurface != "string") {
          dataAttributes += ` data-surface="${JSON.stringify(
            this.dataSurface
          ).replace(/"/g, "'")}" `;
        } else if (this.dataSurface) {
          dataAttributes += ` data-surface="${this.dataSurface}" `;
        }

        dataAttributes += ` data-required="${this.dataRequired}" `;
        dataAttributes += ` data-is-modified="${this.dataIsModified}" `;

        dataAttributes += ` crossOrigin="Anonymous" `;

        svg[0] = dataAttributes;

        return svg;
      };
    })(fabric.Image.prototype._toSVG);

    fabric.Path.prototype._toSVG = (function (_toSVG) {
      return function () {
        let svg = _toSVG.call(this);

        let dataAttributes = "<path  ";

        if (this.dataWidth) {
          dataAttributes += ` data-width="${this.dataWidth}" `;
        }

        if (this.dataHeight) {
          dataAttributes += ` data-height="${this.dataHeight}" `;
        }

        if (this.dataType) {
          dataAttributes += ` data-type="${this.dataType}" `;
        }

        if (this.dataLeft) {
          dataAttributes += ` data-left="${this.dataLeft}" `;
        }

        if (this.dataTop) {
          dataAttributes += ` data-top="${this.dataTop}" `;
        }

        if (this.dataBottom) {
          dataAttributes += ` data-bottom="${this.dataBottom}" `;
        }

        if (this.dataPrint) {
          dataAttributes += ` data-print="${JSON.stringify(
            this.dataPrint
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataSelectedPrintType) {
          dataAttributes += ` data-selected-print-type="${this.dataSelectedPrintType}" `;
        }

        if (this.dataSelectedPrintShortName) {
          dataAttributes += ` data-selected-print-short-name="${this.dataSelectedPrintShortName}" `;
        }

        if (this.dataMotif) {
          dataAttributes += ` data-motif="${JSON.stringify(
            this.dataMotif
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataSelectedMotifName) {
          dataAttributes += ` data-selected-motif-name="${this.dataSelectedMotifName}" `;
        }
        if (this.dataSelectedMotif) {
          dataAttributes += ` data-selected-motif="${JSON.stringify(
            this.dataSelectedMotif
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataSelectedMotifUrl) {
          dataAttributes += ` data-selected-motif-url="${this.dataSelectedMotifUrl}" `;
        }

        if (this.dataMonogram) {
          dataAttributes += ` data-monogram="${JSON.stringify(
            this.dataMonogram
          ).replace(/"/g, "'")}" `;
        }

        if (this.dataSelectedMonogram) {
          dataAttributes += ` data-selected-monogram="${JSON.stringify(
            this.dataSelectedMonogram
          ).replace(/"/g, "'")}" `;
        }
        if (this.dataSelectedMonogramName) {
          dataAttributes += ` data-selected-monogram-name="${this.dataSelectedMonogramName}" `;
        }

        if (this.dataSelectedMonogramPointSize) {
          dataAttributes += ` data-selected-monogram-point-size="${this.dataSelectedMonogramPointSize}" `;
        }
        if (this.dataSelectedMonogramLetterCount) {
          dataAttributes += ` data-selected-monogram-letter-count="${this.dataSelectedMonogramLetterCount}" `;
        }

        if (this.dataMonogramUserText) {
          dataAttributes += ` data-monogram-user-text="${escape(
            this.dataMonogramUserText
          )}" `;
        }

        if (this.dataMonogramUserPreviewText) {
          dataAttributes += ` data-monogram-user-preview-text="${escape(
            this.dataMonogramUserPreviewText
          )}" `;
        }

        if (this.dataElementName) {
          dataAttributes += ` data-element-name="${escape(
            this.dataElementName
          )}" `;
        }

       // if (this.dataIsStyleLinked) {
          dataAttributes += ` data-is-style-linked="${escape(
            this.dataIsStyleLinked
          )}" `;
       // }
        if (this.dataColorStyle) {
          dataAttributes += ` data-color-style="${escape(
            this.dataColorStyle
          )}" `;
        }
        if (this.dataProcessStyle) {
          dataAttributes += ` data-process-style="${escape(
            this.dataProcessStyle
          )}" `;
        }
        if (this.dataFontStyle) {
          dataAttributes += ` data-font-style="${escape(this.dataFontStyle)}" `;
        }

        if (this.dataMonogramCharacters && this.dataMonogramCharacters.length) {
          dataAttributes += ` data-monogram-characters="${JSON.stringify(
            this.dataMonogramCharacters.map((char) => escape(char))
          ).replace(/"/g, "'")}" `;
        }
        if (this.dataHAnchor) {
          dataAttributes += ` data-h-anchor="${escape(this.dataHAnchor)}" `;
        }
        if (this.dataVAnchor) {
          dataAttributes += ` data-v-anchor="${escape(this.dataVAnchor)}" `;
        }

        if (this.dataColor) {
          dataAttributes += ` data-color="${JSON.stringify(
            this.dataColor
          ).replace(/"/g, "'")}" `;
        }

        if (this.defaultColor) {
          dataAttributes += ` default-color="${JSON.stringify(
            this.defaultColor
          ).replace(/"/g, "'")}" `;
        }
        if (this.dataClipart) {
          if (this.dataImgRight) {
            dataAttributes += ` data-img-right="${this.dataImgRight}" `;
          }

          if (this.dataImgBottom) {
            dataAttributes += ` data-img-bottom="${this.dataImgBottom}" `;
          }

          dataAttributes += ` data-clipart="${JSON.stringify(
            this.dataClipart
          ).replace(/"/g, "'")}" `;
        }

        dataAttributes += ` data-visible="${this.dataVisible}" `;
        dataAttributes += ` data-required="${this.dataRequired}" `;
        dataAttributes += ` data-is-modified="${this.dataIsModified}" `;
        dataAttributes += ` data-existing-die="${this.dataExistingDie}" `;

        if (this.dataDefaultColour) {
          dataAttributes += ` data-default-colour="${this.dataDefaultColour}" `;
        }

        svg[0] = dataAttributes;

        return svg;
      };
    })(fabric.Path.prototype._toSVG);

    fabric.IText.prototype.onKeyDown = (function (onKeyDown) {
      return function (e) {
        if (vm.selectedCanvas.dataType == "monogram") {
          if (e.keyCode == 8) this.fromBackSpace = true;
          else {
            this.fromBackSpace = false;
            if (e.target.textLength >= this.maxlength) {
              e.preventDefault();
              e.stopPropagation();
            }
          }
          if (e.keyCode == 13) {
            if (this.textLines.length >= 1) {
              e.preventDefault();
              e.stopPropagation();
            } else onKeyDown.call(this, e);
          }
        }
      };
    })(fabric.IText.prototype.onKeyDown);

    fabric.Textbox.prototype.onKeyDown = (function (onKeyDown) {
      return function (e) {
        if (e.keyCode == 8) this.fromBackSpace = true;
        else {
          this.fromBackSpace = false;
          if (e.target.textLength >= this.maxlength) {
            e.preventDefault();
            e.stopPropagation();
          }
          if (this.textLines.length > this.dataMaxLines) {
            e.preventDefault();
            e.stopPropagation();
          }
        }
        if (e.keyCode == 13) {
          if (this.textLines.length > this.dataMaxLines - 1) {
            e.preventDefault();
            e.stopPropagation();
          } else onKeyDown.call(this, e);
        }
      };
    })(fabric.Textbox.prototype.onKeyDown);

    fabric.Textbox.prototype.onInput = (function () {
      return function (e) {
        if (this.fromBackSpace) {
          this.callSuper("onInput", e);
        }
        if (
          this.__lineWidths[Number(this.dataMaxLines) - 1] + this.fontSize >
          fabric.util.parseUnit(Number(this.dataMaxWidth) + "pt")
        ) {
          this.hiddenTextarea.value = this.text;
          return;
        }
        this.callSuper("onInput", e);
      };
    })(fabric.Textbox.prototype.onInput);

    vm.productFabric.canvas.on("text:changed", function (event) {
      let element = event.target;
      if (element.dataAlignProperty) {
        if (element.dataAlignProperty.anchorAlignment == "bottom") {
          if (element.oldLineCount < element.__lineHeights.length) {
            element.top =
              element.top -
              element.__lineHeights[0] *
                (element.__lineHeights.length - element.oldLineCount);
            element.oldLineCount = element.__lineHeights.length;
          } else if (element.oldLineCount > element.__lineHeights.length) {
            element.top =
              element.top +
              element.__lineHeights[0] *
                (element.oldLineCount - element.__lineHeights.length);
            element.oldLineCount = element.__lineHeights.length;
          }
          element.dataTop = (element.top * 72) / fabric.DPI;
          element.dataBottom =
            ((element.top + element.height) * 72) / fabric.DPI;
        } else if (element.dataAlignProperty.anchorAlignment == "center") {
          if (element.oldLineCount < element.__lineHeights.length) {
            element.top =
              element.top -
              (element.__lineHeights[0] *
                (element.__lineHeights.length - element.oldLineCount)) /
                2;
            element.oldLineCount = element.__lineHeights.length;
          } else if (element.oldLineCount > element.__lineHeights.length) {
            element.top =
              element.top +
              (element.__lineHeights[0] *
                (element.oldLineCount - element.__lineHeights.length)) /
                2;
            element.oldLineCount = element.__lineHeights.length;
          }
          element.dataTop = (element.top * 72) / fabric.DPI;
          element.dataBottom =
            ((element.top + element.height) * 72) / fabric.DPI;
        }
      }
      element.dataIsModified = true;
      element.dataText = element.textLines;

      if (
        ["true", true].includes(element.dataOptional) &&
        element.textLines[0].length == 0
      ) {
        vm.isTextElementPresent = false;
        if (!vm.optionalTextElements.includes(element)) {
          vm.optionalTextElements.push(element);
          vm.optionalTextElements[
            vm.optionalTextElements.length - 1
          ].extraProperty = {
            name: element.dataElementName,
            optional: true,
            originalText:
              element.text.length > 0
                ? element.dataText
                : element.dataDefaultText,
            //dataElementName.split("\n")  //dataDefaultText
          };
        }
      }
      vm.optionalTextElements.filter((ele) => {
        if (ele.dataElementName == element.dataElementName) {
          if (
            element.textLines &&
            element.textLines.length &&
            element.textLines[0].length == 0
          ) {
            ele.extraProperty.optional = true;
          } else {
            vm.isTextElementPresent = true;
            ele.extraProperty.optional = false;
          }
        }
      });

      vm.textChangedKey = !vm.textChangedKey;

      clearTimeout(vm.textChangeTimeoutId);
      vm.textChangeTimeoutId = setTimeout(() => {
        vm.userMadeChangesInSvg();
      }, 500);
    });
  },
  async created() {
    try {
      window.crPdpInstance = this;
      window.addEventListener("beforeunload", handleBeforeUnload);

      try {
        let resp = await this.$store.dispatch("sessionOne/fetchOne", {
          sessionId: this.builderSessionId,
        });
        if (resp && resp.response) {
          let sessionOneDetails = resp.response;
          if (sessionOneDetails && sessionOneDetails.productData) {
            this.productSku = sessionOneDetails.productData.sku;
            this.productId = sessionOneDetails.productData.productId;
            this.userQuantity = sessionOneDetails.productData.quantity;
          }
          if (sessionOneDetails && sessionOneDetails.quoteData) {
            this.itemId = sessionOneDetails.quoteData.itemId;
            this.cartId = sessionOneDetails.quoteData.quoteId;

            if (sessionOneDetails.quoteData.qty)
              this.userQuantity = sessionOneDetails.quoteData.qty;
          }

          // pricer bundle loaded
          if (this.productSku) {
            window.pricerFieldsIdx = pricerFieldsIdx;
            let currentDateInIso = new Date().toISOString();
            let resp = await this.$store.dispatch(
              "pricerBundleOne/fetchPriceBundle",
              { skuList: [this.productSku], availableAt: currentDateInIso }
            );
            if (resp) {
              window.pricer = {
                ...pricerFiles,
                ...resp,
              };
            }
            console.log(window.pricer);
          }
        }
      } catch (err) {
        console.error(err);
        this.$snotify.error(
          `Cloud communication error, please start new session `,
          {
            position: "rightTop",
            showProgressBar: false,
          }
        );
        this.inProgress = false;
       // this.setCorruptedSessionData();
        throw err;
      }

      if (this.productParts.length === 0) {
        await this.$store.dispatch("designerOne/fetchOne", this.productId);
        // if (!this.itemId) {
        if (this.productOne.id)
          await this.$store.dispatch("productPartList/fetchList", {
            productId: this.productOne.id,
            sku: this.productSku,
          });
      }

      this.goToPartSelectPage();

      this.$store.dispatch("productOne/fetchFonts");
      this.$store.dispatch("productOne/fetchProductDetails", this.productSku);
    } catch (err) {
      console.error("Error in builder page loading ",err,{ sessionId: this.builderSessionId, sku:this.productSku });
      this.$snotify.error(`Cloud communication error `, {
        position: "rightTop",
        showProgressBar: false,
      });
      throw err;
    }
  },
  beforeDestroy() {
    window.onbeforeunload = null;
  },
};
</script>
<style lang="scss">
.productpage-select-input .v-field__input {
  align-items: center;
}
.productpage-select-input .v-field__append-inner {
  align-items: center;
  display: flex;
  padding-top: unset;
}

.productpage-select-input
  .v-locale--is-ltr
  .v-field--variant-outlined
  .v-field__outline__start,
.productpage-select-input
  .v-locale--is-ltr
  .v-field--variant-outlined
  .v-field__outline__end {
  border-radius: 0px !important;
}
.font-size-text-input .v-field__input {
  padding: 0px !important;
}
.v-tabs-slider-wrapper {
  color: #2850da;
  max-height: 1px !important;
}

.v-tabs-bar {
  background-color: transparent !important;
}
.v-input__slot {
  padding: 0px !important;
}
.v-text-field.v-text-field--solo .v-label {
  padding-left: 10px;
}
.productpage-stylebar-tabs .v-slide-group__prev {
  display: none !important;
}
.theme--light.v-label {
  color: #2850da;
  text-transform: uppercase !important;
}

.v-autocomplete__content.v-menu__content {
  max-width: 100px;
}
.v-list-item__subtitle {
  word-break: break-word;
  overflow-wrap: break-word;
}
.text-white.theme--light.v-label {
  color: #ffffff !important;
  text-transform: uppercase !important;
}
.paper-tab-full-width .v-tab {
  max-width: 100% !important;
}
.height .v-tabs-bar {
  height: 28px;
}
.tab-bottom-line .v-tabs-slider-wrapper {
  max-height: 0px !important;
}
.v-tab--active .tab-underline {
  border-bottom: 1px solid #2850da;
  background: transparent;
}
.bg-transparent {
  background: transparent;
}
.input-select-primary-border {
  border: thin solid #2850da !important;
}
.input-select-border-radius {
  border-radius: 6px !important;
}
.font-size-slider > .v-input__control > .v-slider__container > .v-slider-track {
  height: 2px !important;
}
.font-size-slider
  > .v-input__control
  > .v-slider__container
  > .v-slider-thumb
  > .v-slider-thumb__surface {
  height: 12px !important;
  width: 12px !important;
}
.font-size-slider
  > .v-input__control
  > .v-slider__container
  > .v-slider-thumb
  > .v-slider-thumb__ripple {
  height: 12px !important;
  width: 12px !important;
}
.bg-white {
  background-color: #ffffff !important;
}
.hidden-elements-radio-label > .v-label {
  opacity: 1;
  font-size: 16px !important;
}
.productpage-part-select-menu.v-menu > .v-overlay__content.v-select__content {
  border-radius: 0px !important;
}
.productpage-part-select-menu.v-menu > .v-overlay__content > .v-list {
  border: thin solid #2850da !important;
  border-radius: 0px !important;
  background: transparent !important;
}
.productpage-part-select-menu--active {
  background: #9cc1ef5c !important;
}
.productpage-part-select-menu > .v-overlay__content > .v-list > .v-list-item {
  min-height: 34px !important;
  font-size: 12px !important;
}
.p-word-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  white-space: pre-wrap;
}
.v-btn.v-slide-group-item--active.v-tab--selected.v-btn--block.v-theme--light.v-btn--density-default.v-btn--size-default.v-btn--variant-text.v-tab.productpage-tab-slider
  .v-btn__content
  div {
  height: 0px !important;
}
.v-btn.v-slide-group-item--active.v-tab--selected.v-btn--block.v-theme--light.text-primary.v-btn--density-default.v-btn--size-default.v-btn--variant-text.v-tab.monogram-tab-border
  .v-btn__content
  div {
  height: 1px !important;
}
.v-btn.v-slide-group-item--active.v-tab--selected.v-btn--block.v-theme--light.text-primary.v-btn--density-default.v-btn--size-default.v-btn--variant-text.v-tab.monogram-tab-border-y
  .v-btn__content
  div {
  height: 1px !important;
}
.part-change-btns {
  position: unset !important;
  // style for overlay properties
  --v-theme-background-overlay-multiplier: 0 !important;
  --v-theme-overlay-multiplier: 0 !important;
}
.scroll-window {
  max-height: calc(100vh - 100px);
}
</style>

<style src="./ProductDetailComponent.scss" lang="scss" />
// sequence to load product part in canvas
// this.updatePage()
// vm.updateCanvas(vm.productFabric.canvas, objects)
// this.loadAllObjectsInCanvas();