<template>
  <div>
    <v-row v-if="!isMobileDevice">
      <v-col>
        <v-card tile flat>
          <v-card-text class="px-0 py-2">
            <v-row no-gutters class="mb-3">
              <v-col cols="12" sm="8" class="pt-4 pr-3" v-if="printLength">
                <span
                  class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
                  >Print Process :</span
                >
                <v-select
                  v-model="monogramsProperty.selectedPrintMethod"
                  :items="monogramsProperty.printOptions"
                  @update:modelValue="setPrintMethod()"
                  class="mt-0 productpage-select-input overflow-btn-icon select-change-icon rounded-0 input-select-primary-border input-select-border-radius-0"
                  hide-details
                  density="compact"
                  variant="solo"
                  elevation="0"
                  ref="printMethod"
                  menu-icon="mdi-plus"
                  item-title="text"
                  item-value="text"
                  :disabled="isStyleDisabled"
                >
                  <template v-slot:selection="{ item }">
                    <span
                      class="ml-3 text-primary fontsize-12 regular-font text-uppercase"
                    >
                      {{ item && item.raw ? item.raw.text : "" }}
                    </span>
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                      class="cursor-pointer"
                      :class="
                        monogramsProperty.selectedPrintMethod == item.raw.text
                          ? 'productpage-part-select-menu--active'
                          : ''
                      "
                    >
                      <div class="d-flex justify-space-between">
                        <v-list-item-title
                          v-bind="props"
                          class="d-block text-truncate fontsize-12 regular-font text-primary text-uppercase"
                          >{{
                            item && item.raw ? item.raw.text : ""
                          }}</v-list-item-title
                        >
                      </div>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                class="pt-4 pl-3"
                v-if="monogramsProperty && monogramsProperty.showStyleControl"
              >
                <span
                  class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
                  >Style :</span
                >
                <v-switch
                  v-model="isStyleApplied"
                  @update:modelValue="setStyleForAllMatched(selectedObject)"
                  density="compact"
                  hide-details
                  color="primary"
                  class="alternate-svg-switch mt-0 text-primary"
                >
                  <template v-slot:label>
                    <span
                      class="text-primary text-uppercase fontsize-12 body-font"
                      >{{ isStyleApplied ? "LINKED" : "UNLINKED" }}</span
                    >
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="12" v-if="isStyleDisabled">
                <p class="text-red fontsize-14 body-font">
                  This is a styled element. To change it we recommend:
                  <br />
                  - going back to {{ firstPartName }} and make adjustments there
                  <br />
                  - OR you can Unlink from style and make your changes for this
                  item only
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6">
                <h4 class="text-uppercase fontsize-12 body-font text-primary">
                  Monogram:
                </h4>
              </v-col>
              <v-col cols="6" class="text-right">
                <h4
                  class="text-uppercase d-block body-font fontsize-12 fontcolor-grey-darken text-truncate"
                >
                  {{ selectedMonogramDesignName }}
                </h4>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="monogramsLength" class="max-height-25">
              <v-col cols="3" class="px-0 pt-2">
                <div
                  @click="removeSelectedMonogram()"
                  class="pt-3 productpage-monogram-library square-danger-border cursor-pointer d-flex align-center"
                >
                  <v-avatar class="cursor-pointer user-image ma-1">
                    <v-icon
                      :size="$vuetify.display.mdAndDown ? 60 : 80"
                      class="text-red"
                      >mdi-window-close</v-icon
                    >
                  </v-avatar>
                </div>
              </v-col>
              <v-col
                cols="3"
                align="center"
                class="px-0 py-2"
                v-for="(item, index) in monograms"
                v-bind:key="index"
              >
                <v-tooltip location="bottom" :text="item.name">
                  <template v-slot:activator="{ props }">
                    <div
                      v-bind="props"
                      v-if="item.thumbnailUrl"
                      @click="setSelectedMonogram(item)"
                      v-bind:class="{
                        'productpage-shape-border':
                          item.name == selectedMonogramDesignName,
                      }"
                      class="productpage-monogram-library cursor-pointer d-flex align-center"
                    >
                      <v-avatar class="cursor-pointer user-image ma-1">
                        <v-img :src="item.thumbnailUrl" />
                      </v-avatar>
                    </div>
                  </template>
                </v-tooltip>
              </v-col>
            </v-row>
            <div v-else>
              <p class="text-red py-2 mb-0">
                Monograms are not present to change
              </p>
            </div>
            <div class="productpage-hr-line my-4 mx-n7"></div>
            <v-row no-gutters class="py-2">
              <v-col>
                <h4 class="text-uppercase fontsize-12 body-font text-primary">
                  color:
                </h4>
              </v-col>
              <v-col>
                <h4
                  v-if="monogramsLength"
                  class="text-uppercase body-font fontcolor-grey-darken float-right"
                >
                  {{ selectedMonogramColorName }}
                </h4>
              </v-col>
            </v-row>
            <v-row class="pr-1 pb-0" v-if="monogramsLength && !isStyleDisabled">
              <ColorComponent
                class="mx-2"
                :key="selectedMonogramColor"
                v-bind:colorCode="selectedMonogramColor"
                :printMethod="monogramsProperty.selectedPrintMethod"
                @clicked="setMonogramColor"
                :colorName="selectedMonogramColorName"
              ></ColorComponent>
            </v-row>
            <v-row v-else-if="isStyleDisabled">
              <v-col cols="12" class="pa-0 pl-3">
                <p class="text-red mb-3">No color options available.</p>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" class="pa-0 pl-3">
                <p class="text-red mb-0">No color is present to change.</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- monogram mobile view  -->
    <v-card tile flat v-if="isMobileDevice">
      <v-card-text class="pb-0">
        <v-row v-if="selectedObject && monogramsLength" class="mb-2">
          <v-col
            cols="12"
            class="pt-4 pl-3"
            v-if="monogramsProperty && monogramsProperty.showStyleControl"
          >
            <span
              class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
              >Style :</span
            >
            <v-switch
              v-model="isStyleApplied"
              @update:modelValue="setStyleForAllMatched(selectedObject)"
              density="compact"
              hide-details
              color="primary"
              class="alternate-svg-switch mt-0 text-primary"
            >
              <template v-slot:label>
                <span
                  class="text-primary text-uppercase fontsize-12 body-font"
                  >{{ isStyleApplied ? "LINKED" : "UNLINKED" }}</span
                >
              </template>
            </v-switch>
          </v-col>
          <v-col cols="12" v-if="isStyleDisabled">
            <p class="text-red fontsize-14 body-font">
              This is a styled element. To change it we recommend:
              <br />
              - going back to {{ firstPartName }} and make adjustments there
              <br />
              - OR you can Unlink from style and make your changes for this item
              only
            </p>
          </v-col>
        </v-row>
        <v-row
          v-if="monogramsLength && monogramsProperty.selectedMonogramObject"
          class="d-flex align-center mx-0"
        >
          <v-col cols="6" class="py-0">
            <v-avatar class="cursor-pointer monogram-thumb-preview ma-1">
              <v-img
                :src="monogramsProperty.selectedMonogramObject.thumbnailUrl"
              />
            </v-avatar>
          </v-col>
          <v-col cols="6" class="text-center pt-0 pr-0">
            <v-btn
              @click="setSelectedMonogram(selectedMonogramsName)"
              class="text-uppercase ls-0 pb-1 px-1 regular-font fontsize-12 rounded-0"
              tile
              block
              flat
              variant="outlined"
              color="primary"
              >UPDATE</v-btn
            >
          </v-col>
        </v-row>
        <v-row v-else-if="monogramsLength && monogramsProperty.monogramObject">
          <v-col cols="12" class="pt-0">
            <v-btn
              @click="setSelectedMonogram(selectedMonogramsName)"
              class="text-uppercase ls-0 pb-1 px-1 regular-font fontsize-12"
              tile
              block
              flat
              variant="outlined"
              color="primary"
              >LAUNCH MONOGRAM STUDIO</v-btn
            >
          </v-col>
        </v-row>
        <div v-else>
          <p class="text-red py-2 mb-0">Monograms are not present to change</p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { altCharCodes } from "@/services/util.service";
import ColorComponent from "@/components/personalize/product-detail/ColorComponent.vue";
export default {
  name: "MonogramsComponent",
  props: [
    "monogramsProperty",
    "selectedObject",
    "isStyleDisabled",
    "firstPartName",
  ],
  components: {
    ColorComponent,
  },
  data: () => ({
    monogramLetters: [],
    monogramStudioDailog: false,
    monogramStudioExitDailog: false,
    monogramStudioCongratsDailog: false,
    setSelectedMonogramStyle: {},
    selectedMonogram: "",
    monogramActiveTab: "style",
    selectedMonogramsName: {},
    colorObject: "",
    reservedChars: ["1", "2", "3", "4", "5"],
    alphaRequiredRule: [
      (v) => /^[a-zA-Z]+$/.test(v) || "This field must contain alphabet only",
    ],
    generatedMonogramPreview: "",
    isStyleApplied: false,
  }),
  computed: {
    monograms() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.selectedMonogramsName = this.monogramsProperty.selectedMonogramsName;
      if (this.monogramsProperty.monograms)
        return this.monogramsProperty.monograms.filter((obj) => {
          if (obj.url) return obj;
        });
      return [];
    },
    monogramsLength() {
      return this.monograms.length;
    },
    selectedMonogramDesignName: {
      get: function () {
        return this.monogramsProperty.selectedMonogramsName;
      },
      set: function (newMonogram) {
        return (this.monogramsProperty.selectedMonogramsName = newMonogram);
      },
    },
    setMonogramLetters: {
      get: function () {
        let lettersCount = this.monograms
          .filter((obj) => obj.name == this.selectedMonogramDesignName)
          .map((obj) => obj.lettersCount)
          .find((obj) => obj);
        if (!lettersCount) return 0;
        return Number(lettersCount);
      },
    },
    setMonogramActiveTab: {
      get: function () {
        return this.setMonogramLetters - 1;
      },
      set: function (val) {
        return val;
      },
    },
    selectedMonogramColor: {
      get: function () {
        return this.monogramsProperty.selectedMonogramColor;
      },
      set: function (newColor) {
        return (this.monogramsProperty.selectedMonogramColor = newColor);
      },
    },
    selectedMonogramColorName: {
      get: function () {
        return this.monogramsProperty.selectedMonogramColorName;
      },
      set: function (newColorName) {
        return (this.monogramsProperty.selectedMonogramColorName =
          newColorName);
      },
    },
    printLength() {
      return this.monogramsProperty.printOptions.length;
    },
    isMobileDevice() {
      return this.$vuetify.display.xs ? true : false;
    },
  },
  watch: {
    setMonogramLetters: function () {
      this.setMonogramActiveTab = this.setMonogramLetters - 1;
    },
    monograms: function () {
      this.isStyleApplied = this.monogramsProperty.dataIsStyleLinked;
    },
  },
  methods: {
    setStyleForAllMatched(selectedObj) {
      this.$emit("setStyleForAllMatched", {
        currentObject: selectedObj,
        isStyleApplied: this.isStyleApplied,
      });
    },
    allowAlphaOnly(event) {
      let char = String.fromCharCode(event.keyCode);
      if (/^[A-Za-z]+$/.test(char)) {
        return true;
      } else event.preventDefault();
    },
    setPrintMethod() {
      this.$emit(
        "changePrintMethod",
        this.monogramsProperty.selectedPrintMethod
      );
    },
    getTextByLetterCount(lettersCount) {
      let text = null;
      switch (Number(lettersCount)) {
        case 1:
          text = "a";
          break;
        case 2:
          text = "ab";
          break;
        case 3:
          text = "abc";
          break;
        case 4:
          text = "abcd";
          break;
        case 5:
          text = "abcde";
          break;
        default:
          text = "abc";
      }
      return text;
    },
    changeToNextTab() {
      if (this.monogramActiveTab == "style")
        this.monogramActiveTab = "initials";
      else if (this.monogramActiveTab == "initials")
        this.monogramActiveTab = "color";
      else if (this.monogramActiveTab == "color")
        this.monogramStudioCongratsDailog = true;
    },
    openCongratsModal() {
      this.monogramStudioCongratsDailog = true;
    },
    generatePreview() {
      try {
        let previewString = "";
        let monogramBuildString = this.selectedMonogram.buildString
          ? this.selectedMonogram.buildString.trim()
          : "";
        for (let i = 0; i < monogramBuildString.length; i++) {
          let stringChar = monogramBuildString[i];
          if (this.reservedChars.indexOf(stringChar) == -1)
            previewString += stringChar;
          else {
            let nextChar = monogramBuildString[i + 1];
            let secondNexChar = monogramBuildString[i + 2];
            if (nextChar == "A" && secondNexChar == "l") {
              let previewChar =
                this.monogramLetters[
                  this.reservedChars[this.reservedChars.indexOf(stringChar)] - 1
                ];
              let altCode = altCharCodes.filter(
                (altcode) => altcode.char == previewChar.toUpperCase()
              )[0];
              if (altCode) previewString += altCode.altCode;
              i = i + 3;
            } else if (nextChar == "A") {
              previewString += this.monogramLetters[stringChar - 1]
                ? this.monogramLetters[stringChar - 1].toUpperCase()
                : "";
              i = i + 1;
            } else if (nextChar == "a") {
              previewString += this.monogramLetters[stringChar - 1]
                ? this.monogramLetters[stringChar - 1].toLowerCase()
                : "";
              i = i + 1;
            } else {
              this.$snotify.warning(`Error in generating Monogram`, {
                position: "rightTop",
                showProgressBar: false,
              });
            }
          }
        }
        this.generatedMonogramPreview = previewString;
        console.log("Generated preview string ", previewString);
      } catch (err) {
        this.$snotify.error(`Cloud communication error `, {
          position: "rightTop",
          showProgressBar: false,
        });
        // this.setCorruptedSessionData()
        throw err;
      }
    },
    async setSelectedMonogram(item) {
      this.$emit("clickedOnSelectedMonogram", item);
    },
    addMonogramOnCanvas() {
      this.monogramStudioDailog = false;
      this.monogramStudioExitDailog = false;
      this.monogramStudioCongratsDailog = false;
      let userText = this.monogramLetters.toString().split(",").join("");
      this.$emit("clicked", {
        monogram: this.selectedMonogram,
        userText: userText,
        userPreviewText: this.generatedMonogramPreview,
      });
      if (this.isMobileDevice && this.colorObject)
        this.$emit("changeMonogramColor", this.colorObject);
    },
    closeMonogramMenu() {
      this.monogramStudioExitDailog = true;
    },
    closeMonogramExitMenu() {
      this.monogramStudioDailog = false;
      this.monogramStudioExitDailog = false;
      this.monogramStudioCongratsDailog = false;
      this.monogramLetters = [];
    },
    continueMonogramExitMenu() {
      this.monogramStudioExitDailog = false;
    },
    setMonogramColor(color) {
      this.selectedMonogramColor = color.colorHex;
      this.selectedMonogramColorName = color.colorName;
      this.colorObject = color;

      if (this.isMobileDevice) {
        this.setSelectedMonogramStyle =
          `line-height:normal; font-family:` +
          this.selectedMonogramDesignName +
          `; color:` +
          this.selectedMonogramColor;
      } else this.$emit("changeMonogramColor", color);
    },
    removeSelectedMonogram() {
      this.selectedMonogramColor = "";
      this.selectedMonogramColorName = "";
      this.selectedMonogramDesignName = "";
      this.colorObject = "";
      this.$emit("removeSelectedMonogram");
    },
  },
  created() {
    this.isStyleApplied = this.monogramsProperty.dataIsStyleLinked;
  },
};
</script>
<style scoped>
.line-height-16 {
  line-height: 1rem !important;
}
.line-height-24 {
  line-height: 1.5rem !important;
}
.monogram-tab-border {
  border: 1px solid blue !important;
  border-radius: 0px !important;
}
.monogram-tab-border-y {
  border-top: 1px solid blue !important;
  border-bottom: 1px solid blue !important;
  border-radius: 0px !important;
}
.p-word-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  white-space: pre-wrap;
}
.tab-active-border-none {
  border: none !important;
}
.v-slide-group-item--active.v-tab--selected.text-primary {
  border-bottom: none !important;
}
.monogram-tab-border > .v-btn__content > .v-tab__slider {
  background: red !important;
  height: 1px !important;
}
.monogram-tab-border-y > .v-btn__content > .v-tab__slider {
  background: red !important;
}
</style>
<style src="./MonogramComponent.scss" lang="scss"/>
