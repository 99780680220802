<template>
  <div class="h-100">
    <v-container
      fluid
      class="white h-100"
      :class="isMobileDevice ? '' : 'pt-0'"
    >
      <ProductSummaryComponent :key="userQuantity" />
    </v-container>
  </div>
</template>
<script>
/* eslint-disable */
import ProductSummaryComponent from "@/components/personalize/product-detail/ProductSummaryComponent.vue";

export default {
  name: "ProductSummary",
  components: {
    ProductSummaryComponent,
  },
  computed: {
    isMobileDevice() {
      return this.$vuetify.display.xs ? true : false;
    },
  },
};
</script>
<style src="./product.scss" lang="scss" />
