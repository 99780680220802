<template>
  <div>
    <v-card tile flat :key="motifsLength">
      <!-- desktop view  -->
      <v-card-text
        v-if="!isMobileDevice"
        class="px-0 pb-0"
        :class="motifsLength === 0 ? 'pt-0' : ''"
      >
        <v-row no-gutters>
          <v-col cols="12" sm="8" class="py-2 pr-3" v-if="printLength">
            <span
              class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
              >Print Process :</span
            >
            <v-select
              v-model="elementsProperty.selectedPrintMethod"
              :items="elementsProperty.printOptions"
              @update:modelValue="setPrintMethod()"
              class="mt-0 productpage-select-input overflow-btn-icon select-change-icon rounded-0 input-select-primary-border input-select-border-radius-0"
              hide-details
              density="compact"
              flat
              variant="solo"
              elevation="0"
              ref="printMethod"
              menu-icon="mdi-plus"
              item-title="text"
              item-value="text"
              :disabled="isStyleDisabled"
            >
              <template v-slot:selection="{ item }">
                <span
                  class="ml-3 text-primary fontsize-12 regular-font text-uppercase"
                >
                  {{ item && item.raw ? item.raw.text : "" }}
                </span>
              </template>
              <template v-slot:item="{ props, item }">
                <v-list-item
                  :class="
                    elementsProperty.selectedPrintMethod == item.raw.text
                      ? 'productpage-part-select-menu--active'
                      : ''
                  "
                >
                  <div class="d-flex justify-space-between">
                    <v-list-item-title
                      v-bind="props"
                      class="d-block text-truncate fontsize-12 regular-font text-primary text-uppercase"
                      >{{
                        item && item.raw ? item.raw.text : ""
                      }}</v-list-item-title
                    >
                  </div>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="pt-4 pl-3"
            v-if="elementsProperty && elementsProperty.showStyleControl"
          >
            <span
              class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
              >Style :</span
            >
            <v-switch
              v-model="isStyleApplied"
              @update:modelValue="setStyleForAllMatched(selectedObject)"
              density="compact"
              hide-details
              color="primary"
              class="alternate-svg-switch mt-0 text-primary"
            >
              <template v-slot:label>
                <span
                  class="text-primary text-uppercase fontsize-12 body-font"
                  >{{ isStyleApplied ? "LINKED" : "UNLINKED" }}</span
                >
              </template>
            </v-switch>
          </v-col>
          <v-col cols="12" v-if="isStyleDisabled">
            <p class="text-red fontsize-14 body-font">
              This is a styled element. To change it we recommend:
              <br />
              - going back to {{ firstPartName }} and make adjustments there
              <br />
              - OR you can Unlink from style and make your changes for this item
              only
            </p>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="6">
            <h4
              class="text-uppercase d-inline-block fontsize-12 body-font text-primary"
            >
              Motif:
            </h4>
          </v-col>
          <v-col cols="6" class="text-right">
            <h4
              class="text-uppercase d-block regular-font fontcolor-grey-darken text-truncate"
            >
              {{ selectedDesignName }}
            </h4>
          </v-col>
        </v-row>
        <v-row v-if="motifsLength" class="px-3">
          <v-col cols="3" class="px-0">
            <div
              @click="removeSelectedMotifs()"
              class="motifs-library square-danger-border cursor-pointer d-flex align-center justify-center"
            >
              <v-avatar class="motifs-image cursor-pointer ma-1">
                <v-icon
                  :size="$vuetify.display.mdAndDown ? 60 : 80"
                  class="text-red"
                  >mdi-window-close</v-icon
                >
              </v-avatar>
            </div>
          </v-col>
          <v-col
            cols="3"
            align="center"
            class="px-0"
            v-for="(items, index) in motifs"
            v-bind:key="index"
          >
            <div
              v-if="items.url"
              @click="setSelectedDesign(items, index)"
              v-bind:class="{
                'productpage-shape-border': items.name == selectedDesignName,
              }"
              class="motifs-library cursor-pointer d-flex align-center justify-center"
            >
              <v-avatar class="motifs-image">
                <v-img :src="items.url" />
              </v-avatar>
            </div>
          </v-col>
        </v-row>
        <v-col cols="12" class="px-3 py-0" v-if="motifsLength === 0">
          <p class="text-red py-2 mb-0" v-if="motifsLength === 0">
            Motifs are not present to change
          </p>
        </v-col>
        <div class="productpage-hr-line my-4 ml-n7 mr-n7"></div>
        <v-row no-gutters class="py-2">
          <v-col>
            <h4 class="text-uppercase fontsize-12 body-font text-primary">
              color:
            </h4>
          </v-col>
          <v-col v-if="elementsProperty.printOptions.length">
            <h4
              v-if="motifsLength"
              class="text-uppercase body-font fontcolor-grey-darken float-right"
            >
              {{ selectedColorName }}
            </h4>
          </v-col>
        </v-row>
        <v-row
          class="pr-1 pb-5"
          v-if="
            motifsLength &&
            elementsProperty.printOptions.length &&
            !isStyleDisabled
          "
        >
          <ColorComponent
            class="mx-2"
            :key="selectedColor"
            v-bind:colorCode="selectedColor"
            :printMethod="elementsProperty.selectedPrintMethod"
            @clicked="setColor"
            :colorName="selectedColorName"
          ></ColorComponent>
        </v-row>
        <v-row v-else-if="isStyleDisabled">
          <v-col cols="12" class="pa-0 pl-3">
            <p class="text-red mb-3">No color options available.</p>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="pa-0 pl-3">
            <p class="text-red mb-3">No color is present to change.</p>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- mobile device view  -->
      <v-card-text v-if="isMobileDevice" class="pb-0">
        <v-row>
          <v-col cols="12" class="py-0" v-if="printLength">
            <span
              class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
              >Print Process :</span
            >
            <v-select
              v-model="elementsProperty.selectedPrintMethod"
              :items="elementsProperty.printOptions"
              @update:modelValue="setPrintMethod()"
              hide-details
              density="compact"
              flat
              variant="solo"
              elevation="0"
              item-title="text"
              item-value="text"
              class="mt-0 mb-3 overflow-btn-icon primary-outline select-change-icon productpage-select-input rounded-0 input-select-primary-border input-select-border-radius-0"
              ref="printMethod"
              menu-icon="mdi-plus"
              :disabled="isStyleDisabled"
            >
              <template v-slot:selection="{ item }">
                <span
                  class="ml-3 text-primary d-block text-truncate fontsize-12 regular-font text-uppercase"
                  >{{ item && item.raw ? item.raw.text : "" }}</span
                >
              </template>
              <template v-slot:item="{ props, item }">
                <v-list-item
                  :class="
                    elementsProperty.selectedPrintMethod == item.raw.text
                      ? 'productpage-part-select-menu--active'
                      : ''
                  "
                >
                  <div class="d-flex justify-space-between">
                    <v-list-item-title
                      v-bind="props"
                      class="d-block text-truncate fontsize-12 regular-font text-primary text-uppercase"
                      >{{
                        item && item.raw ? item.raw.text : ""
                      }}</v-list-item-title
                    >
                  </div>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col
            cols="12"
            class="pt-4 pl-3"
            v-if="elementsProperty && elementsProperty.showStyleControl"
          >
            <span
              class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
              >Style :</span
            >
            <v-switch
              v-model="isStyleApplied"
              @update:modelValue="setStyleForAllMatched(selectedObject)"
              density="compact"
              hide-details
              color="primary"
              class="alternate-svg-switch mt-0 text-primary"
            >
              <template v-slot:label>
                <span
                  class="text-primary text-uppercase fontsize-12 body-font"
                  >{{ isStyleApplied ? "LINKED" : "UNLINKED" }}</span
                >
              </template>
            </v-switch>
          </v-col>
          <v-col cols="12" v-if="isStyleDisabled">
            <p class="text-red fontsize-14 body-font">
              This is a styled element. To change it we recommend:
              <br />
              - going back to {{ firstPartName }} and make adjustments there
              <br />
              - OR you can Unlink from style and make your changes for this item
              only
            </p>
          </v-col>
          <v-col class="py-0" v-if="motifsLength">
            <v-select
              v-model="selectedDesign"
              :items="motifs"
              hide-details
              density="compact"
              flat
              variant="solo"
              elevation="0"
              @update:modelValue="setSelectedDesign()"
              return-object
              no-data-text="No Motifs Availables"
              class="mt-0 mb-3 overflow-btn-icon primary-outline select-change-icon productpage-select-input rounded-0 input-select-primary-border input-select-border-radius-0"
              ref="motifsBtn"
              append-inner-icon="mdi-plus"
              :menu-props="{ class: 'motify-mb-section' }"
            >
              <template v-slot:selection="{ item }">
                <span
                  class="ml-3 text-right text-truncate text-primary fontsize-12 regular-font text-uppercase"
                  >{{ item.title }}</span
                >
              </template>
              <template v-slot:item="{ props, item }">
                <v-list-item density="compact">
                  <v-avatar density="compact">
                    <v-img :src="item.raw.url" width="50" height="40"></v-img>
                  </v-avatar>
                  <v-list-item-title
                    v-bind="props"
                    class="text-uppercase float-right text-truncate text-primary fontsize-12 regular-font text-primary"
                    >{{
                      item && item.raw ? item.raw.name : ""
                    }}</v-list-item-title
                  >
                  <span class="productpage-hr-line-102px"></span>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" v-if="motifsLength === 0 || printLength === 0">
            <p class="text-red" v-if="motifsLength === 0">
              Motifs are not present to change
            </p>
            <p class="text-red" v-if="printLength === 0">
              Print methods are not present to change
            </p>
          </v-col>
        </v-row>
        <!-- <div class="productpage-hr-line mt-3 ml-n4 mr-n4"></div> -->
        <v-row
          class="pr-1"
          no-gutters
          v-if="
            motifsLength &&
            elementsProperty.printOptions.length &&
            !isStyleDisabled
          "
        >
          <v-col cols="2" class="d-flex align-center">
            <span class="text-uppercase regular-font fontsize-12 text-primary"
              >color:</span
            >
          </v-col>
          <v-col cols="10" class="d-flex align-center max-width-70 py-2">
            <ColorComponent
              class="mx-1"
              :key="selectedColor"
              v-bind:colorCode="selectedColor"
              :colorName="selectedColorName"
              :printMethod="elementsProperty.selectedPrintMethod"
              @clicked="setColor"
            ></ColorComponent>
          </v-col>
        </v-row>
        <v-row v-else-if="isStyleDisabled">
          <v-col cols="12" class="pb-0">
            <p class="text-red mb-3">No color options available.</p>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="pb-0">
            <p class="text-red mb-3">No color is present to change.</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import ColorComponent from "@/components/personalize/product-detail/ColorComponent.vue";
export default {
  name: "ElementsComponent",
  props: [
    "elementsProperty",
    "selectedObject",
    "isStyleDisabled",
    "firstPartName",
  ],
  components: { ColorComponent },
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    selectedPattern: "executive",
    selectedWeight: "",
    selectedPrintMethod: "",
    selectedFont: "",
    selectedLibrary: "jan photo new york",
    selectedDesign: {},
    selectedType: "MOTIF",
    absolute: true,
    overlay: false,
    elementSheetModel: false,
    elementsType: ["GRAPHICS"],
    selectedElementType: "GRAPHICS",
    isStyleApplied: false,
  }),
  computed: {
    motifs() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.selectedDesign = this.elementsProperty.selectedMotifsName;
      return this.elementsProperty.motifs.filter((obj) => {
        if (obj.url) return obj;
      });
    },
    motifsLength() {
      return this.motifs.length;
    },
    selectedDesignName: {
      get: function () {
        return this.elementsProperty.selectedMotifsName;
      },
      set: function (newMotif) {
        return (this.elementsProperty.selectedMotifsName = newMotif);
      },
    },
    selectedColor: {
      get: function () {
        return this.elementsProperty.selectedColor;
      },
      set: function (newColor) {
        return (this.elementsProperty.selectedColor = newColor);
      },
    },
    selectedColorName: {
      get: function () {
        return this.elementsProperty.selectedColorName;
      },
      set: function (newColorName) {
        return (this.elementsProperty.selectedColorName = newColorName);
      },
    },
    printLength() {
      return this.elementsProperty.printOptions.length;
    },
    isMobileDevice() {
      return this.$vuetify.display.xs ? true : false;
    },
    type() {
      return this.$store.state.productList.typesMotif;
    },
    patterns() {
      return this.$store.state.productList.patterns;
    },
    library() {
      return this.$store.state.productList.library;
    },
  },
  watch: {
    elementsProperty() {
      this.selectedDesign = this.elementsProperty.selectedMotifsName;
      this.isStyleApplied = this.elementsProperty.dataIsStyleLinked;
    },
  },
  methods: {
    setStyleForAllMatched(selectedObj) {
      this.$emit("setStyleForAllMatched", {
        currentObject: selectedObj,
        isStyleApplied: this.isStyleApplied,
      });
    },
    setPrintMethod() {
      this.$emit(
        "changePrintMethod",
        this.elementsProperty.selectedPrintMethod
      );
    },
    setDrawer() {
      this.$emit("change", true);
    },
    setSelectedLibrary(items) {
      this.selectedLibrary = items;
    },
    setSelectedDesign(items) {
      if (items) {
        this.selectedDesignName = items.name;
        this.$emit("clicked", items);
      } else {
        this.$emit("clicked", this.selectedDesign);
      }
    },
    setSelectedPattern(items) {
      this.selectedPattern = items;
    },
    setColor(color) {
      this.selectedColor = color.colorHex;
      this.selectedColorName = color.colorName;
      this.$emit("changeMotifColor", color);
    },
    removeSelectedMotifs() {
      this.selectedColor = "";
      this.selectedColorName = "";
      this.selectedDesignName = "";
      this.$emit("removeSelectedMotif");
    },
    setType() {},
  },
  created() {
    this.isStyleApplied = this.elementsProperty.dataIsStyleLinked;
  },
};
</script>
<style>
.motify-mb-section
  > .v-overlay__content.v-select__content
  > .v-list.v-theme--light
  v-list--density-default
  > .v-list-item--one-line {
  display: inline !important;
}
</style>
<style src="./ElementsComponent.scss" lang="scss" />
