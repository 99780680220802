<template>
  <div :class="$vuetify.display.smAndUp ? 'h-100' : ''">

  <v-overlay :model-value="isPageLoader" scrim="#9d9a99" persistent  contained class="align-center justify-center">

      <v-container>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              size="80"
              color="primary"
              align="center"
              justify="center"
            ></v-progress-circular>
          </v-col>
          <v-col cols="12" class="text-center">
             <p class="
                    text-uppercase
                    fontsize-16
                    body
                    ls-n01
                    text-primary
                    mb-0
                  "
                >
                 We are building product for you, do not refresh page ...
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
    <v-container fluid class="h-100 px-0 py-0" v-resize="resize">
      <v-row
        class="h-100 d-flex flex-column-reverse flex-sm-row"
        :class="designerModeFlag ? 'pt-12' : 'pt-12'"
      >
        <v-col cols="12" sm="6" md="8" class="pb-4 pb-sm-0">
          <v-row>
            <v-col cols="12" class="py-0">
              <p
                class="text-uppercase fontsize-16 regular-font text-primary mb-0"
                v-if="productOne"
              >
                {{ productOne.name }}
              </p>
            </v-col>
            <v-container
              class="pb-0 max-height-style"
              :style="
                !isMobileDevice
                  ? `height:` +
                    imagePanelHeight +
                    `px; overflow-y:auto; overflow-x:hidden;`
                  : ''
              "
            >
              <v-row class="mx-sm-4">
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  xl="3"
                  v-for="(part, index) in list"
                  :key="index"
                  class="pa-2 mt-2"
                >
                  <div class="summary-border pa-3">
                    <v-img
                      :src="
                        part.imageFailed || !part.thumbnailUrl
                          ? defaultUrl
                          : part.thumbnailUrl
                      "
                      :lazy-src="defaultUrl"
                      width="100%"
                      height="auto"
                      aspect-ratio="1"
                      @click.stop="
                        !userSelectedParts.includes(part.id)
                          ? addSelectedPart(part.id)
                          : deSelectPart(part.id)
                      "
                    >
                      
                      <v-overlay
                        :absolute="true"
                        color="white"
                        contained
                        persistent
                        scrim="white"
                        class="part-image"
                        :model-value="!userSelectedParts.includes(part.id)"
                      >
                        <v-btn 
                          v-if="!userSelectedParts.includes(part.id)"
                          variant="plain"
                          :ripple="false"
                          icon="mdi-plus" 
                          size="x-small"
                          color="primary"
                          @click.stop="addSelectedPart(part.id)"
                        ></v-btn>
                     
                      </v-overlay>
                      <div class="text-right">
                        <v-btn 
                          v-if="userSelectedParts.includes(part.id)"
                          variant="plain"
                          :ripple="false"
                          icon="mdi-minus" 
                          size="x-small"
                          color="primary"
                          @click.stop="deSelectPart(part.id)"
                        ></v-btn>
                        
                      </div>
                    </v-img>
                    <p
                      class="
                        text-primary
                        pt-2
                        mb-0
                        body-font
                        fontsize-12
                        text-uppercase
                        text-center
                      "
                    >
                      {{ part.name }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="mt-4 pb-0" ref="sideBar">
          <v-row class="d-block align-start px-3">
            <v-card tile flat outlined class="productpage-stylebar-border">
              <v-row class="px-7 py-3">
                <v-col class>
                  <p
                    class="text-uppercase text-primary mb-0 fontsize-16 regular-font float-left"
                  >
                    Suite Product Selector
                  </p>
                </v-col>
              </v-row>
              <div color="primary" class="mb-0 productpage-hr-line"></div>
             
              <v-row
                class="max-height-style"
              >
                <v-col cols="12">
                  <v-card flat>
                    <v-card-text class="pt-0 fontsize-12 regular-font">
                      <v-row>
                        <v-col
                          class="d-flex justify-space-between px-7 pt-5"
                          cols="12"
                        >
                          <p class="text-uppercase text-primary mb-0">
                            Products :
                          </p>
                          <p class="text-uppercase text-primary mb-0">
                            Selected
                          </p>
                        </v-col>
                        <v-col class="pt-0 px-7"  cols="12">
                          <v-list class="ml-7 mr-3">
                            <v-list-item
                              v-for="(item, index) in list"
                              :key="index"
                              class="px-0"
                              min-height="34px"
                            >
                              <span
                                class="text-primary text-uppercase mb-0 mt-1 pt-2"
                                >{{ item.name }}</span
                              >
                              <template v-slot:append>
                                 <v-checkbox
                                  v-model="userSelectedParts"
                                  class="checkbox-height"
                                  hide-details
                                  true-icon="mdi-close-box"
                                  :value="item.id"
                                  color="primary"
                                  density="compact"
                                  
                                ></v-checkbox>
                              </template>
                             
                            </v-list-item>
                          </v-list>
                        
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row
            class="d-block px-3 pb-3 d-flex align-end"
            ref="sideBarBottomDiv"
          >
            <v-col class="pa-0">
              <div v-if="!isMobileDevice">
                <p color="primary" class="my-3 productpage-hr-line"></p>
               

                <v-row class="mb-2">
                  <v-col class="pb-0">
                    <p
                      class="text-uppercase text-primary fontsize-12 regular-font mb-0 float-left"
                    >
                      order processing time
                    </p>
                  </v-col>
                  <v-col class="pb-0">
                    <p
                      class="text-uppercase fontcolor-grey-darken mb-0 fontsize-12 regular-font float-right"
                    >
                      {{ processingTime }}
                    </p>
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col cols="6" class="py-0 pr-0">
                    <v-btn
                      block
                      v-if="userSelectedParts.length > 0"
                      :size="$vuetify.display.mdAndUp ? 'x-large' :'small'"
                      class="text-uppercase ls-0 body"
                      rounded="0"
                       variant='outlined'
                       
                      color="primary"
                      dark
                      @click="deSelectAllParts()"
                      >DE-SELECT All</v-btn
                    >
                    <v-btn
                      block
                      v-else
                      :size="$vuetify.display.mdAndUp ? 'x-large' :'small'"
                      class="text-uppercase ls-0 body"
                      rounded="0"
                      variant='outlined'
                       
                      color="primary"
                      dark
                      @click="selectAllParts()"
                      >SELECT All</v-btn
                    >
                  </v-col>
                  <v-col cols="6" class="py-0 pl-0">
                    <v-btn
                      block
                      :size="$vuetify.display.mdAndUp ? 'x-large' :'small'"
                      class="text-uppercase ls-0 body"
                      rounded="0"
                       
                      :disabled="isPersonalizeDisable || !userSelectedParts.length || productPartList <= 2"
                      color="primary"
                      @click.once="goToPersonalized()"
                      >PERSONALIZE</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div v-if="isMobileDevice">
        <p color="primary" class="mt-8 mb-3 productpage-hr-line"></p>
      

        <v-row>
          <v-col class="pb-0">
            <p
              class="text-uppercase text-primary mb-0 fontsize-14 regular-font float-left"
            >
              order processing time
            </p>
          </v-col>
          <v-col class="pb-0">
            <p
              class="text-uppercase fontcolor-grey-darken mb-0 fontsize-14 regular-font float-right"
            >
              {{ processingTime }}
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-5 pb-3">
          <v-col cols="6" class="py-0 pr-0">
            <v-btn
              block
              v-if="userSelectedParts.length > 0"
               :size="$vuetify.display.mdAndUp ? 'x-large' :'small'"
              
              class="text-uppercase ls-0 body pb-1 px-1"
               rounded="0"
               variant='outlined'
              color="primary"
              dark
              @click="deSelectAllParts()"
              >DE-SELECT All</v-btn
            >
            <v-btn
              block
              v-else
              :size="$vuetify.display.mdAndUp ? 'x-large' :'small'"
              
              class="text-uppercase ls-0 body pb-1 px-1"
              tile
              rounded="0"
               variant='outlined'
              color="primary"
              dark
              @click="selectAllParts()"
              >SELECT All</v-btn
            >
          </v-col>
          <v-col cols="6" class="py-0 pl-0">
            <v-btn
              block
              :size="$vuetify.display.mdAndUp ? 'x-large' :'small'"
              class="text-uppercase ls-0 body pb-1 px-1 rounded-0"
              density="default"
              color="primary"
              @click="goToPersonalized()"
              :disabled="isPersonalizeDisable || !userSelectedParts.length || productPartList <= 2"
              >PERSONALIZE</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import { getLable } from "@/services/util.service.js";
import { createHelpers } from "vuex-map-fields";

 const { mapFields: mapProductPartsFields } = createHelpers({
  getterType: "productPartList/getField",
  mutationType: "productPartList/updateField",
});

export default {
  name: "ProductPartsSelectionComponent",

  data() {
    return {
      toolPanelHeight: "",
      setQty: 30,
      processingTime: null,
      selectionPanelHeight: 0,
      imagePanelHeight: 0,
      defaultUrl:
        "https://s3.amazonaws.com/cdn.crane.com/stg-images/cc12011-1_360.webp",
      productSku: null,
      itemId: null,
      userQuantity: null,
      productPartList: [],
      isPageLoader: false,
      isPersonalizeDisable : true
    };
  },
  computed: {
    builderSessionId: function () {
      return this.$route.params.sessionId;
    },
    // magento v1 product details
    sessionProductDetails: function () {
      return this.$store.state.productOne.sessionProductDetails;
    },
    // builder session details
    sessionOneDetails: function () {
      return this.$store.state.sessionOne.one;
    },
    sessionItemList: function () {
      return this.$store.state.sessionItemList.list;
    },    
    categoryId: function () {
      return this.$route.query.categoryId;
    },
    isMobileDevice() {
      // eslint-disable-next-line no-debugger
      // debugger
      console.log(this.$vuetify.display);
      return this.$vuetify.display.xs ? true : false;
    },
    productOne: function () {
      // asset manager product details
      return this.$store.state.designerOne.one;
    },
    cartId: function () {
      return this.$route.params.designerMode;
    },
    designerModeFlag: function () {
      if (this.cartId && this.cartId == "true") return true;
      return false;
    },
    cartItemEdit: function () {
      if (this.cartId && this.cartId !== "true") return true;
      return false;
    },
    productAllDetails: function () {
      // magento v2 product details
      return this.$store.state.productOne.productDetails;
    },
    ...mapProductPartsFields([
      "list",
      "userSelectedParts",
      "inProgress",
      "allProductParts",
    ]),
  },
  watch: {

    productAllDetails: function () {
      if (!this.processingTime) {
        let processTime = this.getCustomAttributeLable(
          this.productAllDetails.customAttribute,
          "processing_time"
        )
          ? this.getCustomAttributeLable(
              this.productAllDetails.customAttribute,
              "processing_time"
            )
          : "Process time : 7 - 8 business days";
        this.processingTime = processTime ? processTime.split(":")[1] : "";
      }
    },
  },
  methods: {
    resize() {
      let headerHeight = 56;
      let sideBarBottomDiv = this.$refs.sideBarBottomDiv
        ? this.$refs.sideBarBottomDiv.clientHeight
        : 0;
      this.selectionPanelHeight =
        window.innerHeight - (headerHeight + sideBarBottomDiv + 120);
      this.imagePanelHeight = window.innerHeight - (headerHeight + 100);
    },
    sideBarPanelHeight: function () {
      return (this.toolPanelHeight = window.innerHeight - 230);
    },
    getCustomAttributeLable(customAttributes, attributeCode) {
      return getLable(customAttributes, attributeCode);
    },
    changeQuantity(qty) {
      this.setQty = Number(this.setQty) + Number(qty);
    },
    checkImageLoadError(index) {
      this.list[index].imageFailed = true;
      this.$store.dispatch("productPartList/setImageFaliedFlag", this.list);
    },
    async goToPersonalized() {
      try {
        this.isPageLoader = true
        if (this.userSelectedParts.length) 
        {
          await this.$store.dispatch("productPartList/setList", []);

          this.isPageLoader = true

          let allPapers = []
          let allOccassions = []
          let allUses = []
          for(let p=0; p<this.productPartList.length; p++){
            let part = this.productPartList[p]
            if(this.userSelectedParts.includes(part.id)){  // && !this.itemId
            // to prevent the duplication for session item
              let isPresent = false
              if(this.sessionItemList.length){
                for(let s=0; s<this.sessionItemList.length; s++){
                  let currentSessionItem = this.sessionItemList[s]
                  if(currentSessionItem && currentSessionItem.productPartId == part.id && currentSessionItem.builderSessionId == this.builderSessionId){
                    isPresent = true
                    break
                  }                    
                }
              }
             // console.log("Part present in current session", isPresent)
              if(!isPresent){
                let sessionItem = {
                  thumbnailUrl: part.thumbnailUrl,
                  svgContent: part.svg,
                  surface: part.surfaceId,
                  productPartId:part.id,
                  surfaceType: 'surface',
                  builderSessionId: this.builderSessionId,
                }

                if(part.assets && part.assets.papers && part.assets.papers.length){
                  allPapers.push(...part.assets.papers)
                }
                allOccassions.push(part.occasion)
                allUses.push(part.use)

                await this.$store.dispatch("sessionItemOne/createItem", { item: sessionItem })
                this.isPageLoader = true

                if(part.alternateSurfaceId){
                  sessionItem.surfaceType = 'alternateSurface'
                  sessionItem.surface = part.alternateSurfaceId
                  sessionItem.svgContent= part.alternateSvg

                  await this.$store.dispatch("sessionItemOne/createItem", { item: sessionItem })
                  this.isPageLoader = true
                } 
              }
            }
          }

          let prevAssets = this.sessionOneDetails.productData.assets
          await this.$store.dispatch("sessionOne/updateOne", {
            sessionId: this.builderSessionId,
            property: {
              productData:{
               ...this.sessionOneDetails.productData,
               selectedPartsList: this.userSelectedParts,
               assets:{
                  ...prevAssets,
                  papers: [...new Set(allPapers)],
                  uses: [...new Set(allUses)],
                  occassions: [...new Set(allOccassions)]
               }
              }
            }
          });
          
        if (this.designerModeFlag) {
          localStorage.setItem(
            "cr_" + this.productSku + "_selectedPartList",
            this.userSelectedParts
          );
          if (this.categoryId)
            this.$router.push({
              name: "ProductPersonalize",
              params: {
                sessionId: this.builderSessionId,
                designerMode: "true"
              },
              query: { categoryId: this.categoryId },
            });
          else
            this.$router.push({
              name: "ProductPersonalize",
              params: {
                sessionId: this.builderSessionId,
                designerMode: "true"
              },
            });
        } else {
          localStorage.setItem(
            "cr_" + this.productSku + "_selectedPartList",
            this.userSelectedParts
          );
          if (this.categoryId)
            this.$router.push({
              name: "ProductPersonalize",
              params: {
                sessionId: this.builderSessionId,
              },
              query: { categoryId: this.categoryId },
            });
          else
            this.$router.push({
              name: "ProductPersonalize",
              params: {
                sessionId: this.builderSessionId,
                quantity: this.userQuantity
              },
            });
        }
        }
      } catch(err){
        this.isPageLoader = false
        console.error("Error while creating session items ",err, {
          sessionId: this.builderSessionId,
          quantity: this.userQuantity
        })
        throw err
      }
      
    },
    deSelectPart(partId) {
      this.$store.dispatch("productPartList/removePartFromList", {
        productId: partId,
      });
    },
    addSelectedPart(partId) {
      this.$store.dispatch("productPartList/addPartToList", {
        productId: partId,
      });
    },
    deSelectAllParts() {
      this.userSelectedParts = [];
    },
    selectAllParts() {
      this.userSelectedParts = this.allProductParts;
    },
  },
  async created() {
    this.isPageLoader = true
    await this.$store.dispatch("sessionOne/fetchOne", {
      sessionId: this.builderSessionId 
    });
    if(this.sessionOneDetails && this.sessionOneDetails.productData){      
      await this.$store.dispatch("sessionItemList/fetchList", {
        sessionId: this.builderSessionId 
      });
      this.productSku = this.sessionOneDetails.productData.sku

      let productId = this.sessionOneDetails.productData.productId
      if(productId){
        await this.$store.dispatch("designerOne/fetchOne", productId);
      } else {
         await this.$store.dispatch("designerOne/fetchOneBySku", this.productSku);
      }
      
      if (this.productOne && this.productOne.id){
        let list = await this.$store.dispatch("productPartList/fetchList", {
            productId: this.productOne.id,
            sku: this.productSku,
          });
          this.productPartList = list
          if (list.length <= 2) {
            await this.goToPersonalized();
          } else {
            this.isPersonalizeDisable = false
          }
          this.isPageLoader = false
      } else
        this.$snotify.error("This product is not available for Personalize", {
          position: "rightTop",
          showProgressBar: false,
          timeout: 3000,
        });
    }
  }
};
</script>
<style scopped>
.white-overlay-color {
  background: white;
}
.part-image.v-overlay--active .v-overlay__scrim {
  opacity: 0.75 !important;
}
.part-image .v-overlay__content {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}
.selection-button.v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before {
  opacity: 0 !important;
}
.selection-button.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0 !important;
}
.selection-button.v-btn:not(.v-btn--round).v-size--default {
  min-width: 34px;
  padding: 0 0;
}
.max-height-80 {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none !important;
}
.max-height-80::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.max-height-20 {
  max-height: 20vh;
}
.checkbox-selection .v-input--selection-controls__ripple {
  opacity: 0;
  margin: 2px !important;
}
.summary-border{
  border: thin solid #2850da8a !important;
}

/* SCSS File changes */
.productpage-stylebar-border {
  border: thin solid #2850da !important;
}
.max-height-150::-webkit-scrollbar, .finish-tab-height::-webkit-scrollbar, .product-side-tabs-tool-panel::-webkit-scrollbar, .max-height-50::-webkit-scrollbar, .max-height-style::-webkit-scrollbar, .max-height-25::-webkit-scrollbar  {
  width: 0px;
  background: transparent;
}
.white {
    background-color: #fff!important;
    border-color: #fff!important;
}

/* Old */
#viewer {
  width: 100%;
  height: 90vh;
}
.part {
  height: 50px;
  width: 50px;
  border: thin solid #0000001f;
  background-color: #fff !important;
}
.part-border {
  border: thin solid #2850da;
}
.no-background-color {
  background-color: transparent !important;
}

.productpage-mail-icon {
  font-size: 70px !important;
}
.productpage-shape-portrait {
  height: 95px;
  width: 55px;
  border: thin solid #0000001f;
  background-color: #fff !important;
}

.productpage-hr-line {
  border-top: 1px solid #2850da;
  content: "";
  display: block;
}
.productpage-shapes:hover {
  border: thin solid #2850da !important;
}
.productpage-card-border.v-card.v-card--outlined {
  border: thin solid #2850da !important;
}
.productpage-shape-text-box {
  min-height: 30px;
  min-width: 95px;
  border: thin solid #0000001f;
  text-align: center;
  vertical-align: middle;
}
.productpage-shape-text-box:hover {
  border: thin solid #2850da;
}
.productpage-shape-line {
  min-height: 2px;
  min-width: 80px;
  border: thin solid #000000;
}
.productpage-shape-line:hover {
  border: thin solid #2850da;
}
.productpage-shapes-border {
  border: thin solid #2850da;
}
.productpage-color-cricle {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 1px solid #2850da;
}
.productpage-color-cricle:hover {
  border: 1px solid #2850da;
}
.productpage-btn-underline {
  border-bottom: 3px solid #2850da !important;
}
.productpage-background-pattern {
  background-color: #C7BEAC;
}
.productpage-shape-library {
  height: 90px;
  width: 90px;
  border: thin solid #0000001f;
}
.productpage-corners-square {
  height: 65px;
  width: 65px;
  border-top: thin solid #0000001f;
  border-right: thin solid #0000001f;
}
.productpage-corners-rounded20 {
  height: 65px;
  width: 65px;
  border-top-right-radius: 20%;
  border-top: thin solid #0000001f;
  border-right: thin solid #0000001f;
}
.productpage-corners-rounded40 {
  height: 65px;
  width: 65px;
  border-top-right-radius: 40%;
  border-top: thin solid #0000001f;
  border-right: thin solid #0000001f;
}
.productpage-corners-rounded60 {
  height: 65px;
  width: 65px;
  border-top-right-radius: 60%;
  border-top: thin solid #0000001f;
  border-right: thin solid #0000001f;
}
.productpage-shape-library:hover {
  border: thin solid #2850da;
}
.productpage-shape-border {
  border: 1px solid #2850da !important;
}
.productpage-flip-frame {
  position: relative;
  background-color: #ffffff;
  display: inline-block;
  text-align: center;
  transition: transform 1s;
  transform-style: preserve-3d;
}
.flipped {
  transform: rotateY(360deg);
}

/* New CSS for vue 3 */
.checkbox-height {
  height: 34px !important;
}
.checkbox-height > .v-input__control > .v-checkbox-btn {
  height: 34px !important;
}
</style>
<style src="./ProductDetailComponent.scss" lang="scss" scopped />