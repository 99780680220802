<template>
  <div class="hide-overflow-x">
    <v-row
      v-if="!isMobileDevice || monogramFlag"
      class="pb-5"
      :key="printMethod"
    >
      <div v-if="!newColors.length">
        <v-col class="pb-0 pr-0">
          <p class="mb-0 mr-3 ml-1 text-red">
            Colors are not present to change
          </p>
        </v-col>
      </div>
      <div v-else v-for="(color, index) in newColors" v-bind:key="index">
        <!-- desktop view -->
        <v-col
          class="pb-1 pr-0"
          v-if="color.colorHex"
          :class="newColors.length < 2 ? 'pl-4 pb-1 pr-3' : ''"
        >
          <v-tooltip location="bottom" :text="color.colorName">
            <template v-slot:activator="{ props }">
              <div
                v-bind="props"
                class="color-circle d-flex justify-center align-center cursor-pointer"
                v-bind:class="{
                  'color-circle-border': color.colorName == selectedColorName,
                }"
              >
                <div
                  :key="printMethod"
                  class="productpage-color-cricle"
                  :style="'background-color:' + color.colorHex"
                  @click="setColor(color, index)"
                ></div>
              </div>
            </template>
          </v-tooltip>
        </v-col>
      </div>
    </v-row>
    <div v-else :key="printMethod" class="d-inline d-flex flex-row max-width-90">
      <!-- mobile view -->
      <div v-if="!newColors.length">
        <v-col class="pb-2 pr-0">
          <p class="mb-0 mr-3 ml-1 text-red">
            Colors are not present to change
          </p>
        </v-col>
      </div>
      <span
        class="px-1 d-flex flex-column justify-center"
        v-else
        v-for="(color, index) in newColors"
        v-bind:key="index"
      >
        <div
          v-if="color.colorHex"
          class="color-circle d-flex justify-center align-center cursor-pointer"
          v-bind:class="{
            'color-circle-border': color.colorName == selectedColorName,
          }"
        >
          <div
            :key="printMethod"
            class="productpage-color-cricle"
            :style="'background-color:' + color.colorHex"
            @click="setColor(color, index)"
          ></div>
        </div>
        <p
          class="mb-0 text-uppercase regular-font fontsize-8 text-center text-truncate"
        >
          {{ color.colorName }}
        </p>
      </span>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import { processDetails } from "@/services/util.service.js";
export default {
  name: "ColorComponent",
  components: {},
  props: ["colorCode", "printMethod", "colorName", "monogramFlag"],
  data: () => ({
    newColors: [],
  }),
  computed: {
    selectedColor: {
      get: function () {
        return this.colorCode;
      },
      set: function () {},
    },
    selectedColorName: {
      get: function () {
        console.log(this.colorName);
        return this.colorName;
      },
      set: function () {},
    },
    selectedPrintMethod: {
      get: function () {
        console.log(this.printMethod);
        return this.printMethod;
      },
      set: function () {},
    },
    getColors() {
      return this.$store.state.productOne.colorList;
    },
    isMobileDevice() {
      return this.$vuetify.display.xs ? true : false;
    },
  },
  watch: {
    printMethod: {
      immediate: true,
      handler() {
        this.modifyColorObject();

        let color = this.newColors.filter((obj) => {
          if (obj.name == this.selectedColorName) return obj;
        });
        if (color && color.length) this.setColor(color[0]);
        else this.setDefualtColor();
      },
    },
  },
  methods: {
    setColor(color) {
      // if color is present in selected print method
      if (color && color.colorHex) {
        this.selectedColor = color.colorHex;
        this.$emit("clicked", color);
      } else {
        // if selected color is not present in selected print method then setting default as [0]
        if (this.newColors && this.newColors.length) {
          this.selectedColor = this.newColors[0].colorHex;
          this.$emit("clicked", this.newColors[0]);
        }
      }
    },
    setDefualtColor() {
      if (this.newColors && this.newColors.length) {
        let flag = this.newColors.filter((obj) => {
          if (obj.name == this.selectedColorName) return obj;
        });
      }
    },
    async modifyColorObject() {
      let printShortName = this.getPrintShortName(
        this.printMethod
      ).toLowerCase();
      await this.$store.dispatch("productOne/filterColorsByPrintName", {
        printShortName,
      });
      return this.getColors;
    },
    getPrintShortName(printMethod) {
      let selectedProcess = processDetails.filter((obj) => {
        if (obj.name == printMethod) return obj;
      });
      return selectedProcess[0].shortName.toLowerCase();
    },
  },
  async created() {
    this.newColors = await this.modifyColorObject();
    this.setDefualtColor();
  },
};
</script>
<style src="./ColorComponent.scss" lang="scss" scoped />
