<template>
  <div>
    <v-overlay :model-value="inProgress" scrim="#9d9a99" persistent class="align-center justify-center">
      <!-- inProgress || -->
      <v-container>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              size="80"
              color="primary"
            ></v-progress-circular>
            <p class="
                    text-captalize
                    fontsize-16
                    body
                    ls-n01
                    text-white
                    mb-0
                  "
                >Please wait, image upload is in progress</p>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
    <v-card tile flat>
      <v-card-text class="px-0 px-sm-7 pb-0 py-0">
        <input
          v-show="false"
          ref="imageUpload"
          type="file"
          @change="saveUploadImage"
          accept="image/*"
        />
        <v-tabs
          fixed-tabs
          v-model="imagesType"
          class="mt-2 mt-sm-4"
          :height="35"
          hide-slider
        >
          <v-tab
            class="productpage-tab-slider text-primary text-uppercase fontsize-12 regular-font ls-0 px-0 rounded-0"
            value="upload"
            >UPLOAD</v-tab
          >
          <v-tab
            class="productpage-tab-slider text-primary text-uppercase fontsize-12 regular-font ls-n008 px-0 rounded-0 border-left-none"
            value="library"
            >LIBRARY</v-tab
          >
        </v-tabs>
        <v-window touchless v-model="imagesType">
          <v-window-item :value="`upload`" class="px-3">
            <v-row>
              <v-col cols="12" class="px-4 px-sm-0">
               
                <v-row>
                  <v-col class="pb-0">
                   
                    <v-card color="#CCB7FF" flat class="my-7">
                      <v-card-text
                        id="dropzone"
                        ref="dzone"
                        title="Click to grap a file from your PC!"
                        width="100%"
                        style="cursor: pointer"
                        height="130"
                        class="pa-2"
                        @drop="saveUploadImage"
                        @dragover="dragOverHandler"
                        @click="$refs.imageUpload.click()"
                      >
                        <v-row justify="center" no-gutters>
                          <v-icon
                            v-if="!dragover"
                            color="primary"
                            size="75"
                            >mdi-cloud-upload-outline</v-icon
                          >
                          <v-icon
                            v-if="dragover"
                            color="primary"
                            size="75"
                            >mdi-book-plus</v-icon
                          >
                        </v-row>
                        <v-row justify="center" no-gutters>
                          <span class="h4 text-primary body-font pb-3"
                            >Drag'n drop or click to upload file!</span
                          >
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="`library`" class="px-3">
            <v-row>
              <v-col cols="12" class="px-4 px-sm-0">
                <v-row no-gutters>
                  <v-col cols="12" sm="8" class="py-2" v-if="printLength">
                    <span
                      class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
                      >Print Process :</span>
                    <v-select
                      v-model="imagesProperty.selectedPrintMethod"
                      :items="imagesProperty.printOptions"
                      @update:modelValue="setPrintMethod()"
                      class="
                        mt-0 
                        overflow-btn-icon 
                        productpage-select-input
                        select-change-icon
                        rounded-0
                        input-select-primary-border
                        input-select-border-radius-0
                      "
                      hide-details
                      density="compact"
                      flat
                      variant="solo"
                      elevation="0"
                      ref="printMethod"
                      menu-icon="mdi-plus"
                      item-title="text"
                      item-value="text"
                      :disabled="isStyleDisabled"
                    >
                      <template v-slot:selection="{ item }">
                        <span
                          class="
                            ml-3 
                            text-primary
                            fontsize-12
                            regular-font
                            text-uppercase
                            d-block 
                            text-truncate 
                            cursor-pointer
                          "
                        >
                          {{ item && item.raw ? item.raw.text : '' }} 
                        </span>
                      </template>
                      <template v-slot:item="{ props, item }">
                        <v-list-item class="cursor-pointer" :class="imagesProperty.selectedPrintMethod == item.raw.text ? 'productpage-part-select-menu--active' : ''">
                          <div class="d-flex justify-space-between">
                            <v-list-item-title
                              v-bind="props"
                              class="d-block text-truncate fontsize-12 regular-font text-primary text-uppercase"
                              >{{ item && item.raw ? item.raw.text : ''}}</v-list-item-title
                            >
                          </div>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-3 pl-3" v-if="imagesProperty && imagesProperty.showStyleControl">
                    <span
                      class="text-uppercase regular-font fontsize-12 text-primary d-inline-block"
                      >Style :</span>
                    <v-switch
                      v-model="isStyleApplied"
                      @update:modelValue="setStyleForAllMatched(selectedObject)"
                      density="compact"
                      hide-details
                      color="primary"
                      class="alternate-svg-switch  mt-0 text-primary"
                    >
                      <template v-slot:label>
                        <span class="text-primary text-uppercase fontsize-12 body-font">{{ isStyleApplied ? 'LINKED' : 'UNLINKED'}}</span>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="12" v-if="isStyleDisabled">
                    <p class="text-red fontsize-14 body-font">
                      This is a styled element. To change it we recommend:
                      <br>
                      - going back to {{firstPartName}} and make adjustments there
                      <br>
                      - OR you can Unlink from style and make your changes for this item only
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-row no-gutters class="d-flex justify-start">
                      <p
                        class="text-uppercase fontsize-12 mb-0 text-truncate d-inline-block body-font text-primary"
                      >
                        Your Stored Photos:
                      </p>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row no-gutters class="d-flex justify-end">
                      <p
                        class="text-uppercase mb-0 fontsize-12 regular-font text-truncate d-inline-block fontcolor-grey-darken"
                      >
                        {{ selectedImgName }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- desktop view -->
                <v-row
                  no-gutters
                  class="images-tab-height"
                  v-if="!isMobileDevice && userImagesList.length"
                >
                  <v-col cols="3" sm="3" class="pb-2 px-0">
                    <div
                      @click="removeUploadedImage()"
                      class="productpage-image-library square-danger-border cursor-pointer d-flex align-center justify-center"
                    >
                      <v-avatar class="cursor-pointer user-image">
                        <v-icon :size="80" class="text-red"
                          >mdi-window-close</v-icon
                        >
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col
                    cols="3"
                    sm="3"
                    align="center"
                    class="pb-2 px-0"
                    v-for="(item, index) in userImagesList"
                    v-bind:key="index"
                    @click="editImageInDailog(item)"
                  >
                    <div
                      v-if="item.url"
                      v-bind:class="{
                        'productpage-image-border':
                          item.name == selectedImgName,
                      }"
                      class="productpage-image-library cursor-pointer d-flex align-center"
                    >
                      <v-avatar class="cursor-pointer user-image ma-1">
                        <v-img :src="item.url" max-width="95" max-height="95">
                          <div class="ml-auto mb-auto text-right">
                            <v-icon icon class="text-red" @click.stop="deleteSelectedImage(item.id)">mdi-window-close</v-icon>
                          </div>
                        </v-img>
                      </v-avatar>
                    </div>
                  </v-col>
                </v-row>
                <!-- mobile view -->
                <div
                  class="d-inline d-flex flex-row max-width-90 pt-2"
                  v-if="isMobileDevice && userImagesList.length"
                >
                  <span class="pb-2 px-0 d-inline mr-1">
                    <div
                      @click="removeUploadedImage()"
                      class="productpage-image-library square-danger-border cursor-pointer d-flex align-center justify-center"
                    >
                      <v-avatar class="cursor-pointer user-image">
                        <v-icon :size="80" class="text-red"
                          >mdi-window-close</v-icon
                        >
                      </v-avatar>
                    </div>
                  </span>
                  <span
                    class="pb-2 px-0 d-inline mx-1"
                    v-for="(item, index) in userImagesList"
                    v-bind:key="index"
                    @click="editImageInDailog(item)"
                  >
                    <div
                      v-if="item.url"
                      v-bind:class="{
                        'productpage-image-border':
                          item.name == selectedImgName,
                      }"
                      class="productpage-image-library cursor-pointer d-flex align-center"
                    >
                      <v-avatar class="cursor-pointer user-image" size="90">
                        <v-img :src="item.url" width="80">
                          <div class="ml-auto mb-auto text-right">
                            <v-icon icon class="text-red" @click.stop="deleteSelectedImage(item.id)">mdi-window-close</v-icon>
                          </div>
                        </v-img>
                      </v-avatar>
                    </div>
                  </span>
                </div>
                <v-col cols="12" class="pa-0" v-if="!userImagesList.length">
                  <p class="text-red mb-0">You have no uploaded photos.</p>
                </v-col>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="imageOperationDailog"
      v-if="imageOperationDailog"
      persistent
      max-width="1920"
      attach
    >
     <!-- max-width="1920" -->
      <v-card tile flat class="all-side-primary-border max-height-80">
        <v-card-title class="py-0 pr-0 pl-7">
          <v-row class="pb-0 mr-0" align="center">
            <v-col cols="10" class="py-4">
              <p
                class="text-uppercase fontsize-12 regular-font ls-n007 line-height-24 text-primary mb-0"
              >
                crane Image studio
              </p>
            </v-col>
            <v-col cols="2" class="d-flex justify-end pa-0">
              <v-btn
                color="primary"
                flat
                variant="outlined"
                :height="40"
                :min-width="40"
                size="small"
                rounded="false"
                @click="closeImageMenu()"
                class="border-right-none border-top-none border-bottom-none rounded-0"
              >
                <v-icon small size="15">mdi mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider class="text-primary opacity-1"></v-divider>
        <v-card-text class="pb-1 pa-3">
          <v-row>
            <v-col cols="12" sm="4" class="pt-3 pb-3 pb-sm-0 mb-5">
              <v-btn-toggle variant="outlined" divided rounded="0" class="py-1 image-btns">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on:click="changeScale(1.5)"
                      v-bind="attrs"
                      v-on="on"
                      class="px-0 px-sm-3"
                    >
                      <v-icon color="purple darken-2">mdi-magnify-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Zoom In</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on:click="changeScale(-1.5)"
                      v-bind="attrs"
                      v-on="on"
                      class="px-0 px-sm-3"
                    >
                      <v-icon color="purple darken-2">mdi-magnify-minus</v-icon>
                    </v-btn>
                  </template>
                  <span>Zoom Out</span>
                </v-tooltip>
              </v-btn-toggle>
              <v-btn-toggle variant="outlined" divided rounded="0" class="py-1 image-btns">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on:click="rotateLeft()"
                      v-bind="attrs"
                      v-on="on"
                      class="px-0 px-sm-3"
                    >
                      <v-icon color="purple darken-2">mdi-rotate-left</v-icon>
                    </v-btn>
                  </template>
                  <span>Rotate Left</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on:click="rotateRight()"
                      v-bind="attrs"
                      v-on="on"
                      class="px-0 px-sm-3"
                    >
                      <v-icon color="purple darken-2">mdi-rotate-right</v-icon>
                    </v-btn>
                  </template>
                  <span>Rotate Right</span>
                </v-tooltip>
              </v-btn-toggle>
              <p
                class="mb-0 mt-1 text-primary text-uppercase fontsize-12 regular-font ls-n007 line-height-24"
              >
                Blur : {{ filter.blur }}px
              </p>
              <v-slider
                v-model="filter.blur"
                class="align-center image-studio-slider"
                @update:modelValue="setFilterForImage('blur')"
                :min="0"
                :max="200"
                :step="1"
                height="20"
                hide-details
                color="primary"
              >
              </v-slider>
              <p
                class="mb-0 mt-1 text-primary text-uppercase fontsize-12 regular-font ls-n007 line-height-24"
              >
                Brightness : {{ filter.brightness }}%
              </p>
              <v-slider
                v-model="filter.brightness"
                class="align-center image-studio-slider"
                @update:modelValue="setFilterForImage('brightness')"
                :min="0"
                :max="200"
                :step="1"
                height="20"
                hide-details
                color="primary"
              >
              </v-slider>
              <p
                class="mb-0 mt-1 text-primary text-uppercase fontsize-12 regular-font ls-n007 line-height-24"
              >
                Contrast: {{ filter.contrast }}%
              </p>
              <v-slider
                v-model="filter.contrast"
                class="align-center image-studio-slider"
                @update:modelValue="setFilterForImage('contrast')"
                :min="0"
                :max="200"
                :step="1"
                height="20"
                hide-details
                color="primary"
              >
              </v-slider>
              <p
                class="mb-0 mt-1 text-primary text-uppercase fontsize-12 regular-font ls-n007 line-height-24"
              >
                Gray Scale : {{ filter.grayScale }}%
              </p>
              <v-slider
                v-model="filter.grayScale"
                class="align-center image-studio-slider"
                @update:modelValue="setFilterForImage('grayScale')"
                :min="0"
                :max="100"
                :step="1"
                height="20"
                hide-details
                color="primary"
              >
              </v-slider>
              <p
                class="mb-0 mt-1 text-primary text-uppercase fontsize-12 regular-font ls-n007 line-height-24"
              >
                Hue Rotate : {{ filter.hueRotate }}Deg
              </p>
              <v-slider
                v-model="filter.hueRotate"
                class="align-center image-studio-slider"
                @update:modelValue="setFilterForImage('hueRotate')"
                :min="0"
                :max="200"
                :step="1"
                height="20"
                hide-details
                color="primary"
              >
              </v-slider>
              <p
                class="mb-0 mt-1 text-primary text-uppercase fontsize-12 regular-font ls-n007 line-height-24"
              >
                Invert : {{ filter.invert }}%
              </p>
              <v-slider
                v-model="filter.invert"
                class="align-center image-studio-slider"
                @update:modelValue="setFilterForImage('invert')"
                :min="0"
                :max="100"
                :step="1"
                height="20"
                hide-details
                color="primary"
              >
              </v-slider>
              <p
                class="mb-0 mt-1 text-primary text-uppercase fontsize-12 regular-font ls-n007 line-height-24"
              >
                Opacity : {{ filter.opacity }}%
              </p>
              <v-slider
                v-model="filter.opacity"
                class="align-center image-studio-slider"
                @update:modelValue="setFilterForImage('opacity')"
                :min="0"
                :max="100"
                :step="1"
                height="20"
                hide-details
                color="primary"
              >
              </v-slider>
              <p
                class="mb-0 mt-1 text-primary text-uppercase fontsize-12 regular-font ls-n007 line-height-24"
              >
                Saturate : {{ filter.saturate }}%
              </p>
              <v-slider
                v-model="filter.saturate"
                class="align-center image-studio-slider"
                @update:modelValue="setFilterForImage('saturate')"
                :min="0"
                :max="200"
                :step="1"
                height="20"
                hide-details
                color="primary"
              >
              </v-slider>
              <p
                class="mb-0 mt-1 text-primary text-uppercase fontsize-12 regular-font ls-n007 line-height-24"
              >
                Sepia: {{ filter.sepia }}%
              </p>
              <v-slider
                v-model="filter.sepia"
                class="align-center image-studio-slider"
                @update:modelValue="setFilterForImage('sepia')"
                :min="0"
                :max="100"
                :step="1"
                height="20"
                hide-details
                color="primary"
              >
              </v-slider>
            <v-row no-gutters class="pt-4">
            <v-col class="px-0">
              <v-btn
                variant="flat"
                block
                :height="40"
                @click="closeImageMenu()"
                color="primary"
                rounded="0"
                >CANCEL</v-btn
              >
            </v-col>
            <v-col class="px-0">
              <v-btn
                rounded="0"
                block
                variant="outlined"
                :height="40"
                outlined
                @click="addOnCanvas()"
                color="primary"
                >SAVE</v-btn
              >
            </v-col>
          </v-row>
            </v-col>
            <v-col cols="12" sm="8" class="py-0" :key="refreshImageDailog">
              <vueCropper
                :style="`height: 85% !important;min-height:${imageHeight}px !important;`"
                ref="cropper"
                :canScale="true"
                :img="cropOptions.img"
                :outputSize="cropOptions.size"
                :outputType="cropOptions.outputType"
                :info="true"
                :full="cropOptions.full"
                :canMove="cropOptions.canMove"
                :canMoveBox="cropOptions.canMoveBox"
                :fixedBox="cropOptions.fixedBox"
                :original="cropOptions.original"
                :autoCrop="cropOptions.autoCrop"
                :autoCropWidth="imageWidth"
                :autoCropHeight="imageHeight"
                :centerBox="cropOptions.centerBox"
                :high="cropOptions.high"
                :infoTrue="cropOptions.infoTrue"
                @cropMoving="cropMoving"
                :enlarge="cropOptions.enlarge"
                :mode="cropOptions.mode"
                @img-load="imgLoad"
              ></vueCropper>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmationDialogFlag" origin="left top" hide-overlay persistent max-width="800px" attach>
      <v-card tile flat>
        <v-card-title class="pb-0">
          <v-row no-gutters>
            <v-col cols="11">
              <p
                class="body mt-1 mb-0 fontcolor-black-light title-white-space"
              >{{confirmation.confirmationMessage}}</p>
            </v-col>
            <v-col cols="1" class="text-right">
              <router-link to class="text-decoration-none text-black fontcolor-black-light">
                <div @click="onDeleteDialogCancel()">X</div>
              </router-link>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions class="px-4 pt-0">
          <v-row no-gutters>
            <v-col cols="12">
              <v-btn
                class="text-uppercase ma-2"
                color="primary"
                variant="elevated"
                tile
                flat                        
                @click="onDeleteDialogCancel()"
              >CANCEL</v-btn>
              <v-btn
                class="text-uppercase ma-2"
                color="error"
                variant="outlined"
                tile
                flat
                @click="removeImage()"
              >
                OK
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "imageOne/getField",
  mutationType: "imageOne/updateField",
});
const imageFolderName = import.meta.env.VITE_APP_IMAGE_UPLOAD_FOLDER_NAME;
const s3URL = import.meta.env.VITE_APP_S3_URL;
import { Buffer } from 'buffer';
export default {
  name: "ImagesComponent",
  props: ["selectedImageName", "imagesProperty","selectedObject","isStyleDisabled","firstPartName"],
  data: () => ({
    selectedDesign: {},
    imagesType: "library",
    selectedImageType: "library",
    imageOperationDailog: false,
    imageData: "",
    refreshImageDailog: 0,
    cropOptions: {
      img: "",
      size: 1,
      full: true,
      outputType: "png",
      canMove: true,
      fixed: true,
      fixedBox: true,
      original: false,
      canMoveBox: true,
      autoCrop: true,
      centerBox: false,
      high: true,
      cropData: {},
      enlarge: 1,
      mode: "contain",
    },
    cropOffsetX: "",
    cropOffsetY: "",
    cropImageScale: "",
    selectedImage: "",
    filter: {
      blur: 0,
      brightness: 0,
      contrast: 0,
      grayScale: 0,
      hueRotate: 0,
      invert: 0,
      opacity: 100,
      saturate: 0,
      sepia: 0,
    },
    dragover: false,
    confirmationDialogFlag: false,
    confirmation: {
      confirmationMessage: "",
    },
    selectedImageForDelete:"",
    isStyleApplied: false
  }),
  computed: {
    // builder session details
    sessionOneDetails: function () {
      return this.$store.state.sessionOne.one;
    },
    libraryImageList() {
      return this.$store.state.imageOne.libraryImageList;
    },
    userImagesList() {
      return this.$store.state.imageOne.userImages;
    },
    selectedImgName: {
      get: function () {
        return this.selectedImageName;
      },
      set: function (newName) {
        return newName;
      },
    },
    isMobileDevice() {
      return this.$vuetify.display.xs ? true : false;
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    userId: function () {
      if (this.userAuth && this.$store.state.accountOne.one)
        return this.$store.state.accountOne.one.id;
      return '';
    },
    printLength() {
      return this.imagesProperty.printOptions.length;
    },
    imageHeight() {
      return this.imagesProperty.imageHeight;
    },
    imageWidth() {
      return this.imagesProperty.imageWidth;
    },
    ...mapFields(["one", "one.name", "one.url", "one.ownerId", "inProgress"]),
  },
  watch: {},
  methods: {
    setStyleForAllMatched(selectedObj){
      this.$emit("setStyleForAllMatched", { currentObject: selectedObj, isStyleApplied: this.isStyleApplied })
    },
    onDeleteDialogCancel(){
      this.confirmationDialogFlag = false
    },
    getFilterString() {
      let filterString = "";

      if (this.filter.blur > 0)
        filterString = filterString + ` blur(${this.filter.blur}px)`;

      if (this.filter.brightness > 0)
        filterString = filterString + ` brightness(${this.filter.brightness}%)`;

      if (this.filter.contrast > 0)
        filterString = filterString + ` contrast(${this.filter.contrast}%)`;

      if (this.filter.grayScale > 0)
        filterString = filterString + ` grayscale(${this.filter.grayScale}%)`;

      if (this.filter.hueRotate > 0)
        filterString =
          filterString + ` hue-rotate(${this.filter.hueRotate}deg)`;

      if (this.filter.invert > 0)
        filterString = filterString + ` invert(${this.filter.invert}%)`;

      if (this.filter.opacity > 0)
        filterString = filterString + ` opacity(${this.filter.opacity}%)`;

      if (this.filter.saturate > 0)
        filterString = filterString + ` saturate(${this.filter.saturate}%)`;

      if (this.filter.sepia > 0)
        filterString = filterString + ` sepia(${this.filter.sepia}%)`;

      return filterString;
    },
    setFilterForImage(filterType) {
      let filterString = this.getFilterString(filterType, this.filter);
      this.$refs.cropper.$el.getElementsByClassName(
        "cropper-view-box"
      )[0].lastElementChild.style.filter = filterString;
    },
    setSelectedLibrary(items) {
      this.selectedLibrary = items;
    },
    getBase64Image(blobData) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(blobData);
        reader.onloadend = function () {
          let base64data = reader.result;
          resolve(base64data);
        };
      });
    },
    async editImageInDailog(image) {
      this.inProgress = true;
      this.imageOperationDailog = true;
      this.cropOptions.img = await image.toolsSettings.originalUrl
        ? image.toolsSettings.originalUrl+ "?" + new Date().getTime()
        : image.url+ "?" + new Date().getTime()
      this.selectedImage = image;
      this.filter = Object.assign(
        {},
        image.toolsSettings.filter ? image.toolsSettings.filter : {}
      );
      this.inProgress = false;
    },
    closeImageMenu() {
      this.imageData = "";
      this.refreshImageDailog = !this.refreshImageDailog;
      this.imageOperationDailog = false;
    },
    async addOnCanvas() {
      this.inProgress = true;
    //  this.$store.commit("productOne/inProgress", true);
      this.imageOperationDailog = false;
      this.refreshImageDailog = !this.refreshImageDailog;
      this.cropImageScale = this.$refs.cropper.scale;

      let imgData = null
      await this.$refs.cropper.getCropData(async(data) => {
        imgData = data 
        await this.setSelectedImage(imgData);
        // this.inProgress = false;
      })      
    },
    setPrintMethod() {
      this.$emit("changePrintMethod", this.imagesProperty.selectedPrintMethod);
    },
    async deleteSelectedImage(imageId){
      this.selectedImageForDelete = imageId;
      (this.confirmationDialogFlag = true),
        (this.confirmation = {
          confirmationMessage:
            "Are you sure you would like to remove this photo?",
      });
    },
    async removeImage() {
      this.confirmationDialogFlag = false;
      // if (action.submit) {
       this.ownerId = this.getSessionItemOwner(this.sessionOneDetails)
        await this.$store.dispatch("imageOne/deleteSelectedImage", { imageId: this.selectedImageForDelete });
        await this.$store.dispatch("imageOne/fetchUserImages",{ ownerId: this.ownerId, sku: this.productSku });
     // }
    },
    async setSelectedImage(cropImageData) {
      try {
        let vm = this;
        vm.inProgress = true; 

        let idxDot = vm.selectedImage.url.lastIndexOf(".") + 1;
        let extFile = vm.selectedImage.url
          .substr(idxDot, vm.selectedImage.url.length)
          .toLowerCase();
        vm.name = vm.selectedImage.name;

        if (
          extFile == "jpg" ||
          extFile == "jpeg" ||
          extFile == "png" ||
          extFile == "gif"
        ) {
          vm.name = vm.name.split(" ").join("_");
          let num = Math.floor(Math.random() * 100 + 1);

          let imgName =
            imageFolderName + "images/" + vm.name + "_" + num + "." + extFile;
          vm.name = vm.name + "_" + num;
          let contentType = "";
          if (extFile == "jpg" || extFile == "jpeg") contentType = "image/jpeg";
          else if (extFile == "png") contentType = "image/png";
          else if (extFile == "gif") contentType = "image/gif";

          cropImageData = await new Buffer.from(
            cropImageData.replace(/^data:image\/\w+;base64,/, ""),
            "base64"
          );
          vm.inProgress = true; 
          await vm.$store.dispatch("imageOne/uploadToS3", {
            bucketName: "cdn.crane.com",
            bucketRegion: "us-east-1",
            name: imgName,
            type: contentType,
            data: cropImageData,
          });
          vm.inProgress = true; 
          vm.url = `${s3URL}/${imgName}`;

          vm.ownerId = await this.getSessionItemOwner(this.sessionOneDetails)
          if (vm.ownerId) {
            let data = await vm.$store.dispatch("imageOne/save", {
              toolsSettings: {
                originalUrl: this.selectedImage.toolsSettings
                  ? this.selectedImage.toolsSettings.originalUrl
                  : this.selectedImage.url,
                filter: this.filter,
                crop: {
                  cropOffsetX: this.cropOffsetX,
                  cropOffsetY: this.cropOffsetY,
                  cropImageScale: this.cropImageScale,
                },
              },
            });
            let message = null;
            if (data.new == true) message = "Image Inserted successfully";
            vm.$snotify.success(message, {
              position: "rightTop",
              showProgressBar: false,
            });
            await vm.$store.dispatch("imageOne/fetchUserImages", { ownerId:vm.ownerId, sku: this.productSku });
            if (data.response && data.response.id) {
              vm.inProgress = false;
              vm.imagesType = "library";
              vm.$emit("uploadImage", {
                response: data.response,
                cropOffsetX: vm.cropOffsetX,
                cropOffsetY: vm.cropOffsetY,
                cropImageScale: vm.cropImageScale,
              });
            }
          } else {
            vm.$snotify.error("Please re-login again", {
              position: "rightTop",
              showProgressBar: false,
            });
          }
        } else {
          this.inProgress = false;
          vm.$snotify.error(
            "Please select image file (jpeg, jpg, png, gif) Only",
            {
              position: "rightTop",
              showProgressBar: false,
            }
          );
        }
      } catch (err) {
        this.inProgress = false;
        console.error("Error in setting image on canvas ",err)
        this.$snotify.error(`Cloud communication error, please start new session `, {
            position: "rightTop",
            showProgressBar: false,
        });
        throw err;
      }
    },
    imageRotate(side) {
      this.$emit("imageRotate", side);
    },
    imageZoom(side) {
      this.$emit("imageZoom", side);
    },
    async saveUploadImage(event) {
      try {
        let vm = this;
        let selectedFile = {};
        vm.inProgress = true;
        event.preventDefault();
        if(event.currentTarget){
          for (var i = 0; i < event.currentTarget.files.length; i++) {
            selectedFile = event.currentTarget.files[i];
          }
        }
        let input = event.target;
        if (input&&input.files && input.files[0]) {
          selectedFile = input.files[0];
        }
        if (selectedFile && selectedFile.name) {
         // vm.$emit("pageLoader", true);

          let idxDot = selectedFile.name.lastIndexOf(".") + 1;
          vm.name = selectedFile.name.substr(0, idxDot - 1);

          let extFile = selectedFile.name
            .substr(idxDot, selectedFile.name.length)
            .toLowerCase();
          if (
            extFile == "jpg" ||
            extFile == "jpeg" ||
            extFile == "png" ||
            extFile == "gif"
          ) {
            vm.name = vm.name.split(" ").join("_");
            let imgName = imageFolderName + "images/" + vm.name + "." + extFile;
            let contentType = "";
            if (extFile == "jpg" || extFile == "jpeg")
              contentType = "image/jpeg";
            else if (extFile == "png") contentType = "image/png";
            else if (extFile == "gif") contentType = "image/gif";

            await vm.$store.dispatch("imageOne/uploadToS3", {
              bucketName: "cdn.crane.com",
              bucketRegion: "us-east-1",
              name: imgName,
              type: contentType,
              data: selectedFile,
            });
            vm.url = `${s3URL}/${imgName}`;

            vm.ownerId = this.getSessionItemOwner(this.sessionOneDetails)

            if (vm.ownerId) {
              let data = await vm.$store.dispatch("imageOne/save", {
                toolsSettings: {
                  originalUrl: this.selectedImage.toolsSettings
                    ? this.selectedImage.toolsSettings.originalUrl
                    : this.selectedImage.url,
                  filter: this.filter,
                  crop: {
                    cropOffsetX: this.cropOffsetX,
                    cropOffsetY: this.cropOffsetY,
                    cropImageScale: this.cropImageScale,
                  },
                },
              });
              let message = null;
              if (data.new == true) message = "Image Inserted successfully";
              vm.$snotify.success(message, {
                position: "rightTop",
                showProgressBar: false,
              });
              await vm.$store.dispatch("imageOne/fetchUserImages", { ownerId:vm.ownerId, sku: this.productSku });
              if (data.response && data.response.id) {
                vm.inProgress = false;
                vm.imagesType = "library";
                vm.imageOperationDailog = true;
                vm.cropOptions.img = vm.url+ "?" + new Date().getTime();
                vm.selectedImage = data.response;
              }
            } else {
              vm.inProgress = false;
              vm.$snotify.error("Please re-login again", {
                position: "rightTop",
                showProgressBar: false,
              });
            }
          } else {
            vm.inProgress = false;
            vm.$snotify.error(
              "Please select image file (jpeg, jpg, png, gif) Only",
              {
                position: "rightTop",
                showProgressBar: false,
              }
            );
          }
        }
       // this.inProgress = false;
      } catch (err) {
       this.inProgress = false;
       console.error("Error in uploading image ", err, {  sku:this.productSku });
       this.$snotify.error(`Cloud communication error, please start new session `, {
            position: "rightTop",
            showProgressBar: false,
        });
        throw err;
      }
    },
    removeUploadedImage() {
      this.$emit("removeImage", true);
    },
    setType() {},
    startCrop() {
      // start
      this.crap = true;
      this.$refs.cropper.startCrop();
    },
    stopCrop() {
      //  stop
      this.crap = false;
      this.$refs.cropper.stopCrop();
    },
    clearCrop() {
      // clear
      this.$refs.cropper.clearCrop();
    },
    refreshCrop() {
      // clear
      this.$refs.cropper.refresh();
    },
    changeScale(num) {
      num = num || 1;
      this.cropImageScale = num;
      this.$refs.cropper.changeScale(num);
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    cropMoving(data) {
      this.cropOffsetX = data.axis.x1;
      this.cropOffsetY = data.axis.y1;
      this.cropOptions.cropData = data;
    },
    imgLoad() {
      let vm = this;
      vm.inProgress = true;
      setTimeout(function () {
        let toolsSettings = vm.selectedImage.toolsSettings;
        try {
          vm.$refs.cropper.cropOffsertX = toolsSettings.crop.cropOffsetX
            ? toolsSettings.crop.cropOffsetX
            : vm.$refs.cropper.cropOffsertX;
          vm.$refs.cropper.cropOffsertY = toolsSettings.crop.cropOffsetY
            ? toolsSettings.crop.cropOffsetY
            : vm.$refs.cropper.cropOffsertX;
          vm.$refs.cropper.scale = toolsSettings.crop.cropImageScale
            ? toolsSettings.crop.cropImageScale
            : vm.$refs.cropper.scale;

          let filterString = vm.getFilterString();
          vm.$refs.cropper.$el.getElementsByClassName(
            "cropper-view-box"
          )[0].lastElementChild.style.filter = filterString;
          vm.inProgress = false;
        } catch {
          vm.inProgress = false;
          vm.$refs.cropper.cropOffsertX = vm.cropOffsetX;
          vm.$refs.cropper.cropOffsertY = vm.cropOffsetY;
          vm.$refs.cropper.changeScale(vm.cropImageScale);
        }
      }, 1500);
    },
    dragOverHandler(ev) {
      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();
    },
    getSessionItemOwner(sessionItemObj){
      if(sessionItemObj && sessionItemObj.userData){
        if (this.userAuth) {
          return sessionItemObj.userData.customerId
        } else {
          return sessionItemObj.userData.guestId
        }
      }
      return null
    },
  },
  async created() {
    this.isStyleApplied = this.imagesProperty.dataIsStyleLinked

    if(this.sessionOneDetails && this.sessionOneDetails.productData){
      this.productSku = this.sessionOneDetails.productData.sku
    }
    this.$store.dispatch("imageOne/new");

    this.ownerId = this.getSessionItemOwner(this.sessionOneDetails)
    this.$store.dispatch("imageOne/fetchUserImages", { ownerId: this.ownerId, sku: this.productSku });
  },
};
</script>
<style>
.image-input {
  display: block !important;
}
.image-input > div {
  flex-direction: row !important;
}
.opacity-1 {
  opacity: 1 !important;
}
.image-studio-slider > .v-input__control > .v-slider__container > .v-slider-track {
  height: 2px !important;
}
.image-studio-slider > .v-input__control > .v-slider__container > .v-slider-thumb > .v-slider-thumb__surface {
  height: 12px !important;
  width: 12px !important;
}
.image-studio-slider > .v-input__control > .v-slider__container > .v-slider-thumb > .v-slider-thumb__ripple {
  height: 12px !important;
  width: 12px !important;
}
.image-studio-slider > .v-input__control > .v-slider__container > .v-slider-track > .v-slider-track__background.bg-primary.v-slider-track__background--opacity {
  height: 2px !important;
}
.title-white-space {
  white-space: pre-wrap !important;
}

</style>
<style src="./ImagesComponent.scss" lang="scss"/>
