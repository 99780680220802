<template>
  <ProductDetailComponent />
</template>
<script>
import ProductDetailComponent from "@/components/personalize/product-detail/ProductDetailComponent.vue";
export default {
  name: "ProductPage",
  data() {
    return {
      showMobileComponent: false,
    };
  },
  components: {
    ProductDetailComponent,
  },
};
</script>
<style src="./product.scss" lang="scss" scoped />
