<template>
  <v-container class="py-0 my-0">
    <v-dialog
      v-model="confirmationDialogkey"
      origin="left top"
      hide-overlay
      persistent
      max-width="800px"
      attach
    >
      <v-card tile flat>
        <v-card-title class="pb-0">
          <v-row no-gutters>
            <v-col cols="11">
              <p class="body mt-1 mb-0 fontcolor-black-light">
                {{ confirmationDetails.confirmationMessage }}
              </p>
            </v-col>
            <v-col cols="1" class="text-right">
              <router-link
                to
                class="text-decoration-none black--text fontcolor-black-light"
              >
                <div @click="onAction(false)">X</div>
              </router-link>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions class="px-4 pt-0">
          <v-row no-gutters>
            <v-col cols="12">
              <v-btn
                class="text-uppercase ma-2"
                color="primary"
                tile
                depressed
                @click="onAction(false)"
                >CANCEL</v-btn
              >
              <v-btn
                class="text-uppercase ma-2"
                color="error"
                tile
                depressed
                @click="onAction(true)"
              >
                OK
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: "ConfirmationDialog",
  props: {
    confirmationDialog: {
      type: String,
      default: "",
    },
    confirmationDetails: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      confirmationDialogkey: this.confirmationDialog,
    };
  },
  components: {},
  computed: {},
  methods: {
    onAction: function (action) {
      this.$emit("action-remove", { submit: action });
    },
  },
};
</script>
